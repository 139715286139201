import axios from "axios";
import guo from "../lang/i118n";
import { ElMessage } from "element-plus";
import router from "../router";
//创建axios的一个实例
var instance = axios.create({
  timeout: 20000, //设置超时
});

// 设置post请求头
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.get["Content-Type"] =
  "application/x-www-form-urlencoded";
instance.defaults.headers.Accept = "*/*";

//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    token && (config.headers.Authorization = "Bearer " + token);
    config.headers["Accept-Language"] = guo.global.locale;
    const inv = localStorage.getItem("inv");
    if (inv) {
      config.headers["inv"] = inv;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(
  function (response) {
    if (response.data.code == 200) {
      return response.data.data ? response.data.data : response.data;
    } else if (response.data.code == 508) {
      ElMessage({
        message: response.data.msg ? response.data.msg : response.data.message,
        type: "warning",
      });
      localStorage.removeItem("userInfo")
      localStorage.removeItem("token")
      router.push({ path: "/login", query: router.currentRoute.value });
    } else {
      ElMessage({
        message: response.data.msg ? response.data.msg : response.data.message,
        type: "warning",
      });
    }
  },
  function (error) {
    // 对响应错误做点什么
    console.log("拦截器报错");
    return Promise.reject(error);
  }
);

/**
 * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export default instance;
