module.exports = {
  language: {
    chinese: "Chinois",
    English: "Anglais",
    Italiano: "Italien",
    Español: "Espagnol",
    Français: "Français",
    Deutsch: "Allemand",
  },
  commonData: {
    orderStatus: [
      { value: 0, label: "Non payé" },
      { value: 1, label: "Non payé" },
      { value: 2, label: "En cours de révision" },
      { value: 3, label: "Complété" },
      { value: 4, label: "Rejeté" },
      { value: 5, label: "Annuler" },
      { value: 6, label: "Payé" },
      { value: 7, label: "Remboursement" },
    ],
    paymentMode: [
      { value: 1, label: "Carte de crédit" },
      { value: 2, label: "Paypal" },
      { value: 3, label: "Alipay" },
      { value: 4, label: "Dépôt" },
    ],
    type: [
      { value: 1, label: "Visa chinois" },
      { value: 2, label: "Carte Wi-Fi" },
      { value: 3, label: "Vip Airport Pick Up" },
    ],
    childType: [
      { value: 1, label: "Visa d'études" },
      { value: 2, label: "Visa de travail" },
      { value: 3, label: "China PR" },
      { value: 4, label: "Renouvellement du visa de travail" },
      { value: 5, label: "Business e-visa" },
      { value: 6, label: "Visa collectif" },
      { value: 7, label: "Vip Welcome" },
      { value: 8, label: "Transferts aéroport Vip" },
      { value: 9, label: "Carte Wi-Fi" },
    ],
    occupationList: [
      { label: "Employé", value: 1 },
      { label: "Entrepreneur", value: 2 },
      { label: "Ménagère", value: 3 },
      { label: "Retraité", value: 4 },
      { label: "Étudiant", value: 5 },
      { label: "Enfant", value: 6 },
    ],
    emergencyContactRelationshipList: [
      { label: "Les parents", value: 1 },
      { label: "Parents", value: 2 },
      { label: "Soeur", value: 3 },
      { label: "Frère", value: 4 },
      { label: "Ami", value: 5 },
      { label: "Conjoint", value: 6 },
      { label: "Fille/fils", value: 7 },
    ],
    punishList: [
      { label: "Oui", value: 1 },
      { label: "Non", value: 0 },
    ],
    comStatus: [
      { label: "Oui", value: 1 },
      { label: "Non", value: 2 },
    ],
    gender: [
      { label: "homme", value: 1 },
      { label: "femelle", value: 0 },
    ],
    maritalStatus: [
      { label: "Marié(e)", value: 1 },
      { label: "Célibataire", value: 2 },
      { label: "Divorce", value: 3 },
      { label: "Veuve", value: 4 },
    ],
    education: [
      { label: "PostDocteur", value: "PostDoctor" },
      { label: "Doctorat", value: "Doctorate" },
      { label: "Maître", value: "Master" },
      { label: "Premier cycle universitaire", value: "Undergraduate" },
      { label: "Lycée", value: "High school" },
      {
        label: "Niveau inférieur à l'école secondaire",
        value: "Below high school",
      },
    ],
    phoneType: [
      { label: "China +86", value: 1 },
      { label: "autres pays", value: 2 },
    ],
    serviceType: [
      { title: "Normal", desc: "5 jours ouvrables", value: 1 },
      {
        title: "Express",
        desc: "3 jours ouvrables. Le service express est payant.",
        value: 2,
      },
      {
        title: "Extra urgent",
        desc: "2 working days. Urgent service requires extra fee",
        value: 3,
      },
    ],
    groupServiceType: [
      { title: "Normal", desc: "5 jours ouvrables", value: 1 },
      {
        title: "Express",
        desc: "3 jours ouvrables. Le service express est payant.",
        value: 2,
      },
    ],
    vipCars: [
      { title: "Business 7 Siège", value: 1 },
      { title: "Premium 5 Siège", value: 2 },
    ],
    expressType: [
      { title: "Fedex", value: 1 },
      { title: "DHL", value: 2 },
      { title: "SF-EXPRESS", value: 3 },
      { title: "EMS", value: 4 },
    ],
  },
  contactLink: {
    aboutUs: "À propos de nous",
    chineseVisa: "Visa chinois",
    contactUs: "Nous contacter contact",
    insurance: "Assurances",
    advertise: "La publicité",
    wcic: "Carte Wi-fi",
    faq: "FAQ",
    vipApu: "Transport aéroportuaire",
    privacy: "Privacy",
    wechat: "APP",
  },
  common: {
    copyright: "COPYRIGHT©2021 Hereshanghai.com, All Rights Reserved.",
    collectSuccess: "Sauvé avec succès",
    cancelSuccess: "Annulé",
    cancelCollection: "Supprimer",
    collectFail: "Échec",
    unit: "",
    month: "Mois",
    create: "Premier post",
    update: "Dernière mise à jour",
    Viewed: "Affiché",
    times: "Voûtes",
    isImage: "L'image doit être au format GIF、JPG、PNG.",
    isLt2M: "La taille de l'image ne doit pas dépasser 2MB",
    success: "La requête a été envoyée avec succès",
    payFail: "Échec du paiement",
    paySuccess: "Succès de paiement",
    updSuccess: "Modifié avec succès",
    loginAgain: "Veuillez vous reconnecter",
    updSubmit: "Confirmation",
    codeError: "Code de vérification incorrect",
    username: "Nom de l'usager",
    FocusOnSuccess: "Suivre",
    AttentionCancelled: "Annulé",
    PublishedSuccessfully: "Publié avec succès",
    delete: "Supprimer",
    deleteSuccess: "Supprimé",
    Redact: "Modifier",
    Note: "Note",
    NotLoggedIn: "Veuillez vous connecter",
    Deleted: "Supprimé",
    Rejected: "Rejeté",
    noVisa: "Il n'est pas possible de demander la nationalité choisie. ",
    noCity:
      "Nous ne fournissons pas de réception VIP pour la ville sélectionnée.",
    sample: "Montrer l'exemple",
    aggrement: "Privacy and Terms of service",
    printInvoice: "Imprimer la facture",
  },
  navigation: {
    Back: "Haut de page",
    SignIn: "Connexion",
    Register: "Registre",
  },
  message: {
    success: "avec succès",
    registerSuccess: "Succès de l'enregistrement",
    title: "Succès de l'enregistrement",
    desc: "Veuillez protéger les données de votre compte",
    goHome: "Aller à la première page",
  },
  resetPw: {
    title: "Réinitialiser un mot de passe oublié",
    password: "Définir un mot de passe de 6 à 20 caractères",
    next: "Procéder",
  },
  login: {
    EmailLogin: "Email",
    Email: "Email",
    MobileNumber: "Numéro de téléphone",
    accountNumber: "Email",
    Password: "Mot de passe",
    PleaseEnterYourEmailAddress: "Entrez votre adresse e-mail",
    PleaseEnterYourPassword: "Entrer le mot de passe",
    MobilePhone:
      "Numéro de téléphone (uniquement pour les numéros de téléphone chinois)",
    PleaseEnterYourPhone: "Entrez votre numéro de téléphone",
    AuthCode: "Code d'autorisation",
    PleaseEnterTheAuthCode: "Code d'autorisation",
    GetCode: "Entrez le code d'autorisation",
    SendCode: "Envoi",
    SignIn: "Connectez-vous",
    StayloggedIn: "Restez connecté",
    Register: "Registre",
    ForgotPassword: "Vous avez perdu votre mot de passe?",
    Individual: "Private",
    CompanyOrAgency: "Société/agence",
    Company: "Société/agence",
    sendCode: "Envoyé avec succès",
  },
  register: {
    validateRealName: "Nom et prénom",
    validatePhone: "Numéro de téléphone",
    validatePhoneInspect:
      "Veuillez saisir le numéro de téléphone mobile correct",
    validateNewPhone:
      "Le nouveau numéro de téléphone ne peut être le même que le précédent.",
    validateEmail: "Entrez votre adresse e-mail",
    validateEmailInspect: "Veuillez entrer l'adresse e-mail correcte",
    validateEmailCode: "Entrez le code de vérification de l'email",
    validateCountry: "Nationalité",
    validatePassword: "Entrez un mot de passe",
    validatePasswordAgin: "Confirmer le mot de passe",
    validatePasswordAtypism: "Les deux mots de passe ne sont pas cohérents",
    validateNickName: "Nom de l'usager",
    validatePhoneCode: "Entrez le code de vérification par SMS",
    validateCompanyName: "Nom de la société/agence",
    validateCompanyType: "Type d'entreprise",
    validateCompanySn: "TVA",
    validateCompanyWebsite: "Site web de l'entreprise",
    validateCompanyAddress: "Adresse de l'entreprise",
    validateCompanyPost: "Code postal",
    validateCompanyCountry: "Sélectionnez le pays",
    validateCompanyCity: "Ville",
    validateCompanyContact: "Nom de l'usager",
    EmailRegister: "Email Register",
    MobileNumberRegister: "Inscription avec numéro de téléphone",
    checkBox: "Cochez la case pour accepter",
    checkBoxText: "《Accord de service aux utilisateurs》",
    checkBoxUserAgreement:
      "J'accepte'the Terms and Conditions and Privacy Statement",
    AlreadyRegisteredSignIn:
      "Vous avez déjà un compte, connectez-vous maintenant",
    Register: "Registre",
    PleaseSelect: "Veuillez sélectionner",
    PleaseInput: "Veuillez saisir",
    Email: "Email",
    AuthCode: "Confirmer l'e-mail",
    Password: "Mot de passe",
    ConfirmedPassword: "Confirmer le mot de passe",
    CompanyName: "Nom de l'entreprise",
    Typology: "Type d'entreprise",
    VATTax: "TVA",
    Website: "Site web",
    ContactPerson: "Personne de contact",
    PhoneNumber: "Numéro de téléphone",
    Address: "Adresse",
    PostalCode: "Code postal",
    Country: "Pays",
    City: "Ville",
    Account: "Nom d'utilisateur",
    GetCode: "Obtenir le code de vérification",

    Name: "Nom",
    MobileNumber: "Numéro de téléphone",
    Nationality: "Nationalité",
    MobileNumberchina: "Numéro de téléphone mobile",
    yam: "Code de vérification",
  },
  home: {
    FoodRecommendation: "Recommandations alimentaires",
    LeisurePlay: "Loisirs et divertissements",
    visa: "Visa chinois",
    Journalism: "Nouvelles",
    ContactUs: "Contactez-nous",
    AcceptanceOrganization: "Institutions acceptées",
    AcceptanceOrganizationInfo:
      "L'administration nationale des migrations confie aux autorités d'entrée et de sortie des organes de sécurité publique au niveau de la préfecture (y compris les comtés sous la juridiction des municipalités relevant directement du gouvernement central) ou à un niveau supérieur le soin d'accepter les permis d'entrée et de sortie des étrangers. Après approbation de l'administration nationale des migrations par les organes de sécurité publique au niveau provincial, les organes de sécurité publique au niveau du comté peuvent accepter les permis d'entrée et de sortie des étrangers sur la base de la délégation de l'administration nationale des migrations.",
  },
  contact: {
    email: "E-mail",
    name: "Nom",
    nationality: "Nationalité",
    title: "Titre",
    content: "Contenu",
    verification: "Vérification",
    submit: "Soumettre",
    insertEmail: "Insérer l'e-mail",
    insertEmail2:
      "Veuillez indiquer votre adresse électronique dans l'adresse de livraison",
    insertName: "Insérer le nom",
    insertTitle: "Insérer l'e-mail",
    insertCountry: "Insérer Pays",
    insertContent: "Insérez ici votre message",
    slidingValidation:
      "Appuyez sur le curseur et maintenez-le enfoncé, puis faites-le glisser vers l'extrême droite.",
    validateEmailCode: "Format d'adresse électronique incorrect",
    validateLengthCode: "Longueur minimale de 2",
    validateLengthContent: "La longueur doit être comprise entre 10 et 150",
    bottomTitle: "Shanghai",
    bottomDesc:
      "Shanghai Chende Talent Consulting Service Co., LTD  Phone: +86 21 61806993  Mobile: +86 21 61806993  E-mail: info@hereshanghai.com",
  },
  groupSignature: {
    insertEmail: "Insérer l'e-mail",
    tipBeCare: "Note: ",
    submitBeCareFul: {
      title:
        "Veuillez vérifier les informations relatives à chaque membre. Le visa sera délivré en fonction des informations que vous aurez fournies.",
    },
    detailsBeCareFul: {
      title:
        "Veuillez vérifier les informations relatives à chaque membre. Le visa sera délivré en fonction des informations que vous aurez fournies.",
    },
    successBeCareFul: {
      title:
        "Votre demande a été soumise, merci ! Nous allons la vérifier, veuillez attendre notre e-mail de confirmation.",
    },
    payAfter: {
      successText:
        "Votre demande est soumise, vous pouvez vérifier le statut de votre pratique dans votre compte.",
    },
    beCareFul: {
      title: `
		  Les visas de groupe sont délivrés à un groupe composé d'au <span style='font-weight: 900'>moins 2 et de 8 membres</span> au maximum.<br />
			Remplissez et joignez les données du premier passager, puis remplissez celles du deuxième et du troisième... pax.<br />
			Choses à savoir avant de demander un visa
			Le visa de groupe est appliqué sur un seul certificat pour l'ensemble du groupe, plutôt que sur le passeport individuel. Tous les membres du groupe doivent voyager ensemble à l'entrée et à la sortie de la Chine.
		`,
      infoTitle: "Ce qu'il faut savoir avant de demander un visa :",
      infoDesc:
        "Le visa de groupe s'applique à un certificat unique pour l'ensemble du groupe, plutôt qu'à un passeport individuel. Tous les membres du groupe doivent voyager ensemble à l'entrée et à la sortie de la Chine.",
    },
    comTipBeCareFul: {
      title:
        "Après avoir reçu votre demande, notre personnel vous contactera par courrier électronique pour vous informer des documents à fournir pour demander le visa.",
      success:
        "Votre demande a été soumise, merci ! Nous allons la vérifier, veuillez attendre notre e-mail de confirmation.",
    },
    uploadInfo: {
      format: "Formats supportés : GIF/JPG/PNG/PDF",
      success: "Téléchargement réussi",
      see: "Cliquez pour voir",
      uploadText: "Téléchargement de fichiers",
    },
    tipText: {
      voluntarily:
        "Note : Le système se remplit automatiquement, veuillez faire attention à vérifier",
    },
    essentialInformation: "Informations personnelles",
    personnelInformation: "Données personnelles des passagers",
    otherInformation: "Autres informations",
    entryInformation: "Informations",
    uploadInformation: "Téléchargement de documents",
    confirmationInformation: "Vérification et confirmation",
    serviceMode: "Type de service",
    payment: "Paiement",
    applicationStatus: "Statut du dossier",
    pleaseEnter: "Entrez",
    pleaseSelect: "Sélectionnez",
    confimNex: "Confirmez et procédez",
    memberTitle: "Ajouter un passager:",
    rules: {
      passport: "Télécharger une copie du passeport",
      vipTicket: "Télécharger une copie des billets de voyage",
      ticket: "Télécharger les copies des billets d'avion ou de train ",
      hotelOrder: "Télécharger une copie des réservations d'hôtel",
      englishName: "Seules les lettres majuscules peuvent être saisies",
      idCard: "Télécharger une copie de la photo d'identite",
      vaccinationCertificate:
        "Télécharger une copie du certificat de vaccination",
      invitationLetter: "Télécharger une copie de la lettre d'invitation",
      companyLicense:
        "Télécharger une copie de la licence d'entreprise chinoise",
      deposit: "Télécharger le reçu du virement bancaire",
    },
    submitInfo: {
      name: "Nom et prénom",
      No: "No.",
      seeDetails: "Voir les détails",
      passengersInfo: "Informations pour les passagers",
    },
    informationForm: {
      SerialNumber: "No.",
      No: "No.",

      passport:
        "Télécharger une copie du passeport (page avec les informations)",
      EnglishSurname: "Nom de famille",
      EnglishName: "Nom",
      ChineseName: "Nom chinois",
      nationality: "Nationalité",
      Gender: "Sexe",
      dateBirth: "Date de naissance",
      birthplace: "Lieu de naissance",
      placeIssue: "Lieu d'émission",
      certificatesNumber: "Numéro de passeport",
      passengersEmail: "E-mail",
      CertificateDate: "Date de sortie",
      CertificateDateValid: "Date d'expiration",
      occupation: "Profession",
      emergencyContact: "Nom du contact en cas d'urgence",
      emergencyContactRelationship: "Relation avec la personne de contact",
      EmergencyContactInformation: "Numéro de téléphone d'urgence",
      punish: "Avez-vous un casier judiciaire en Chine ou dans d'autres pays?",
      OtherNameInChina:
        "S'agit-il de votre premier passeport ou en avez-vous eu un autre dans le passé ? (premier passeport/avait un ancien passeport)?",
      OtherNationalities: "Avez-vous d'autres nationalités?",
      getShop: "Récipiendaire",
      maritalStatus: "État civil",
      SpouseName: "Nom de l'époux(se)",
      spouseDate: "Date de naissance de l'époux(se)",
      SpouseNationality: "Nationalité de l'époux(se)",
      ResidenceSpouse: "Adresse de l'époux(se)",
      SpouseContactNumber: "Numéro de téléphone de l'époux(se)",
      HighestEducation: "Votre diplôme",
      SchoolName: "Nom de l'institut",
      NameUnitOrganization: "Nom de l'entreprise",
      OverseasGroupAgency: "Nom de l'agence de voyage",
      otherCountriesVisited:
        "Pays visités au cours des deux dernières années (à l'exception de la Chine)",
      ExpressAddress: "Adresse postale",
      TelephoneNumber: "Numéro de téléphone",
      addressInChina: "Adresse en Chine",
      CarPassengers: "Passagers en voiture (max. 4 pax)",
      DestinationAddress: "Adresse de destination (Shanghai uniquement)",
      expressCountry: "Pays",
      expressCity: "Ville",
      expressStreet: "Numéro de rue et de maison",
      expressPostcode: "Code postal",
      contactAddress: "Adresse",
      consignee: "Nom du destinataire",
    },
    entry: {
      enterCity: "Ville d'arrivée (Chine)",
      outCity: "Ville de départ (Chine)",
      enterDate: "Date d'arrivée en Chine",
      outDate: "Date de départ de la Chine",
      inboundAirport: "Aéroport d'entrée en Chine",
      inboundFlightNo: "Numéro de vol pour la Chine",
      carsType: "Type de véhicule",
    },
    upload: {
      vipTicket: "Billets de voyage",
      ticket: "Billet de voyage (avion/train/coach/bateau)",
      hotelOrder: "Réservations d'hôtel (toutes les nuits)",
      idCard: "Photo d'identité",
      vaccinationCertificate: "Certificat de vaccination",
      invitationLetter: "Lettre d'invitation",
      companyLicense: "Licence d'entreprise chinoise",
    },
    confirm: {
      memberInformation: "Infos membres",
      contactNumber: "Numéro de téléphone",
      newMember: "Ajouter un autre voyaguer",
      viewDetail: "Voir les détails",
    },
  },
  search: {
    PleaseInput: "Veuillez saisir",
  },
  messageText: {
    groupSignature:
      "Un minimum de deux personnes est requis pour soumettre un visa de groupe.",
  },
  personalCenter: {
    userInfo: {
      avatar: "Avatar",
      nickName: "Nom d'utilisateur",
      loginName: "Nom d'utilisateur",
      name: "Nom",
      companyName: "Nom de l'entreprise",
      companyType: "Type d'entreprise",
      vatTax: "TVA",
      website: "Site web",
      email: "E-mail",
      contactPerson: "Personne de contact",
      phone: "Numéro de téléphone",
      mobileNumber: "Numéro de téléphone",
      password: "Mot de passe",
      nationality: "Nationalité",
      address: "Adresse",
      city: "Ville",
      postalCode: "Code postal",
      state: "Statut de connexion",
    },
    userInfoBtn: {
      changePhone: "Modifier Téléphone",
      changePassword: "Modifier le mot de passe",
      changePhoneTag: "(Réservé aux numéros de téléphone mobile chinois)",
      login: "Connecté",
      logout: "Déconnexion",
      placeholderInput: "Veuillez saisir",
      newMobileNumber: "Nouveau numéro de téléphone mobile",
      newPassword: "Nouveau mot de passe",
      confirmedPassword: "Confirmer le nouveau mot de passe",
      confirmedPasswordInput: "Veuillez reconfirmer le nouveau mot de passe",
      submit: "Compléter",
    },
  },

  hereStroe: {
    buyNow: "Acheter",
    address: "Adresse de réception",
    addressInput: "Veuillez d'abord indiquer l'adresse de livraison",
    addAddress: "Ajouter une adresse de livraison",
    nameInput: "Destinataire",
    phoneInput: "Numéro de téléphone du destinataire",
    postAddress: "Adresse du courrier",
    PleaseInput: "Veuillez saisir",
    pleaseSelect: "Veuillez sélectionner",
    streetAndNumber: "Veuillez saisir le nom de la route",
    postalcode: "Veuillez saisir votre code postal",
    city: "Sélectionnez une ville",
    country: "Sélectionnez un pays",
    confirm: "Confirmer",
    dataList: "Liste des documents",
    processingTime: "Temps de traitement",
    note: "Note",
    Price: "Price",
    OtherContent: "Autre contenu",
    ProductDescription: "Description du produit",
    inventory: "Pas en stock",
  },

  elMessageBoxText: {
    title: "Retrait",
    releaseTitle: "Sortie de secours",
    releaseDesc: "Cette opération va sortir de l'édition, dois-je continuer?",
    addDesc: "Cette opération va sortir de l'édition, dois-je continuer?",
    desc: "Cette action va vous déconnecter, voulez-vous continuer?",
    MyArticleOpinionDesc:
      "Cette action supprimera le commentaire, dois-je continuer?",
    delDesc: "Cette opération supprimera les données, dois-je continuer?",
    visaText:
      "Cette action soumettra les informations pour s'assurer qu'elles sont correctes?",
    confirmButtonText: "Confirmer",
    cancelButtonText: "Annuler",
    Confirm: "Confirmer",
  },

  payMent: {
    Payment: "Paiement",
    toPayment: "Payer",
    practiceDetails: "Détails de la commande",
    type: "Nom du produit",
    OrderDate: " Date de l'ordre",
    PracticeNumber: "Numéro de pratique",
    Total: "Total",
    PaymentMethod: "Mode de paiement",
    PaymentDetails: "Détails du paiement",
    PassengerName: "Nom du passager",
    Price: "Prix",
    ExpressFee: "Taxe expresse",
    TotalAll: "Total",
    TotalPayAll: "Total",
    Pay: "Payer",
    CreditCard: "Carte de crédit",
    Alipay: "Alipay",
    Deposit: "Dépôt",
    submit: "Soumettre",
    submit2: "Soumettre",
    DepositNote: "(à vérifier avec l'agent)",
    YourRequestIsSubmitted: "Paiement réussi",
    saveAndPrevious: "Sauvegarde et précédent",
    saveAndOrderInfo: "Confirmer et voir les commandes",
    imgFormat: "Seuls les fichiers au format jpg/png peuvent être téléchargés.",
    cancel: "Annuler",
  },

  payMentType: {
    wifiCard: "Carte d'accès à Internet",
    businessVisa: "Visa d'affaires",
    studyVisa: "Visa d'études",
    jobVisa: "Visa de travail",
  },

  foreignerServiceStation: {
    title: "Ressources",
    allAreas: "Classification régionale",
  },

  food: {
    MostPopular: "Lieux de restauration populaires",
    RestaurantList: "Catégories d'aliments",
  },

  LeisurePlay: {
    leisureplay: "Loisirs / Amusement",
    youMightAlsoLike: "Vous pouvez aussi aimer",
    time: "Temps:",
    address: "Adresse :",
    activityContent: "Activité Contenu:",
  },

  forum: {
    join: "Sujets participants",
    recommendedTopics: "Sujets d'actualité",
    hotTopics: "Sujets d'actualité",
    followed: "Suivi",
    favorites: "Favoris",
    more: "Plus",
    comments: "Commentaires",
    Reply: "Répondre",
    prevText: "Précédent Texte",
    nextText: "Texte suivant",
    ReleasePost: "Release Post",
    ReleaseTouchPost: "Libération du poste de touche",
    newTopic: "Nouveau sujet",
    UploadCove: "Télécharger la couverture",
    PublishContent: "Publier du contenu",
    submit: "Envoyer",
    loginIn: "Connexion",
    follow: "Suivre",
    article: "Article",
    concerned: "Concernés",
    other: "Autres",
    PleaseSelect: "Veuillez sélectionner",
    datas: "Veuillez compléter les données",
    successfully: "Libération réussie",
    Comments: "Commentaire",
    Comment: "Commentaire",
    back: "Retour",
    reply: "Retour",
    NoComment: "Pas de commentaire",
    NoAttention: "Pas d'attention ",
    NoCollection: "Pas de collecte",
  },

  book: {
    books: "Livres",
    more: "Plus",
    category: "Catégorie",
  },

  house: {
    title: "Hôtel",
    university: "Université:",
    road: "Rue:",
    houseTitle: "Informations sur le logement",
    houseIntroduction: "Description de la maison：",
    housing: "Hôtel",
    serviceApartment: "Hôtel",
    allAreas: "Toutes les zones",
    city: "Ville",
    street: "Rue",
    like: "Vous pouvez aimer",
    price: "Prix:",
    type: "Type:",
    rooms: "Chambre:",
    size: "Zone:",
    floor: "Plancher:",
    mainWindowFacing: "Orientation:",
    district: "Quartier:",
    metroStation: "Station de métro:",
  },

  secondHand: {
    price: "Prix：",
    newGoods: "Nouveaux produits",
    unit: "Unité",
    Description: "Description",
    PickUp: "Ramassage",
    Contact: "Contact",
    class: "Nom de la classification",
    secondHandgoods: "Articles d'occasion",
    CommodityInformation: "Articles info",
    name: "Titre Nom",
    address: "Adresse de prise en charge",
    phone: "Numéro de téléphone",
    price: "Prix",
    classification: "Classification",
    introductionPicture: "Introduction Image",
    details: "Détails",
    release: "Libération",
    cancel: " Annuler",
    success: "Succès",
    fail: "échouer",
    rulesCover: "Télécharger au moins une image de couverture",
  },

  recruit: {
    Favorite: "Favoris",
    Salary: "Salaire: ",
    CandidateLocation: "Localisation: ",
    MostPopularJob: "Emploi le plus populaire",
    JobType: "Type d'emploi",
    Resumes: "C.V.",
    JobProfile: "Profil d'emploi",
  },
  travel: {
    MostRecent: "Plus récents",
  },

  ticket: {
    HotPlaces: "Attractions populaires",
    RecommendedAttractions: "Attractions recommandées",
    Price: "Prix：",
    Classification: "Classification",
  },

  medical: {
    MostPopular: "Les plus populaires",
    Phone: "Téléphone：",
    Room: "Chambre：",
    Address: "Adresse：",
    Introduction: " Introduction：",
    suite: "Suite",
    primaryClassification: "Classification primaire",
    secondaryClassification: "Classification secondaire",
  },

  study: {
    mostPopular: "Les plus populaires",
    education: "L'éducation",
    introduction: "Introduction：",
  },

  tableConfig: {
    createDate: "Date de la commande",
    orderNumber: "Numéro d'ordre",
    childType: "Gamme de produits",
    enterDate: "Entrer la date",
    leaveDate: "Date de départ",
    price: "Prix",
    orderStatus: "État de la commande",
  },

  orderInfo: {
    PracticeInfo: "Info pratique",
    PostAddress: "Adresse postale",
    CourierNo: "Courrier No",
    Other: "Vous pouvez télécharger ici la copie du visa e-Business/Groupe",
    FeedbackDescription: "Description du retour d'information",
    label: {
      Type: "Types de visa：",
      PaxNo: "Pax No：",
      PurchaseNo: "Numéro d'achat：",
      PracticeNo: "Pratique No：",
      PaymentNo: "Paiement No：",
      Price: "Prix：",
      Discount: "Remise：",
      Status: "Statut：",
      MethodOfPayment: "Méthode de paiement：",
      Recipient: "Récipiendaire：",
      PhoneNumber: "Numéro de téléphone：",
      Address: "Adresse：",
      ExpressOrderNo: "N° de commande express：",
      Time: "Temps：",
      DescriptionContent: "Description Contenu：",
    },
    NoLogisticsInformation: "Pas d'information sur la logistique",
    NoInformation: "Aucune information",
    NoFeedbackDescription: "Pas de retour d'information Description",
    CheckTheLogistics: "Vérifier la logistique",
    Download: "Télécharger",
    continue: "Continuer",
  },
  orderStatus: [
    { value: 0, label: " Non payé" },
    { value: 1, label: " Non payé" },
    { value: 2, label: " En cours de révision " },
    { value: 3, label: " Complété" },
    { value: 4, label: " Rejeté" },
    { value: 5, label: " Annulé" },
    { value: 6, label: " Payé" },
    { value: 7, label: " Remboursé" },
  ],
};
