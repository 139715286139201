<template>
  <div class="">
    <top-search-one></top-search-one>
    <topSelect></topSelect>
    <div class="application">
      <div class="title">Application</div>
      <div class="sign-up">
        <div class="left">
          <img src="../../../static/images/school/edit.png" />
          <div class="fill">
            <div>填写申请表</div>
            <div>New Application</div>
          </div>
        </div>
        <div class="right">
          <img src="../../../static/images/school/tianxie.png" />
          <div class="fill">
            <div>已填写申请表</div>
            <div>My Application</div>
          </div>
        </div>
      </div>
    </div>
    <contact-us-two></contact-us-two>
  </div>
</template>

<script>
import topSearchOne from "../home/topSearchOne.vue";
import topSelect from "../home/topSelect.vue";
import contactUsTwo from "../home/contactUsTwo.vue";
export default {
  components: {
    topSearchOne,
    topSelect,
    contactUsTwo,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
::v-deep() .bottom-bottom-title {
  font-size: 36px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 40px;
  cursor: pointer;
}
.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sign-up {
    display: flex;
    justify-content: center;
    margin-top: 95px;
    .right {
      width: 577px;
      height: 400px;
      background: #fdeed4;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 100px;
      cursor: pointer;
      img {
        width: 100px;
        height: 100px;
      }
      .fill {
        margin-top: 100px;
        font-size: 36px;
        font-weight: 900;
        text-align: center;
        color: #444444;
      }
    }
    .left {
      width: 577px;
      height: 400px;
      opacity: 1;
      background: #f0f8fd;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 100px;
        height: 100px;
      }
      .fill {
        margin-top: 100px;
        font-size: 36px;
        font-weight: 900;
        text-align: center;
        color: #444444;
      }
    }
  }
  .title {
    font-size: 44px;
    font-weight: 600;
    color: #222a41;
    padding: 0 20px 0 20px;
    text-align: center;
    background: linear-gradient(
      to right,
      #fdeed4 236px,
      #fdeed4 236px,
      white 52%,
      white 100%
    );
    margin-top: 100px;
  }
}
</style>
