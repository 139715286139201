export function getCookie() {
    var info = {}; // 返回值
    // return
    if (document.cookie.length > 0) {
        
        var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下        
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('&='); //再次切割
          
          //判断查找相对应的值
          if (arr2[0] == 'usernameQS') {
            info.username = arr2[1];
          }else if (arr2[0] == 'passwordQS') {
            info.password = arr2[1];
          }else if (arr2[0] == 'clientQS') {
            info.client = arr2[1];
          }else if (arr2[0] == 'loginTypeQS') {
            info.loginType = Number(arr2[1]);
          }else if (arr2[0] == 'checkedQS') {
            info.checked = arr2[1]==='true'?true:false;
          }
        }     
        
        return info
        
      }else{
        return info
      }
}

//设置cookie
export function setCookie(info, c_checked, exdays) {
    var exdate = new Date(); //获取时间
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
    //字符串拼接cookie
    window.document.cookie = "usernameQS" + "&=" + info.username + ";path=/;expires=" + exdate.toGMTString();
    window.document.cookie = "passwordQS" + "&=" + info.password + ";path=/;expires=" + exdate.toGMTString();
    window.document.cookie = "clientQS" + "&=" + info.client + ";path=/;expires=" + exdate.toGMTString();
    window.document.cookie = "loginTypeQS" + "&=" + info.loginType + ";path=/;expires=" + exdate.toGMTString();
    window.document.cookie = "checkedQS" + "&=" + c_checked;
}

//清除cookie
export function clearCookie() {
  setCookie("", "", -1); //修改最后值都为空，天数为负1天就好了
}