<template>
  <div class="backgound">
    <div class="top">
      <div
        v-for="(item, index) in title"
        :key="index"
        :class="titleIndex == index + 1 ? 'title-check' : 'title-default'"
        @click="changeTitleIndex(index + 1)"
        class="marginRight40"
      >
        {{ item }}
      </div>
    </div>

    <One v-if="titleIndex == 1" ref="RefOne" />
    <Two v-if="titleIndex == 2" ref="RefTwo" />
    <Three v-if="titleIndex == 3" ref="RefThree" />
    <Four v-if="titleIndex == 4" ref="RefFour" />
  </div>
</template>

<script>
import {
  reactive,
  nextTick,
  ref,
  toRefs,
  getCurrentInstance,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import One from "./myForum/One.vue";
import Two from "./myForum/Two.vue";
import Three from "./myForum/Three.vue";
import Four from "./myForum/Four.vue";
export default {
  components: { One, Two, Three, Four },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();

    // 当前卡片下标
    let titleIndex = ref(1);
    const data = reactive({
      title: proxy.$commonData.personalCardTitle.myForum,
    });

    // 切换tab,获取数据
    const changeTitleIndex = (i) => {
      titleIndex.value = i;
    };

    const RefOne = ref(); // 旅游攻略
    const RefTwo = ref(); // 美食
    const RefThree = ref(); // 休闲/玩乐
    const RefFour = ref(); // 外国人服务站

    watchEffect(() => {
      if (titleIndex.value == 1) {
        nextTick(() => {
          RefOne.value.getData();
        });
      } else if (titleIndex.value == 2) {
        // DOM更新后调用
        nextTick(() => {
          RefTwo.value.getData();
        });
      } else if (titleIndex.value == 3) {
        nextTick(() => {
          RefThree.value.getData();
        });
      } else if (titleIndex.value == 4) {
        nextTick(() => {
          RefFour.value.getData();
        });
      }
    });

    return {
      ...toRefs(data),
      titleIndex,
      RefOne,
      RefTwo,
      RefThree,
      RefFour,
      changeTitleIndex,
    };
  },
};
</script>

<style scoped lang="scss">
.top {
  border-bottom: 1px solid #e6e9f4;
  display: flex;
  padding: 24px 0 0 41px;

  background-color: #fff;
  .marginRight40 {
    margin-right: 40px;
  }
  .title-check {
    height: 34px;
    color: #444444;
    line-height: 24px;
    border-bottom: 4px solid #f5a623;
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    cursor: pointer;
  }
  .title-default {
    height: 34px;
    color: #444444;
    line-height: 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #999999;
  }
}
.backgound {
  width: 1058px;
  // background: #ffffff;
  margin-left: 22px;
}
.content {
  background-color: none;
}
</style>
