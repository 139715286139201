<template>
  <div class="count">
    <div class="count-box" v-if="isUser">
      <img src="../../../static/images/personal/user.png">
      <div>{{user}}</div>
    </div>

    <div class="count-box">
      <img src="../../../static/images/personal/msg.png">
      <div>{{msg}}</div>
    </div>

    <div class="count-box" v-if="collect && favorite">
      <img class="check-img" v-if="favorite.collection==true" src="../../../static/images/icon/2.png" @click.stop="selectClick(favorite)"/>
      <img class="unCheck-img" v-else src="../../../static/images/icon/1.png" @click.stop="selectClick(favorite)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CountUser",
  props: {
    user: {
      type:[Number,String],
      default: 0
    },
    msg: {
      type:Number,
      default: 0
    },
    collect: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: true
    },
    favorite: {
      type:Object,
    },
  },
  setup(props,{emit}) {
    // 收藏
    const selectClick = ((data) => {
      emit('collects',data)
    });

    return {
      selectClick,
    }
  }
};
</script>

<style scoped>
.count {
  display: flex;
}

.count-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 35px;
}

img {
  width: 30px;
  height: auto;
  margin-right: 9px;
}

span {
  font-size: 16px;
  font-weight: 900;
  color: #666666;
}

</style>
