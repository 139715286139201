<template>
  <div class="background">
    <div class="forget-password">
      <div class="close">
        <div @click="$router.go(-1)">
          <img class="closeBtn" src="../../../static/images/login/close.png" alt="">
        </div>
      </div>
      <div class="forget-content">
        <img src="../../../static/images/login/logo.png" class="logo" />
        <div class="cz-password">{{ $t("resetPw.title") }}</div>
        <el-form ref="form" :model="form" :rules="rules" class="form">
           <!--手机号-->
          <!-- <el-form-item
            v-if="step == 1"
            :label="$t('login.MobilePhone')"
            prop="nowPhone"
            class="input-style">
            <div class="input">
              <img src="../../../static/images/login/User.png" />
              <el-input clearable v-model="form.nowPhone" :placeholder="$t('login.PleaseEnterYourPhone')"></el-input>
            </div>
          </el-form-item> -->
          <!-- 邮箱 -->
          <el-form-item
            v-if="step == 1"
            :label="$t('login.Email')"
            prop="nowEmail"
            class="input-style">
            <div class="input">
              <img src="../../../static/images/login/email.png" />
              <el-input clearable v-model="form.nowEmail" :placeholder="$t('login.PleaseEnterYourEmailAddress')"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="nowEmailCode"
                        v-if="step == 1"
                        :label="$t('login.AuthCode')"
                        class="input-style left37"> <!--验证码-->
            <div class="input2">
              <img src="../../../static/images/login/code.png" />
              <el-input clearable maxlength="6" v-model="form.nowEmailCode"
                        :placeholder="$t('login.PleaseEnterTheAuthCode')"></el-input>
            </div>
            <div v-if="codeType == 0" class="get-code" @click="getCode">{{ $t("register.GetCode") }}</div>
            <!--获取验证码-->
            <div v-if="codeType == 1" class="get-code">{{ waitCode }}</div>
          </el-form-item>
          <el-form-item prop="newPassword"
                        v-if="step == 2"
                        :label="$t('register.Password')"
                        class="input-style"> <!--密码-->
            <div class="input">
              <img src="../../../static/images/login/Lock.png" />
              <el-input clearable show-password maxlength="20" v-model="form.newPassword"
                        :placeholder="$t('resetPw.password')"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="confirmedPassword"
                        v-if="step == 2"
                        :label="$t('register.ConfirmedPassword')"
                        class="input-style"> <!--密码-->
            <div class="input">
              <img src="../../../static/images/login/Lock.png" />
              <el-input clearable show-password maxlength="20" v-model="form.confirmedPassword"
                        :placeholder="$t('register.validatePasswordAgin')"></el-input>
            </div>
          </el-form-item>
        </el-form>
                 <!-- <div class="submit" @click="next(step)">{{$t('resetPw.next')}}</div> -->
        <el-button class="submit" :loading="btnLoading" @click="next(step)">{{ $t("resetPw.next") }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import api from "@/comm/api";
import { ElMessage } from "element-plus";
import { encryptedData } from "@/utils/encrypt";
import { clearCookie} from "@/utils/cookie.js";

export default {
  data() {
    const checkMobilePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validatePhone")));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t("register.validatePhoneInspect")));
        }
      }
    };
    const checkNowEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("login.PleaseEnterYourEmailAddress")));
      } else {
        if (util.validateEmail(value)) {
          callback();
        } else {
          return callback(new Error(this.$t("register.validateEmailInspect")));
        }
      }
    };
    const checkAuthCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("login.PleaseEnterTheAuthCode")));
      } else {
        callback();
      }
    };
    const checkNewPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validatePassword")));
      } else {
        if (util.checkPassword(value)) {
          callback();
        } else {
          return callback(new Error(this.$t("resetPw.password")));
        }
      }
    };
    const checkConfirmedPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validatePasswordAgin")));
      } else {
        if (util.checkPassword(value)) {
          if (util.checkPasswordAgain(this.form.newPassword, value)) {
            callback();
          } else {
            callback(this.$t("register.validatePasswordAtypism"));
          }
        } else {
          callback(new Error(this.$t("resetPw.password")));
        }
        // if (util.checkPasswordAgain(this.form.newPassword,value)) {
        //   callback();
        // } else {
        //   return callback(
        //     new Error(this.$t("register.validatePasswordAtypism"))
        //   );
        // }
      }
    };
    return {
      step: 1, // 第一步
      btnLoading: false,
      waitCode: "60",
      codeType: 0,
      form: {
        nowPhone: "",
        nowPhoneCode: "",
        nowEmail: "",
        nowEmailCode: "",
        newPassword: "",
        confirmedPassword: ""
      },
      rules: {
        nowPhone: [{ validator: checkMobilePhone, trigger: "blur" }],
        nowPhoneCode: [{ validator: checkAuthCode, trigger: "blur" }],
        nowEmail: [{ validator: checkNowEmail, trigger: "blur" }],
        nowEmailCode: [{ validator: checkAuthCode, trigger: "blur" }],
        newPassword: [{ validator: checkNewPassword, trigger: "blur" }],
        confirmedPassword: [{ validator: checkConfirmedPassword, trigger: "change" }]
      }
    };
  },
  methods: {
    //切换语言
    languageChange(type) {
      this.$i18n.locale = type == 0 ? "en" : "ch";
    },
    getCode: util.debounce(function() {
      //防抖成功  
      this.sendCode(this.form.nowEmail)
    }),

    // 发送验证码
    sendCode(operation) {
      const dat = {
        type: 1,
        operation,
      };
      api.registerCode(dat).then((res) => {
        if (res) {
          this.$message.success(this.$t('login.sendCode'));
          this.codeType = 1;      
          this.beginInterval();
        }
      })
    },
    
    beginInterval() {
      this.waitCode = this.waitCode - 1;
      if (this.waitCode <= 0) {
        this.waitCode = 60;
        this.codeType = 0;
        this.disabled = false;
      } else {
        setTimeout(this.beginInterval, 1000);
      }
    },
    // 重置密码
    restPasswordData(info) {
      api.restPassword(info).then((res) => {     
          if(res) {
            ElMessage({
              message: this.$t("common.updSuccess"),
              type: "success",
            });          
            this.btnLoading = false;
            clearCookie(); // 清除cookie
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
            sessionStorage.clear();
            setTimeout(() => {
              this.$router.push({path: '/login'})
            },2000)
          }else {
            this.btnLoading = false
          }         
      }).catch(() => {
        this.btnLoading = false;
      })
    },
    // 验证    
    verifyCode(info) {
      api.resetVerifyCode(info).then((res) => {   
        if(res) {
          this.btnLoading = false;
          this.step = 2;  
        }else {          
         this.btnLoading = false;
        }         
      }).catch(() => {
        this.btnLoading = false;
      })
    },
    // 下一步
    // next: util.debounce(function(e) {
    //   console.log("防抖成功,",e[0]);
    //   const step = e[0];
    next(step) {
      this.btnLoading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 第一步
          if (step == 1) {     
            const info = {
              mail: this.form.nowEmail,
              mailCode: this.form.nowEmailCode,
            }
            this.verifyCode(info);

          }
          // 第二步 重置密码
          if (step == 2) {
            const info = {
              mail: this.form.nowEmail,
              // mailCode: this.form.nowEmailCode,
              newPassword: '',
              newPasswordConfirm: '',
            }

            const newPassword = encryptedData(this.form.newPassword);
            
            newPassword.then(res => {
              info.newPassword = res;
              info.newPasswordConfirm = res;
              this.restPasswordData(info);
            });
          }

        } else {
          this.btnLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
:deep .el-input__inner {
  border: 0;
  background-color: transparent;
}

:deep .el-form-item__label {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #a8acb9;
  padding-left: 20px;
  box-sizing: border-box;
}

:deep .left37 .el-form-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.submit {
  width: 408px;
  height: 69px;
  opacity: 1;
  background: #f5ad34;
  border-radius: 100px;
  border: none;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  //line-height: 69px;
  letter-spacing: 2px;
  margin-top: 80px;
  margin-bottom: 134px;
  cursor: pointer;
}
.el-button {
  background: #f5ad34;
  color: #ffffff;
}
.el-button:hover{
  background: #f5ad34;
  color: #ffffff;
}
.input-style {
  width: 405px;
  display: flex;
  flex-direction: column;

  .get-code {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }

  .input2 {
    width: 228px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input > > > .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }

    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }

  .input {
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input > > > .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }

    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #a8acb9;
    margin: 20px 0 10px 20px;
  }
}

.forget-password {
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 29px 40px 0px rgba(0, 0, 0, 0.62);

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
  }

  .forget-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 85px 30px 85px;

    .cz-password {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: #444444;
      //margin: 50px 0 0px 0;
      margin: 12px 0 25px 0;
    }

    .logo {
      width: 236px;
      height: 64px;
      margin: 47px 0;
    }
  }
}

.background {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../static/images/login/bg.png") no-repeat;
  background-size: cover;
}

.language {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  color: #AAADBA;
  font-size: 14px;
}

.language div {
  cursor: pointer;
}

.language .active {
  color: #f5ad34;
}

.closeBtn {
  width: 20px;
  height: 20px;
}

</style>
