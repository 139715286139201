<template>
  <div>
    <!--工作列表-->
    <div class="job-list">
      <div class="listwrap" v-for="(item, index) in list" :key="index">
        <div class="job-list-item" :style="{'background':background}" @click="goDetails(item.id)" >
          <FavoriteCard @click.stop="collect(item,index)" :favorite="item.favorite" />
          <img :src="item.image" />
          <div class="list-title">
            <div class="name">{{ JSON.parse(item.company)[$i18n.locale] }}</div>
            <div class="money">{{item.price}}</div>
          </div>
          <div class="list-desc text-overflow-five">
            {{$t('recruit.JobProfile')}}：{{JSON.parse(item.jobRequirement)[$i18n.locale]}}
          </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
import { ElMessage } from "element-plus";
import {useI18n} from 'vue-i18n';
import FavoriteCard from '@/components/FavoriteCard/FavoriteCard.vue';
export default {
  name: "HotRecruit",
  components: {FavoriteCard},
  props: {
    background: {
      type: String,
      default: '#f7f7f7'
    },
    list: {
      type: Array,
    },
  },
  setup(props,{emit}) {
    const {t} = useI18n();
    // 收藏
    const collect = ((val,i) => {
      let  favorite = val.favorite ? false : true;
      api.recruitFavorite({
        recruitId	: val.id,
        favorite
      }).then((res) => {
        if (res) {
          ElMessage({
            message: favorite?t("common.collectSuccess"):t("common.cancelSuccess"),
            type: "success"
          });
          emit('changeData');
        }
      });
    });

    // 去详情页面
    const goDetails = ((id) => {        
      window.scrollTo(0, 0);
      emit('goDetails',id)
    });

    return {
      collect,
      goDetails
    }
  }
};
</script>

<style scoped lang="scss">
.job-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.listwrap{
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-list-item {
  width: 395px;
  height: 571px;
  background: #f7f7f7;
  border-radius: 16px;
  margin-top: 40px;
  padding: 18px;
  cursor: pointer;
  position: relative;

  img {
    width: 394px;
    height: 304px;
    border-radius: 16px;
  }

  .collect-img {
    width: 36px;
    height: auto;
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
.list-title {
  display: flex;
  justify-content: space-between;
  margin: 31px 0 8px 0;

  .money {
    font-size: 22px;
    color: #f49d44;
    letter-spacing: 1px;
  }

  .name {
    width: 60%;
    font-size: 22px;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.list-desc {
  line-height: 32px;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}
.more {
  margin: 29px 0;
}
.icon-shoucang{
  top:30px;
  right:30px;
}
.icon-xuanzhong{
  top:23px;
  right:30px;
}
.collect-warp {
  top: 40px;
  right: 40px;  
}
</style>
