<template>
  <div class="infomation">
    <div style="display: flex; flex-direction: column; align-items: center">
      <!-- 入境城市 -->
      <el-form label-position="right" label-width="auto">
        <el-form-item :label="$t('groupSignature.entry.enterCity')">
          <el-input
            v-model="input"
            :placeholder="$t('groupSignature.pleaseEnter')"
        /></el-form-item>

        <!-- 出境城市 -->
        <el-form-item :label="$t('groupSignature.entry.outCity')">
          <el-input
            v-model="input"
            :placeholder="$t('groupSignature.pleaseEnter')"
        /></el-form-item>
        <!-- 入境日期 -->
        <el-form-item :label="$t('groupSignature.entry.enterDate')">
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="Pick a day"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker
        ></el-form-item>

        <!-- 出境日期 -->
        <el-form-item :label="$t('groupSignature.entry.outDate')">
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="Pick a day"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker
        ></el-form-item>
      </el-form>

      <el-button plain class="submit-next" @click="entryInformationChange">
        {{ $t("groupSignature.confimNex") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      informationIndex: 0,
      input: "",
    };
  },
  methods: {
    entryInformationChange() {
      this.$emit("entryInformationChange");
    },
  },
};
</script>

<style scoped lang="scss">
.other-style {
  :deep .el-input {
    width: 220px !important;
    margin-right: 15px;
  }
}
:deep .el-upload {
  text-align: left !important;
}
:deep .el-input {
  width: 500px !important;
}
:deep .el-form-item__label {
  font-size: 18px;
  font-weight: 900;
  color: #444444;
}
.infomation {
  margin-top: 60px;
  .form-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #333333;
    margin-bottom: 45px;
  }
  .submit-next {
    background: #f5a623;
    border-radius: 3px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding: 25px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
