<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <!--景点详情-->
          <div class="recruit-info" v-if="infos != null">
            <div class="info-title">
              {{ JSON.parse(infos.title)[$i18n.locale] }}
            </div>
            <div class="info-desc">
              <div class="info-desc-text">
                <img src="../../../static/images/food/address.png" />
                <span>{{ JSON.parse(infos.address)[$i18n.locale] }}</span>
              </div>
              <div class="info-desc-text" v-if="infos.openingHours">
                <img src="../../../static/images/food/time.png" />
                <span>{{ infos.openingHours }}</span>
              </div>
              <div
                class="info-desc-text"
                v-if="
                  infos.announcement &&
                  JSON.parse(infos.announcement)[$i18n.locale]
                "
              >
                <img src="../../../static/images/food/gg.png" />
                <span>{{
                  JSON.parse(infos.announcement)[$i18n.locale] == ""
                    ? "--"
                    : JSON.parse(infos.announcement)[$i18n.locale]
                }}</span>
              </div>
            </div>
            <!--收藏-->
            <favorite @collect="collect" :favorite="infos.favorite" />
            <div class="xian"></div>
            <div class="other-info" v-html="infos.content"></div>
          </div>

          <!--推荐景点-->
          <div class="recruit-title">
            {{ $t("ticket.RecommendedAttractions") }}
          </div>
          <hot-ticket
            :hot-list="hotList"
            @change-data="changeData"
            @go-details="goDetails"
          />
        </div>
      </div>
    </div>
    <contact-us-one></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import api from "../../comm/api";
import Carousel from "../../components/Carousel/Carousel";
import HotTicket from "./ChildComp/HotTicket";
import favorite from "../../components/Favorite/Favorite";
import { useStore } from "vuex";
import util from "@/comm/util";
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, toRefs, watchEffect } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  components: {
    favorite,
    HotTicket,
    Carousel,
    topSearchThree,
    contactUsOne,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      bannerList: [],
      advertises: [],
      hotList: [], //热门景点
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US", //中英文
      ids: useRoute().query.id, //获取父页面 路由传参值  id
      infos: null,
    });

    //获取热门景点
    const hotListGet = async () => {
      data.hotList = await api.etHotList();
    };
    //刷新热门景点 景点列表
    const changeData = () => {
      hotListGet();
      getInfos();
    };
    // 去详情页面
    const goDetails = (id) => {
      getInfos(id);
    };

    // 收藏
    const collect = () => {
      const val = data.infos;
      let favorite = val.favorite ? false : true;
      api
        .sceneryFavorite({
          sceneryId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            data.infos.favorite = favorite;
            changeData();
          }
        });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 8); // 获取广告位 图
      getInfos(); //获取详情
      hotListGet(); //热门景点
    });
    //获取详情
    const getInfos = async (id) => {
      let config = id ? id : data.ids;
      await api.sceneryInfos({ id: config }).then((res) => {
        data.infos = res;
        data.bannerList = res.banner.split(","); //banner图
        data.infos.content = JSON.parse(res.content)[data.local];
        // //处理富文本图片
        const regex = new RegExp("<img", "gi");
        data.infos.content = data.infos.content.replace(
          regex,
          `<img style="width: 100%; height: auto;margin:10px 0;"`
        );
        data.infos.content = data.infos.content
          .replace(
            "<iframe",
            `<video style="width:100%;" controls="" autoplay=""`
          )
          .replace("</iframe>", "</video>");
      });
    };
    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      collect,
      goDetails, //详情页
      changeData, //刷新
      hotListGet,
    };
  },
};
</script>

<style scoped lang="scss">
.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 70%;
}
.recruit-title {
  height: 64px;
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #444444;
  margin: 80px 0 14px 0;
}
.recruit-info {
  background: #ffffff;
  padding: 26px;
  box-sizing: border-box;
  margin-top: 70px;
}
.info-title {
  font-size: 30px;
  font-weight: 900;
  color: #333333;
  margin: 22px 0 16px 0;
}
.info-desc {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}
.info-desc-text {
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}
.xian {
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin: 35px 0;
}
</style>
