<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div class="type">
      <div class="check">
        <div class="icon"></div>
        <div class="title">Normal</div>
        <div class="info">5 working days</div>
      </div>
      <div class="check" style="margin-left: 100px">
        <div class="icon"></div>
        <div class="title">Express</div>
        <div class="info">
          3 working days. Express service requires extra fee
        </div>
      </div>
    </div>
    <el-button plain class="submit-next" @click="servieType">
      {{ $t("groupSignature.confimNex") }}
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    servieType() {
      this.$emit("servieType");
    },
  },
};
</script>

<style scoped lang="scss">
.submit-next {
  background: #f5a623;
  border-radius: 3px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  width: auto;
  margin-top: 100px;
}
.type {
  display: flex;
  margin-top: 30px;
  .check {
    border: 2px solid #ffd997;
    border-radius: 16px;
    box-shadow: 0px 12px 17px 2px rgba(14, 7, 68, 0.05),
      0px 6px 40px 0px rgba(18, 9, 84, 0.06);
    padding: 25px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #333333;
    margin: 6px 0 20px 0;
  }
  .info {
    font-size: 18px;
    font-weight: 400;
    color: #666660;
    width: 270px;
    text-align: center;
  }
}
</style>
