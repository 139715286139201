<template>
  <div class="background">
    <div class="top-title">Register</div>
    <!-- 注册 -->
    <div class="register">
      <div class="close">
        <div @click="$router.go(-1)">
          <img
            class="closeBtn"
            src="../../../static/images/login/close.png"
            alt=""
          />
        </div>
      </div>
      <div class="register-info">
        <img src="../../../static/images/login/logo.png" class="logo" />
        <div
          class="login-type"
          v-if="changeType == 1"
          style="display: flex; align-item: center; justify-content: center"
        >
          <!-- :class="loginTypeIndex == 0 ? 'list-check' : 'list-default'" -->
          <div
            class="list-default"
            style="color: #444"
            @click="changeLoginTypeIndex(0, 1)"
          >
            {{ $t("register.EmailRegister") }}
            <!--邮箱注册-->
          </div>
          <!--手机号注册-->
          <!-- <div
            :class="loginTypeIndex == 1 ? 'list-check' : 'list-default'"
            @click="changeLoginTypeIndex(1,2)"
          >
            {{ $t("register.MobileNumberRegister") }}
           
          </div> -->
        </div>
        <el-scrollbar class="elScrollbar">
          <el-form
            ref="personal"
            :model="personalForm"
            :rules="personalRules"
            class="form"
          >
            <!-- 个人 -->
            <individual
              v-if="changeType == 1"
              :loginTypeIndex="loginTypeIndex"
              :form="personalForm"
            ></individual>
          </el-form>
          <el-form
            ref="company"
            :model="companyForm"
            :rules="personalRules"
            class="form"
          >
            <!-- 公司 -->
            <company :form="companyForm" v-if="changeType == 2"></company>
          </el-form>
        </el-scrollbar>

        <div class="input-desc">
          <el-checkbox v-model="checked">
            <!--勾选同意 《用户服务协议》-->
            <template v-if="$i18n.locale == 'en'">
              {{ $t("register.checkBox") }}
            </template>
            <template v-else>
              {{ $t("register.checkBox") }}
              <!--              <span style="color: #f5ad34">{{-->
              <!--                $t("register.checkBoxText")-->
              <!--              }}</span>-->
              <router-link
                tag="a"
                target="_blank"
                to="/agreement"
                style="color: #f5ad34"
                >{{ $t("register.checkBoxText") }}</router-link
              >
            </template>
          </el-checkbox>
        </div>
        <div class="registerBtn" @click="submit">
          {{ $t("register.Register") }}
        </div>
        <!--注册-->

        <div class="footerBottom">
          <div class="check-login">
            <router-link to="/login" style="color: #aaadba">{{
              $t("register.AlreadyRegisteredSignIn")
            }}</router-link>
          </div>
          <!--已有账号，立即登录-->
          <div class="language">
            <div
              :class="$i18n.locale == 'US' ? 'active' : ''"
              @click="languageChange(0)"
            >
              English
            </div>
            /
            <div
              :class="$i18n.locale == 'ZH' ? 'active' : ''"
              @click="languageChange(1)"
            >
              中文
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import company from "./company.vue";
import individual from "./Individual.vue";
import api from "../../comm/api";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  components: {
    company,
    individual,
  },

  data() {
    //手机号验证
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validatePhone")));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t("register.validatePhoneInspect")));
        }
      }
    };

    //邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validateEmail")));
      } else {
        const reg =
          /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t("register.validateEmailInspect")));
        }
      }
    };
    //两次密码确认
    var validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.validatePasswordAgin")));
      } else {
        if (
          this.personalForm.password != this.personalForm.passwordAgin ||
          this.companyForm.password != this.companyForm.passwordAgin
        ) {
          return callback(
            new Error(this.$t("register.validatePasswordAtypism"))
          );
        } else {
          return callback();
        }
      }
    };

    return {
      changeType: -1, //1 个人  2 公司
      loginTypeIndex: 0, //0 邮箱注册 1 手机号注册
      checked: false,
      waitCode: "60",
      codeType: 0,
      personalRules: {
        // phone: {
        //   validator: checkPhone,
        //   trigger: "blur",
        // },
        loginName: {
          validator: validateEmail,
          trigger: "blur",
        },
        passwordAgin: {
          validator: validatePassword,
          trigger: "blur",
        },
      },
      personalForm: {
        realName: "", //真实姓名
        phoneAscription: "45", //电话号码归属
        phoneCode: "", //电话号码
        //phone: "", //电话号码
        loginName: "", //邮箱
        emailCode: "", //验证码
        country: "", //国籍
        password: "", //密码
        passwordAgin: "", //再次输入密码
        nickName: "", //用户名
        identity: 1, //身份 1个人 2企业
        type: 1, // 注册类型：1邮箱， 2手机号
      },
      //公司表单
      companyForm: {
        phoneAscription: "45", //电话号码归属
        loginName: "", //邮箱
        emailCode: "", //验证码
        identity: 2, //身份 1个人 2企业
        password: "", //密码
        passwordAgin: "", //再次输入密码
        companyName: "", //公司名称
        companyType: "", //公司类型
        sn: "", //税号
        website: "", //网址
        contact: "", //联系人
        contactPhone: "", //联系人电话
        address: "", //联系地址
        post: "", //邮编
        country: "", //国家
        city: "", //城市
        nickName: "", //用户名
        type: 1, // 注册类型 1邮箱
      },
    };
  },
  mounted() {
    this.changeType = this.$route.query.type;
    // 获取国家-区号
    this.$store.dispatch("getAreaCodeData");
  },

  methods: {
    // 切换注册类型
    changeLoginTypeIndex(index, type) {
      this.loginTypeIndex = index;
      this.personalForm.type = type;
    },
    submit() {
      if (!this.checked) {
        ElMessage(this.$t("register.checkBoxUserAgreement"));
        return;
      }
      if (this.changeType == 1) {
        this.$refs["personal"].validate((valid) => {
          if (valid) {
            //个人邮箱 手机号注册
            this.personalForm.type = this.loginTypeIndex + 1;
            api.register(this.personalForm).then((res) => {
              if (res) {
                // ElMessage({
                //   message: this.$t("message.registerSuccess"),
                //   type: "success",
                // });
                this.$router.push({ name: "registerSucceed" });
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs["company"].validate((valid) => {
          if (valid) {
            //企业注册
            api.register(this.companyForm).then((res) => {
              if (res) {
                // ElMessage({
                //   message: this.$t("message.registerSuccess"),
                //   type: "success",
                // });
                this.$router.push({ name: "registerSucceed" });
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    //切换语言
    languageChange(type) {
      // this.$router.go(0);
      this.$i18n.locale = type == 0 ? "US" : "US";
      localStorage.setItem("local", type == 0 ? "US" : "US");
    },
  },
};
</script>

<style scoped lang="scss">
:deep .el-input__inner {
  border: 0;
  background-color: transparent;
}
.top-title {
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
  margin-top: 240px;
}
.input-style {
  width: 405px;
  margin-top: 20px;
  .submit {
    width: 408px;
    height: 69px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 99px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 69px;
    letter-spacing: 2px;
    margin-top: 80px;
    margin-bottom: 40px;
    cursor: pointer;
  }
  .get-code {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  .input2 {
    width: 228px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .input {
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;
    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #a8acb9;
    margin: 20px 0 10px 20px;
  }
}
.register {
  width: 1020px;
  // height: 80%;
  margin-bottom: 200px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 29px 40px 0px rgba(0, 0, 0, 0.62);
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
  }
  .check-login {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #aaadba;
    letter-spacing: 2px;
    border-bottom: 1px solid #aaadba;
    margin-top: 30px;
    cursor: pointer;
  }
  .registerBtn {
    width: 438px;
    height: 69px;
    background: #f5ad34;
    border-radius: 99px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 69px;
    letter-spacing: 2px;
  }
  .el-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .register-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 85px;
    box-sizing: border-box;
    .elScrollbar {
      flex: 1;
      height: 100%;
      padding: 20px 0;
      box-sizing: border-box;
    }
    .input-desc {
      height: 10px;
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left37 {
        margin-left: 37px;
      }
    }
    .logo {
      width: 236px;
      height: 64px;
    }
  }
  .close {
    display: flex;
    justify-content: space-between;
  }
}
.background {
  width: 100%;
  // height: 100%;
  display: flex;
  // padding: 200px 0;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  background: url("../../../static/images/login/bg.png") no-repeat;
  // position: fixed;
  background-size: cover;
}

.language {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  color: #aaadba;
  font-size: 14px;
}
.language div {
  cursor: pointer;
}
.language .active {
  color: #f5ad34;
}
.closeBtn {
  width: 20px;
  height: 20px;
}
.footerBottom {
  width: 438px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-type {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: 40px;
  .list-default {
    cursor: pointer;
    // width: 163px;
    height: 40px;
    font-size: 24px;
    height: 30px;
    font-weight: 900;
    text-align: center;
    color: #999999;
  }
  .list-check {
    cursor: pointer;
    // width: 163px;
    font-size: 24px;
    height: 38px;
    font-weight: 900;
    color: #444444;
    text-align: center;
    border-bottom: 2px solid #f5ad34;
  }
}

:deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #f5ad34;
  border-color: #f5ad34;
}
:deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f5ad34;
}
.bottom-box {
  width: 100%;
  height: 200px;
}
</style>
