<template>
  <div class="collection" :class="$i18n.locale == 'US' ? 'collection-US' : 'collection-ZH'">
    <span>{{$t('recruit.Favorite')}}</span>
    <img class="check_img" @click.stop="collect" v-if="favorite==true" src="../../../static/images/bg/2.png"/>
    <img class="unCheck_img" @click.stop="collect" v-else src="../../../static/images/bg/1.png"/>
    <!-- <i @click.stop="collect" class="iconfont" :class="favorite==true?'icon-xuanzhong1':'icon-shoucang1'"></i> -->
  </div>
</template>

<script>
export default {
  name: "Favorite",
  props: {
    favorite: {
      type: Boolean,
      default: false
    }
  },
  setup(props,{emit}) {
    // 收藏
    const collect = (() => {
      emit('collect')
    });

    return {
      collect,
    }
  }
};
</script>

<style scoped lang='scss'>
.collection {
 
  background: #ffffff;
  height: 48px;
  border: 1px solid #afafaf;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 30px 0;
}

.collection-US {
 width: 150px !important;
}
.collection-ZH {
  width: 120px !important;
}

.check_img {
  width: 36px;
  height: auto;
  position: relative;
  top: inherit;
  right: inherit;
  position: relative;
  bottom: 2px;
  right: 2px;
}
.unCheck_img {
  width: 36px;
  height: auto;
  position: relative;
  top: inherit;
  right: inherit;
  position: relative;
  top: 1px;
  right: 2px;
}

.icon-shoucang1 {
  font-size: 34px;
  position: relative;
  top: inherit;
  right: inherit;
  position: relative;
  top: 1px;
  right: 2px;
}
.icon-xuanzhong1 {
  position: relative;
  font-size: 44px;
  top: inherit;
  right: inherit;
  color: #F5A623;
}
</style>
