<template>
  <div class="contens">
    <div class="form">
      <template v-if="loginTypeIndex == 0">
        <el-form-item
          :label="$t('register.Name')"
          class="input-style"
          prop="realName"
          :rules="[
            {
              required: true,
              message: $t('register.validateRealName'),
              trigger: 'blur',
            },
          ]"
        >
          <!--真实姓名-->
          <div class="input">
            <img src="../../../static/images/login/User.png" />
            <el-input
              clearable
              v-model="form.realName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.MobileNumber')"
          class="input-style left37"
          prop="phone"
        >
          <!--电话号码-->
          <div class="input3">
            <el-select
              filterable
              v-model="form.phoneAscription"
              :placeholder="$t('register.PleaseSelect')"
            >
              <el-option
                v-for="item in $store.state.areaCodeList"
                :key="item.id"
                :label="
                  $i18n.locale == 'ZH'
                    ? item.zh + '+' + item.areaCode
                    : item.us + '+' + item.areaCode
                "
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="input2">
            <el-input
              clearable
              v-model="form.phone"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Email')"
          class="input-style"
          prop="loginName"
          :rules="[
            {
              required: true,
              message: $t('register.validateEmailCode'),
              trigger: 'blur',
            },
          ]"
        >
          <!--邮箱-->
          <div class="input">
            <img src="../../../static/images/login/email.png" />
            <el-input
              clearable
              v-model="form.loginName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.yam')"
          class="input-style left37"
          :rules="[
            {
              required: true,
              message: $t('register.validateEmailCode'),
              trigger: 'blur',
            },
          ]"
          prop="emailCode"
        >
          <!--邮箱验证-->
          <div class="input2">
            <img src="../../../static/images/login/code.png" />
            <el-input
              clearable
              v-model="form.emailCode"
              :placeholder="$t('register.PleaseInput')"
              maxlength="6"
            ></el-input>
          </div>
          <div v-if="codeType == 0" class="get-code" @click="getCode(1)">
            {{ $t("register.GetCode") }}
          </div>
          <!--获取验证码-->
          <div v-if="codeType == 1" class="get-code">{{ waitCode }}</div>
        </el-form-item>

        <el-form-item
          :label="$t('register.Nationality')"
          class="input-style"
          :rules="[
            {
              required: true,
              message: $t('register.validateCountry'),
              trigger: 'blur',
            },
          ]"
          prop="country"
        >
          <!--国籍-->
          <div class="input">
            <div class="input">
              <img src="../../../static/images/login/gj.png" />
              <!-- <el-input
                clearable
                v-model="form.country"
                :placeholder="$t('register.PleaseInput')"
              ></el-input> -->
              <el-select
                filterable
                v-model="form.country"
                :placeholder="$t('register.PleaseSelect')"
              >
                <el-option
                  v-for="item in $store.state.areaCodeList"
                  :key="item.id"
                  :label="$i18n.locale == 'ZH' ? item.zh : item.us"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>

        <el-form-item
          :label="$t('register.Password')"
          class="input-style"
          :rules="[
            {
              required: true,
              message: $t('register.validatePassword'),
              trigger: 'blur',
            },
          ]"
          prop="password"
        >
          <!--密码-->
          <div class="input">
            <img src="../../../static/images/login/Lock.png" />
            <el-input
              type="password"
              clearable
              v-model="form.password"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.ConfirmedPassword')"
          class="input-style"
          prop="passwordAgin"
          :rules="[
            {
              required: true,
              message: $t('register.validatePassword'),
              trigger: 'blur',
            },
          ]"
        >
          <!--确认密码-->
          <div class="input">
            <img src="../../../static/images/login/Lock.png" />
            <el-input
              type="password"
              clearable
              v-model="form.passwordAgin"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Account')"
          class="input-style"
          :rules="[
            {
              required: true,
              message: $t('register.validateNickName'),
              trigger: 'blur',
            },
          ]"
          prop="nickName"
        >
          <!--用户名-->
          <div class="input">
            <img src="../../../static/images/login/User.png" />
            <el-input
              clearable
              v-model="form.nickName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
      </template>

      <template v-else>
        <el-form-item
          :label="$t('register.MobileNumberchina')"
          class="input-style"
          prop="phone"
        >
          <!--手机号-->
          <div class="input">
            <img src="../../../static/images/login/phone.png" />
            <el-input
              clearable
              v-model="form.phone"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('login.AuthCode')"
          class="input-style left37"
          prop="phoneCode"
          :rules="[
            {
              required: true,
              message: $t('register.validatePhoneCode'),
              trigger: 'blur',
            },
          ]"
        >
          <!--验证码-->
          <div class="input2">
            <img src="../../../static/images/login/code.png" />
            <el-input
              clearable
              v-model="form.phoneCode"
              :placeholder="$t('register.PleaseInput')"
              maxlength="6"
            ></el-input>
          </div>
          <div v-if="codeType2 == 0" class="get-code" @click="getCode(2)">
            {{ $t("register.GetCode") }}
          </div>
          <!--获取验证码-->
          <div v-if="codeType2 == 1" class="get-code">{{ waitCode2 }}</div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Name')"
          class="input-style"
          prop="realName"
          :rules="[
            {
              required: true,
              message: $t('register.validateRealName'),
              trigger: 'blur',
            },
          ]"
        >
          <!--真实姓名-->
          <div class="input">
            <img src="../../../static/images/login/User.png" />
            <el-input
              clearable
              v-model="form.realName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Nationality')"
          class="input-style"
          prop="country"
          :rules="[
            {
              required: true,
              message: $t('register.validateCountry'),
              trigger: 'blur',
            },
          ]"
        >
          <!--国籍-->
          <div class="input">
            <div class="input">
              <img src="../../../static/images/login/gj.png" />
              <el-input
                clearable
                v-model="form.country"
                :placeholder="$t('register.PleaseInput')"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Email')"
          class="input-style"
          prop="loginName"
        >
          <!--邮箱-->
          <div class="input">
            <img src="../../../static/images/login/email.png" />
            <el-input
              clearable
              v-model="form.loginName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('register.AuthCode')"
          class="input-style left37"
          prop="emailCode"
          :rules="[
            {
              required: true,
              message: $t('register.validateEmailCode'),
              trigger: 'blur',
            },
          ]"
        >
          <!--邮箱验证-->
          <div class="input2">
            <img src="../../../static/images/login/code.png" />
            <el-input
              clearable
              v-model="form.emailCode"
              maxlength="6"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
          <div v-if="codeType3 == 0" class="get-code" @click="getCode(3)">
            {{ $t("register.GetCode") }}
          </div>
          <!--          <el-button-->
          <!--            :disabled="sendCode3"-->
          <!--            @click="getCode(3)"-->
          <!--            class="code-btn-style">{{ sendCode3?waitCode3:$t("register.GetCode") }}</el-button>-->
          <!-- 获取验证码-->
          <div v-if="codeType3 == 1" class="get-code">{{ waitCode3 }}</div>
        </el-form-item>
        <el-form-item
          :label="$t('register.Account')"
          class="input-style"
          :rules="[
            {
              required: true,
              message: $t('register.validateNickName'),
              trigger: 'blur',
            },
          ]"
          prop="nickName"
        >
          <!--用户名-->
          <div class="input">
            <img src="../../../static/images/login/User.png" />
            <el-input
              clearable
              v-model="form.nickName"
              :placeholder="$t('register.PleaseInput')"
            ></el-input>
          </div>
        </el-form-item>
      </template>
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import api from "@/comm/api";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  props: ["loginTypeIndex", "form"],
  data() {
    return {
      waitCode: "60",
      waitCode2: "60",
      waitCode3: "60",
      codeType3: 0,
      codeType2: 0,
      codeType: 0,
      sendCode3: false,
      rules: [],
    };
  },
  methods: {
    getCode: util.debounce(function (e) {
      let that = this;
      switch (e[0]) {
        case 1:
          if (!util.validateEmail(this.form.loginName)) {
            ElMessage({
              message: this.$t("register.validateEmailInspect"),
              type: "warning",
            });
            return;
          }

          var dat = {
            type: 1,
            operation: this.form.loginName,
          };
          api.registerCode(dat).then((res) => {
            if (res) {
              this.$message.success(this.$t("login.sendCode"));
              that.codeType = 1;
              that.beginInterval(e[0]);
            }
          });

          break;
        case 2:
          if (!util.checkPhone(this.form.phone)) {
            ElMessage({
              message: this.$t("register.validatePhoneInspect"),
              type: "warning",
            });
            return;
          }

          var da = {
            type: 2,
            operation: this.form.phone,
          };
          api.registerCode(da).then((res) => {
            if (res) {
              this.$message.success(this.$t("login.sendCode"));
              that.codeType2 = 1;
              that.beginInterval(e[0]);
            }
          });
          break;
        case 3:
          if (!util.validateEmail(this.form.loginName)) {
            ElMessage({
              message: this.$t("register.validateEmailInspect"),
              type: "warning",
            });
            return;
          }
          that.sendCode3 = true;
          var param = {
            type: 1,
            operation: this.form.loginName,
          };
          api
            .registerCode(param)
            .then((res) => {
              if (res) {
                this.$message.success(this.$t("login.sendCode"));
                that.codeType3 = 1;
                that.beginInterval(e[0]);
              }
            })
            .catch(() => {
              that.sendCode3 = false;
            });
          break;
        default:
          break;
      }
    }),
    beginInterval(e) {
      let that = this;

      switch (e) {
        case 1:
          that.waitCode = that.waitCode - 1;
          if (that.waitCode <= 0) {
            that.waitCode = 60;
            that.codeType = 0;
          } else {
            setTimeout(() => {
              that.beginInterval(e);
            }, 1000);
          }
          break;
        case 2:
          that.waitCode2 = that.waitCode2 - 1;
          if (that.waitCode2 <= 0) {
            that.waitCode2 = 60;
            that.codeType2 = 0;
          } else {
            setTimeout(() => {
              that.beginInterval(e);
            }, 1000);
          }
          break;
        case 3:
          that.waitCode3 = that.waitCode3 - 1;
          if (that.waitCode3 <= 0) {
            that.waitCode3 = 60;
            that.codeType3 = 0;
            that.sendCode3 = false;
          } else {
            setTimeout(() => {
              that.beginInterval(e);
            }, 1000);
          }
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep .el-input__inner {
  border: 0;
  background-color: transparent;
}
:deep .el-form-item {
  display: flex;
  flex-direction: column;
}
:deep .el-form-item__label {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #a8acb9;
  padding-left: 20px;
  box-sizing: border-box;
}
:deep .left37 .el-form-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep .el-select {
  width: 400px;
}
// :deep(.el-select){
//   width: 400px;
// }
.contens {
  width: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-style {
  width: 405px;
  margin-top: 20px;
  .submit {
    width: 408px;
    height: 69px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 99px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 69px;
    letter-spacing: 2px;
    margin-top: 80px;
    margin-bottom: 40px;
    cursor: pointer;
  }
  .get-code {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  .code-btn-style {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    border: none;
  }
  .input3 {
    width: 140px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .el-select {
      width: 140px;
    }
  }
  .input2 {
    width: 228px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .input {
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;
    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #a8acb9;
    margin: 20px 0 10px 20px;
  }
}
</style>
