<template>
  <div class="search-wrap">
    <div class="search">
      <div class="top">
        <div class="top-select">
          <div class="title">
            <div @click="selectIndex == 1 ? (selectIndex = -1) : (selectIndex = 1)"
              :class="selectIndex == 1 ? 'border-bottom' : ''" style="cursor: pointer">
              CHINA TOURS1
            </div>
            <div class="select-check1" v-if="selectIndex == 1">
              <div class="select-title">CHINA TOURS</div>
              <div class="platform-list">
                <div v-for="(item, index) in platformList" :key="index" class="list1"
                  @click="$router.push(item.router)">
                  {{ item.title }}
                </div>
              </div>
              0
            </div>
          </div>
          <div class="title">
            <div @click="selectIndex == 2 ? (selectIndex = -1) : (selectIndex = 2)"
              :class="selectIndex == 2 ? 'border-bottom' : ''" style="cursor: pointer">
              CHINESE VISA
            </div>
            <div class="select-check1" v-if="selectIndex == 2">
              <div class="select-title">CHINESE VISA</div>
              <div class="platform-list">
                <div v-for="(item, index) in schoolLifet" :key="index" class="list2" @click="$router.push(item.router)">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <div @click="selectIndex == 3 ? (selectIndex = -1) : (selectIndex = 3)"
              :class="selectIndex == 3 ? 'border-bottom' : ''" style="cursor: pointer">
              WI-FI CARD
            </div>
            <div class="select-check1 right1" v-if="selectIndex == 3">
              <div class="select-title">WI-FI CARD</div>
              <div class="platform-list">
                <div v-for="(item, index) in livingInShanghai" :key="index" class="list2"
                  @click="$router.push(item.router)">
                  {{ JSON.parse(item.title)[$i18n.locale] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <el-input placeholder="请输入关键词" suffix-icon="el-icon-search"></el-input>
        <div class="user">
          <div class="login">登录</div>
          <div class="register">注册</div>
          <div class="back-home">返回首页</div>
          <!-- <img src="../../../static/images/home/head.png" />
					<div class="user-name">Cammy Hedling</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectIndex: -1,
      platformList: [
        { title: "Here-STORE" },
        { title: "论坛", router: "forum" },
        { title: "住宿", router: "accommodation" },
        { title: "学习/培训" },
        { title: "留学生须知", router: "overseasStudentNews" },
        { title: "书籍" },
        { title: "外国人服务站" },
        { title: "数字人民币" },
        { title: "新闻", router: "overseasStudentNews" },
      ],
      schoolLifet: [
        { title: "签证", router: "groupSignature" },
        { title: "上网卡" },
        { title: "保险" },
        { title: "数字人民币" },
        { title: "Vip接机" },
        { title: "Here-Store" },
      ],
      livingInShanghai: [
        { title: "住宿登记", router: "accommodation" },
        { title: "旅游攻略", router: "travel" },
        { title: "美食", router: "deliciousFood" },
        { title: "招聘", router: "recruit" },
        { title: "医疗" },
        { title: "休闲/玩乐" },
        { title: "二手买卖" },
        { title: "论坛", router: "forum" },
        { title: "景点门票", router: "ticket" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.search-wrap {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.top {
  margin-left: 100px;

  .top-select {
    width: 874px;
    height: 80px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    .title {
      font-size: 24px;
      font-weight: 900;
      color: #222a41;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;
      height: 40px;
    }

    .border-bottom {
      border-bottom: 3px solid #f7bf62;
    }

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }

    .right1 {
      right: -70px;
    }

    .select-check1 {
      visibility: visible;

      background: #ffffff;
      width: 453px;
      border-radius: 36px;
      top: 50px;
      position: absolute;
      padding: 30px;

      .select-title {
        font-size: 30px;
        font-weight: 900;
        color: #444444;
      }

      .platform-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .list1 {
          width: 40%;
          font-size: 22px;
          font-weight: 900;
          color: #444444;
          margin-top: 30px;
          cursor: pointer;
        }

        .list2 {
          width: 30%;
          font-size: 22px;
          font-weight: 900;
          color: #444444;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #f1eae3;
  padding: 8px 0 8px 0;

  .logo {
    height: 64px;
    margin-left: 130px;
    mix-blend-mode: multiply;
  }

  .right {
    display: flex;
    align-items: center;

    .el-input {
      width: 320px;
    }

    .user {
      display: flex;
      align-items: center;

      .login {
        font-size: 22px;
        font-weight: 900;
        color: #444444;
        margin: 0 26px 0 16px;
      }

      .back-home {
        height: 48px;
        max-height: 48px !important;
        font-size: 20px;
        font-weight: 900;
        color: #fff;
        margin-left: 10px;
        background: #f5ad34;
        border-radius: 24px;
        line-height: 40px;
        padding: 5px 8px;
        border-radius: 20px;
        white-space: nowrap;
      }

      .register {
        width: 108px;
        height: 54px;
        background: #f5ad34;
        border-radius: 8px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;
        line-height: 54px;
      }

      img {
        width: 54px;
        height: 54px;
        margin: 0 15px 0 20px;
      }

      .user-name {
        width: 200px;
        font-size: 16px;
        font-weight: 500;
        color: #444444;
      }
    }
  }
}
</style>
