<template>
  <div class="content-wrap">
    <div class="flex-wrap">
      <div class="empty-content" v-if="collectionList.length == 0">
        <el-empty :image-size="200"></el-empty>
      </div>
      <div class="listWrap" v-else v-for="(item, index) in collectionList" :key="index">
        <div class="list">
          <img :src="item.image[0]" alt="" />
          <div class="list-title">
            <div class="title">{{item.company[$i18n.locale]}}</div>
            <div class="money">{{item.price}}</div>
          </div>
          <div class="desc text-overflow-four">{{$t('recruit.JobProfile')}}：{{item.jobRequirement[$i18n.locale]}}{{item.jobRequirement[$i18n.locale]}}</div>
        </div>
      </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from "@/components/page.vue";
import { reactive, toRefs } from "vue";
import api from "@/comm/api";
export default {
  components: { Page },
  name: "Company",
  setup() {
    const data = reactive({
      collectionList: [],
      total: 0,
      common: { page: 1, pageSize: 6, search: {} },
    });

    // 获取[已收藏岗位]数据
    const getData = () => {
      api.myRecruit(data.common).then((res) => {
        if (res) {
          data.total = res.total;
          res.records.forEach((item) => {
            item.image = item.image.split(",");
            item.company = JSON.parse(item.company);
            item.jobRequirement = JSON.parse(item.jobRequirement);
          });
          data.collectionList = res.records;
        }
      });
    };

    // 根据页码改变列表数据
    const changePage = (e) => {
      data.common.page = e;
      getData();
    };

    return {
      ...toRefs(data),
      getData,
      changePage,
    };
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  min-height: 500px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 40px 40px 10px 40px;
  box-sizing: border-box;
  min-height: 518px;
}
.page-wrap {
  margin-top: 40px;
}
.img-box {
  width: 100%;
  height: 180px;
  img {
    border-radius: 11px 11px 0 0;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}
.listWrap{
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.list {
  width: 290px;
  opacity: 1;
  background: #f7f7f7;
  border: 1px solid #d8d8d8;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  height: 430px;
  // margin-right: 22px;
  cursor: pointer;
  img {
    width: 100%;
    height: 224px;
    border-radius: 16px;
    object-fit: cover;
  }
}
.list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 30px 0 20px 0;
}
.title {
  width: 50%;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  color: #333333;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.money {
  font-size: 18px;
  font-weight: 900;
  color: #f49d44;
  letter-spacing: 1px;
}
.desc {
  flex: 1;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #666666;
  line-height: 26px;
}
</style>