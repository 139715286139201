<template>
  <div class="content-wrap">
    <div class="flex-wrap">
        <div class="empty-content" v-if=" collectionList.length == 0">
          <el-empty :image-size="200"></el-empty>
        </div>
        <div v-else v-for="(item, index) in collectionList" :key="index"  class="listWrap">
          <div class="list" @click="$router.push({ path: '/deliciousFoodDetail', query: { id:item.id } })">
              <div class="img-box">
                  <img :src="item.image[0]" alt="" />
              </div>
              <div class="desc">
              <div>{{item.detail[$i18n.locale]}}</div>
              </div>
          </div>
        </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from '@/components/page.vue';
import {reactive, toRefs} from 'vue';
import api from "@/comm/api";
export default {
  components: { Page },
  name: "Two",
  setup() {
    const data = reactive({
        collectionList: [],
        total: 0,
        common: { page:1, pageSize: 8,search: {} },
    });

    // 获取[美食]数据
    const getData = (() => {
        api.myDeliciousFood(data.common).then((res) => {
            if(res) {
                data.total = res.total;            
                res.records.forEach(item => {
                    item.image = item.image.split(',');
                    item.detail = JSON.parse(item.detail);
                });
                data.collectionList = res.records;
            }
        })
    })

    // 根据页码改变列表数据
    const changePage = ((e) => {
      data.common.page = e;
      getData();
    })

    return {
        ...toRefs(data),
        getData,
        changePage,
    }
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  min-height: 500px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 40px 40px 10px 40px;
  box-sizing: border-box;
  min-height: 518px;
}
.page-wrap {
    margin-top: 40px;
}
.listWrap{
  width: 25%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.list {
  width: 232px;
  height: 310px;
  opacity: 1;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.img-box {
    width: 100%;
    height: 180px;
    img {
        border-radius: 11px 11px 0 0;
        width: 100%;
        height: 180px;
        object-fit: cover;
    }
}

.desc {
    flex: 1;
    width: 100%;
    padding: 17px 11px 25px 11px;
    box-sizing: border-box;
    div {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #666666;
        line-height: 23px;
        letter-spacing: 0px;
        //margin-top: 14px;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 4;
    }
}
</style>