<template>
  <div class="topic">
    <!--话题列表-->
    <div class="bottom" v-if="topicList.length != 0">
      <div
        v-for="(item, index) in topicList"
        :key="index"
        @click="$router.push({ path: '/forumDetails', query: { id: item.id } })"
      >
        <div class="scrollbar-title text-overflow-one">
          {{ JSON.parse(item.title)[$i18n.locale] }}
        </div>
        <div class="scrollbar-desc text-overflow-four">
          <!-- {{ JSON.parse(item.description)[$i18n.locale] }} -->
          <rich-text
            v-html="JSON.parse(item.description)[$i18n.locale]"
          ></rich-text>
        </div>
        <div class="flex">
          <img v-for="(ite, inde) in item.images" :key="inde" :src="ite" />
        </div>

        <CountUser
          :msg="item.comments == null ? 0 : item.comments"
          :user="item.createUserName == null ? '--' : item.createUserName"
          :collect="true"
          :favorite="item"
          @collects="collects"
        />
      </div>
    </div>
    <el-empty :image-size="200" v-else></el-empty>
    <!--分页-->
    <div class="pages">
      <page
        :total="total"
        :page-size="tableForm.pageSize"
        @change-page="changePage"
      ></page>
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
import { useStore } from "vuex";
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import CountUser from "../../../components/CountUser/CountUser";
import Page from "../../../components/page";
export default {
  name: "TopicList",
  components: { CountUser, FavoriteCard, Page },
  props: {
    topicId: {
      type: String,
    },
    updateShow: {
      type: Boolean,
    },
    type: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const data = reactive({
      topicList: [],
    });
    //翻页
    const params = reactive({
      total: 0,
      tableForm: {
        page: 1,
        pageSize: 3,
        search: { topicId: props.topicId ? props.topicId : "" },
      },
    });

    //获取文章列表
    const articleListGet = async () => {
      api.articleList(params.tableForm).then((res) => {
        params.total = res.total;
        data.topicList = res.records;
        if (props.updateShow) {
          emit("changeUpdate");
        }
        data.topicList.map((val) => {
          val.images = val.images.split(",");
        });
      });
    };

    // 改变页码
    const changePage = (e) => {
      params.tableForm.page = e;
      articleListGet();
    };

    //收藏
    const collects = (item) => {
      let collection = item.collection ? false : true;
      api
        .articleUpdate({
          aimId: item.id,
          topicId: item.topicId,
          collection,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: collection
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            articleListGet(); //获取文章列表
            emit("clickTrue");
          }
        });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      // articleListGet();//获取文章列表
    });

    watchEffect(() => {
      // if(props.type=='1'){
      //   articleListGet() //获取文章列表
      // }
      if (props.updateShow) {
        articleListGet(); //获取文章列表
      }
      if (props.topicId) {
        params.tableForm.search.topicId = props.topicId;
        articleListGet(); //获取文章列表
      } else {
        articleListGet();
      }
    });

    return {
      ...toRefs(params),
      ...toRefs(data),
      changePage, //改变页码
      collects, //收藏
    };
  },
};
</script>

<style scoped lang="scss">
.bottom {
  box-sizing: border-box;
  padding: 0 0 36px 36px !important;
  // width: 944px;
  background: #f8f9fc;
  .count-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: auto;
      margin-right: 9px;
      object-fit: cover;
    }
    span {
      font-size: 16px;
      font-weight: 900;
      color: #666666;
    }
  }
  .scrollbar-title {
    width: 80%;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin-top: 40px;
  }
  .scrollbar-desc {
    width: 80%;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    margin: 8px 0 10px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  img {
    width: 176px;
    height: 136px;
    margin-right: 15px;
    margin-bottom: 11px;
    object-fit: cover;
  }
}
.pages {
  margin-top: 30px;
}
</style>
