<template>
  <div class="content-wrap">
    <div class="content" v-if="details != null">
      <!-- 订单信息 -->
      <div class="list">
        <div class="title">{{ $t('orderInfo.PracticeInfo') }}</div>
        <el-row :gutter="20">
          <el-col :span="8" v-if="details.childType != 6">
            {{ $t('payMent.PassengerName') }}：
            <!-- {{ details.childType }} -->
            <span v-if="details.childType >= 1 && details.childType < 6">{{
              details.visaAll && details.visaAll[0]
                ? details.visaAll[0].englishName +
                  ' ' +
                  details.visaAll[0].surname
                : ''
            }}</span>
            <span v-if="details.childType == 7">{{
              details.vipAirportPickup && details.vipAirportPickup[0]
                ? details.vipAirportPickup[0].englishName +
                  ' ' +
                  details.vipAirportPickup[0].surName
                : ''
            }}</span>
            <span v-if="details.childType == 8">{{
              details.vipAirportPickup && details.vipAirportPickup[0]
                ? details.vipAirportPickup[0].chineseName
                : ''
            }}</span>
            <span v-if="details.childType == 9">{{
              details.vipAirportPickup && details.vipAirportPickup[0]
                ? details.vipAirportPickup[0].englishName +
                  ' ' +
                  details.visvipAirportPickupaAll[0].surName
                : ''
            }}</span>
            <span v-if="details.childType == 9">{{
              details.trafficCardOrderInfo
                ? JSON.parse(details.trafficCardOrderInfo[0].trafficCard.name)[
                    $i18n.locale
                  ]
                : '-'
            }}</span>
          </el-col>
          <el-col :span="8">
            {{ $t('tableConfig.childType') }}：
            <span v-if="details.childType == 9">{{
              details.trafficCardOrderInfo
                ? JSON.parse(details.trafficCardOrderInfo[0].trafficCard.name)[
                    $i18n.locale
                  ]
                : '-'
            }}</span>
            <span v-else>{{
              details.childType
                ? $commonData.myOrder.childType[details.childType - 1].label
                : '-'
            }}</span>
          </el-col>
          <el-col :span="8">
            <span v-if="details.childType == 7"
              >{{ $t('orderInfo.label.PurchaseNo')
              }}{{ details.vipAirportPickup.length }}</span
            >
            <span v-else-if="details.childType == 8"
              >{{ $t('orderInfo.label.PaxNo')
              }}{{ details.vipAirportPickup[0].carPassengers }}</span
            >
            <span v-else-if="details.childType == 9"
              >{{ $t('orderInfo.label.PurchaseNo')
              }}{{ details.trafficCardOrderInfo[0].count }}</span
            >
            <span v-else
              >{{ $t('orderInfo.label.PaxNo')
              }}{{ details.visaAll.length }}</span
            >
          </el-col>
          <el-col :span="8"
            >{{ $t('orderInfo.label.PracticeNo')
            }}{{ details.orderNumber }}</el-col
          >
          <el-col :span="8"
            >{{ $t('orderInfo.label.PaymentNo')
            }}{{ details.payerId ? details.payerId : '-' }}</el-col
          >
          <el-col :span="8"
            >{{ $t('orderInfo.label.Price')
            }}{{
              details.price ? Number(details.price).toFixed(2) : '0.00'
            }}</el-col
          >
          <!-- <el-col :span="8">{{ $t('orderInfo.label.Discount') }}0.00</el-col> -->
          <el-col :span="8">
            {{ $t('orderInfo.label.Status') }}
            {{
              details.orderStatus != undefined
              ?
                ($tm('orderStatus')[details.orderStatus]).label
                : '-'
            }}
          </el-col>

          <el-col :span="8"
            >{{ $t('orderInfo.label.MethodOfPayment') }}
            <!-- {{
              details.paymentMode
                ? $commonData.myOrder.paymentMode[details.paymentMode - 1].label
                : "-"
            }} -->

            <span v-if="details.childType >= 5">
              {{
                details.paymentMode == 1
                  ? $t('payMent.CreditCard')
                  : details.paymentMode == 2
                  ? 'Paypal'
                  : details.paymentMode == 3
                  ? $t('payMent.Alipay')
                  : details.paymentMode == 4
                  ? $t('payMent.Deposit')
                  : ''
              }}
            </span>
            <span v-else>--</span>
          </el-col>
          <el-col :span="8"
            >{{ $t('groupSignature.serviceMode') }}:{{
              details.urgentName
            }}</el-col
          >
        </el-row>
      </div>
      <div class="list">
        <div class="title">
          {{ $t('groupSignature.submitInfo.passengersInfo') }}
        </div>
        <el-row
          :gutter="20"
          v-if="
            (details.childType >= 1 && details.childType <= 6) ||
            details.childType == 8
          "
          v-for="(groupItem, index) in details.visaAll"
          :key="index"
        >
          <el-col :span="8"
            >{{ $t('payMent.PassengerName') }}：{{
              groupItem.englishName + ' ' + groupItem.surname
            }}</el-col
          >
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </div>
      <!-- 寄送地址 -->
      <div
        class="list"
        v-if="
          details.childType != 7 &&
          details.childType != 8 &&
          details.childType != 5
        "
      >
        <div class="title">{{ $t('orderInfo.PostAddress') }}</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <span>{{ $t('orderInfo.label.Recipient') }}</span>
            <span v-if="details.childType == 7 || details.childType == 8"
              >{{ details.vipAirportPickup[0].englishName }}&nbsp;{{
                details.vipAirportPickup[0].surname
              }}</span
            >
            <span v-else-if="details.childType == 9">{{
              details.trafficCardOrderInfo[0].addressInfo.name
            }}</span>
            <span v-else>{{ details.receiveUser }}</span>
          </el-col>
          <el-col :span="8">
            <span>{{ $t('orderInfo.label.PhoneNumber') }}</span>
            <span v-if="details.childType == 7 || details.childType == 8">{{
              details.receivePhone
            }}</span>
            <span v-else-if="details.childType == 9">{{
              details.receivePhone
            }}</span>
            <span v-else>{{ details.receivePhone }}</span>
          </el-col>

          <el-col :span="16">
            <span>{{ $t('orderInfo.label.Address') }}</span>
            <span v-if="details.childType == 7 || details.childType == 8">{{
              details.receiveAddress
            }}</span>
            <span v-else-if="details.childType == 9">{{
              details.receiveAddress
            }}</span>
            <span v-else>{{ details.receiveAddress }}</span>
          </el-col>
        </el-row>
      </div>
      <!-- 快递单号 -->
      <div class="list" v-if="details.childType != 7 && details.childType != 8">
        <div class="title">{{ $t('orderInfo.CourierNo') }}</div>
        <div class="flexRow" v-if="details.expressNumber">
          <div>
            {{ $tm('commonData.expressType')[details.logistics - 1].title
            }}{{ $t('orderInfo.label.ExpressOrderNo')
            }}{{ details.expressNumber }}
          </div>
          <div class="tip-text" @click="goLogistics">
            {{ $t('orderInfo.CheckTheLogistics') }}
          </div>
        </div>
        <div class="flexRow" v-else>
          {{ $t('orderInfo.NoLogisticsInformation') }}
        </div>
      </div>
      <!-- 其他 -->
      <div class="list">
        <div class="title">{{ $t('orderInfo.Other') }}</div>
        <template v-if="details.documents != null">
          <div class="flexRow">
            <div>
              {{
                JSON.parse(details.documents)
                  ? JSON.parse(details.documents).name
                  : ''
              }}
            </div>
            <div
              v-if="
                JSON.parse(details.documents) &&
                JSON.parse(details.documents).url
              "
              class="tip-text"
              @click="downLoad(JSON.parse(details.documents).url)"
            >
              {{ $t('orderInfo.Download') }}
            </div>
          </div>
          <div class="flexRow">
            <div>
              {{
                JSON.parse(details.manual)
                  ? JSON.parse(details.manual).name
                  : ''
              }}
            </div>
            <div
              v-if="
                JSON.parse(details.documents) &&
                JSON.parse(details.documents).url
              "
              class="tip-text"
              @click="downLoad(JSON.parse(details.manual).url)"
            >
              {{ $t('orderInfo.Download') }}
            </div>
          </div>
        </template>
        <div v-else class="flexRow">{{ $t('orderInfo.NoInformation') }}</div>
      </div>
      <!-- 反馈说明 -->
      <div class="list">
        <div class="title">{{ $t('orderInfo.FeedbackDescription') }}</div>
        <div v-if="operatorInfoList.length == 0" class="flexRow">
          {{ $t('orderInfo.NoFeedbackDescription') }}
        </div>
        <div v-else v-for="(item, index) in operatorInfoList" :key="index">
          <div class="flexRow">
            <div>{{ $t('orderInfo.label.Time') }}</div>
            <div>{{ item.createDate }}</div>
          </div>
          <div class="flexRow">
            <div>{{ $t('orderInfo.label.DescriptionContent') }}</div>
            <div>{{ JSON.parse(item.operatorInformation)[$i18n.locale] }}</div>
          </div>
        </div>
        <el-pagination
          v-if="common.page > 1"
          class="mt-40"
          layout="prev, pager, next"
          :total="total"
          :page-size="common.pageSize"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import api from '@/comm/api'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n' //要在js中使用国际化
export default {
  name: 'OrderDetails',
  props: ['orderId'],
  setup(props) {
    const { t } = useI18n()
    const data = reactive({
      details: null,
      operatorInfoList: [],
      total: 0,
      common: { page: 1, pageSize: 10, search: { orderId: props.orderId } },
    })

    // 获取[订单详情]数据
    const getData = () => {
      api.getOrderInfoDetails({ id: props.orderId }).then((res) => {
        if (res) {
          data.details = res
        }
      })
    }

    // 反馈说明（操作人信息）
    const getOperatorInformation = () => {
      api.operatorInformation(data.common).then((res) => {
        if (res) {
          data.operatorInfoList = res.records
          data.total = res.total
        }
      })
    }

    // 根据页码改变反馈说明（操作人信息）
    const changePage = (e) => {
      data.common.page = e
      getOperatorInformation()
    }

    // 根据类型去不同的物流官网
    const goLogistics = () => {
      const webType = {
        1: 'https://www.fedex.com/', // Fedex
        2: 'https://www.dhl.com/', // DHL
        3: 'https://www.sf-express.com/', // SF-EXPRESS
        4: 'https://www.ems.com.cn/', //EMS
      }
      let i = Number(data.details.logistics)
      window.open(webType[i], '_blank')
    }

    // 下载[其他]文件
    const downLoad = (url) => {
      window.open(url)
    }

    return {
      ...toRefs(data),
      getData,
      getOperatorInformation,
      goLogistics,
      downLoad,
      changePage,
    }
  },
}
</script>
<style scoped lang="scss">
.content {
  min-height: 518px;
  padding: 26px;
  box-sizing: border-box;
}
.list {
  padding: 23px;
  box-sizing: border-box;
  border: 1px solid #cecece;
  margin-bottom: 20px;
}
.title {
  color: #444444;
  font-size: 18px;
  font-weight: 600;
}
.el-col-8,
.el-col-16,
.el-col-24,
.flexRow {
  margin-top: 10px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
}
.tip-text {
  color: #f5a623;
  margin-left: 40px;
  cursor: pointer;
}
.mt-40 {
  margin-top: 20px;
  margin-left: -10px;
}
</style>
