<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? '#fff' : ''"
      :advertises="advertises"
    />

    <div :class="className" class="content">
      <Carousel :list="bannerList" />
      <div class="cardModelWrap">
        <div class="fooddetails">
          <div class="fooddetailsInfos">
            <img
              class="address"
              src="../../../static/images/food/address.png"
              alt=""
            />
            <span>{{ details.location == "" ? "--" : details.location }}</span>
          </div>
          <div class="wraps">
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/part.png" alt="" />
              <span>{{
                details.partOf == "" ? "--" : "Part of：" + details.partOf
              }}</span>
            </div>
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/phone.png" alt="" />
              <span>{{
                details.phone == "" || details.phone == null
                  ? "--"
                  : details.phone
              }}</span>
            </div>
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/train.png" alt="" />
              <span>{{ details.traffic == "" ? "--" : details.traffic }}</span>
            </div>
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/wx.png" alt="" />
              <span>{{
                details.contact == "" || details.contact == null
                  ? "--"
                  : "ID：" + details.contact
              }}</span>
            </div>
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/web.png" alt="" />
              <span>{{
                details.web == "" || details.web == null
                  ? "--"
                  : "Web-" + details.web
              }}</span>
            </div>
            <div class="fooddetailsInfos fooddetailsInfosWidth">
              <img src="../../../static/images/food/time.png" alt="" />
              <span>{{
                details.openingHours == "" || details.openingHours == null
                  ? "--"
                  : details.openingHours
              }}</span>
            </div>
          </div>
        </div>
        <!-- 富文本 -->
        <div class="fooddetails fooddetailsHeight">
          <div class="textsTitle">
            <div>{{ details.title }}</div>
            <div>Last updated: {{ details.updateDate }}</div>
          </div>
          <!--收藏-->
          <favorite @collect="collect" :favorite="details.favorite" />
          <!-- 富文本内容 -->
          <div v-html="details.content"></div>
        </div>
      </div>
      <!-- 热门场所美食 -->
      <hot-food @change-data="changeDataById" @go-details="goDetails" />
    </div>

    <!--联系我们-->
    <contact-us-one background="#F5F5F5"></contact-us-one>
  </div>
</template>

<script>
import contactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import page from "@/components/page";
import api from "@/comm/api";
import util from "@/comm/util";
import { useRoute } from "vue-router";
import { reactive, toRefs, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import Favorite from "@/components/Favorite/Favorite";
import HotFood from "./ChildCom/HotFood";
import Carousel from "@/components/Carousel/Carousel";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import { ElMessage } from "element-plus";

export default {
  components: {
    Carousel,
    HotFood,
    Favorite,
    contactUsOne,
    topSearchThree,
    page,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      details: {}, // 详情
      bannerList: [], //banner 数组
      className: "",
      advertises: [],
    });

    // 获取详情
    const getFoodDetails = (e) => {
      api.deliciousFoodById({ id: e ? e : route.query.id }).then((res) => {
        // 处理数据
        if (res) {
          for (const [key, val] of Object.entries(res)) {
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;
                // 判断首尾是否是{}
                if (first && last) {
                  data.details[key] = JSON.parse(val)[data.local];
                } else {
                  data.details[key] = val;
                }
              } else {
                // 判断val不是字符串
                data.details[key] = val;
              }
            }
          }

          const regex = new RegExp("<img", "gi");
          data.details.content = data.details.content.replace(
            regex,
            `<img style="width: 100%; height: auto;margin:10px 0;"`
          );
          data.details.content = data.details.content
            .replace(
              "<iframe",
              `<video style="width:100%;" controls="" autoplay=""`
            )
            .replace("</iframe>", "</video>");
          // 获取bannerList
          data.bannerList = res.banner.split(",");
        }
      });
    };
    // 从推荐喜欢来
    const changeDataById = (e) => {
      getFoodDetails(e);
    };

    //喜欢列表 子组件
    const goDetails = (e) => {
      getFoodDetails(e);
    };

    // 收藏
    const collect = () => {
      const val = data.details;
      let favorite = val.favorite ? false : true;
      api
        .deliciousFoodFavorite({
          classification: val.classification,
          deliciousFoodId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            data.details.favorite = favorite;
          }
        });
    };

    onMounted(() => {
      store.dispatch("getAdvertisesData", 5); // 获取广告位 图
      window.scrollTo(0, 0);
      getFoodDetails();
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      collect,
      changeDataById,
      goDetails,
    };
  },
};
</script>

<style scoped lang="scss">
.foodWrap {
  background: #f5f5f5;
}

.banner-carousel {
  :deep .el-carousel__container {
    height: 670px;
  }

  .top-banner {
    width: 100%;
    height: 670px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
}

.cardModelWrap {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.planeFood {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 31px;

  .planeTitle {
    margin-bottom: 36px;
    font-size: 44px;
    color: #444;
  }
}

.pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 20px;
}

.planeFoodList {
  width: 100%;
  display: flex;
  align-items: center;

  .planeFoodList_listsWrap {
    width: 33.3%;
    height: 532px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.planeFoodList_listsWrap:first-child {
  justify-content: flex-start;
}

.planeFoodList_listsWrap:last-child {
  justify-content: flex-end;
}

.planeFoodList_listsWrap > .lists {
  width: 416px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  .images {
    width: 100%;
    height: 322px;
    position: relative;

    img:nth-of-type(1) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  .foodInfos {
    flex: 1;
    width: 100%;
    padding: 27px 22px;
    box-sizing: border-box;
    font-size: 18px;
    color: #666;

    div {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
  }
}

.fooddetails {
  width: 100%;
  // height: 463px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 46px;
  padding: 43px 36px 0 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fooddetailsInfos {
  display: flex;
  align-items: flex-start;
  font-size: 30px;
  color: #333;
  margin-bottom: 43px;

  img {
    width: 34px;
    height: 34px;
    margin-right: 24px;
  }

  .address {
    width: 32px;
    height: 37px;
  }
}

.wraps {
  flex: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 20px;

  .fooddetailsInfosWidth {
    width: 50%;

    span {
      font-size: 24px;
    }
  }
}

.fooddetailsHeight {
  height: inherit;
}

.textsTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:nth-of-type(1) {
    font-size: 30px;
  }

  div:nth-of-type(2) {
    font-size: 20px;
    color: #666;
  }
}

.Favorite {
  width: 146px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #afafaf;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #444;
  margin: 17px 0 20px;
  padding: 0 16px;
  box-sizing: border-box;
}
</style>
