<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className" class="content">
      <div class="forum">
        <my-like-forum :updateShow="updateShow" @is-show-click="isShowClick" />
        <div class="right">
          <!--话题列表-->
          <div class="bottom" v-if="infos != null">
            <div class="top-back" @click="$router.replace({ path: '/forum' })">
              <i class="iconfont icon-arrow-left" style="margin-right: 10px"></i
              >{{ $t("forum.back") }}
            </div>
            <!--话题内容-->
            <div class="pad-20">
              <div class="scrollbar-title">
                {{ JSON.parse(infos.title)[$i18n.locale] }}
              </div>
              <div
                class="scrollbar-desc"
                v-html="JSON.parse(infos.description)[$i18n.locale]"
              ></div>
              <div class="flex">
                <img
                  class="scrollbar-desc-img"
                  v-for="(ite, inde) in infos.images"
                  :key="inde"
                  :src="ite"
                />
              </div>

              <count-user
                :msg="infos.comments == null ? 0 : infos.comments"
                :user="
                  infos.createUserName == null ? '--' : infos.createUserName
                "
                :collect="true"
                :favorite="infos"
                @collects="collects"
              />
            </div>
            <!--评论列表-->
            <div class="comments pad-20">
              <div class="comments-top">
                <div class="comments-title">
                  {{ infos.comments == null ? 0 : infos.comments
                  }}{{ $t("forum.Comments") }}
                </div>
                <template v-if="conmmentOnelist.length != 0">
                  <div
                    class="comments-list"
                    v-for="(item, index) in conmmentOnelist"
                    :key="index"
                  >
                    <img
                      v-if="item.avatar != null"
                      class="list-left"
                      :src="item.avatar"
                      alt=""
                    />
                    <img
                      v-else
                      class="list-left"
                      src="../../../static/images/home/head.png"
                      alt=""
                    />
                    <div class="list-right">
                      <!--一级评论-->
                      <div
                        class="cursor-pointer"
                        @click="handleShowReply(item, index)"
                      >
                        <div class="right-head">
                          <div class="right-head-user">
                            {{ item.nickName == null ? "--" : item.nickName }}
                          </div>
                          <div class="right-head-time">
                            {{ item.createDate }}
                          </div>
                        </div>
                        <div class="right-desc" v-if="item.opinion.length > 50">
                          <span v-if="!item.isShow"
                            >{{ item.opinion.slice(0, 200) }}...</span
                          >
                          <span v-else>{{ item.opinion }}</span>
                          <span
                            style="
                              color: #f5a623;
                              cursor: pointer;
                              margin-top: 15px;
                            "
                            v-if="!item.isShow"
                            @click="item.isShow = true"
                            >OPEN</span
                          >
                          <span
                            style="
                              color: #f5a623;
                              cursor: pointer;
                              margin-top: 15px;
                            "
                            v-else
                            @click="item.isShow = false"
                            >HIDE</span
                          >
                        </div>
                        <div v-else class="right-desc">
                          <span>{{ item.opinion }}</span>
                        </div>
                        <div class="count-box">
                          <img
                            src="../../../static/images/personal/msg.png"
                            alt=""
                          />
                          <div>{{ $t("forum.reply") }}</div>
                        </div>
                      </div>
                      <!--二级评论回复-->
                      <div v-if="currentIndex == index" class="show-reply">
                        <div
                          class="comments-list"
                          v-for="(child, childIndex) in conmmentTwolist"
                          :key="childIndex"
                        >
                          <img
                            v-if="child.avatar != null"
                            class="list-left"
                            :src="child.avatar"
                            alt=""
                          />
                          <img
                            v-else
                            class="list-left"
                            src="../../../static/images/home/head.png"
                            alt=""
                          />
                          <div class="list-right">
                            <!--一级评论-->
                            <div>
                              <div class="right-head">
                                <div class="right-head-user">
                                  {{
                                    child.nickName == null
                                      ? "--"
                                      : child.nickName
                                  }}
                                </div>
                                <div class="right-head-time">
                                  {{ child.createDate }}
                                </div>
                              </div>
                              <div
                                class="right-desc"
                                v-if="child.opinion.length > 50"
                              >
                                <span v-if="!child.isShow"
                                  >{{ child.opinion.slice(0, 200) }}...</span
                                >
                                <span v-else>{{ child.opinion }}</span>
                                <span
                                  style="
                                    color: #f5a623;
                                    cursor: pointer;
                                    margin-top: 15px;
                                  "
                                  v-if="!child.isShow"
                                  @click="child.isShow = true"
                                  >OPEN</span
                                >
                                <span
                                  style="
                                    color: #f5a623;
                                    cursor: pointer;
                                    margin-top: 15px;
                                  "
                                  v-else
                                  @click="child.isShow = false"
                                  >HIDE</span
                                >
                              </div>
                              <div v-else class="right-desc">
                                <span>{{ child.opinion }}</span>
                              </div>
                              <!-- <div class="count-box">
                                <img src="../../../static/images/personal/msg.png" alt="" />
                                <div>{{$t('forum.reply')}}</div>
                              </div> -->
                            </div>
                            <!--二级评论回复-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else class="nodata">{{ $t("forum.NoComment") }}</div>
              </div>
              <!--分页-->
              <div class="comments-center" v-if="conmmentOnelist.length != 0">
                <div class="comments-center-page">
                  <el-pagination
                    :prev-text="$t('forum.prevText')"
                    :next-text="$t('forum.nextText')"
                    :hide-on-single-page="hidePage"
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="tableForm.pageSize"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                  >
                  </el-pagination>
                </div>
              </div>
              <!--评论-->
              <div class="comments-bottom">
                <div class="comments-bottom-input">
                  <el-input
                    v-model="opinion"
                    :placeholder="
                      checkInfos == null
                        ? $t('forum.Reply')
                        : $t('forum.reply') + checkInfos
                    "
                  ></el-input>
                  <div class="form-style">
                    <el-button @click="submit">Send</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <contact-us-two background="#fff"></contact-us-two>
  </div>
</template>

<script>
import contactUsTwo from "../home/contactUsOne.vue";
// import { CScrollbar } from "c-scrollbar"; // 滚动页
import TopSearchThree from "../home/topSearchThree";
import MyLikeForum from "./ChildCom/MyLikeForum";
import CountUser from "../../components/CountUser/CountUser";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  components: {
    CountUser,
    MyLikeForum,
    TopSearchThree,
    contactUsTwo,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const data = reactive({
      token: localStorage.getItem("token"),
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      advertises: [],
      bannerList: [],
      closeReply: false, // 关闭回复显示
      currentIndex: -1, // 当前评论下标
      infos: null, //详情
      ids: route.query.id,
      conmmentOnelist: [], //一级评论
      conmmentTwolist: [], //二级评论

      opinion: "", //评论内容
      parent: "", //父级id
      updateShow: false,
      checkInfos: null, //点击回复评论某人
    });
    //翻页
    const params = reactive({
      total: 0,
      tableForm: {
        page: 1,
        pageSize: 5,
        search: { articleId: route.query.id, parent: -1 },
      },
    });
    // 是否有广告
    let advertising = ref(true);
    let hidePage = ref(false); // 当分页只有一页的时候隐藏

    // 是否显示回复框
    const handleShowReply = (item, i) => {
      data.conmmentTwolist = [];
      if (i == data.currentIndex) {
        data.currentIndex = -1;
        data.parent = "";
        data.checkInfos = null;
      } else {
        data.currentIndex = i;
        data.parent = item.id;
        data.checkInfos = item.nickName;
        commentGetTwo(item.id);
      }
    };

    //文章详情
    const getInfos = (id) => {
      api.articleInfo(id ? id : route.query.id).then((res) => {
        data.infos = res;
        data.infos.images = data.infos.images.split(",");
        if (!params.tableForm.topicId) {
          commentGetOne(); //获取父级评论
        }
      });
    };

    //获取父级评论
    const commentGetOne = async () => {
      params.tableForm.topicId = data.infos.topicId;
      api.articleOpinionList(params.tableForm).then((res) => {
        params.total = res.total;
        data.conmmentOnelist = res.records;
      });
    };

    //获取子级评论
    const commentGetTwo = async (id) => {
      api
        .articleOpinionChildlist({
          page: 1,
          pageSize: 1,
          search: {
            parent: id,
            topicId: data.infos.topicId,
            articleId: route.query.id,
          },
        })
        .then((res) => {
          data.conmmentTwolist = res;
        });
    };

    //发起评论
    const submit = () => {
      if (!data.token) {
        ElMessage({
          message: t("common.NotLoggedIn"),
          type: "warning",
        });
        return;
      }
      if (data.opinion == "") {
        ElMessage({
          message: t("forum.datas"),
          type: "warning",
        });
        return;
      }
      let config = {
        articleId: route.query.id,
        topicId: data.infos.topicId,
        parent: data.currentIndex == -1 ? -1 : data.parent,
        opinion: data.opinion,
      };
      api.articleOpinionoption(config).then((res) => {
        if (res) {
          ElMessage({
            message: t("common.PublishedSuccessfully"),
            type: "warning",
          });
          if (data.currentIndex != -1) {
            commentGetTwo(data.parent);
          } else {
            commentGetOne();
          }
          data.opinion = "";
          data.infos.comments =
            data.infos.comments == null ? 0 : Number(data.infos.comments);
          data.infos.comments += 1;
        }
      });
    };

    //话题翻页
    const handleSizeChange = (val) => {
      params.tableForm.pageSize = val;
      commentGetOne();
    };
    const handleCurrentChange = (val) => {
      params.tableForm.page = val;
      commentGetOne();
    };

    //收藏
    const collects = (item) => {
      let collection = item.collection ? false : true;
      api
        .articleUpdate({
          aimId: item.id,
          topicId: item.topicId,
          collection,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: collection
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            getInfos(data.ids); //获取文章列表
            data.updateShow = true;
          }
        });
    };

    const isShowClick = () => {
      data.updateShow = false;
    };

    watchEffect(() => {
      if (route.query.id) {
        getInfos(); //文章详情
      }
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(data.advertises.length, 0);
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 9); // 获取广告位 图
    });

    return {
      advertising,
      hidePage,
      ...toRefs(params),
      ...toRefs(data),
      handleShowReply,
      collects, //收藏
      commentGetOne, //父级评论
      commentGetTwo, //子级评论
      handleSizeChange, //翻页
      handleCurrentChange, //翻页
      submit, //发起评论
      isShowClick,
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.forum {
  padding-top: 70px !important;
  align-items: flex-start;

  .left {
    width: 250px;
    background: #f8f9fc;
    padding: 35px 25px 0 25px;

    .collection {
      margin-top: 26px;

      .collection-title {
        font-size: 18px;
        font-weight: 500;
        color: #202020;
        margin-bottom: 6px;
      }

      .collection-desc {
        font-size: 14px;
        font-weight: 500;
        color: #757575;
        margin-bottom: 15px;
      }

      .xian {
        width: 100%;
        height: 1px;
        background: #c9c9c9;
        margin-bottom: 14px;
      }
    }

    .follow-more {
      width: 153px;
      height: 41px;
      opacity: 1;
      background: #f3ca15;
      border-radius: 4px;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 41px;
      margin-bottom: 40px;
    }

    .follow-list {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      color: #000000;
      line-height: 25px;
      margin-top: 20px;
    }

    .follow-title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      margin-top: 20px;
    }

    .head {
      img {
        margin-right: 10px;
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
  }

  .right {
    width: 980px;
    margin-left: 38px;

    .recommended {
      .recommended-img {
        position: relative;

        .collect-img {
          width: 30px;
          height: auto;
          position: absolute;
          top: 22px;
          right: 22px;
        }
      }

      .count-box {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: auto;
          margin-right: 9px;
        }

        span {
          font-size: 16px;
          font-weight: 900;
          color: #666666;
        }
      }
    }

    .bottom {
      padding: 0 36px;
      width: 944px;
      background: #f8f9fc;
      box-sizing: border-box;

      .count-box {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: auto;
          margin-right: 9px;
        }

        span {
          font-size: 16px;
          font-weight: 900;
          color: #666666;
        }
      }

      .scrollbar-title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        margin-top: 40px;
        word-break: break-all;
      }

      .scrollbar-desc {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        margin: 8px 0 10px 0;
        word-break: break-all;
      }

      .scrollbar-desc-img {
        width: 176px;
        height: 136px;
        margin-right: 15px;
        margin-bottom: 11px;
        object-fit: cover;
      }
    }

    .bottom:last-child {
      padding-bottom: 40px;
    }

    .title {
      font-size: 34px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 30px;
    }

    .marlef35 {
      margin-left: 35px;
    }
  }

  width: 100%;
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
}

.alitem-center {
  align-items: center;
}

.justcenter {
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.justspace {
  justify-content: space-between;
}

.mb-40 {
  padding-bottom: 40px;
}

.comments {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  margin-top: 29px;
  line-height: 22px;
}

.comments-top {
  padding: 20px;
}

.comments-center {
  border-top: 1px solid #dfe1e5;
  border-bottom: 1px solid #dfe1e5;
  text-align: center;
}

.comments-bottom {
  padding: 20px;
}

.comments-bottom-input {
  height: 42px;
  display: flex;

  :deep .el-input__inner {
    height: 100%;
  }

  .form-style {
    margin-left: 10px;
    box-sizing: border-box;

    :deep .el-button {
      height: 100%;
    }
  }
}

.comments-center-page {
  padding: 20px;

  :deep .el-pagination {
    font-size: 16px;
    .active {
      color: #f7bf62 !important;
    }

    .number:hover,
    .btn-next:hover,
    .btn-prev:hover {
      color: #f7bf62 !important;
    }
  }
}

.comments-title {
  font-weight: 500;
  padding-bottom: 11px;
}

.comments-list {
  display: flex;
  padding: 13px 0;
  box-sizing: border-box;

  border-top: 1px solid #c9c9c9;

  .list-left {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 9px;
  }

  .list-right {
    flex: 1;
  }
}

.right-head {
  display: flex;
  justify-content: space-between;
  color: #757575;
  width: 100%;

  .right-head-user {
    width: 75%;
  }

  .right-head-time {
    width: 20%;
    text-align: right;
  }
}

.right-desc {
  margin: 9px 0;
}

.count-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 35px;
  color: #666666;

  img {
    width: 30px;
    height: auto;
    margin-right: 9px;
  }
}

.show-reply {
  padding-top: 17px !important;
}

.mt-20 {
  padding-top: 17px !important;
}
.top-back {
  color: #333333;
  font-size: 18px;
  padding-top: 24px;
  cursor: pointer;
  .icon-arrow-left {
    font-size: 18px;
    color: #333333;
  }
}
.pad-20 {
  padding: 0 20px;
  box-sizing: border-box;
}
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: #999;
}
</style>
