<template>
  <div class="infomation">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div style="width: 100%">
        <div class="title">
          {{ $t("groupSignature.confirm.memberInformation") }}
        </div>
      </div>

      <div v-for="(item, index) in 2" :key="index" class="list-syle">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#ECECEC', color: '#666666' }"
        >
          <el-table-column
            prop="number"
            :label="$t('groupSignature.informationForm.SerialNumber')"
          />
          <el-table-column
            prop="name"
            :label="$t('groupSignature.informationForm.EnglishName')"
          />
          <el-table-column
            prop="sex"
            :label="$t('groupSignature.informationForm.Gender')"
          />
          <el-table-column
            prop="contry"
            :label="$t('groupSignature.informationForm.nationality')"
          />
          <el-table-column
            prop="idCard"
            :label="$t('groupSignature.informationForm.certificatesNumber')"
          />
          <el-table-column
            prop="data"
            :label="$t('groupSignature.entry.enterDate')"
          />
          <el-table-column
            prop="phone"
            :label="$t('groupSignature.confirm.contactNumber')"
          />
        </el-table>
        <div class="show-info" @click="showInformationDetail">
          {{ $t("groupSignature.confirm.viewDetail") }}
        </div>
      </div>

      <div class="add-menber" @click="addNewMenber">
        <img
          class="add-menber-top"
          src="../../../static/images/groupSignature/addNewMenber.png"
        />
        <div class="add-menber-info">
          <img
            class="add"
            src="../../../static/images/groupSignature/add.png"
          />
          <div>{{ $t("groupSignature.confirm.newMenber") }}</div>
        </div>
      </div>

      <el-button plain class="submit-next" @click="confimInformation">
        {{ $t("groupSignature.confimNex") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          number: 1,
          name: "Ruby Fleming",
          sex: "男",
          contry: "中国",
          idCard: "271837283999103",
          data: "2021-11-10",
          phone: "17353166886",
        },
      ],
    };
  },
  methods: {
    addNewMenber() {
      //新增成员
      this.$emit("addNewMenber");
    },
    confimInformation() {
      this.$emit("confimInformation");
    },
    showInformationDetail() {
      this.$emit("showInformationDetail");
    },
  },
};
</script>

<style scoped lang="scss">
.add-menber {
  background: #ffffff;
  border: 2px solid #ffd997;
  border-radius: 16px;
  box-shadow: 0px 6px 40px 0px rgba(18, 9, 84, 0.06);
  margin-top: 50px;
  padding: 15px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .add-menber-info {
    display: flex;
    align-items: center;
    img {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
    div {
      font-size: 24px;
      font-weight: 900;
      color: #333333;
    }
  }
  .add-menber-top {
    width: 58px;
    height: 58px;
  }
}
.submit-next {
  background: #f5a623;
  border-radius: 3px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  width: auto;
  margin-top: 56px;
}
.list-syle {
  display: flex;
  align-items: center;
  .show-info {
    font-size: 16px;
    font-weight: 400;
    color: #f5a623;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 20px;
  }
}
.infomation {
  display: flex;
  width: 1200px;
  .el-table {
    width: 1100px;
    margin-top: 25px;
  }
  .title {
    width: 80px;
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    border-bottom: 4px solid #f5a623;
    padding-bottom: 5px;
    margin-top: 20px;
    text-align: center;
    text-align: left;
  }
}
</style>
