<template>
  <div class="page-wrap">
    <top-search-three :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''" :advertises="advertises" />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="news-details">
        <div class="news-details-wrap">
          <div class="title"></div>
          <!-- {{newInfos}} -->
          <div class="desc" v-html="newInfos"></div>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import ContactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import Carousel from "../../components/Carousel/Carousel";
import util from "../../comm/util";
import api from "../../comm/api";
import { useStore } from "vuex";
import { useRoute} from 'vue-router'
import { reactive, toRefs, onMounted, watchEffect } from "vue";

export default {
  name: "newsDetails",
  components: { Carousel, ContactUsOne, topSearchThree },
  setup() {
    const store = useStore();

    const states =reactive({
      local: localStorage.getItem("local") ? localStorage.getItem("local") : "US",//中英文
      newsId:useRoute().query.id ,//获取父页面 路由传参值  新闻id
      advertises: [],//广告位
      bannerList: [],//banner 数组
      newInfos:null,//详情
    })
    //监听
    watchEffect(() => {
      // 获取广告位
      states.advertises = store.state.advertisesList;
      // 获取头部className
      states.className = util.changeClassName(states.advertises.length,states.bannerList.length);
    });

    //获取详情
    const deliciousNewsInfos=async () =>{
      await api.deliciousNewsInfos(states.newsId).then((res)=>{
        states.bannerList=res.banner.split(',');//banner图
        states.newInfos=JSON.parse(res.content)[states.local]
        //处理富文本图片
        const regex = new RegExp('<img', 'gi')
        states.newInfos=states.newInfos.replace(regex, `<img style="width: 100%; height: auto;margin:10px 0;"`)
        states.newInfos = states.newInfos.replace("<iframe", `<video style="width:100%;" controls="" autoplay=""`).replace("</iframe>", '</video>');
      })
    }

    onMounted(() => {
      store.dispatch("getAdvertisesData", 2);// 获取广告位 图 
      window.scrollTo(0, 0);
      deliciousNewsInfos();//获取详情
    });

    return {
      ...toRefs(states),
      deliciousNewsInfos,//获取详情
    };
  }
};
</script>

<style scoped lang="scss">
.page-wrap{
  background: #fff !important;
}
.news-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.news-details-wrap {
  width: 70%;
  font-weight: 500;
  color: #444440;
}

.title {
  text-align: center;
  font-size: 28px;
  line-height: 40px;
  margin: 92px 0 55px 0;
}

.desc {
  font-size: 18px;
  line-height: 25px;
  p {
    font-size: 18px;
    line-height: 25px;
  }
}
</style>
