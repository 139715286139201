<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <div class="recruit-title">{{ $t("travel.MostRecent") }}</div>
          <!--工作列表-->
          <div class="job-list">
            <div
              class="job-list-wrap"
              v-for="(item, index) in newTravelList"
              :key="index"
            >
              <div
                class="job-list-item job-list-item-height"
                @click="
                  $router.push({ path: '/travelInfo', query: { id: item.id } })
                "
              >
                <FavoriteCard
                  style="z-index: 8"
                  @click.stop="collect(item, index, 1)"
                  :favorite="item.favorite"
                />
                <img :src="item.titleImage" />
                <div class="list-title">{{ item.title }}</div>
                <div class="list-desc text-overflow-three">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>

          <!--地址选择-->
          <div class="address">
            <el-select
              filterable
              v-model="tableForm.search.country"
              placeholder="Select"
            >
              <el-option
                v-for="(item, index) of cityList"
                :key="index"
                :label="item.address"
                :value="item.address"
                @click.prevent="countryClick(item)"
              ></el-option>
            </el-select>
            <div class="xian"></div>
            <el-select
              filterable
              v-model="tableForm.search.province"
              placeholder="Select"
            >
              <el-option
                v-for="(item, index) of provinceList"
                :key="index"
                :label="item.lable"
                :value="item.lable"
                @click.prevent="provinceClick(item)"
              ></el-option>
            </el-select>
            <div class="xian"></div>
            <el-select
              filterable
              v-model="tableForm.search.city"
              placeholder="Select"
            >
              <el-option
                v-for="(item, index) of cityChidrenList"
                :key="index"
                :label="item.lable"
                :value="item.lable"
                @click.prevent="CityClick(item)"
              ></el-option>
            </el-select>
            <div class="xian"></div>
          </div>

          <!--瀑布流列表-->
          <div class="waterfall">
            <!-- <my-waterfall :data="travelAllList"></my-waterfall> -->
          </div>
          <!--        <macy />-->
          <!--工作列表-->
          <div class="job-list">
            <div
              class="job-list-wrap"
              v-for="(item, index) of travelAllList"
              :key="index"
            >
              <div
                class="job-list-item"
                @click="
                  $router.push({ path: '/travelInfo', query: { id: item.id } })
                "
              >
                <FavoriteCard
                  style="z-index: 8"
                  @click.stop="collect(item, index, 2)"
                  :favorite="item.favorite"
                />
                <img :src="item.titleImage" />
                <div class="list-title">{{ item.title }}</div>
                <!-- <div class="list-desc text-overflow-three">{{item.description}}</div> -->
                <div class="list-desc">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <!--更多工作列表-->
          <div class="more">
            <page
              :total="total"
              :page-size="tableForm.pageSize"
              @change-page="changePage"
            ></page>
          </div>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import { reactive, toRefs, onMounted, watchEffect } from "vue";
import util from "@/comm/util";
import api from "@/comm/api";
import { useStore } from "vuex";
import Carousel from "@/components/Carousel/Carousel";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard";
import Page from "@/components/page";
import Macy from "./ChildComp/Macy";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  components: {
    Macy,
    Carousel,
    Page,
    topSearchThree,
    contactUsOne,
    FavoriteCard,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const states = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US", //中英文
      advertises: [], //广告位
      bannerList: [], //banner 数组
      newTravelList: [], //最新旅游攻略
      travelAllList: [], //全部旅游攻略
      cityList: [], //城市
      provinceList: [], //省份
      cityChidrenList: [], //市
    });
    //翻页
    const params = reactive({
      total: 0,
      tableForm: {
        page: 1,
        pageSize: 9,
        search: {
          country: states.local == "US" ? "全国" : "Country",
          province: states.local == "US" ? "省份" : "Province",
          city: states.local == "US" ? "市" : "City",
        },
      },
    });
    //获取banner 图
    const getBanners = async () => {
      states.bannerList = await api.getBanner({ type: "TRAVELLING" });
    };
    //监听
    watchEffect(() => {
      // 获取广告位
      states.advertises = store.state.advertisesList;
      // 获取头部className
      states.className = util.changeClassName(
        states.advertises.length,
        states.bannerList.length
      );
    });

    //国家选择
    const countryClick = (e) => {
      states.provinceList = []; //省份
      states.cityChidrenList = []; //市
      params.tableForm.search.province =
        states.local == "US" ? "省份" : "Province";
      params.tableForm.search.city = states.local == "US" ? "市" : "City";
      if (!e.children) {
        travelAll();
        return;
      }
      states.provinceList = e.children;
      states.provinceList.map((val) => {
        if (typeof val.address == "string") {
          val.address = JSON.parse(val.address);
          val.lable = val.address[states.local];
        }
      });
      travelAll();
    };
    //省份选择
    const provinceClick = (e) => {
      states.cityChidrenList = []; //市
      params.tableForm.search.city = states.local == "US" ? "市" : "City";
      states.cityChidrenList = e.children;
      states.cityChidrenList.map((val) => {
        if (typeof val.address == "string") {
          val.address = JSON.parse(val.address);
          val.lable = val.address[states.local];
        }
      });
      travelAll();
    };
    //选择市
    const CityClick = (e) => {
      travelAll();
    };

    // 改变页码 翻页
    const changePage = (e) => {
      params.tableForm.page = e;
      travelAll(); //获取旅游攻略
    };

    //获取城市列表
    const getCityAll = async () => {
      await api.getAddressAll().then((res) => {
        res.map((val) => {
          val.address = JSON.parse(val.address)[states.local];
        });
        let all = { address: states.local == "US" ? "全国" : "Country" };
        states.cityList = res;
        states.cityList.unshift(all);
      });
    };

    //获取最新旅游功能
    const newTravel = async () => {
      await api.tourismStrategyGetlist().then((res) => {
        res.map((val) => {
          val.title = JSON.parse(val.title)[states.local];
          val.description = JSON.parse(val.description)[states.local];
        });
        states.newTravelList = res;
      });
    };

    //获取旅游攻略
    const travelAll = async () => {
      await api.tourismStrategyList(params.tableForm).then((res) => {
        res.records.map((val) => {
          val.title = JSON.parse(val.title)[states.local];
          val.description = JSON.parse(val.description)[states.local];
        });
        states.travelAllList = res.records;
        params.total = res.total;
      });
    };

    // 收藏
    const collect = (val, i, type) => {
      let favorite = val.favorite ? false : true;
      api
        .tourismStrategyUpdateFavorite({
          tourismId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            if (type == 1) {
              states.newTravelList[i].favorite = favorite;
            } else {
              states.travelAllList[i].favorite = favorite;
            }
            newTravel(); //获取最新旅游功能
            travelAll(); //获取旅游攻略
          }
        });
    };

    onMounted(() => {
      store.dispatch("getAdvertisesData", 13); // 获取广告位 图
      window.scrollTo(0, 0);
      getBanners(); //获取banner
      getCityAll(); //获取城市
      newTravel(); //获取最新旅游功能
      travelAll(); //获取旅游攻略
    });

    return {
      ...toRefs(params),
      ...toRefs(states),
      changePage, //翻页
      collect, //收藏
      getCityAll, //获取城市
      newTravel, //获取最新旅游功能
      travelAll, //获取旅游攻略
      countryClick, //国家选择
      provinceClick, //省份选择
      CityClick, //市选择
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 72%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recruit-title {
  height: 64px;
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #444444;
  margin: 80px 0 54px 0;
}

.job-list {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.job-list-wrap {
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-list-item {
  width: 416px;
  height: 609px;
  background: #f7f7f7;
  border-radius: 16px;
  margin-bottom: 40px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 416px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .collect-img {
    width: 36px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.job-list-item-height {
  height: 609px;
  img {
    height: 416px;
  }
}

.list-title {
  display: flex;
  justify-content: space-between;
  margin: 31px 0 8px 0;
  font-weight: 400;
  font-size: 22px;
  color: #666666;
  line-height: 30px;
  margin: 25px 28px 0 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.list-desc {
  line-height: 32px;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  margin: 17px 28px 17px 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.more {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 29px 0;

  span {
    display: inline-block;
    margin-right: 24px;
    font-size: 24px;
    font-weight: 600;
    color: #444444;
  }
}

.address {
  margin: 68px 0;
  width: 97%;
  height: 80px;
  opacity: 1;
  background: #ffffff;
  border: 2px solid #979797;
  border-radius: 18px;
  padding: 26px 0px;
  display: flex;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
  color: #333333;

  .xian {
    width: 1px;
    height: 80px;
    background: #bababa;
    margin: 0 30px;
  }
}
:deep .address .el-input__suffix {
  display: none;
}
:deep .address {
  .el-select,
  .select-trigger,
  .el-input--suffix,
  .el-input__inner {
    width: 220px;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 40px;
    color: #333;
  }
}
</style>
