<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <div class="recruit-title">{{ $t("recruit.MostPopularJob") }}</div>
          <div class="top-label">
            <img src="../../../static/images/bg/recruit.png" />
            <p>
              A large number of job opportunities make it easy to find a job
            </p>
          </div>

          <!--工作分类-->
          <div class="select-class">
            <span>{{ $t("recruit.JobType") }}</span>
            <el-select
              filterable
              v-model="tableRecruit.search.classification"
              :placeholder="$t('register.PleaseSelect')"
              clearable
              @change="getRecruitList"
            >
              <el-option
                v-for="item in recruitClassArray"
                :key="item.id"
                :label="item.title ? item.title[$i18n.locale] : ''"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>

          <!--工作列表-->
          <div class="recruitWrap">
            <hot-recruit
              :list="recruitListArray"
              @change-data="changeData"
              @go-details="goDetails"
            />
            <!--招聘翻页-->
            <div class="more recruit-more">
              <page
                :total="totalRecruit"
                :page-size="tableRecruit.pageSize"
                @change-page="changePageRecruit"
              ></page>
            </div>
          </div>

          <div class="recruitWrap">
            <div class="recruit-title">{{ $t("recruit.Resumes") }}</div>
            <div class="elite-team-list">
              <div
                class="elite-team-item"
                v-for="(item, index) in eliteListArray"
                :key="index"
              >
                <FavoriteCard
                  @click.stop="collectElite(item, index)"
                  :favorite="item.favorite"
                />
                <img :src="item.image" />
                <div class="username">
                  {{ JSON.parse(item.name)[$i18n.locale] }}
                </div>
                <div
                  class="desc"
                  v-html="JSON.parse(item.content)[$i18n.locale]"
                ></div>
              </div>
            </div>
            <!--精英翻页-->
            <div class="more recruit-more">
              <page
                :total="totalElite"
                :page-size="tableElite.pageSize"
                @change-page="changePageElite"
              ></page>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import Carousel from "../../components/Carousel/Carousel";
import Page from "../../components/page";
import HotRecruit from "./childCom/HotRecruit";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  components: {
    HotRecruit,
    Carousel,
    Page,
    topSearchThree,
    contactUsOne,
    FavoriteCard,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const params = reactive({
      totalRecruit: 0, //招聘
      tableRecruit: { page: 1, pageSize: 9, search: { classification: "" } },

      totalElite: 0, //精英
      tableElite: { page: 1, pageSize: 6 },
    });

    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      className: "",
      advertises: [],
      bannerList: [], //banner 数组
      recruitClassArray: [], //招聘分类
      eliteListArray: [], //精英列表
      recruitListArray: [], //招聘列表
    });

    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "RECRUITMENT" });
    };

    //获取招聘分类
    const getRecruitClass = async () => {
      data.recruitClassArray = await api.recruitClass();
      data.recruitClassArray.map((val) => {
        val.title = JSON.parse(val.title);
      });
    };

    //获取招聘列表
    const getRecruitList = async () => {
      await api.recruitList(params.tableRecruit).then((res) => {
        params.totalRecruit = res.total;
        data.recruitListArray = res.records;
      });
    };

    //获取精英列表
    const getEliteList = async () => {
      await api.eliteList(params.tableElite).then((res) => {
        params.totalElite = res.total;
        data.eliteListArray = res.records;
      });
    };

    //精英 收藏
    const collectElite = (val, i) => {
      let favorite = val.favorite ? false : true;
      api
        .eliteFavorite({
          eliteId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            getEliteList();
          }
        });
    };

    //刷新页面 招聘列表
    const changeData = () => {
      getRecruitList();
    };

    // 去详情页面
    const goDetails = (id) => {
      router.push({ path: "/recruitInfo", query: { id } });
    };

    //招聘翻页
    const changePageRecruit = (e) => {
      params.tableRecruit.page = e;
      getRecruitList();
    };

    //精英翻页
    const changePageElite = (e) => {
      params.tableElite.page = e;
      getEliteList();
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 17); // 获取广告位 图
      getBanners(); //获取banner 图
      getRecruitClass(); //招聘分类
      getRecruitList(); //招聘列表
      getEliteList(); //精英列表
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      ...toRefs(params),
      getBanners, //获取banner图
      collectElite, //精英收藏
      changePageRecruit, //招聘翻页
      changePageElite, //精英翻页
      changeData, //刷新
      goDetails, //详情
      getRecruitList, //获取招聘列表 搜索
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recruit-title {
  height: 64px;
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #444444;
  margin: 80px 0 54px 0;
}

.top-label {
  width: 100%;
  height: 378px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  p {
    width: 753px;
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    letter-spacing: -1px;
  }
}

.select-class {
  font-size: 26px;
  color: #444444;
  width: 100%;
  margin-top: 45px;

  :deep.el-select {
    width: 314px;
    height: 80px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    background: #ffffff;
    border: 1px solid #f5ad34;
    border-radius: 22px;
    padding: 20px;
    box-sizing: border-box;
  }

  :deep .el-select-dropdown__item {
    font-size: 24px;
    color: #333333;
    font-weight: 500 !important;
  }

  :deep .el-input__inner {
    font-size: 24px;
    color: #333333;
    border: 1px solid #b7babe;
    padding: 24px 32px;
    margin-left: 25px;
  }

  :deep .el-input__inner::placeholder {
    font-size: 24px;
  }

  :deep .el-input__suffix {
    margin-top: 4px;
  }
}

.job-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.job-list-item {
  width: 395px;
  height: 571px;
  background: #f7f7f7;
  border-radius: 16px;
  margin-top: 40px;
  padding: 18px;
  cursor: pointer;
  position: relative;

  img {
    width: 394px;
    height: 304px;
    border-radius: 16px;
  }

  .collect-img {
    width: 36px;
    height: auto;
    position: absolute;
    top: 40px;
    right: 40px;
  }
}

.list-title {
  display: flex;
  justify-content: space-between;
  margin: 31px 0 8px 0;

  .money {
    font-size: 22px;
    color: #f49d44;
    letter-spacing: 1px;
  }

  .name {
    font-size: 22px;
    color: #333333;
  }
}

.list-desc {
  line-height: 32px;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}

.more {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 29px 0;
  span {
    display: inline-block;
    margin-right: 24px;
    font-size: 24px;
    font-weight: 600;
    color: #444444;
  }
}

.elite-team-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.elite-team-item {
  width: 405px;
  height: 485px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 77px 0px rgba(0, 0, 0, 0.06);
  padding: 28px;
  box-sizing: border-box;
  margin-bottom: 52px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  .collect-img {
    width: 36px;
    height: auto;
    position: absolute;
    top: 40px;
    right: 40px;
  }
  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  .username {
    font-size: 24px;
    color: #37474f;
    padding: 24px 0;
  }
  .desc {
    flex: 1;
    //height: 251px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(55, 71, 79, 0.72);
    line-height: 26px;
    // text-overflow: ellipsis;
    // display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    // -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    // -webkit-line-clamp:9;/**显示的行数**/
    // overflow:hidden;/**隐藏超出的内容**/
    // word-break: break-all;

    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  .desc::-webkit-scrollbar {
    display: none;
  }
}

.recruit-more {
  margin-top: 0;
  margin-bottom: 0;
}
.recruitWrap {
  width: 103%;
}
</style>
