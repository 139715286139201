<template>
  <div class="banner-carousel">
    <el-carousel :interval="6000"
                 arrow="always"
                 indicator-position="none"
                 v-if="list.length > 0">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <img :src="item.image" class="top-banner" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'Advertises',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style scoped lang="scss">
.banner-carousel {
  :deep .el-carousel__container {
    height: 236px;
  }
}
.top-banner {
  width: 100%;
  height: 236px;
}
</style>
