<template>
  <div class="content">
    <div class="title">How to Apply:Business eVisa on Arrival</div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="info">
        <img src="../../../static/images/home/top.png" />
        <div class="info-title">{{ item.title }}</div>
        <div class="info-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "Fill out online application",
          desc: "Complete our easy oline application pay with credit card or Paypal",
          icon: "",
        },
        {
          title: "receive visa on arrival confirmation via email",
          desc: "no need to deal with the embassy or lose valuable time at destination airport",
          icon: "",
        },
        {
          title: "Enter destination",
          desc: "Present your Passport and Document we provide at destination airport. Receive visa and enter country",
          icon: "",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  background: #f5f5f5;
  padding: 75px 0 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: #4a4c5b;
  }
  .list {
    display: flex;
    margin-top: 90px;
    .info {
      background: #ffffff;
      padding: 80px 40px 40px 40px;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 28px;
      img {
        width: 180px;
        height: 118px;
      }
      .info-title {
        width: 344px;
        font-size: 26px;
        font-weight: 900;
        text-align: center;
        color: #404250;
        margin: 60px 0;
      }
      .info-desc {
        width: 325px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #6c7b8a;
        line-height: 24px;
      }
    }
  }
}
</style>
