<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? '#fff' : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <!--提示头部-->
          <!--二手交易详情-->
          <div class="info">
            <div class="info-title">{{ details.title }}</div>
            <div class="info-price">
              <span>{{ $t("secondHand.price") }}</span>
              <span class="info-price-unit">{{ details.price }}</span>
            </div>
            <div class="info-desc">
              {{ $t("common.create") }}{{ details.createDate
              }}{{ $t("common.update") }}{{ details.updateDate
              }}{{ $t("common.Viewed") }} {{ details.viewCount || 0
              }}{{ $t("common.times") }}
            </div>
            <!--收藏-->
            <Favorite
              @collect="collect(details)"
              :favorite="details.collection"
            />
            <div class="xian"></div>

            <div class="info-list">
              <h1>{{ $t("secondHand.Description") }}</h1>
              <p>{{ details.content ? details.content : details.detail }}</p>
            </div>
            <div class="info-list">
              <h1>{{ $t("secondHand.PickUp") }}</h1>
              <p>{{ details.address }}</p>
            </div>
            <div class="info-list">
              <h1>{{ $t("secondHand.Contact") }}</h1>
              <p v-if="$store.state.user.isLogin">
                {{ details.phone ? details.phone : "- " }}
              </p>
              <p v-else>
                <span v-if="$i18n.locale == 'US'"
                  >Please <span class="hand-shank">sign in</span> or
                  <span class="hand-shank">register</span> to contact this
                  user</span
                >
                <span v-else
                  >请
                  <span class="hand-shank" @click="$router.push('/login')"
                    >登录</span
                  >
                  或
                  <span
                    class="hand-shank"
                    @click="
                      $router.push({ path: '/login', query: { login: 1 } })
                    "
                    >注册</span
                  >
                  联络此用户</span
                >
              </p>
            </div>
          </div>
          <el-button
            :loading="btnLoading"
            class="submit-btn"
            @click="submit"
            v-if="details.whether"
            >{{ $t("common.delete") }}</el-button
          >
          <!--你可能还喜欢-->
          <div class="recruit-title">
            {{ $t("ticket.RecommendedAttractions") }}
          </div>

          <!-- 空数据 -->
          <!-- <el-empty v-if="recommendList.length>0" v-if="recommendList.length==0" :image-size="200"></el-empty> -->

          <el-carousel
            v-if="recommendList.length > 0"
            indicator-position="none"
            arrow="never"
            @setActiveItem="setActiveItem"
            @change="onSlideChange"
            ref="cardShow"
          >
            <el-carousel-item
              v-for="(child, index) in recommendList"
              :key="index"
            >
              <div
                class="item-info"
                v-for="(item, m) in child"
                :key="m"
                @click.stop="likegoDatail(item.id)"
              >
                <div class="head">
                  <img
                    class="head-img"
                    :src="
                      item.avatar
                        ? item.avatar
                        : require('../../../static/images/home/head.png')
                    "
                  />
                  <div class="head-right">
                    <div class="flexRow flexJustifySpaceBetween">
                      <div class="name">
                        {{
                          item.userName ? item.userName : $t("common.username")
                        }}
                      </div>
                      <div class="collect">
                        <div>{{ $t("secondHand.price") }}}{{ item.price }}</div>
                        <FavoriteCard
                          @click.stop="collect(item, index, m)"
                          :favorite="item.collection"
                        />
                      </div>
                    </div>
                    <div class="desc">{{ item.classificationName }}</div>
                  </div>
                </div>

                <div class="top-img">
                  <div
                    class=""
                    v-for="(cover, coverIndex) in item.cover"
                    :key="coverIndex"
                  >
                    <img :src="cover" />
                  </div>
                </div>
                <div class="desc-title text-overflow-two">{{ item.title }}</div>
                <div class="desc text-overflow-three">{{ item.content }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <!-- 翻页 -->
          <div class="pagesClick" v-if="recommendList.length > 0">
            <img
              @click="preBtn"
              src="../../../static/images/food/left.png"
              alt=""
            />
            <div>
              <span>{{ clickIndex }} / {{ recommendList.length }}</span>
            </div>
            <img
              @click="nextBtn"
              src="../../../static/images/food/right1.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <contact-us-one></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import Carousel from "../../components/Carousel/Carousel";
import Favorite from "../../components/Favorite/Favorite";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import { reactive, toRefs, onMounted, ref, watchEffect } from "vue";

export default {
  components: {
    Favorite,
    Carousel,
    FavoriteCard,
    topSearchThree,
    contactUsOne,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      advertises: [],
      bannerList: [], //banner 数组
      details: {},
      recommendList: [], // 最新推荐
      clickIndex: 1, //swiper 下标
      btnLoading: false,
    });

    // 收藏
    const collect = (val, x, y) => {
      let collection = val.collection ? false : true;
      api
        .favoriteTransaction({
          transactionId: val.id,
          collection,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: collection
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });

            if (x || x == 0) {
              // 推荐列表收藏
              data.recommendList[x][y].collection = collection;
              if (data.recommendList[x][y].id == data.details.id) {
                data.details.collection = collection;
              }
            } else {
              // 详情收藏
              data.details.collection = collection;
              data.recommendList.map((item) => {
                item.map((val) => {
                  if (val.id == data.details.id) {
                    val.collection = collection;
                  }
                });
              });
            }
          }
        });
    };
    //喜欢点击获取详情
    const likegoDatail = (e) => {
      window.scrollTo(0, 0);
      getDetails(e);
    };

    // 获取详情
    const getDetails = (e) => {
      api.transactionById({ id: e ? e : route.query.id }).then((res) => {
        // 处理数据
        if (res) {
          for (const [key, val] of Object.entries(res)) {
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;
                // 判断首尾是否是{}
                if (first && last) {
                  if (key != "price") {
                    data.details[key] = JSON.parse(val)[data.local];
                  } else {
                    data.details[key] = JSON.parse(val).ZH;
                  }
                } else {
                  data.details[key] = val;
                }
              } else {
                // 判断val不是字符串
                data.details[key] = val;
              }
            }
          }

          // const regex = new RegExp("<img", "gi");
          // data.details.content = data.details.content.replace(
          //   regex,
          //   `<img style="max-width: 100%; height: auto;margin:10px 0;"`
          // );
          // 获取bannerList
          data.bannerList = res.banner ? res.banner.split(",") : [];
        }
      });
    };
    // 删除二手交易
    const submit = () => {
      api.transactionDel({ id: data.details.id }).then((res) => {
        if (res) {
          ElMessage({
            message: t("common.deleteSuccess"),
            type: "success",
          });
          router.go(-1);
        }
      });
    };
    // 二手交易列表-最新推荐
    const getRecommendData = () => {
      const info = { page: 1, pageSize: 10 };
      api.recommendTransaction(info).then((res) => {
        if (res) {
          res.records.forEach((item) => {
            item.cover = item.cover.split(",");
          });
          jsonData(res.records, "recommendList");
        }
      });
    };

    //swiper
    const setActiveItem = (swiper) => {
      // data.clickIndex=swiper.activeIndex==7?2:swiper.activeIndex
    };
    const onSlideChange = (swiper) => {
      data.clickIndex = swiper + 1;
    };

    const cardShow = ref(null);

    // 下一张
    const preBtn = () => {
      if (data.clickIndex == 1) {
        return;
      }
      // data.clickIndex--;
      cardShow.value.prev();
    };

    // 上一张
    const nextBtn = () => {
      if (data.clickIndex == data.recommendList.length) {
        return;
      }
      // data.clickIndex++;
      cardShow.value.next();
    };

    // 处理数据
    const disposeData = reactive({
      newList: [], // 新数组
      newJson: {}, // 单数据
    });

    // 处理数据
    const jsonData = (res, list) => {
      // 初始化数据
      disposeData.newList = [];
      // 判断数据是否有值
      if (res && res.length > 0) {
        // 循环便利数组
        res.forEach((item) => {
          disposeData.newJson = {};
          // 循环便利对象
          for (const [key, val] of Object.entries(item)) {
            // 如果val不为空
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;

                // 判断首尾是{}
                if (first && last) {
                  disposeData.newJson[key] = JSON.parse(val)[data.local];
                } else {
                  disposeData.newJson[key] = val;
                }
              } else {
                // 判断val不是字符串
                disposeData.newJson[key] = val;
              }
            }
          }

          disposeData.newList.push(disposeData.newJson);
        });
      }
      // 赋值给对应的list
      // 将数组分割成2个一组
      data[list] = [];
      for (var i = 0; i < disposeData.newList.length; i += 2) {
        data[list].push(disposeData.newList.slice(i, i + 2));
      }
      // data[list] = disposeData.newList;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 15); // 获取广告位 图
      getDetails();
      getRecommendData();
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      value: ref(""),
      ...toRefs(data),
      collect,
      preBtn,
      nextBtn,
      setActiveItem,
      onSlideChange,
      likegoDatail, //点击喜欢数据获取详情
      cardShow,
      submit, //删除二手机交易
    };
  },
};
</script>

<style scoped lang="scss">
.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  //width: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  //width: 100%;
  width: 70%;
  background: #fff;
  margin: 70px 0 55px;
  padding: 33px 44px;
  color: #444444;
  box-sizing: border-box;
}

.info-title {
  font-size: 26px;
}

.info-price {
  font-size: 20px;
  margin: 26px 0;

  .info-price-unit {
    font-size: 26px;
    margin-left: 10px;
  }
}

.info-desc {
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
}

.info-list {
  h1 {
    font-size: 20px;
    color: #333;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    .hand-shank {
      color: #2772dd;
    }
  }
}

img {
  width: 100%;
  height: auto;
}
.xian {
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin: 35px 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}
.el-carousel {
  width: 100%;
  height: 472px;
  padding: 0 200px;
  box-sizing: border-box;
}
.el-carousel__item {
  width: 100%;
  height: 472px;
  display: flex;
  justify-content: space-between;
}
.el-carousel__item .item-info {
  width: 49%;
  height: 100%;
  background: #fff;
  padding: 30px 36px;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);
}
.el-carousel__button {
  display: none;
}
.el-carousel__item:nth-child(2n) {
  background-color: #f5f5f5;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #f5f5f5;
}
.item-info {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-img {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      margin-right: 15px;
      object-fit: cover;
    }
    .head-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      color: #101010;
      font-size: 20px;
      .collect {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .collect-img {
          width: 36px;
          height: auto;
          margin-left: 21px;
        }
      }
      .desc {
        font-size: 16px;
        color: #666666;
      }
    }
  }
  .top-img {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    img {
      width: 216px;
      height: 167px;
      object-fit: cover;
    }
  }
  .desc-title {
    font-size: 24px;
    color: #444444;
    margin: 13px 0 9px 0;
  }

  .desc {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
}
.recruit-title {
  font-weight: 500;
}
.pagesClick {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 00px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  div {
    margin: 0 20px;
  }
}
.collect-warp {
  position: relative;
  top: 0;
  right: 0;
  margin-left: 21px;
}
.submit-btn {
  background: #f5a623;
  border-color: #f5a623;
  border-radius: 3px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  padding: 0 50px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #ffc972;
    border-color: #ffc972;
    color: #ffffff;
  }
}
</style>
