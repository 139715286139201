import {
  createRouter,
  createWebHistory
} from "vue-router";

const routerHistory = createWebHistory();

// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

// const originalReplace = Router.prototype.replace;
// Router.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch((err) => err);
// };

import login from "@/view/login/login.vue"; //登录
import register from "@/view/login/register.vue"; //注册
import forget from "@/view/login/forget.vue"; //忘记密码

import home from "@/view/home/home.vue"; //首页
import homeDetail from "@/view/home/homeDetail.vue"; //首页详情
import overseasStudentNews from "@/view/overseasStudent/news.vue"; //留学生新闻
import overseasStudentNewsDetails from "@/view/overseasStudent/newsDetails.vue"; //留学生新闻详情
import showSchool from "@/view/showSchool/showSchool.vue"; //学校展示
import registerSchool from "@/view/showSchool/registerSchool.vue"; // 在校报名
import accommodation from "@/view/showSchool/accommodation.vue"; //学校住宿
import internationalStudentAssociation from "@/view/overseasStudent/internationalStudentAssociation.vue"; //留学生协会
import recruit from "@/view/recruit/recruit.vue"; //招聘
import recruitInfo from "@/view/recruit/recruitInfo.vue"; //招聘详情
import secondHand from "@/view/secondHand/secondHand.vue"; //二手交易
import secondHandDetails from "@/view/secondHand/secondHandDetails.vue"; //二手交易详情
import forum from "@/view/forum/forum.vue"; //论坛
import forumTopic from "@/view/forum/forumTopic.vue"; //论坛话题页
import forumDetails from "@/view/forum/forumDetails.vue"; //论坛话题详情页
import editForumTopic from "@/view/forum/editForumTopic.vue"; //编辑论坛话题
import travel from "@/view/travel/travel.vue"; //旅游攻略
import travelInfo from "@/view/travel/travelInfo.vue"; //旅游攻略详情
import ticket from "@/view/travel/ticket.vue"; //景点门票
import ticketInfo from "@/view/travel/ticketInfo.vue"; //景点门票详情
import personalCenter from "@/view/personalCenter/personalCenter.vue"; //个人中心
import deliciousFood from "@/view/deliciousFood/deliciousFood.vue"; //中国美食
import deliciousFoodDetail from "@/view/deliciousFood/deliciousFoodDetail.vue"; //中国美食
import groupSignature from "@/view/groupSignature/groupSignature.vue"; //团签
import faq from "@/view/FAQ/FAQ.vue"; //faq
const router = createRouter({
  history: routerHistory,
  routes: [{
      path: "/",
      name: "home",
      component: home,
      children: [{
          path: "/",
          name: "homeDetail",
          component: homeDetail,
        },
        {
          path: "/faq",
          name: "faq",
          component: faq,
        },
        {
          path: "/deliciousFood",
          name: "deliciousFood",
          component: deliciousFood,
        },
        {
          path: "/deliciousFoodDetail",
          name: "deliciousFoodDetail",
          component: deliciousFoodDetail,
        },

        {
          path: "/forum",
          name: "forum",
          component: forum,
        },
        {
          path: "/forumTopic",
          name: "forumTopic",
          component: forumTopic,
        },
        {
          path: "/forumDetails",
          name: "forumDetails",
          component: forumDetails,
        },
        {
          path: "/editForumTopic",
          name: "EditForumTopic",
          component: editForumTopic,
        },
        {
          path: "/ticket",
          name: "ticket",
          component: ticket,
        },
        {
          path: "/ticketInfo",
          name: "TicketInfo",
          component: ticketInfo,
        },
        {
          path: "/travel",
          name: "travel",
          component: travel,
        },
        {
          path: "/travelInfo",
          name: "TravelInfo",
          component: travelInfo,
        },
        {
          path: "/overseasStudentNews",
          name: "overseasStudentNews",
          component: overseasStudentNews,
        },
        {
          path: "/overseasStudentNewsDetails",
          name: "overseasStudentNewsDetails",
          component: overseasStudentNewsDetails,
        },
        {
          path: "/registerSchool",
          name: "registerSchool",
          component: registerSchool,
        },
        {
          path: "/showSchool",
          name: "showSchool",
          component: showSchool,
        },
        {
          path: "/accommodation",
          name: "accommodation",
          component: accommodation,
        },
        {
          path: "/internationalStudentAssociation",
          name: "internationalStudentAssociation",
          component: internationalStudentAssociation,
        },
        {
          path: "/recruit",
          name: "recruit",
          component: recruit,
        },
        {
          path: "/recruitInfo",
          name: "recruitInfo",
          component: recruitInfo,
        },
        {
          path: "/secondHand",
          name: "secondHand",
          component: secondHand,
        },
        {
          path: "/secondHandDetails",
          name: "secondHandDetails",
          component: secondHandDetails,
        },
      ],
    },
    {
      path: "/groupSignature",
      name: "groupSignature",
      component: groupSignature,
    },
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/register",
      name: "register",
      component: register,
    },
    {
      path: "/forget",
      name: "forget",
      component: forget,
    },
    {
      path: "/personalCenter",
      name: "personalCenter",
      component: personalCenter,
    },
    // 个人中心-修改用户信息
    {
      path: "/changeUserInfo",
      name: "ChangeUserInfo",
      component: () =>
        import("@/view/personalCenter/actions/changeUserInfo.vue"),
    },
    // 用户协议
    {
      path: "/agreement",
      name: "agreement",
      component: () => import("@/view/login/agreement.vue"),
    },
    // 搜索
    {
      path: "/search",
      name: "search",
      component: () => import("@/view/search/search.vue"),
    },
    // 注册成功
    {
      path: "/registerSucceed",
      name: "registerSucceed",
      component: () => import("@/view/login/succeed.vue"),
    },
    // hereStore
    {
      path: "/hereStore",
      name: "hereStore",
      component: () => import("@/view/hereStore/index"),
    },
    // hereStore 分类列表展示页  签证 上网卡 vip服务
    {
      path: "/hereStore/class",
      name: "hereStoreClass",
      component: () => import("@/view/hereStore/classificationList"),
    },
    // hereStore 上网卡详情
    {
      path: "/hereStore/card",
      name: "hereStoreCard",
      component: () => import("@/view/hereStore/card/index"),
      meta: {
        title: "辰德-上网卡详情",
      },
    },
    // hereStore 上网卡支付
    {
      path: "/hereStore/payMent",
      name: "hereStorePayMent",
      component: () => import("@/view/hereStore/card/payment"),
      meta: {
        title: "辰德-上网卡支付",
      },
    },
    // hereStore 上网卡支付 成功
    {
      path: "/hereStore/paymentStatus",
      name: "hereStoreSuccess",
      component: () => import("@/view/hereStore/card/success"),
      meta: {
        title: "辰德-上网卡支付成功",
      },
    },
    // 休闲&玩乐
    {
      path: "/leisureTime",
      name: "leisureTime",
      component: () => import("@/view/leisureTime/index"),
      meta: {
        title: "休闲&玩乐",
      },
    },
    // 休闲&玩乐 详情
    {
      path: "/leisureTime/detail",
      name: "leisureTimeDetail",
      component: () => import("@/view/leisureTime/detail"),
      meta: {
        title: "休闲&玩乐 详情",
      },
    },
    // 签证 ---- 学习签证
    {
      path: "/hereStore/ChineseVisa",
      name: "hereStoreStudyVisa",
      component: () => import("@/view/hereStore/visa/studyVisa/index"),
      meta: {
        title: "学习签证",
      },
    },
    // 书籍推荐
    {
      path: "/book",
      name: "bookIndex",
      component: () => import("@/view/book/index"),
      meta: {
        title: "书籍推荐",
      },
    },
    // 书籍详情
    {
      path: "/book/detail",
      name: "bookDetail",
      component: () => import("@/view/book/detail"),
      meta: {
        title: "书籍详情",
      },
    },
    //住宿列表
    {
      path: "/accommodation/list",
      name: "accommodationList",
      component: () => import("@/view/showSchool/accommodationList"),
      meta: {
        title: "住宿列表",
      },
    },
    //住宿列表-详情
    {
      path: "/accommodationInfo",
      name: "AccommodationInfo",
      component: () => import("@/view/showSchool/accommodationInfo"),
      meta: {
        title: "住宿详情",
      },
    },
    //学习培训
    {
      path: "/learningTraining",
      name: "learningTrainingIndex",
      component: () => import("@/view/learningTraining/index"),
      meta: {
        title: "学习/培训",
      },
    },
    //学习培训 详情
    {
      path: "/learningTraining/detail",
      name: "learningTrainingDetail",
      component: () => import("@/view/learningTraining/infos"),
      meta: {
        title: "学习/培训-详情",
      },
    },
    // 医疗
    {
      path: "/medicalTreatment",
      name: "MedicalTreatment",
      component: () => import("@/view/medicalTreatment/medicalTreatment"),
      meta: {
        title: "医疗",
      },
    },
    // 医疗-详情
    {
      path: "/medicalTreatmentDetails",
      name: "MedicalTreatmentDetails",
      component: () => import("@/view/medicalTreatment/details"),
      meta: {
        title: "医疗-详情",
      },
    },
    // 关于我们
    {
      path: "/aboutUs",
      name: "AboutUs",
      component: () => import("@/view/aboutUs/aboutUs"),
      meta: {
        title: "关于我们",
      },
    },
    // 联系我们-微信
    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/view/contact/contact"),
      meta: {
        title: "联系我们",
      },
    },
    // 广告
    {
      path: "/advertising",
      name: "Advertising",
      component: () => import("@/view/advertising/advertising"),
      meta: {
        title: "广告",
      },
    },
    // 保险
    {
      path: "/insurance",
      name: "Insurance",
      component: () => import("@/view/insurance/insurance"),
      meta: {
        title: "保险",
      },
    },
    // 数字人民币
    {
      path: "/DCEP",
      name: "DCEP",
      component: () => import("@/view/DCEP/DCEP"),
      meta: {
        title: "数字人民币",
      },
    },
    // VIP接机
    {
      path: "/vipAirportPickUp",
      name: "VipAirportPickUp",
      component: () => import("@/view/vipAirportPickUp/vipAirportPickUp"),
      meta: {
        title: "VIP接机",
      },
    },
    // 微信二维码
    {
      path: "/weChat",
      name: "weChat",
      component: () => import("@/view/weChat/index"),
      meta: {
        title: "微信二维码",
      },
    },
    // 上网卡
    {
      path: "/wifiCard",
      name: "WifiCard",
      component: () => import("@/view/wifiCard/wifiCard"),
      meta: {
        title: "上网卡",
      },
    },
    // 1团签 2商务签证
    {
      path: "/Visa",
      name: "BusinessVisa",
      component: () => import("@/view/visa/business"),
      meta: {
        title: "商务签证",
      },
    },
    // 公共签证--visaType: 0, // 1学习 2工作 3中国永居 4延期签证
    {
      path: "/commonVisa",
      name: "Visa",
      component: () => import("@/view/visa/commonVisa"),
      meta: {
        title: "签证",
      },
    },
    // vip服务--1vip廊桥服务 2vip接机包车
    {
      path: "/vipServe",
      name: "VipServe",
      component: () => import("@/view/vipServe/vipServe"),
      meta: {
        title: "vip服务",
      },
    },
    // 菜单
    {
      path: "/meau",
      name: "meau",
      component: () => import("@/view/meau/index"),
      meta: {
        title: "菜单",
      },
    },
    // 二手交易 分类
    {
      path: "/secondClass",
      name: "secondClass",
      component: () => import("@/view/secondHand/secondClass"),
      meta: {
        title: "二手交易-分类",
      },
    },
    // 二手交易 发布
    {
      path: "/release",
      name: "Release",
      component: () => import("@/view/secondHand/release"),
      meta: {
        title: "二手交易-发布",
      },
    },
    // 付款--失败
    {
      path: "/api/v2/pay/cancel",
      name: "PayCancel",
      component: () => import("@/view/payResult/cancel"),
      meta: {
        title: "失败",
      },
    },
    // 付款--成功
    {
      path: "/api/v2/pay/success",
      name: "PaySuccess",
      component: () => import("@/view/payResult/success"),
      meta: {
        title: "成功",
      },
    },
    // 支付宝付款--失败
    {
      path: "/alipayCancel",
      name: "AlipayCancel",
      component: () => import("@/view/payResult/aliPayCancel"),
      meta: {
        title: "失败",
      },
    },
    // 支付宝付款--成功
    {
      path: "/alipaySuccess",
      name: "AlipaySuccess",
      component: () => import("@/view/payResult/aliPaySuccess"),
      meta: {
        title: "成功",
      },
    },
    // 支付宝支付页面
    {
      path: "/payGateWay",
      name: "PayGateWay",
      component: () => import("@/view/payGateWay/payGateWay"),
      meta: {
        title: "支付宝支付",
      },
    },
  ],
});
export default router;