<template>
  <div id="macy-container">
    <div >
      <img src="../../../../static/images/contact/contact.png" />
    </div>
  </div>
</template>

<script>
// import Macy from 'macy';
// const masonry = new Macy({
//   container: '#macy-container', // 图像列表容器id
//   trueOrder: false,
//   waitForImages: false,
//   useOwnImageLoader: false,
//   debug: true,
//
//   //设计间距
//   margin: {
//     x: 10,
//     y: 10
//   },
//
//   //设置列数
//   columns: 6,
//
//   //定义不同分辨率（1200，940，520，400这些是分辨率）
//   breakAt: {
//     1200: {
//       columns: 5,
//       margin: {
//         x: 23,
//         y: 4
//       }
//     },
//     940: {
//       margin: {
//         y: 23
//       }
/*    },*/
//     520: {
//       columns: 3,
//       margin: 3,
//     },
//     400: {
//       columns: 2
//     }
//   }
// })
</script>

<style scoped>

</style>
