<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <!--banner-->
    <div :class="className" class="content">
      <div class="banner-wrap">
        <div class="banner-carousel">
          <Carousel :list="bannerList" />
        </div>

        <div class="banner-right">
          <img v-if="bannerRight.length != 0" :src="bannerRight[0].image[0]" />
          <div class="form-style">
            <el-button
              class="join-btn"
              @click="
                $router.push({
                  path: '/forumTopic',
                  query: { id: bannerRight[0].topicId },
                })
              "
            >
              {{ $t("forum.join") }}
            </el-button>
          </div>
        </div>
      </div>

      <div class="forum">
        <new-topic-edit :details="infos" />
      </div>
    </div>

    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
// import { CScrollbar } from "c-scrollbar"; // 滚动页
import TopSearchThree from "../home/topSearchThree";
import Carousel from "@/components/Carousel/Carousel";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import ContactUsOne from "../home/contactUsOne.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import NewTopicEdit from "./ChildCom/NewTopicEdit.vue";

export default {
  components: { TopSearchThree, Carousel, ContactUsOne, NewTopicEdit },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      advertises: [],
      bannerList: [], //banner 数组
      recommenList: [], //推荐话题
      bannerRight: [], //banner 右话题
      hotList: [], //热门话题
      infos: {}, // 帖子详情
    });
    // 获取轮播列表
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "TOPIC" });
    };
    //获取推荐话题
    const recommentopicGet = async () => {
      api.recommendedTopicList().then((res) => {
        let lists = res;
        lists.map((val) => {
          val.image = val.image.split(",");
        });
        data.recommenList = lists.slice(0, 2);
        data.bannerRight = lists.slice(2, 3);
      });
    };

    //获取帖子详情
    const getDetails = (id) => {
      api.articleInfo(id ? id : route.query.id).then((res) => {
        if (res) {
          res.images = res.images.split(",");
          jsonData(res, "infos");
        }
      });
    };

    // 处理数据
    const disposeData = reactive({
      newList: [], // 新数组
      newJson: {}, // 单数据
    });

    // 处理数据
    const jsonData = (item, obj) => {
      disposeData.newJson = {};
      // 循环便利对象
      for (const [key, val] of Object.entries(item)) {
        // 如果val不为空
        if (val) {
          // 判断val是否为字符串
          if (typeof val == "string") {
            let first = val.indexOf("{") == -1 ? false : true;
            let last = val.lastIndexOf("}") == -1 ? false : true;

            // 判断首尾是{}
            if (first && last) {
              disposeData.newJson[key] = JSON.parse(val);
            } else {
              disposeData.newJson[key] = val;
            }
          } else {
            // 判断val不是字符串
            disposeData.newJson[key] = val;
          }
        }
      }
      // 赋值给对应的对象
      data[obj] = disposeData.newJson;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 9); // 获取广告位 图
      getBanners(); //获取banner 图
      recommentopicGet(); //获取推荐话题
      getDetails();
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      getBanners,
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.banner-wrap {
  display: flex;
  height: 670px;
}

.banner-carousel {
  width: 1130px;

  :deep .el-carousel__container {
    height: 670px;
  }

  :deep .el-carousel__arrow {
    width: 48px;
    height: 48px;
    background: #ffffff;
    border-radius: 50%;
    color: #f5ad34;
    font-size: 35px;
  }

  :deep .el-carousel__arrow--left {
    position: absolute;
    bottom: 0;

    &:hover {
      opacity: 0.5;
    }
  }

  :deep .el-carousel__arrow--right {
  }
}

.top-banner {
  width: 100%;
  height: 670px;
}

.banner-right {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form-style {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 226px;
  }
}

.forum {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .new-topic {
    width: 70%;
    margin-top: 40px;
  }
}

.flex {
  display: flex;
}

.alitem-center {
  align-items: center;
}

.justcenter {
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.justspace {
  justify-content: space-between;
}

.mb-40 {
  padding-bottom: 40px !important;
}

.topic {
  margin-top: 60px;
}
</style>
