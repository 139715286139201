import {
  createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import "lib-flexible";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import api from "./comm/api";
import i18n from "./lang/i118n";
import enData from "./comm/enData";
import store from "./store";

/* 公用样式 */
import "./assets/css/all.css";
import "./assets/css/main.scss";

let local = localStorage.getItem("local");
if (local == undefined || local == null || local == '') {
  local = "US";
}


const app = createApp(App, {
  api: api
});
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(store);
app.config.globalProperties.$enData = enData;
app.config.globalProperties.$commonData = enData;
// 此段代码写入main.js中
app.config.globalProperties.$tm = i18n.global.tm
app.config.globalProperties.$t = i18n.global.t
app.mount("#app");