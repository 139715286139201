<template>
  <div class="" :style="{ background: background }">
    <div class="bottom">
      <div class="common-title">
        <div class="common-title-bg"></div>
        <span>{{ $t("home.ContactUs") }}</span>
      </div>
      <div class="contact">
        <img src="../../../static/images/contact/contact.png" />
        <div class="right">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
            label-width="auto"
          >
            <!-- :label-width="$i18n.locale === 'US'?'200px':'140px'" -->
            <el-form-item :label="$t('contact.email')" prop="email">
              <el-input
                v-model="ruleForm.email"
                :placeholder="$t('contact.insertEmail')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('contact.name')"
              prop="userName"
              :rules="[
                {
                  required: true,
                  message: `${$t('contact.insertName')}`,
                  trigger: 'blur',
                },
                {
                  min: 2,
                  max: 30,
                  message: `${$t('contact.validateLengthCode')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :maxlength="30"
                v-model="ruleForm.userName"
                :placeholder="$t('contact.insertName')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('contact.nationality')"
              prop="country"
              :rules="[
                {
                  required: true,
                  message: `${$t('contact.insertCountry')}`,
                  trigger: 'blur',
                },
                {
                  min: 2,
                  max: 30,
                  message: `${$t('contact.validateLengthCode')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="ruleForm.country"
                :placeholder="$t('contact.insertCountry')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('contact.title')"
              prop="title"
              :rules="[
                {
                  required: true,
                  message: `${$t('contact.insertTitle')}`,
                  trigger: 'blur',
                },
                {
                  min: 2,
                  max: 30,
                  message: `${$t('contact.validateLengthCode')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="ruleForm.title"
                :placeholder="$t('contact.insertTitle')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('contact.content')"
              prop="content"
              :rules="[
                {
                  required: true,
                  message: `${$t('contact.insertContent')}`,
                  trigger: 'blur',
                },
                {
                  min: 10,
                  max: 150,
                  message: `${$t('contact.validateLengthContent')}`,
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                v-model="ruleForm.content"
                rows="5"
                :placeholder="$t('contact.insertContent')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <sliding-validation v-model:verification="verification" />
            </el-form-item>
            <el-form-item>
              <div class="submit" @click="submit">
                {{ $t("contact.submit") }}
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="link">
        <div class="bottom-bottom">
          <div class="bottom-top">
            <div
              v-for="(item, index) in $enData.bottomLink"
              :key="index"
              class="list"
              @click="$router.push(item.router)"
            >
              <!-- {{ item.title }} -->
              {{ $t("contactLink."+item.key) }}
            </div>
          </div>
          <div>
            <div class="bottom-bottom-title">
              {{ $t("contact.bottomTitle") }}
            </div>
            <div class="desc" v-if="contactUs != null">
              <div>{{ JSON.parse(contactUs.address)[$i18n.locale] }}</div>
              <div>Phone: {{ contactUs.phone }}</div>
              <div>Mobile: {{ contactUs.mobile }}</div>
              <div>E-mail: {{ contactUs.email }}</div>
              <div>{{ contactUs.emails }}</div>
            </div>
          </div>
          <!-- <div>
            <div class="bottom-bottom-title">International Student News</div>
            <div class="bottom-bottom-title">Accommodation registration</div>
            <div class="bottom-bottom-title">Study Visa</div>
            <div class="bottom-bottom-title">Contact us</div>
          </div> -->
        </div>
      </div>
      <div class="bq">{{ $t("common.copyright") }}</div>
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
import util from "@/comm/util.js";
import { ElMessage } from "element-plus";
import SlidingValidation from "@/components/SlidingValidation/SlidingValidation";

export default {
  components: { SlidingValidation },
  props: {
    background: {
      type: String,
      default: "#f5f5f5",
    },
  },
  data() {
    // 验证邮箱
    const validateEmailCurrent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("contact.insertEmail")));
      } else if (!util.validateEmail(value)) {
        callback(new Error(this.$t("contact.validateEmailCode")));
      } else {
        callback();
      }
    };
    return {
      verification: false, // '是否验证'
      labelWidth: "",
      contactUs: null,
      ruleForm: {
        content: "",
        country: "",
        email: "",
        title: "",
        userName: "",
      },
      rules: {
        email: [{ validator: validateEmailCurrent, trigger: "blur" }],
      },
    };
  },
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 获取广告数据
    getData() {
      api.getAdvertisingPage({ id: "6" }).then((res) => {
        if (res) {
          this.contactUs = res;
        }
      });
    },
    // 提交表单
    submit() {
      let _this = this;

      _this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!_this.verification) {
            _this.$message.error("请拖动滑块进行验证");
            return;
          }
          api.lw(_this.ruleForm).then((res) => {
            ElMessage({
              message: "添加成功",
              type: "success",
            });
            _this.resetForm();
          });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

.bottom-bottom-title {
  font-size: 36px;
  // font-weight: 600;
  color: #444444;
  margin-bottom: 40px;
  cursor: pointer;
}
.bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;
  .bq {
    width: 100%;
    height: 88px;
    opacity: 1;
    background: #2a2a2c;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 88px;
  }
  .link {
    padding-top: 158px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    margin-top: -80px;
    .bottom-bottom {
      display: flex;
      width: 70%;
      justify-content: space-between;
      padding-bottom: 46px;
      z-index: 2;
      
      
      .desc {
        font-size: 18px;
        font-weight: 500;
        color: #444444;
        line-height: 36px;
      }
    }
    .bottom-top {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
      z-index: 2;
      .list {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
        width: 49%;
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .list:hover {
    color: #ffce7b !important;
  }
  .link:after {
    width: 140%;
    height: 100%;
    position: absolute;
    left: -20%;
    top: 0;
    z-index: 1;
    content: "";
    border-radius: 50% 50% 0 0;
    background: linear-gradient(#beb9b2, #beb9b2);
  }

  .contact {
    z-index: 2;
    .right {
      flex: 1;
      padding-left: 60px;
      box-sizing: border-box;
      .submit {
        width: 152px;
        height: 50px;
        opacity: 1;
        background: #f1d7b5;
        border-radius: 40px;
        font-size: 24px;
        // font-weight: 600;
        text-align: center;
        color: #444444;
        line-height: 50px;
        //margin-top: 30px;
        cursor: pointer;
      }
      .right-title {
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        margin: 18px 0 14px 0;
      }
      //margin-left: 65px;
      :deep .el-form-item__label {
        line-height: 60px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }
      .el-input .el-input__inner {
        background: #e3e0dc;
        height: 195px !important;
      }
      :deep(.el-input__inner) {
        background: #e3e0dc;
        height: 60px;
      }
      :deep(.el-textarea__inner) {
        background: #e3e0dc;
      }
    }
    padding: 0 25px;
    width: 1270px;
    height: 716px;
    opacity: 1;
    background: #f7f4ef;
    border-radius: 12px;
    display: flex;
    align-items: center;
    img {
      width: 533px;
      height: 533px;
    }
  }
  .title {
    width: 230px;
    background: rgba(245, 166, 35, 0.2);
    border-radius: 3px;
    margin-bottom: 70px;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    color: #000000;
  }
}
.demo-ruleForm {
  //:deep .el-form-item {
  //  margin-bottom: 24px;
  //}
  :deep .el-input__inner {
    font-size: 15px;
    color: #999999;
  }
  :deep .el-form-item__error {
    font-size: 15px;
  }
  :deep .el-form-item__label:before {
    display: none;
  }
}
.common-title {
  margin-bottom: 71px;
}
.common-title-bg {
  width: 236px;
  margin-left: -170px;
}
::placeholder {
  font-size: 12px !important;
}
</style>
