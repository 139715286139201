<template>
  <div class="content-wrap">
    <div class="flex-wrap">
      <div class="empty-content" v-if="collectionList.length == 0">
        <el-empty :image-size="200"></el-empty>
      </div>
      <div v-else v-for="(item, index) in collectionList" :key="index" class="list" @click=" $router.push({ path: '/internationalStudentAssociation'}) ">
        <div class="list-img">
          <img :src="url" v-for="(url, i) in item.image" :key="i" alt="" />
        </div>
        <div class="list-desc text-overflow-four">{{item.content[$i18n.locale]}}{{item.content[$i18n.locale]}}</div>
      </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from "@/components/page.vue";
import { reactive, toRefs } from "vue";
import api from "@/comm/api";
export default {
  components: { Page },
  name: "Four",
  setup() {
    const data = reactive({
      collectionList: [],
      total: 0,
      common: { page: 1, pageSize: 2, search: {} },
    });

    // 获取[休闲/玩乐]数据
    const getData = () => {
      api.myForeigners(data.common).then((res) => {
        if (res) {
          data.total = res.total;
          res.records.forEach((item) => {
            item.image = item.image.split(",");
            item.content = JSON.parse(item.content);
          });
          data.collectionList = res.records;
        }
      });
    };

    // 根据页码改变列表数据
    const changePage = (e) => {
      data.common.page = e;
      getData();
    };

    return {
      ...toRefs(data),
      getData,
      changePage,
    };
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  min-height: 500px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  padding: 40px 40px 10px 40px;
  box-sizing: border-box;
  min-height: 518px;
}
.page-wrap {
  margin-top: 40px;
}
.img-box {
  width: 100%;
  height: 180px;
  img {
    border-radius: 11px 11px 0 0;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}

.list {
  width: 888px;
  background: #fff6e7;
  border-radius: 16px;
  padding: 40px;
  margin-bottom: 20px;
}
.list-img {
  display: flex;
  justify-content: space-between;
  img {
    width: 241px;
    height: 245px;
    border-radius: 10px;
    object-fit: cover;
  }
}
.list-desc {
  width: 100%;
  height: 80px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444444;
  line-height: 25px;
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 20px;
  box-sizing: border-box;
}
.list-desc::-webkit-scrollbar {
    display: none;
}
</style>