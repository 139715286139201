module.exports = {
  language: {
    chinese: "Chinese", //简体中文
    English: "English", //英文
    Italiano: "Italiano", //意大利
    Español: "Español", //西班牙
    Français: "Français", //法语
    Deutsch: "Deutsch", //德语
  },
  commonData: {
    // 订单状态
    orderStatus: [
      { value: 0, label: "Unpaid" },
      { value: 1, label: "Unpaid" },
      { value: 2, label: "Under review " },
      { value: 3, label: "Completed" },
      { value: 4, label: "Rejected" },
      { value: 5, label: "Cancel" },
      { value: 6, label: "Paid" },
      { value: 7, label: "Refund" },
    ],
    // 支付方式
    paymentMode: [
      { value: 1, label: "Credit card" },
      { value: 2, label: "Paypal" },
      { value: 3, label: "Alipay" },
      { value: 4, label: "Deposit" },
    ],
    // 大类
    type: [
      { value: 1, label: "Visa" },
      { value: 2, label: "Wi-Fi Card" },
      { value: 3, label: "Vip Airport Pick Up" },
    ],
    // 子类
    childType: [
      { value: 1, label: "Study visa" },
      { value: 2, label: "Work visa" },
      { value: 3, label: "China PR" },
      { value: 4, label: "Renewal work visa" },
      { value: 5, label: "Business e-visa" },
      { value: 6, label: "Group visa" },
      { value: 7, label: "Vip Welcome" },
      { value: 8, label: "Vip Airport Transfers" },
      { value: 9, label: "Wi-Fi Card" },
    ],
    // 职业
    occupationList: [
      { label: "Employee", value: 1 },
      { label: "Businessman", value: 2 },
      { label: "Housewife", value: 3 },
      { label: "Retired", value: 4 },
      { label: "Student", value: 5 },
      { label: "Child", value: 6 },
    ],
    // 与紧急联系人关系
    emergencyContactRelationshipList: [
      { label: "Parents", value: 1 },
      { label: "Relatives", value: 2 },
      { label: "Sister", value: 3 },
      { label: "Brother", value: 4 },
      { label: "Friend", value: 5 },
      { label: "Spouse", value: 6 },
      { label: "Daughter/Son", value: 7 },
    ],
    // 曾在中国因违法被处罚的情况
    punishList: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    comStatus: [
      { label: "Yes", value: 1 },
      { label: "No", value: 2 },
    ],
    gender: [
      { label: "male", value: 1 },
      { label: "female", value: 0 },
    ],
    // 结婚状况
    maritalStatus: [
      { label: "Married", value: 1 },
      { label: "Unmarried", value: 2 },
      { label: "Divorce", value: 3 },
      { label: "Widowed", value: 4 },
    ],
    // 教育状况
    education: [
      { label: "PostDoctor", value: "PostDoctor" },
      { label: "Doctorate", value: "Doctorate" },
      { label: "Master", value: "Master" },
      { label: "Undergraduate", value: "Undergraduate" },
      { label: "High school", value: "High school" },
      { label: "Below high school", value: "Below high school" },
    ],
    // 电话
    phoneType: [
      { label: "China +86", value: 1 },
      { label: "other countries", value: 2 },
    ],
    // 服务类型
    serviceType: [
      { title: "Normal", desc: "5 working days", value: 1 },
      {
        title: "Express",
        desc: "3 working days. Express service requires extra fee",
        value: 2,
      },
      {
        title: "Extra urgent",
        desc: "2 working days. Urgent service requires extra fee",
        value: 3,
      },
    ],
    // 团签--服务类型
    groupServiceType: [
      { title: "Normal", desc: "5 working days", value: 1 },
      {
        title: "Express",
        desc: "3 working days. Express service requires extra fee",
        value: 2,
      },
    ],
    // vip接机车型
    vipCars: [
      { title: "Business 7 Seater", value: 1 },
      { title: "Premium 5 Seater", value: 2 },
    ],
    // 快递类型
    expressType: [
      { title: "Fedex", value: 1 },
      { title: "DHL", value: 2 },
      { title: "SF-EXPRESS", value: 3 },
      { title: "EMS", value: 4 },
    ],
  },
  contactLink: {
    aboutUs: "About us",
    chineseVisa: "Chinese Visa",
    contactUs: "Contact Us",
    insurance: "Insurance",
    advertise: "Advertise",
    wcic: "Wifi-card/Internet in China",
    faq: "FAQ",
    vipApu: "Vip Airport Pick-Up",
    privacy: "Privacy",
    wechat: "APP",
  },
  common: {
    copyright: "©2021 Hereshanghai.com, All Rights Reserved.",
    collectSuccess: "Collection of success", // 收藏成功
    cancelSuccess: "Cancelled collection", // 已取消收藏
    cancelCollection: "Cancel collection", // 取消收藏
    collectFail: "Collection of failure", // 收藏失败
    unit: "",
    month: "Month",
    create: "First Posted", // 首次发布于
    update: ", last refreshed on", // 最后更新于
    Viewed: " · Viewed", // 被浏览
    times: " times", // 次
    isImage: "Picture must be GIF、JPG、PNG format!", // 图片限制
    isLt2M: "Picture size can not exceed 2MB!", // 大小限制
    success: "Your request is submitted", // 申请成功
    payFail: "Payment Failure", // 付款失败
    paySuccess: "Your request is submitted", // 付款成功
    updSuccess: "modify successfully", // 修改成功
    loginAgain: "Please login in again!", // 请重新成功
    updSubmit: "confirm", // 确定修改
    codeError: "Verification code error", // 验证码错误
    username: "username", // 用户名
    FocusOnSuccess: "Focus on success", //关注成功
    AttentionCancelled: "Attention cancelled", //已取消关注
    PublishedSuccessfully: "Published successfully", //发布成功
    delete: "Delete", // 删除
    deleteSuccess: "Successfully delete", // 删除成功
    Redact: "Redact", // 编辑
    Note: "Note", // 注意
    NotLoggedIn: "Not logged in", //未登录
    Deleted: "Deleted", //已删除
    Rejected: "Rejected", //已驳回
    noVisa: "This nationality cannot be processed", //该国籍不能办理
    noCity: "There are no chartered Vip pick-up buses in the city", //该城市没有Vip接机包车
    sample: "Sample",
    aggrement: "Privacy and Terms of service",
    printInvoice: "Print invoice",
  },
  // 头部导航菜单
  navigation: {
    Back: "Home Page",
    SignIn: "Login",
    Register: "Sign Up",

    studentLife: "CHINA TOURS", // 英文: CHINA TOURS  意大利语：TOUR CINA
    visaTravelServices: "CHINA ONLINE VISA", // 英文：CHINA ONLINE VISA  意大利语：VISTO CINESE ONLINE
    livingInShangHai: "IN SHANGHAI", // 英文：IN SHANGHAI 意大利语：IN SHANGHAI
  },
  message: {
    success: "success",
    registerSuccess: "register successful",
    title: "Registration Successful",
    desc: "Please keep your account information safe",
    goHome: "Go to the Home",
  },
  // 重置密码
  resetPw: {
    title: "Reset lost password",
    password: "Set a password of 6 to 20 characters",
    next: "Proceed",
  },
  //登录
  login: {
    EmailLogin: "Email", // 邮箱登录
    Email: "Email",
    MobileNumber: "Mobile number", //短信登录
    accountNumber: "Email", //账号
    Password: "Password", //密码
    PleaseEnterYourEmailAddress: "Please enter your email address", //请输入邮箱
    PleaseEnterYourPassword: "Please enter your password", //'请输入密码'
    MobilePhone: "Mobile number（Only Chinese mobile number）", //手机号
    PleaseEnterYourPhone: "Please enter your mobile number", //请输入手机号
    AuthCode: "Auth code", //验证码
    PleaseEnterTheAuthCode: "Please enter the auth code", //请输入验证码
    GetCode: "Get code", //获取验证码
    SignIn: "Sign In", //登录
    StayloggedIn: "Stay logged in", //记住登录状态
    Register: "Register", //注册新用户
    ForgotPassword: "Forgot password?", //忘记密码
    Individual: "Individual", //个人
    CompanyOrAgency: "Company/Agency", //公司
    Company: "Company", //公司
    sendCode: "Send successfully", //send successfully
  },
  //注册
  register: {
    validateRealName: "Please enter your real name", //验证姓名
    validatePhone: "Please enter mobile phone number", //验证手机号
    validatePhoneInspect: "Please enter the correct mobile phone number", //检查手机号是否正确
    validateNewPhone:
      "The new mobile phone number cannot be the same as the old one", //验证手机号
    validateEmail: "Please enter your email address",
    validateEmailInspect: "Please enter the correct mailbox",
    validateEmailCode: "Please enter email verification code",
    validateCountry: "Please enter your nationality",
    validatePassword: "Please input a password",
    validatePasswordAgin: "Please enter the password again",
    validatePasswordAtypism: "The two passwords are inconsistent",
    validateNickName: "Please enter your user name",
    validatePhoneCode: "Please enter SMS verification code",

    validateCompanyName: "Please enter company name",
    validateCompanyType: "Please enter company type",
    validateCompanySn: "Please enter tax number",
    validateCompanyWebsite: "Please enter the company website",
    validateCompanyAddress: "Please enter company address",
    validateCompanyPost: "Please enter zip code",
    validateCompanyCountry: "Please select country",
    validateCompanyCity: "Please enter a city",
    validateCompanyContact: "Please enter contact",

    EmailRegister: "Email", //邮箱注册
    MobileNumberRegister: "Mobile number", //手机号注册
    checkBox: "I Accept ", //勾选同意 《用户服务协议》
    checkBoxText: "the Terms and Conditions and Privacy Statement",
    checkBoxUserAgreement: "Please check the user agreement",
    AlreadyRegisteredSignIn: "Already registered? Sign In", //已有账号，立即登录
    Register: "Register", //注册
    PleaseSelect: "Please select", //请选择
    PleaseInput: "Please input", //请输入
    Email: "Email", //邮箱
    AuthCode: "Auth code", //确认邮箱
    Password: "Password", //密码
    ConfirmedPassword: "Confirmed password", //确认密码
    CompanyName: "Company’s name", //公司名称
    Typology: "Typology", //公司类型
    VATTax: "VAT tax", //税号
    Website: "Website", //网址
    ContactPerson: "Contact person", //联系人
    PhoneNumber: "Phone number", //电话号码
    Address: "Address", //地址
    PostalCode: "Postal code", //邮编
    Country: "Country", //国家
    City: "City", //城市
    Account: "Account", //用户名
    GetCode: "Get code", //获取验证码

    Name: "Name", //真实姓名
    MobileNumber: "Mobile number", //电话号码
    Nationality: "Nationality", //国籍
    MobileNumberchina: "Mobile number", //手机号(中国)
    yam: "Auth code", //验证码
  },
  home: {
    FoodRecommendation: "WI-FI card/Internet in China",
    LeisurePlay: "Things to do",
    visa: "Visa",
    Journalism: "News",
    ContactUs: "Contact us",
    AcceptanceOrganization: "Acceptance organization",
    AcceptanceOrganizationInfo:
      "The State Administration of migration entrusts the entry-exit administration of public security organs at or above the prefecture level city (including districts and counties under the jurisdiction of the municipality directly under the central government) to accept foreigners' entry-exit permits. Upon the approval of the State Administration of immigration upon the instruction of the entry-exit administration of the provincial public security organ, the entry-exit administration of the county-level public security organ may accept the entry-exit permit of foreigners as entrusted by the State Administration of immigration.",
  },
  contact: {
    email: "Email", //邮箱
    name: "Name", //姓名
    nationality: "Nationality", //国籍
    title: "Title", //标题
    content: "Content", //内容
    verification: "Verification", //验证
    submit: "submit",
    insertEmail: "Insert your email address here",
    insertEmail2: "Insert your email address in Post address",
    insertName: "Insert your name here",
    insertTitle: "Insert your email title",
    insertContent: "Insert your information here",
    insertCountry: "Insert your nationality here",
    slidingValidation: "Hold and drag to the far right",
    validateEmailCode: "Please enter the correct mailbox",
    validateLengthCode: "The shortest length is 2",
    validateLengthContent: "Length should be 10 to 150",
    bottomTitle: "HereShanghai",
    bottomDesc:
      "Shanghai Orange & Orange Travel Agency Co., Ltd. - 251 YaoHua Rd.Free-Trade Zone Shanghai (China) Office: Room 1803, Building No.228 NingGuo Rd. Shanghai (China) Phone: +86 21 61806991 Mobile: +86 16601891870 E-mail: info@chinavisadirect.com marketing@chinavisadirect.co",
  },
  groupSignature: {
    insertEmail: "Insert your email address here",
    tipBeCare: "Note: ",
    // 确定团员信息注意
    submitBeCareFul: {
      title:
        "Please check each member's information, the visa will be issued according to the info you fill in.",
    },
    // 查看团员信息注意
    detailsBeCareFul: {
      title:
        "Please check each member's information, the visa will be issued according to the info you fill in.",
    },
    // 申请成功注意
    successBeCareFul: {
      title:
        "Note: Your request is submitted, thank you! We will check it, please wait for our confirmed email.",
    },
    // 支付后的状态提示
    payAfter: {
      successText:
        "Your request is submitted, you can check the status of your practice in your account.",
    },
    // 常规注意
    beCareFul: {
      title: `
        The Group Visa is issued to a group composed of <span style="font-weight: 900">at least 2 members</span> and maximum 8 members.<br />
        Fill in and attach the first passenger's information, then fill in the second and third... pax.<br />
        <span style="font-weight: 900">Things that you should know before applying for visa:</span><br />
        The Group Visa is applied to a single certificate for the entire group, rather than on individual passport. All members of the group must travel together both incoming and outgoing from China.
      `,
      infoTitle: "Things that you should know before applying for visa:",
      infoDesc:
        "The Group Visa is applied to a single certificate for the entire group, rather than on individual passport. All members of the group must travel together both incoming and outgoing from China.",
    },
    // 学习签证注意提示
    comTipBeCareFul: {
      title:
        "After received your request, our staff will contact you by email to inform you which documents you should provide to request the visa.", // 一个订单只能申请一个人。
      success:
        "Your request is submitted, thank you! We will check it, please wait for our confirmed email.", // 申请成功提示
    },
    uploadInfo: {
      format: "Supported formats：GIF/JPG/PNG/PDF", // 支持格式
      success: "successfully upload", // 上传成功
      see: "click to view", // 点击查看
      uploadText: "Click to upload", // 上传文件
    },
    tipText: {
      voluntarily: "Note: Please check your personal info!", // 自动填充
    },
    essentialInformation: "Personal Info", //基本信息
    personnelInformation: "Passenger personal info", //人员信息
    otherInformation: "Other information", //其他信息
    entryInformation: "Travel Info", //入境信息
    uploadInformation: "Documents upload", //上传信息
    confirmationInformation: "Review&Confirm", //确认信息
    serviceMode: "Service", //服务方式
    payment: "Pay", //付款
    applicationStatus: "Submit order", //申请状态
    pleaseEnter: "Please enter", //请输入
    pleaseSelect: "Please select", //请选择
    confimNex: "Confirm&proceed",
    memberTitle: "Added passenger：", // 已添加成员
    rules: {
      passport: "Please upload your passport", // 请上传护照
      vipTicket: "Please upload your round trip ticket", // 请上传往返机票
      ticket: "Please upload round-trip ticket photo or train ticket photo", // 请上传往返机票/火车票照片
      hotelOrder: "Please upload your hotel order", // 请上传酒店订单
      englishName: "Only uppercase English can be entered", // 只能输入2至20位英文
      idCard: "Please upload your id photo", //请上传证件照
      vaccinationCertificate: "Please upload vaccination certificate", //请上传疫苗接种证明
      invitationLetter: "Please upload the invitation letter.", //请上传邀请函
      companyLicense: "Please upload the business license of Chinese company", //请上传中国公司营业执照
      deposit: "Please upload the deposit voucher", //请上传押金凭证
    },
    submitInfo: {
      name: "Name", //英文姓名
      No: "No.", //序号
      seeDetails: "Click to view details", //查看详情
      passengersInfo: "Passengers info details", //团成员信息
    },
    informationForm: {
      //基本信息表单
      SerialNumber: "No.", //序号
      passport: "Upload your passport info page", //护照识别
      EnglishSurname: "Surname", //英文姓
      EnglishName: "Given name", //英文名
      ChineseName: "Chinese name", //中文名
      nationality: "Nationality", //国籍
      Gender: "Gender", //性别
      dateBirth: "Date of birth", //出生日期
      birthplace: "Place of birth", //出生地
      placeIssue: "Passport issuing place", //护照签发地
      certificatesNumber: "Passport no", //所持证件号码
      passengersEmail: "Passenger`s email", //所持证件号码
      CertificateDate: "Passport issuing date", //所持证件签发日期
      CertificateDateValid: "Passport Expiration date", //所持证件有效日期
      occupation: "Current occupation", //职业
      emergencyContact: "Emergency contact name", //紧急联系人
      emergencyContactRelationship: "Relationship to you", //与紧急联系人关系
      EmergencyContactInformation: "Emergency contact phone number", //紧急联系人方式
      punish:
        "Do you have any criminal record in China or in any other country？", //曾在中国因违法被处罚的情况
      OtherNameInChina:
        "Is this your first passport or have you had another one in the past? (first passport/had an old passport)?", //出境入境中国使用的其他名字
      OtherNationalities: "Do you have any other nationality?", //具有其他国籍
      getShop: "Consignee",
      maritalStatus: "Marital status", //婚姻状况
      SpouseName: "Spouse's name", //配偶姓名
      spouseDate: "Spouse's date of birth", //配偶出生日期
      SpouseNationality: "Spouse's nationality", //配偶国籍
      ResidenceSpouse: "Spouse's address", //配偶居住地
      SpouseContactNumber: "Spouse's phone number", //配偶联系电话
      HighestEducation: "Higher education degree", //最高学历
      SchoolName: "Name of institution", //学校名称
      NameUnitOrganization: "Name of employer", //单位/机构名称,
      OverseasGroupAgency: "Name of travel agency", //境外组团社
      otherCountriesVisited:
        "Countries visited in the past 2 years (except China)", //近2年访问过的其他国家（中国除外）
      ExpressAddress: "Post Address", //快递地址
      TelephoneNumber: "Phone number", //电话号码
      addressInChina: "Address in China", //在华地址
      CarPassengers: "Car passengers (max 4pax)", //坐车人数（最多为4人）
      DestinationAddress: "Destination address (only Shanghai)", //包车目的地的地址
      expressCountry: "Country", //国家
      expressCity: "City", //城市
      expressStreet: "Street and number", //路名
      expressPostcode: "Postcode", //邮编
      contactAddress: "Contact address", //系地址
      consignee: "Recipient name", //收货人
    },
    entry: {
      //入境表单
      enterCity: "City of arrival", //入境城市
      outCity: "City of departure", //出境城市
      enterDate: "Date of arrival in China", //入境日期
      outDate: "Date of departure from China", //出境日期
      inboundFlightNo: "Inbound Flight No", //入境航班
      inboundAirport: "Airport of entry", //入境机场
      carsType: "Service model", //服务车型
    },
    upload: {
      vipTicket: "Travel ticket", //往返机票
      ticket: "Travel ticket (flight/train/bus/boat）", //往返机票/火车票
      hotelOrder: "Hotel reservation (all nights)", //酒店订单
      idCard: "Photo", //证件照
      vaccinationCertificate: "Certificate of vaccination", //疫苗接种证明
      invitationLetter: "Invitation letter", //邀请函
      companyLicense: "License of Chinese company", //中国公司营业执照
    },
    confirm: {
      memberInformation: "Member information", //成员信息
      contactNumber: "Phone", //联系电话
      newMember: "Add another passenger", //新增成员信息
      viewDetail: "View details", //查看详情
    },
  },
  // 个人中心
  personalCenter: {
    // 我的信息
    userInfo: {
      avatar: "Profile photo", // 头像
      nickName: "nickname", // 昵称
      loginName: "Account", // 用户名
      name: "Name", // 真实姓名
      companyName: "Company’s name", // 公司名称
      companyType: "Typology", // 公司类型
      vatTax: "VAT tax", // 税号
      website: "Website", // 网址
      email: "Email", // 注册邮箱
      contactPerson: "Contact person", // 联系人
      phone: "Mobile number", // 绑定手机号
      mobileNumber: "Mobile number", // 电话号码
      password: "Password", // 密码
      nationality: "Nationality", // 国籍
      address: "Address", // 地址
      city: "City", // 城市
      postalCode: "Postal code", // 邮编
      state: "Logged in", // 登录状态
    },
    // 我的信息-按钮
    userInfoBtn: {
      changePhone: "Change mobile phone", // 更换绑定
      changePassword: "Reset lost password",
      changePhoneTag: "（Only Chinese mobile number）", // （只限制中国手机号）
      login: "Login", // 登录
      logout: "Logout", // 退出登录
      placeholderInput: "Please input ", // 请输入,
      newMobileNumber: "New mobile number", // 新手机号
      newPassword: "New password", // 新密码
      confirmedPassword: "Confirmed new password", // 确认新密码
      confirmedPasswordInput: "Please confirm the new password again", // 请再次确认新密码
      submit: "submit", // 完成
    },
  },
  // 搜索
  search: {
    PleaseInput: "please input",
  },
  // 提示语
  messageText: {
    groupSignature: "The group visa must be request for at least 2 pax", // 团签至少需要两个人才能提交
  },
  //hereStroe
  hereStroe: {
    buyNow: "Buy now", //立即购买
    address: "Post address", //收货地址
    addressInput: "Fill in the delivery address", //请先填写收货地址
    addAddress: "Post address", //添加收货地址
    nameInput: "Recipient", //收货人
    phoneInput: "Phone number", //收货人电话
    postAddress: "Post address", //快递地址
    pleaseSelect: "Please select", //请选择
    PleaseInput: "Please input", //请输入
    streetAndNumber: "Street and number", //请输入路名
    postalcode: "Postalcode", //请输入邮编
    city: "City", //请选择城市
    country: "Country", //请选择国家
    confirm: "Confirm", //保存
    Price: "Price", // 费用
    note: "Note", //注意事项
    processingTime: "Processing time", //处理时间
    dataList: "Bill of materials", //材料清单
    OtherContent: "Other content", //其他内容
    ProductDescription: "Product description", //商品描述
    inventory: "No inventory", // 暂无库存
  },
  // 弹框提示确认
  elMessageBoxText: {
    title: "Out to determine", // 退出确定
    releaseTitle: "Out to release", // 退出发布
    desc: "This operation will log out. Do you want to continue?", // 此操作将退出登录, 是否继续?
    MyArticleOpinionDesc:
      "This operation will delete the comment. Do you want to continue?", // 此操作将删除评论, 是否继续?
    delDesc: "This operation will delete this data. Do you want to continue?", // 此操作将删除该条数据, 是否继续?
    confirmButtonText: "confirm", // 确定
    cancelButtonText: "cancel", // 取消
    visaText:
      "Are you sure all the information are correct? If yes, click to 'confirm'. Note: if there are any mistake, our company will not take any responsability.",
    releaseDesc:
      "This operation will exit publishing. Do you want to continue?",
    addDesc: "Do you want to continue releasing products?", // 是否要继续发布商品?
    Confirm: "Confirm", // 确定
  },
  //支付
  payMent: {
    Payment: "Pay", //在线支付
    toPayment: "Submit",
    practiceDetails: "Practice details", //订单详情
    type: "Type", //产品名称
    OrderDate: "Order date", //下单时间
    PracticeNumber: "Practice number", //订单编号
    Total: "Total", //订单金额
    PaymentMethod: "Payment method", //支付方式
    PaymentDetails: "Details", //支付信息
    PassengerName: "Passenger name", //申请人姓名
    Price: "Price", //上网卡费用
    ExpressFee: "加急费用", //加急费用
    TotalAll: "Total", //应付金额
    TotalPayAll: "Total", //总金额
    Pay: "Pay", //确认支付
    CreditCard: "Credit card", //信用卡
    Alipay: "Alipay", //支付宝
    Deposit: "Deposit", //押金
    submit: "Submit", // 提交
    submit2: "Submit", // 提交
    DepositNote: "(need to check wih the agency)", //押金提示
    YourRequestIsSubmitted: "Your request is submitted", //付款成功
    saveAndPrevious: "save and previous", //确认&上一步
    saveAndOrderInfo: "Confirm&View", //确认&查看订单
    imgFormat: "Only JPG/PNG files can be uploaded", //确认&上一步
    cancel: "Payment cancel",
  },
  // 支付产品类型
  payMentType: {
    wifiCard: "Wifi card", // 上网卡
    businessVisa: "Business e-visa", // 商务签证
    studyVisa: "Study visa", // 学习签证
    jobVisa: "job visa", // 工作签证
  },
  // 留学生协会
  foreignerServiceStation: {
    title: "RESOURCES", // 外国人服务站
    allAreas: "All Areas", // 区域分类
  },
  // 美食
  food: {
    MostPopular: "Most Popular", //热门场所美食
    RestaurantList: "Restaurant List", //美食种类分类
  },
  //休闲玩乐
  LeisurePlay: {
    leisureplay: "Leisure / play", //休闲/玩乐
    youMightAlsoLike: "You Might Also Like", //你可能也喜欢
    time: "Open time", // 时间
    address: "Address：", // 地点
    activityContent: "Activity content：", // 活动内容
  },
  // 论坛
  forum: {
    join: "Join in the topic", // 参与话题
    recommendedTopics: "Recommended Topics", // 推荐话题
    hotTopics: "Hot Topics", // 热门话题
    followed: "Followed", // 关注的话题
    favorites: "My Favorites", // 收藏的话题
    more: "More", // 查看更多
    Comments: "comments", // 条评论
    Comment: "comment", // 条评论
    Reply: "Reply to Guest…", // 写下你的评论…
    prevText: "prev", // 上一页
    nextText: "next", // 下一页…

    ReleasePost: "Release a Post", // 请添加话题标题
    ReleaseTouchPost: "Please avoid to post “Touchy topics”", // 请添加话题标题
    newTopic: "Please add a topic title", // 请添加话题标题
    UploadCove: "Upload the cover", // 上传封面
    PublishContent: "Publish content", // 发布内容
    submit: "submit", //发布
    loginIn: "Login In", //立即登录
    follow: "follow", //关注
    article: "article", //帖
    concerned: "concerned", //已关注
    other: "Other recommendations", //其他推荐
    PleaseSelect: "Please select a topic", //请选择话题
    datas: "Please improve the data", //请完善数据
    successfully: "Published successfully", //发布成功
    Comments: "Comments", //条评论
    back: "back", //返回
    reply: "reply", //回复
    NoComment: "No comment", //暂无评论
    NoAttention: "No attention", //暂无关注
    NoCollection: "No collection", //暂无收藏
  },
  //书籍
  book: {
    books: "Books", //书籍
    more: "More", //更多
    category: "Category", //分类
  },
  //住宿
  house: {
    title: "The dormitory information", // 宿舍信息
    university: "university:",
    road: "road:",
    houseTitle: "Housing information", // 房屋信息
    houseIntroduction: "Housing Introduction：", // 房屋简介：
    housing: "Housing", //住宿
    serviceApartment: "Serviced Apartments", //酒店式公寓
    allAreas: "All Areas", //区域分类
    city: "City", //区
    street: "Street", //街道
    like: "You Might Also Like", //你可能喜欢
    price: "Price:", //价格
    type: "Type:", //类型
    rooms: "Rooms:", //房型
    size: "Size:", //面积
    floor: "Floor:", //楼层
    mainWindowFacing: "Main Window Facing:", //朝向
    district: "District:", //区域
    metroStation: "Metro Station:", //地铁站
  },
  // 二手交易
  secondHand: {
    price: "price：", //价格
    newGoods: "Most Recent", //最新发布的商品
    unit: "RMB",
    Description: "Description", // 描述
    PickUp: "Pick Up", // 取货
    Contact: "Contact", // 联系
    class: "Classification name", //分类名称
    secondHandgoods: "Release second-hand goods", //发布二手商品
    name: "Headling name", // 标题名称
    address: "Pick-up address", // 取货地址
    phone: "Contact number", // 联系方式
    price: "Price", // 价格
    classification: "Sort", // 分类
    introductionPicture: "Product picture", // 介绍图片
    details: "Details", // 详情
    release: "Publice", // 发布
    cancel: "Cancel", // 取消
    success: "Added successfully", // 添加成功
    fail: "Add failed", // 添加失败
    rulesCover: "Please least upload one cover images！", // 请上传封面图
  },
  // 职位
  recruit: {
    Favorite: "Favorite", // 收藏
    Salary: "Salary: ", // 月薪
    CandidateLocation: "Candidate Location: ", // 地点
    MostPopularJob: "Most Popular Job", // 热门职位
    JobType: "Job type", // 工作分类
    Resumes: "Resumes", // 精英
    JobProfile: "Job profile", //职位简介
    CommodityInformation: "Commodity information", //商品信息
  },
  // 旅游攻略
  travel: {
    MostRecent: "Most Recent", // 最新攻略
  },
  // 景点门票
  ticket: {
    HotPlaces: "Hot places", // 热门景点
    RecommendedAttractions: "You Might Also Like", // 推荐景点
    Price: "Price：", // 售价：
    Classification: "Classification", // 分类
  },
  // 医疗
  medical: {
    MostPopular: "Most Popular", // 热门推荐
    Phone: "Phone：", // 联系电话
    Room: "Room：", // 房间数
    Address: "Address：", // 医院地址
    Introduction: " Introduction：", //  医院简介
    suite: "suite", //  套房
    primaryClassification: "Primary classification", //  二级分类
    secondaryClassification: "Secondary classification", //  二级分类
  },
  //学习/培训
  study: {
    mostPopular: "Most Popular", //热门推荐
    education: "Education", //学习/培训
    introduction: "Course introduction：", //课程简介
  },

  // 订单表格配置
  tableConfig: {
    createDate: "Order date",
    orderNumber: "Practice no.",
    childType: "Type",
    enterDate: "Date of arrival",
    leaveDate: "Date of departure",
    price: "Price",
    orderStatus: "Status",
    type: "status",
  },
  // 订单信息
  orderInfo: {
    PracticeInfo: "Practice info", // 订单信息
    PostAddress: "Post address", // 订单信息
    CourierNo: "Courier no", // 快递单号
    Other: "Here you can download the copy of your e-Business/Group visa", // 其他
    PostAddress: "Postal address", // 反馈说明
    FeedbackDescription: "Feedback", // 其他
    label: {
      Type: "Type", // 签证种类
      PaxNo: "Pax no：", // 人数数量
      PurchaseNo: "Purchase no：", // 购买数量
      PracticeNo: "Practice no：", // 订单单号
      PaymentNo: "Payment no：", // 付款单号
      Price: "Price：", // 价格
      Discount: "Discount：", // 折扣
      Status: "Status：", // 订单状态
      MethodOfPayment: "Method of payment：", // 付款方式
      Recipient: "Recipient：", // 收货人
      PhoneNumber: "Phone number：", // 联系电话
      Address: "Address：", // 地址
      ExpressOrderNo: " express order no：", // 快递单号
      Time: "Time：", // 时间
      DescriptionContent: "Description content：", // 说明内容
    },
    NoLogisticsInformation: "No logistics information", // 暂无物流信息
    NoInformation: "No information", // 暂无
    NoFeedbackDescription: "No feedback description", // 暂无反馈说明
    CheckTheLogistics: "Check the logistics", // 查看物流
    Download: "Download", // 查看物流
    continue: "Continue",
  },
  orderStatus: [
    { value: 0, label: "Unpaid" },
    { value: 1, label: "Unpaid" },
    { value: 2, label: "Under Review" },
    { value: 3, label: "Completed" },
    { value: 4, label: " Rejected" },
    { value: 5, label: "Canceled" },
    { value: 6, label: "Paid" },
    { value: 7, label: " Refunded" },
  ],
};
