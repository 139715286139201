<template>
  <!--热门景点-->
  <div class="ticket-list">
    <div class="listwrap" v-for="(item, index) in hotList" :key="index">
      <div class="ticket-list-item" @click="goDetails(item.id)">
        <FavoriteCard
          @click.stop="collect(item, index)"
          :favorite="item.favorite"
        />
        <img :src="item.faceImage" />
        <div class="list-title">
          {{ JSON.parse(item.title)[$i18n.locale] }}
        </div>
        <div class="list-price">
          {{ $t("ticket.Price") }}
          <span v-if="JSON.parse(item.price).ZH != ''">
            <span>{{ JSON.parse(item.price).ZH }}</span>
          </span>
          <span v-else>--</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
export default {
  name: "HotTicket",
  components: { FavoriteCard },
  props: {
    hotList: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    // 收藏
    const collect = (val, i) => {
      let favorite = val.favorite ? false : true;
      api
        .sceneryFavorite({
          sceneryId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            emit("changeData");
          }
        });
    };

    // 去详情页面
    const goDetails = (id) => {
      window.scrollTo(0, 0);
      emit("goDetails", id);
    };

    return {
      collect,
      goDetails,
    };
  },
};
</script>

<style scoped lang="scss">
.listwrap {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.ticket-list-item {
  width: 305px;
  border-radius: 16px;
  margin-top: 40px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 235px;
  }

  .collect-img {
    width: 36px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.list-title {
  margin: 31px 0 8px 0;
  font-size: 24px;
  color: #333333;
  margin: 20px 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.list-price {
  font-size: 20px;
  color: #f49d44;
}
</style>
