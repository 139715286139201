<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <!--banner-->
    <div :class="className" class="content">
      <div class="banner-wrap">
        <div class="banner-carousel">
          <Carousel :list="bannerList" />
        </div>

        <div class="banner-right">
          <img
            v-if="recommenList.length != 0"
            :src="recommenList[0].image[0]"
          />
          <div class="form-style">
            <el-button
              class="join-btn"
              @click="
                $router.push({
                  path: '/forumTopic',
                  query: { id: recommenList[0].id },
                })
              "
            >
              {{ $t("forum.join") }}
            </el-button>
          </div>
        </div>
      </div>

      <div class="forum">
        <my-forum :updateShow="updateShow" @is-show-click="isShowClick" />
        <!-- 推荐话题 -->
        <div class="right">
          <div class="title">{{ $t("forum.recommendedTopics") }}</div>
          <div class="flex justspace tj">
            <div
              v-for="(item, index) in recommenList"
              :key="index"
              @click="
                $router.push({
                  path: '/forumTopic',
                  query: { id: item.id },
                })
              "
              class="flex recommended"
            >
              <div class="recommended-img">
                <FavoriteCard
                  style="z-index: 8"
                  @click.stop="collect(item, 1)"
                  :favorite="item.follow == null ? false : item.follow"
                />
                <img class="titleImages" :src="item.image[0]" alt="" />
              </div>
              <div class="flexCol justspace">
                <div class="tj-title">
                  {{ JSON.parse(item.title)[$i18n.locale] }}
                </div>
                <div class="tj-desc">
                  <rich-text
                    v-html="JSON.parse(item.description)[$i18n.locale]"
                  ></rich-text>
                </div>
                <count-user
                  :msg="item.comment == null ? 0 : item.comment"
                  :user="item.people == null ? 0 : item.people"
                />
              </div>
            </div>
          </div>

          <!--热门话题-->
          <div class="title">{{ $t("forum.hotTopics") }}</div>
          <div class="hot">
            <div
              v-for="(item, index) in hotList"
              :key="index"
              @click="
                $router.push({ path: '/forumTopic', query: { id: item.id } })
              "
            >
              <div class="hotList">
                <div class="recommended-img">
                  <FavoriteCard
                    style="z-index: 8; right: 0"
                    @click.stop="collect(item, 2)"
                    :favorite="item.follow == null ? false : item.follow"
                  />
                  <img :src="item.image[0]" alt="" style="object-fit: cover" />
                </div>
                <div class="hot-desc text-overflow-two">
                  <!-- {{ JSON.parse(item.description)[$i18n.locale] }} -->
                  <rich-text
                    v-html="JSON.parse(item.description)[$i18n.locale]"
                  ></rich-text>
                </div>
                <count-user
                  :msg="item.comment == null ? 0 : item.comment"
                  :user="item.people == null ? 0 : item.people"
                />
              </div>
            </div>
          </div>

          <!--文章列表-->
          <div class="topic">
            <TopicList @click-true="clickTrue" :type="1" />
          </div>
        </div>
      </div>
    </div>

    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
// import { CScrollbar } from "c-scrollbar"; // 滚动页
import TopSearchThree from "../home/topSearchThree";
import MyForum from "./ChildCom/MyLikeForum";
import Carousel from "@/components/Carousel/Carouse2";
import CountUser from "../../components/CountUser/CountUser";
import TopicList from "./ChildCom/TopicList";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import ContactUsOne from "../home/contactUsOne.vue";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  components: {
    TopicList,
    CountUser,
    MyForum,
    TopSearchThree,
    Carousel,
    ContactUsOne,
    FavoriteCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      advertises: [],
      bannerList: [], //banner 数组
      recommenList: [], //推荐话题
      bannerRight: [], //banner 右话题
      hotList: [], //热门话题
      updateShow: false, //子组件刷新
      follow: [
        "My first thought about art, as a child",
        "My You can't depend on your eyes when…",
        "The one way of tolerating existence is to lose…",
        "The truth is the only thing worth having",
        "My first thought about art, as a child",
      ],
      collection: [
        {
          title: "UNION CITY STORE",
          desc: "4501 Palisade Ave Union City, NJ 07087, USA",
        },
        {
          title: "LONG ISLAND CITY STORE",
          desc: "27-35 Jackson Ave Long Island City, NY 11101, USA",
        },
        {
          title: "MANHATTAN STORE",
          desc: "132-160 Ludlow St New York, NY 10002, USA",
        },
        {
          title: "BROOKLYN STORE",
          desc: "921 E New York Ave Brooklyn, NY 11203, USA",
        },
      ],
    });
    // 获取轮播列表
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "TOPIC" });
    };
    //获取推荐话题
    const recommentopicGet = async () => {
      api.recommendedTopicOrtherlist().then((res) => {
        let lists = res;
        lists.map((val) => {
          val.image = val.image.split(",");
        });
        data.recommenList = lists.slice(0, 2);
        data.bannerRight = lists.slice(2, 3);
      });
    };

    //热门话题
    const hottopicGet = async () => {
      api.recommendedTopichotlist().then((res) => {
        data.hotList = res;
        data.hotList.map((val) => {
          val.image = val.image.split(",");
        });
      });
    };

    // 收藏 1-推荐话题 2-热门话题
    const collect = (val, type) => {
      let follow = val.follow ? false : true;
      if (type == 1 || type == 2) {
        //话题
        api
          .topicFollowUpdate({
            topicId: type == 1 ? val.topicId : val.id,
            follow,
          })
          .then((res) => {
            if (res) {
              ElMessage({
                message: follow
                  ? t("common.FocusOnSuccess")
                  : t("common.AttentionCancelled"),
                type: "success",
              });
              recommentopicGet(); //推荐话题
              hottopicGet(); //热门话题
              data.updateShow = true;
            }
          });
      }
    };
    //刷新子组件
    const isShowClick = () => {
      data.updateShow = false;
    };
    const clickTrue = () => {
      data.updateShow = true;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 9); // 获取广告位 图
      getBanners(); //获取banner 图
      recommentopicGet(); //获取推荐话题
      hottopicGet(); //获取热门话题
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      getBanners,
      collect, //关注
      isShowClick,
      clickTrue,
    };
  },
};
</script>
<style></style>
<style scoped lang="scss">
::deep .el-carousel {
  width: auto !important;
  height: auto !important;
}
.page-wrap {
  background-color: #fff;
}

.banner-wrap {
  display: flex;
  height: 670px;
}

.banner-carousel {
  width: 1130px;

  :deep .el-carousel__container {
    height: 670px;
  }

  :deep .el-carousel__arrow {
    width: 48px;
    height: 48px;
    background: #ffffff;
    border-radius: 50%;
    color: #f5ad34;
    font-size: 35px;
  }

  :deep .el-carousel__arrow--left {
    position: absolute;
    bottom: 0;

    &:hover {
      opacity: 0.5;
    }
  }

  :deep .el-carousel__arrow--right {
  }
}

.top-banner {
  width: 100%;
  height: 670px;
}

.banner-right {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form-style {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 226px;
  }
}

.forum {
  margin-top: 47px;
  align-items: flex-start;

  .right {
    width: 980px;
    margin-left: 38px;

    .recommended {
      .recommended-img {
        position: relative;

        .collect-img {
          width: 30px;
          height: auto;
          position: absolute;
          top: 22px;
          right: 22px;
        }
      }

      .count-box {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: auto;
          margin-right: 9px;
          object-fit: cover;
        }
        span {
          font-size: 16px;
          font-weight: 900;
          color: #666666;
        }
      }
    }

    .bottom {
      padding: 0 0 0 36px;
      width: 944px;
      //height: 1108px;
      background: #f8f9fc;
      margin-top: 70px;

      .count-box {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: auto;
          margin-right: 9px;
          object-fit: cover;
        }

        span {
          font-size: 16px;
          font-weight: 900;
          color: #666666;
        }
      }

      .scrollbar-title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        margin-top: 40px;
      }

      .scrollbar-desc {
        width: 648px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        margin: 8px 0 10px 0;
      }

      img {
        width: 176px;
        height: 136px;
        margin-right: 15px;
        margin-bottom: 11px;
      }
    }

    .bottom:last-child {
      padding-bottom: 40px;
    }

    .hot {
      width: 100%;
      display: flex;

      .recommended-img {
        position: relative;
        width: 100%;

        .collect-img {
          width: 30px;
          height: auto;
          position: absolute;
          top: 22px;
          right: 22px;
        }

        img {
          width: 100%;
          height: 174px;
        }
      }

      div {
        width: 25%;
        .hotList {
          width: 90%;
          .hot-desc {
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            margin-top: 18px;
            opacity: 0.8;
            margin-bottom: 12px;
          }

          img {
            width: 100%;
            height: 174px;
          }

          .count-box {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;

            img {
              width: 30px;
              height: auto;
              margin-right: 9px;
            }

            span {
              font-size: 16px;
              font-weight: 900;
              color: #666666;
            }
          }
        }
      }
    }

    .title {
      font-size: 34px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 30px;
    }

    .el-icon-user {
      font-size: 32px;
      margin-right: 5px;
    }

    .el-icon-chat-line-square {
      font-size: 32px;
      margin-right: 5px;
    }

    .marlef35 {
      margin-left: 35px;
    }

    .tj {
      margin-bottom: 80px;

      img {
        width: 195px;
        height: 198px;
        margin-right: 15px;
        object-fit: cover;
      }

      .tj-title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .tj-desc {
        width: 265px;
        font-size: 15px;
        font-weight: 500;
        color: #666666;
        line-height: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }

  width: 100%;
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
}

.alitem-center {
  align-items: center;
}

.justcenter {
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.justspace {
  justify-content: space-between;
}

.mb-40 {
  padding-bottom: 40px !important;
}

.topic {
  margin-top: 60px;
}
</style>
