<template>
  <div class="backgound">
    <div class="top">
      <div v-for="(item, index) in title" :key="index" :class="titleIndex == index ? 'title-check' : 'title-default'"
        @click="titleIndex = index" class="marginRight40">
        {{ item }}
      </div>
    </div>
    <order-details ref="RefDetails" v-if="isDetails" :orderId="orderId" />
    <div class="flexRow flexJustifyCenter" v-if="isDetails">
      <el-button class="back-btn" @click="goBack">{{
          $t('forum.back')
      }}</el-button>
    </div>
    <template v-else>
      <div class="form-style">
        <template v-for="(childData, index) in tableData" :key="index">
          <el-table class="table-box" :data="childData" border
            :header-cell-style="{ background: '#ECECEC', color: '#666666' }">
            <!--下单日期-->
            <el-table-column prop="createDate" :label="$t('tableConfig.createDate')"></el-table-column>
            <!--订单编号-->
            <el-table-column prop="orderNumber" :label="$t('tableConfig.orderNumber')" width="200px"></el-table-column>
            <!--产品种类-->
            <el-table-column prop="childType" :label="$t('tableConfig.childType')">
              <template v-slot="scope">
                <div>
                  {{
                      scope.row.childType
                        ? $commonData.myOrder.childType[scope.row.childType - 1]
                          .label
                        : '-'
                  }}
                </div>
              </template>
            </el-table-column>
            <!--入境日期-->
            <el-table-column prop="enterDate" :label="$t('tableConfig.enterDate')">
              <template v-slot="scope">
                <div>{{ scope.row.enterDate ? scope.row.enterDate : '-' }}</div>
              </template>
            </el-table-column>
            <!--出境日期-->
            <el-table-column prop="leaveDate" :label="$t('tableConfig.leaveDate')">
              <template v-slot="scope">
                <div>{{ scope.row.leaveDate ? scope.row.leaveDate : '-' }}</div>
              </template>
            </el-table-column>
            <!--金额-->
            <el-table-column prop="price" :label="$t('tableConfig.price')">
              <template v-slot="scope">
                <div>
                  {{ scope.row.price ? Number(scope.row.price).toFixed(2) : 0 }}
                </div>
              </template>
            </el-table-column>
            <!--订单状态-->
            <el-table-column prop="orderStatus" :label="$t('tableConfig.orderStatus')">
              <template v-slot="scope">
                <div>

                  
                  {{

                      scope.row.childType
                        ? ($tm('orderStatus')[scope.row.orderStatus])
                          .label
                        : '-'
                      
                  }}
                </div>

                <div class="order-details" @click="goOrderDetails(scope.row.id)">
                  {{ $t('groupSignature.confirm.viewDetail') }}
                </div>
                <!-- {{scope.row.childType}} -->
                <div v-if="
                  ((scope.row.childType == 5 ||
                    scope.row.childType == 6 ||
                    scope.row.childType == 7 ||
                    scope.row.childType == 8 ||
                    scope.row.childType == 9) &&
                    scope.row.orderStatus == 1) ||
                  scope.row.orderStatus == 0
                " @click="goOderInfo(scope.row)" class="order-details">
                  <!-- @click="toPay(scope.row.childType, scope.row)" -->
                  {{
                      scope.row.orderStatus == 1
                        ? $t('payMent.Payment')
                        : $t('orderInfo.continue')
                  }}
                </div>
				<print ref="printRefs" :data="objData"></print>
				<div @click="printClick(scope.row.id)" class="order-details" v-if="scope.row.orderStatus == 6 || scope.row.orderStatus == 3 && scope.row.childType == 6 || scope.row.childType == 5">{{ $t('common.printInvoice') }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
    </template>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  nextTick,
  toRefs,
  getCurrentInstance,
  onMounted,
} from 'vue'
import Page from '@/components/page'
import api from '@/comm/api'
import OrderDetails from './myOrder/details.vue'
import { useRouter } from 'vue-router'
import print from "./print.vue"

export default {
  components: { Page, OrderDetails, print },
  setup() {
    const { proxy } = getCurrentInstance()

    // 当前卡片下标
    let titleIndex = ref(0)
    const router = useRouter()
    const data = reactive({
      title: '',
      total: 0,
      common: { page: 1, pageSize: 3 },
      isDetails: false, // 是否在详情页
      orderId: '',
	  objData: {}
    })

    // 订单数据
    const order = reactive({
      // 表单配置
      tableConfig: proxy.$commonData.myOrder.tableInfo,
      tableData: [],
    })

    const RefDetails = ref() // 订单详情

    // 查看订单详情
    const goOrderDetails = (id) => {
      data.isDetails = true
      data.orderId = id
      nextTick(() => {
        RefDetails.value.getData()
        RefDetails.value.getOperatorInformation()
      })
    }

    // 查看订单数据
    const getData = () => {
      api.getOrderInfoList(data.common).then((res) => {
        if (res) {
          data.total = res.total
          order.tableData = []
          // 将数组分割成3个一组
          for (var i = 0; i < res.records.length; i += 1) {
            order.tableData.push(res.records.slice(i, i + 1))
          }
        }
      })
    }

    // 根据页码改变列表数据
    const changePage = (e) => {
      data.common.page = e
      getData()
    }

    // 返回表格页面
    const goBack = () => {
      data.isDetails = false
    }

    const toPay = (type, data) => {
      if (type == 9) {
        // 去支付
        router.push({
          path: '/hereStore/payMent',
          query: {
            details: encodeURIComponent(JSON.stringify(data)),
            data: encodeURIComponent(JSON.stringify({})),
          },
        })
      }
    }
	
	

    onMounted(() => {
      getData()
    })
    return {
      titleIndex,
      goOrderDetails,
      ...toRefs(data),
      ...toRefs(order),
      changePage,
      RefDetails,
      goBack,
      toPay
    }
  },
  methods: {
	printClick(id) {
		api.getOrderInfoDetails({ id }).then((res) => {
		  if (res) {
		    const obj = {
				invoiceNo: res.invoiceNo, // 发票号
				createDate: res.createDate, // 下单日期
				orderNumber: res.orderNumber, // 单号
				peopleNumber: res.visaAll.length, // 人数
				unitPrice: (Number(res.price) / res.visaAll.length).toFixed(2), // 价格
				totalPrice: Number(res.price), // 总价
				childType: res.childType // 产品种类
			}
			this.objData = obj
			this.$nextTick(()=> {
				this.$refs.printRefs.handlPrint()
			})
		  }
		})
	},
    goOderInfo(e) {
      // this.$router.push(
      //   "commonVisa?visaType=" + e.childType + "&nowIndex=" + 2
      // );
      // console.log(e.childType)
      if (e.childType > 4 && e.childType < 7) {
        this.$router.push({
          path: 'Visa',
          query: {
            visaType: e.childType,
            nowIndex: 3,
            orderNumber: e.id,
            data: encodeURIComponent(JSON.stringify(e)),
          },
        })
      } else if (e.childType == 9) {
        api.detailCardToPay(e.id).then((res) => {

          let detail = {
            "orderNumber": res.order_number,
            "orderId": res.order_id,
            "createDate": res.create_date,
            "urgentName": res.urgent_name,
            "orderPrice": res.order_price - 0,
            "orderCount": res.order_count - 0,
            "orderUserInfo": [
              {
                "englishName": res.english_name,
                "surname": res.surname,
                "name": res.name,
                "urgentAmount": 0,
                "price": res.price - 0,
                "urgentName": res.urgent_name,
                "count": res.count
              }
            ]
          }
          let data = {
            "title": res.title,
            "typeId": res.type_id,
            "type": res.type,
            "childType": res.child_type
          }
          this.$router.push({
            path: '/hereStore/payMent',
            query: {
              details: encodeURIComponent(JSON.stringify(detail)),
              data: encodeURIComponent(JSON.stringify(data)),
            },
          })
        })



      } else if (e.childType >= 7) {
        this.$router.push({
          path: 'vipServe',
          query: {
            vipServeType: e.childType == 7 ? 1 : 2,
            nowIndex: 3,
            orderNumber: e.id,
            data: encodeURIComponent(JSON.stringify(e)),
          },
        })
      } else {
        this.$router.push({
          path: 'commonVisa',
          query: {
            visaType: e.childType,
            nowIndex: 2,
            orderNumber: e.id,
            data: encodeURIComponent(JSON.stringify(e)),
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.login-back {
  width: 100px;
  height: 40px;
  background: #f5a623;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0px;
  margin-left: 36px;
  cursor: pointer;
}

.flex {
  display: flex;
}

.order-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .order-title {
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    margin-bottom: 9px;
  }

  .upload {
    font-size: 16px;
    font-weight: 400;
    color: #f5a623;
    margin-left: 80px;
    cursor: pointer;
  }

  .order-desc {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-right: 100px;
    margin-bottom: 20px;
    display: flex;
  }

  .order-detail {
    width: 90%;
    background: #ffffff;
    border: 1px solid #cecece;
    padding: 14px 23px 6px 23px;
    margin-bottom: 20px;
  }
}

.form-style {
  padding: 20px;
  background: #ffffff;
  box-sizing: border-box;
  min-height: 520px;

  .list-syle {
    margin-bottom: 20px;
  }

  .table-box {
    margin-bottom: 20px;
  }
}

.top {
  border-bottom: 1px solid #e6e9f4;
  display: flex;
  padding: 24px 0 0 41px;
  background: #ffffff;

  .marginRight40 {
    margin-right: 40px;
  }

  .title-check {
    height: 34px;
    color: #444444;
    line-height: 24px;
    border-bottom: 4px solid #f5a623;
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    cursor: pointer;
  }

  .title-default {
    height: 34px;
    color: #444444;
    line-height: 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #999999;
  }
}

.backgound {
  width: 1058px;
  margin-left: 22px;
}

.order-details {
  cursor: pointer;
}

.order-details:hover {
  color: #5c9dff;
}

.common-width {
  width: 400px;
}

.page-wrap {
  margin-top: 40px;
}

.back-btn {
  background-color: #f5a623;
  border-color: #f5a623;
  color: white;
  margin-top: 40px;
}

.back-btn:hover {
  background-color: #f5a623;
  border-color: #f5a623;
  color: white;
  margin-top: 40px;
}
</style>
