import {
  createI18n
} from 'vue-i18n'

let local = localStorage.getItem('local');
if (local == undefined || local == null || local == '') {
  local = "US";
}

const i18n = createI18n({
  locale: local,
  messages: {
    // ZH: require('./zh.js'), //引入语言文件
    US: require('./en.js'),
    IT: require('./it.js'),
    ES: require('./es.js'),
    FR: require('./fr.js'),
    DE: require('./de.js'),
  },
})

export default i18n // 将i18n暴露出去，在main.js中引入挂载