<template>
  <div class="background">
    <div class="login">
      <div class="top-title" v-if="login == 0">Sign In</div>
      <!-- 登录 -->
      <div class="login-info" v-if="login == 0">
        <div class="close close-box">
          <div class="back_home" @click="$router.push('/')">
            <!-- <img class="closeBtn" src="../../../static/images/login/close.png" alt="" /> -->
            {{$t("navigation.Back")}} 
            <i class="el-icon-arrow-right"></i>
          </div>
          
        </div>
        <img src="../../../static/images/login/logo.png" class="logo" />
        <div class="login-type">
          <div class="list-default" @click="loginTypeIndex = 0">
            {{ $t("login.EmailLogin") }}
          </div>
          <!-- <div
            :class="loginTypeIndex == 1 ? 'list-check' : 'list-default'"
            @click="loginTypeIndex = 1"
          >
            {{ $t("login.MobileNumber") }}
          </div> -->
        </div>
        <!-- 邮箱登陆 -->
        <div v-if="loginTypeIndex == 0" class="input-style">
          <div class="title">{{ $t("login.Email") }}</div>
          <!-- 邮箱 -->
          <div class="input">
            <img src="../../../static/images/login/User.png" />
            <el-input :placeholder="$t('login.PleaseEnterYourEmailAddress')" v-model="emailLogin.username" clearable>
            </el-input>
          </div>
          <div class="title martop20">{{ $t("login.Password") }}</div>
          <!-- 密码 -->
          <div class="input">
            <img src="../../../static/images/login/Lock.png" />
            <el-input show-password :placeholder="$t('login.PleaseEnterYourPassword')" v-model="emailLogin.password1"
              clearable></el-input>
          </div>
        </div>
        <!-- 短信登陆 -->
        <div v-if="loginTypeIndex == 1" class="input-style">
          <div class="title">{{ $t("login.MobilePhone") }}</div>
          <!-- 手机号 -->
          <div class="input">
            <img src="../../../static/images/login/phone.png" />
            <el-input :placeholder="$t('login.PleaseEnterYourPhone')" v-model="phoneLogin.username"></el-input>
          </div>
          <div class="title martop20">{{ $t("login.AuthCode") }}</div>
          <!-- 验证码 -->
          <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div class="input2">
              <img src="../../../static/images/login/code.png" />
              <el-input :placeholder="$t('login.PleaseEnterTheAuthCode')" v-model="phoneLogin.password"></el-input>
            </div>
            <div v-if="codeType == 0" class="get-code" @click="getCode">
              {{ $t("login.GetCode") }}
              <!-- 获取验证码 -->
            </div>
            <div v-if="codeType == 1" class="get-code">{{ waitCode }}</div>
          </div>
        </div>
        <el-button class="login" :loading="btnLoading" @click="userLogin">{{
            $t("login.SignIn")
        }}</el-button>

        <!--        <div class="login" @click="userLogin">{{ $t("login.SignIn") }}</div>-->
        <!-- 登录 -->
        <div class="other-operation">
          <!-- 记住登录状态 -->
          <el-checkbox v-model="checked" v-if="loginTypeIndex == 0">
            {{ $t("login.StayloggedIn") }}
          </el-checkbox>
          <div v-else></div>

          <div class="right">
            <!-- 注册新用户 -->
            <div class="desc left30" @click="login = 1">
              {{ $t("login.Register") }}
            </div>

            <!-- 忘记密码？ -->
            <div class="desc left30" @click="goNext">
              {{ $t("login.ForgotPassword") }}
            </div>
          </div>
        </div>
        <!-- <div class="language">
          <div :class="$i18n.locale == 'US' ? 'active' : ''" @click="languageChange(0)">
            English
          </div>
          /
          <div :class="$i18n.locale == 'ZH' ? 'active' : ''" @click="languageChange(1)">
            中文
          </div>
        </div> -->
        <div class="language">

          <el-select v-model="defaultI18n" placeholder="请选择" @change="changeLocale">
            <el-option
              v-for="item in languageList"
              :key="item.id"
              :label="item.text"
              :value="item.id">
             <div class="local" style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-top: 4px;">
              <img class="icon" :src="item.icon" style="width: 28px;height: 28px;" alt="" />
              <span>{{ item.text }}</span>
             </div>
            </el-option>
          </el-select>

          
      </div>
      </div>

      <!-- 注册新用户 -->
      <div class="register" v-if="login == 1">
        <div class="close">
          <div @click="login = 0">
            <img class="closeBtn" style="height: 40px;width: 40px;" src="../../../static/images/login/close.png" alt="" />
          </div>
        </div>
        <div class="register-info">
          <div class="register-title">Sing In / Register</div>
          <div class="register-flexs">
            <div>
              <img class="closeBtn" src="../../../static/images/login/Individual.png" alt="" />
              <span>{{ $t("login.Individual") }}</span>
              <div class="changeBtn" @click="changRegister(1)">
                {{ $t("login.Individual") }}
              </div>
              <!-- 个人 -->
            </div>
            <div>
              <img class="closeBtn" src="../../../static/images/login/Company.png" alt="" />
              <span>{{ $t("login.CompanyOrAgency") }}</span>
			  <el-tooltip effect="dark" content="Before register your Company/Agency account, please contact us to take your Discount." placement="top">
				<div class="changeBtn" @click="changRegister(2)">
				  {{ $t("login.Company") }}
				</div>
			  </el-tooltip>
              <!-- 公司 -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import { getCookie, setCookie, clearCookie } from "@/utils/cookie.js";
import api from "@/comm/api";
import { ElMessageBox, ElMessage } from "element-plus";
import { encryptedData } from "@/utils/encrypt";

export default {
  data() {
    return {
      btnLoading: false,
      loginTypeIndex: 0,
      checked: false,
      waitCode: "60",
      codeType: 0,
      login: 0,
      forgetPasswordType: 0,
      emailLogin: {
        client: "APP",
        loginType: 0,
        username: "", // 354743266@qq.com  2553183448@qq.com  18325223538
        password: "", // zhouzhou123  zhou123  zhou123
      },
      phoneLogin: {
        client: "APP",
        loginType: 1,
        username: "",
        password: "",
      },
      cookieInfo: {},
      inv: "",
      defaultI18n: this.$i18n.locale,
      languageList: [
        {id: 'US', icon: require('../../../static/images/english.png'), text: this.$t("language.English")},
        {id: 'IT', icon: require('../../../static/images/Italiano.jpg'), text: this.$t("language.Italiano")},
        {id: 'ES', icon: require('../../../static/images/espanol.jpg'), text: this.$t("language.Español")},
        {id: 'FR', icon: require('../../../static/images/francais.jpg'), text: this.$t("language.Français")},
        {id: 'DE', icon: require('../../../static/images/Deutsch.jpg'), text: this.$t("language.Deutsch")}
      ]
    };
  },
  mounted() {
    this.inv = this.$route.query.inv;
    if (this.inv) {
      localStorage.setItem("inv", this.inv);
    }
    let _this = this;
    if (_this.$route.query.login) {
      _this.login = Number(_this.$route.query.login);
    }

    // 获取cookie数据
    _this.getCookieData();

    api.topMenu().then((res) => {
      localStorage.setItem("menuItem", JSON.stringify(res));
    });
  },
  methods: {
    getCookieData() {
      let _this = this;
      const info = getCookie();
      _this.cookieInfo = JSON.parse(JSON.stringify(info));

      if (!info) {
        return;
      }
      _this.checked = info.checked;
      _this.loginTypeIndex = info.loginType ? info.loginType : 0;
      delete info.checked;
      if (info.loginType == 1) {
        _this.phoneLogin = info;
      } else if (info.loginType == 0) {
        _this.emailLogin = info;
      }
    },
    changeLocale(e) {
      (this.$i18n.locale = e),
        (this.i18n = e),
      localStorage.setItem("local", e);
      this.$router.go(0);
    },
    userLogin() {
      if (this.loginTypeIndex == 0) {
        if (!util.validateEmail(this.emailLogin.username)) {
          ElMessage({
            message: this.$t("register.validateEmailInspect"),
            type: "warning",
          });
          return;
        }
        if (!this.emailLogin.password1) {
          ElMessage({
            message: this.$t("register.validatePassword"),
            type: "warning",
          });
          return;
        }
        this.btnLoading = true;
        //邮箱登陆
        // let isLoginRSA = localStorage.getItem("isLoginRSA") ? false : false;

        let isChecked = this.cookieInfo.checked
          ? this.cookieInfo.checked
          : false;
        // return

        if (
          isChecked &&
          this.cookieInfo &&
          this.emailLogin.username == this.cookieInfo.username
        ) {
          this.loginEmail(this.emailLogin);
        } else {
          // 密码加密
          const password = encryptedData(this.emailLogin.password1);
          password.then((res) => {
            this.emailLogin.password = res;
            this.loginEmail(this.emailLogin);
          });
        }
        // if (!isLoginRSA && this.cookieInfo.checked) {
        //   const password = encryptedData(this.emailLogin.password);
        //   password.then(res => {
        //     this.emailLogin.password = res;
        //     this.isLoginRSA = true;
        //     // localStorage.setItem("isLoginRSA",true)
        //     this.loginEmail(this.emailLogin);
        //   });
        // } else {
        //   this.loginEmail(this.emailLogin);
        // }
      } else {
        if (!util.checkPhone(this.phoneLogin.username)) {
          ElMessage({
            message: this.$t("register.validatePhoneInspect"),
            type: "warning",
          });
          return;
        }
        if (!this.phoneLogin.password) {
          ElMessage({
            message: this.$t("register.validatePhoneCode"),
            type: "warning",
          });
          return;
        }
        this.btnLoading = true;

        const phoneInfo = JSON.parse(JSON.stringify(this.phoneLogin));
        const password = encryptedData(phoneInfo.password);
        password.then((res) => {
          phoneInfo.password = res;
          //短信登陆
          api
            .login(phoneInfo)
            .then((res) => {
              if (res) {
                this.btnLoading = false;
                // 是否记住登录状态
                if (this.checked == true) {
                  //传入账号名，密码，和保存天数3个参数
                  setCookie(this.phoneLogin, this.checked, 7);
                } else {
                  //清空Cookie
                  clearCookie();
                }
                localStorage.setItem("token", res.accessToken);
                this.$store.commit("setIsLogin", true);
                let path = this.$route.query.fullPath;
                if (path) {
                  this.$router.replace(path);
                } else {
                  this.$router.replace("/");
                }
              }
              {
                this.btnLoading = false;
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        });
      }
    },
    // 邮箱登录
    loginEmail(data) {
      api
        .login(data)
        .then((res) => {
          if (res) {
            this.btnLoading = false;
            localStorage.setItem("token", res.accessToken);
            this.$store.commit("setIsLogin", true);
            this.$store.commit("setIsLoginRSA", true);
            // 是否记住登录状态
            if (this.checked == true) {
              //传入账号名，密码，和保存天数3个参数
              setCookie(this.emailLogin, this.checked, 7);
            } else {
              //清空Cookie
              clearCookie();
            }
            let path = this.$route.query.fullPath;
            if (path) {
              this.$router.replace(path);
            } else {
              this.$router.replace("/");
            }
          } else {
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //忘记密码
    goNext() {
      this.$router.push("/forget");
    },
    //注册
    changRegister(type) {
      // 1-个人 2-企业
      this.$router.push("/register?type=" + type);
    },
    //切换语言
    languageChange(type) {
      this.$i18n.locale = type == 0 ? "US" : "US";
      localStorage.setItem("local", type == 0 ? "US" : "US");
    },
    getCode: util.debounce(function () {
      //防抖成功
      let that = this;
      if (!util.checkPhone(this.phoneLogin.username)) {
        ElMessage({
          message: this.$t("register.validatePhoneInspect"),
          type: "warning",
        });
        return;
      }
      //发送验证码
      var da = {
        loginType: 1,
        username: this.phoneLogin.username,
      };
      api.loginCode(da).then((res) => {
        if (res) {
          this.codeType = 1;
          that.beginInterval();
        }
      });
    }),
    beginInterval() {
      this.waitCode = this.waitCode - 1;
      if (this.waitCode <= 0) {
        this.waitCode = 60;
        this.codeType = 0;
        this.disabled = false;
      } else {
        setTimeout(this.beginInterval, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep .el-input__inner {
  border: 0;
  background-color: transparent;
}

.input-style {
  width: 438px;
  margin-top: 20px;

  .submit {
    width: 408px;
    height: 69px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 99px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 69px;
    letter-spacing: 2px;
    margin-top: 80px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .get-code {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }

  .input2 {
    width: 228px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input>.el-input__inner {
      border: none !important;
      background: #eff0f4;
    }

    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }

  .input {
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input>.el-input__inner {
      border: none !important;
      background: #eff0f4;
    }

    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #a8acb9;
    margin: 20px 0 10px 20px;
  }
}

.login {
  .other-operation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .left30 {
      margin-left: 30px;
    }

    .el-checkbox {
      font-size: 16px;
      color: #666666;
    }

    .right {
      display: flex;

      .desc {
        font-size: 16px;
        text-align: center;
        color: #f5ad34;
        font-weight: 600;;
        letter-spacing: 2px;
        border-bottom: 1px solid #aaadba;
        cursor: pointer;
        padding-bottom: 2px;
      }
    }
  }

  .login-info {
    position: relative;

    .login {
      width: 438px;
      height: 69px;
      background: #f5ad34;
      border-radius: 99px;
      font-size: 24px;
      font-weight: 900;
      text-align: center;
      color: #ffffff;
      border: none;
      //line-height: 69px;
      cursor: pointer;
      margin-top: 50px;
    }

    .close-box {
      position: absolute;
      top: 25px;
      right: 25px;
      .back_home{
        // color: #f5ad34;
        // font-weight: 800;
        i{
          font-weight: 800;
        }
        font-size: 16px;
        background-color: #76CC56;
        color: white;
        padding: 8px 16px;
        border-radius: 30px;
        font-weight: 800;
        cursor: pointer;
      }
    }

    .login-type {
      display: flex;
      width: 90%;
      justify-content: center;
      margin-top: 50px;

      .list-default {
        cursor: pointer;
        // width: 163px;
        height: 40px;
        font-size: 24px;
        height: 30px;
        font-weight: 900;
        text-align: center;
        color: #444;
      }

      .list-check {
        cursor: pointer;
        // width: 163px;
        font-size: 24px;
        height: 38px;
        font-weight: 900;
        color: #444444;
        text-align: center;
        border-bottom: 2px solid #f5ad34;
      }
    }

    .logo {
      width: 236px;
      height: 64px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 47px 85px 70px 85px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.62);
  }

  .top-title {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
  }
}

.background {
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  // background-color: pink;
  background: url("../../../static/images/login/bg.png") no-repeat;
  background-size: cover;
}

.language {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  color: #aaadba;
  font-size: 14px;

}

.language div {
  cursor: pointer;
}

.language .active {
  color: #f5ad34;
}

.closeBtn {
  width: 20px;
  height: 20px;
}

.register {
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 29px 40px 0px rgba(0, 0, 0, 0.62);
}

.register .close {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}

.register-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 120px 30px;

  .input-desc {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left37 {
      margin-left: 37px;
    }
  }

  .logo {
    width: 236px;
    height: 64px;
  }
}

.register-title {
  font-size: 30px;
  color: #333;
}

.register-flexs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
  box-sizing: border-box;
}

.register-flexs div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-flexs div:first-child {
  padding-right: 250px;
  box-sizing: border-box;
}

.register-flexs div img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.register-flexs div span {
  font-size: 18px;
  color: #333;
  padding: 20px 0;
  box-sizing: border-box;
}

.register-flexs div .changeBtn {
  padding: 8px 25px;
  background-color: #f5ad34;
  font-size: 16px;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
}

:deep .el-checkbox__inner{
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
}

:deep .el-checkbox__inner::after{
  left: 5px;
  top: 2px;
}

:deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #f5ad34;
  border-color: #f5ad34;
}

:deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #f5ad34;
}
</style>
