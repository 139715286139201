<template>
  <div>
    <div class="backgound">
      <div class="top">
        <div v-for="(item, index) in title"
             :key="index"
             :class="titleIndex == index ? 'title-check' : 'title-default'"
             @click="changeTitleIndex(index)"
             class="marginRight40">
          {{ item }}
        </div>
      </div>

      <div class="content">
        <div class="empty-content"
             v-if="
            titleIndex == 0
              ? myTransactionList.length == 0
              : myFavoriteList.length == 0
          ">
          <el-empty :image-size="200"></el-empty>
        </div>

        <div v-else
             v-for="(item, index) in titleIndex == 0
            ? myTransactionList
            : myFavoriteList"
             :key="index"
             class="list">
          <div class="shop-list"
               @click="goDetails(item)">
            <div class="top2">
              <div class="head">
                <img class="head-img"
                     :src="
                    item.avatar
                      ? item.avatar
                      : require('../../../static/images/home/head.png')
                  " />
                <div class="head-right">
                  <div class="flexRow flexJustifySpaceBetween">
                    <div class="name">
                      {{
                        item.userName ? item.userName : $t("common.username")
                      }}
                    </div>
                    <div v-if="item.release == 1">
                      {{ $t("secondHand.price") }}{{ item.price }}
                    </div>
                    <div class="skew"
                         v-if="item.release == 2 || item.release == 3">
                      <div class="skew-main">
                        {{
                          item.release == 2
                            ? $t("common.Rejected")
                            : $t("common.Deleted")
                        }}
                      </div>
                    </div>
                  </div>

                  <div class="flexRowCenter">
                    <div class="desc">{{ item.classificationName }}</div>
                    <div v-if="item.release == 2 || item.release == 3">
                      {{ $t("secondHand.price") }}{{ item.price }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="top-img">
              <div class=""
                   v-for="(cover, coverIndex) in item.cover"
                   :key="coverIndex">
                <img :src="cover" />
              </div>
            </div>
            <div class="title-box">
              <div class="desc-title text-overflow-two">{{ item.title }}</div>
              <div class="desc text-overflow-three">
                {{ item.content ? item.content : item.detail }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="change-page">
      <page :total="total"
            :page-size="pageSize"
            @change-page="changePage"></page>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  toRefs,
  getCurrentInstance,
  onMounted,
  watchEffect,
} from "vue";
import Page from "@/components/page";
import api from "@/comm/api";
import { useRouter } from "vue-router";

export default {
  components: { Page },
  setup () {
    const { proxy } = getCurrentInstance();
    const router = useRouter();

    // 当前卡片下标
    let titleIndex = ref(0);
    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      title: proxy.$commonData.personalCardTitle.mySecondHand,
      myTransactionList: [], // 我发布的二手
      myFavoriteList: [], // 我的收藏
      total: 0,
      pageSize: 0,
    });

    const params = reactive({
      myTransactionTotal: 0,
      myTransaction: { page: 1, pageSize: 4, search: {} },
      myFavoriteTotal: 0,
      myFavorite: { page: 1, pageSize: 4, search: {} },
    });

    // 获取我发布的二手数据
    const getMyTransaction = () => {
      api.myTransaction(params.myTransaction).then((res) => {
        if (res) {
          params.myTransactionTotal = res.total;
          res.records.forEach((item) => {
            item.cover = item.cover.split(",");
          });
          jsonData(res.records, "myTransactionList");
        }
      });
    };

    // 获取我的收藏数据
    const getMyFavorite = () => {
      api.myFavorite(params.myFavorite).then((res) => {
        if (res) {
          params.myFavoriteTotal = res.total;
          res.records.forEach((item) => {
            item.cover = item.cover.split(",");
          });
          jsonData(res.records, "myFavoriteList");
          // data.myFavoriteList = res.records;
        }
      });
    };

    // 去详情
    const goDetails = (val) => {
      if (val.release == 1 || val.release == 3) {
        // 已发布或已删除
        router.push({ path: "/secondHandDetails", query: { id: val.id } });
      } else {
        // 已驳回 去编辑
        router.push({
          path: "/release",
          query: { data: encodeURIComponent(JSON.stringify(val)) },
        });
      }
    };

    // 切换tab,获取数据
    const changeTitleIndex = (i) => {
      titleIndex.value = i;
      if (i == 0 && data.myTransactionList.length == 0) {
        getMyTransaction();
      } else if (i == 1 && data.myFavoriteList.length == 0) {
        getMyFavorite();
      }
    };

    // 改变页码
    const changePage = (e) => {
      if (titleIndex.value == 0) {
        params.myTransaction.page = e;
        getMyTransaction();
      } else {
        params.myFavorite.page = e;
        getMyFavorite();
      }
    };

    // 处理数据
    const disposeData = reactive({
      newList: [], // 新数组
      newJson: {}, // 单数据
    });

    // 处理数据
    const jsonData = (res, list) => {
      // 初始化数据
      disposeData.newList = [];
      // 判断数据是否有值
      if (res && res.length > 0) {
        // 循环便利数组
        res.forEach((item) => {
          disposeData.newJson = {};
          // 循环便利对象
          for (const [key, val] of Object.entries(item)) {
            // 如果val不为空
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;

                // 判断首尾是{}
                if (first && last) {
                  if (key == "price") {
                    disposeData.newJson[key] = JSON.parse(val).ZH;
                  } else {
                    disposeData.newJson[key] = JSON.parse(val)[data.local];
                  }
                } else {
                  disposeData.newJson[key] = val;
                }
              } else {
                // 判断val不是字符串
                disposeData.newJson[key] = val;
              }
            }
          }
          disposeData.newList.push(disposeData.newJson);
        });
      }

      // 赋值给对应的list
      data[list] = disposeData.newList;
    };

    onMounted(() => {
      getMyTransaction();
    });

    watchEffect(() => {
      if (titleIndex.value == 0) {
        data.pageSize = params.myTransaction.pageSize;
        data.total = params.myTransactionTotal;
      } else {
        data.pageSize = params.myFavorite.pageSize;
        data.total = params.myFavoriteTotal;
      }
    });

    return {
      titleIndex,
      changeTitleIndex,
      ...toRefs(data),
      ...toRefs(params),
      changePage,
      goDetails,
    };
  },
};
</script>

<style scoped lang="scss">
.change-page {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 26px;
  box-sizing: border-box;

  .empty-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list {
    width: 492px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    // padding-bottom: 20px;

    .shop-list {
      opacity: 1;
      cursor: pointer;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);

      .desc-title {
        font-size: 18px;
        font-weight: 500;
        color: #444444;
        margin: 13px 0 9px 0;
      }

      .top-img {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0 18px;

        img {
          width: 145px;
          height: 112px;
          object-fit: cover;
        }
      }

      .desc {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        //margin-top: 4px;
        margin: 13px 0 9px 0;
      }

      .top2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 0 20px;
        font-weight: 600;

        .head {
          display: flex;
          align-items: center;
          border-radius: 50%;
          flex: 1;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-right: 15px;
            object-fit: cover;
          }

          //.name {
          //  font-size: 20px;
          //  font-weight: 600;
          //  color: #101010;
          //  margin-bottom: 6px;
          //}
        }
      }
    }
  }
}

.top {
  border-bottom: 1px solid #e6e9f4;
  display: flex;
  padding: 24px 0 0 41px;

  .marginRight40 {
    margin-right: 40px;
  }

  .title-check {
    height: 34px;
    color: #444444;
    line-height: 24px;

    font-size: 18px;
    font-weight: 900;
    color: #444444;
    cursor: pointer;
    border-bottom: 4px solid #f5a623;
    position: relative;
  }

  // .title-check::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 0%;
  //   height: 100%;
  //   transition: 0.2s all linear;

  // }
  // .title-check::after {
  //   width: 100%;
  // }

  .title-default {
    height: 34px;
    color: #444444;
    line-height: 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #999999;
  }
}

.backgound {
  width: 1058px;
  min-height: 560px;
  background: #ffffff;
  margin-left: 22px;
  padding-bottom: 22px;
}
.head-right {
  flex: 1;
}
.title-box {
  margin: 0 18px;
  box-sizing: border-box;
}
.skew {
  background: rgb(242, 58, 58);
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 10px;
  text-align: center;
  font-size: 16px;
  transform: skew(-25deg);
  color: #fff;
}

.skew-main {
  transform: skew(25deg);
}
</style>
