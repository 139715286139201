<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="application">
        <div class="title">{{ $t("secondHand.newGoods") }}</div>
        <div class="btns">
          <div @click.stop="$router.push({ path: '/release' })">
            {{ $t("secondHand.secondHandgoods") }}
          </div>
        </div>

        <!-- 空数据 -->
        <!-- <el-empty v-if="recommendList.length==0" :image-size="200"></el-empty> -->

        <swiper
          v-if="recommendList.length > 0"
          @swiper="setControlledSwiper"
          class="swiper"
          :centeredSlides="true"
          :slideToClickedSlide="true"
          :slidesPerView="2"
          :initialSlide="2"
          :loop="true"
          :autoplay="{ autoplay: true }"
          @slideChangeTransitionStart="onSlideChangeTransitionStart"
          :spaceBetween="50"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="(item, index) in recommendList"
            :key="index"
            @click.stop="
              $router.push({
                path: '/secondHandDetails',
                query: { id: item.id },
              })
            "
          >
            <div class="shop-list">
              <div class="top">
                <div class="head">
                  <img
                    class="head-img"
                    :src="
                      item.avatar
                        ? item.avatar
                        : require('../../../static/images/home/head.png')
                    "
                  />
                  <div class="head-right">
                    <div class="flexRow flexJustifySpaceBetween">
                      <div class="name">
                        {{
                          item.userName ? item.userName : $t("common.username")
                        }}
                      </div>
                      <div class="collect">
                        <div>
                          {{ $t("secondHand.price") }}：{{ item.price }}
                        </div>
                        <FavoriteCard
                          @click.stop="collect(item, index)"
                          :favorite="item.collection"
                        />
                      </div>
                    </div>
                    <div class="desc">{{ item.classificationName }}</div>
                  </div>
                </div>
              </div>
              <div class="top-img">
                <div
                  class=""
                  v-for="(cover, coverIndex) in item.cover"
                  :key="coverIndex"
                >
                  <img :src="cover" />
                </div>
              </div>
              <div class="desc-title text-overflow-two">{{ item.title }}</div>
              <div class="desc text-overflow-three">{{ item.content }}</div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 翻页 -->
        <div class="pagesClick" v-if="recommendList.length > 0">
          <img
            @click="preBtn"
            src="../../../static/images/food/left.png"
            alt=""
          />
          <div>
            <span>{{ clickIndex }} / {{ recommendList.length }}</span>
          </div>
          <img
            @click="nextBtn"
            src="../../../static/images/food/right1.png"
            alt=""
          />
        </div>

        <img src="../../../static/images/home/delete5.png" class="center-img" />
      </div>
      <div class="bottom" style="background: #fff">
        <div class="bottom-class">
          <div class="left-img">
            <img src="../../../static/images/home/delete6.png" alt="" />
          </div>
          <div class="right-class">
            <div
              v-for="(item, index) in classifyList"
              :key="index"
              class="list2"
              @click="
                $router.push({
                  path: '/secondClass',
                  query: {
                    classification: item.id,
                    name: JSON.parse(item.title)[$i18n.locale],
                  },
                })
              "
            >
              <img :src="item.image" alt="" />
              <div class="title2">
                <div>{{ JSON.parse(item.title)[$i18n.locale] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us-two background="#fff"></contact-us-two>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsTwo from "../home/contactUsOne.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import Carousel from "@/components/Carousel/Carousel";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, onMounted, watchEffect } from "vue";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default {
  components: {
    Carousel,
    topSearchThree,
    contactUsTwo,
    Swiper,
    SwiperSlide,
    FavoriteCard,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      token: localStorage.getItem("token"),
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      className: "",
      advertises: [],
      bannerList: [], //banner 数组
      classifyList: [], // 分类
      recommendList: [], // 推荐列表
      clickIndex: 0, //swiper 下标
      mySwiper: {},
    });
    const controlledSwiper = ref(null);
    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "SECONDHAND" });
    };

    //swiper
    const onSlideChange = (swiper) => {
      // data.clickIndex=swiper.activeIndex==7?2:swiper.activeIndex
    };

    const onSlideChangeTransitionStart = (swiper) => {
      data.clickIndex =
        swiper.activeIndex == data.recommendList.length + 2
          ? 1
          : swiper.activeIndex - 1;
    };

    const setControlledSwiper = (swiper) => {
      data.mySwiper = swiper;
    };

    const preBtn = () => {
      if (data.clickIndex == 1) {
        return;
      }
      data.clickIndex--;
      data.mySwiper.slidePrev();
    };

    const nextBtn = () => {
      if (data.clickIndex == 5) {
        return;
      }
      data.clickIndex++;
      data.mySwiper.slideNext();
    };

    // 二手交易列表-分类
    const getClassifyData = async () => {
      data.classifyList = await api.transactionClassification();
    };

    // 二手交易列表-最新推荐
    const getRecommendData = () => {
      const info = { page: 1, pageSize: 5 };
      api.recommendTransaction(info).then((res) => {
        if (res) {
          res.records.forEach((item) => {
            // 后台 1 PC 0
            item.cover = item.cover.split(",");
            // item.image = item.type == 1?item.image.split(','):item.image;
          });
          jsonData(res.records, "recommendList");
          // data.recommendList = res.records;
        }
      });
    };

    // 收藏
    const collect = (val, i) => {
      let collection = val.collection ? false : true;
      api
        .favoriteTransaction({
          transactionId: val.id,
          collection,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: collection
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            data.recommendList[i].collection = collection;
            // data.hotFoodList[i].favorite = favorite;
          }
        });
    };

    // 处理数据
    const disposeData = reactive({
      newList: [], // 新数组
      newJson: {}, // 单数据
    });

    // 处理数据
    const jsonData = (res, list) => {
      // 初始化数据
      disposeData.newList = [];
      // 判断数据是否有值
      if (res && res.length > 0) {
        // 循环便利数组
        res.forEach((item) => {
          disposeData.newJson = {};
          // 循环便利对象
          for (const [key, val] of Object.entries(item)) {
            // 如果val不为空
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;

                // 判断首尾是{}
                if (first && last) {
                  if (key != "price") {
                    disposeData.newJson[key] = JSON.parse(val)[data.local];
                  } else {
                    disposeData.newJson[key] = JSON.parse(val).ZH;
                  }
                } else {
                  disposeData.newJson[key] = val;
                }
              } else {
                // 判断val不是字符串
                disposeData.newJson[key] = val;
              }
            }
          }
          disposeData.newList.push(disposeData.newJson);
        });
      }

      // 赋值给对应的list
      data[list] = disposeData.newList;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 15); // 获取广告位 图
      getBanners(); //获取banner 图
      getClassifyData();
      getRecommendData();
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      controlledSwiper,
      ...toRefs(data),
      getBanners, //获取banner图
      onSlideChange,
      onSlideChangeTransitionStart,
      setControlledSwiper,
      preBtn,
      nextBtn,
      collect,
    };
  },
};
</script>

<style scoped lang="scss">
.swiper {
  width: 100%;
  margin-top: 50px;
}

.desc-title {
  height: 60px;
  font-size: 24px;
  font-weight: 500;
  color: #444444;
  margin: 13px 0 9px 0;
}

.desc {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bottom-class {
    margin-top: 150px;

    .left-img {
      height: 100%;
      width: 663px;
      img {
        width: 663px;
        height: 100%;
        border-radius: 20px 0 0 20px;
      }
    }

    .right-class {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-left: 90px;
      justify-content: space-between;
      .list2 {
        width: 176px;
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 39px;
        cursor: pointer;

        .title2 {
          flex: 1;
          div {
            width: 100%;
            font-size: 24px;
            font-weight: 900;
            text-align: center;
            color: #444444;
            margin-top: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
          }
        }

        img {
          width: 176px;
          height: 136px;
          object-fit: cover;
        }
      }
    }

    width: 1646px;
    height: 554px;
    opacity: 1;
    background: #f5f5f5;
    border-radius: 20px;
    display: flex;
  }

  .center-classification {
    width: 1646px;
    display: flex;
    justify-content: space-between;

    .list {
      margin-top: 75px;
      width: 390px;
      height: 244px;
      opacity: 1;
      background: #ffffff;
      box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;

      img {
        width: 140px;
        height: 140px;
        border-radius: 10px;
        margin-left: 80px;
        object-fit: cover;
      }

      .class-title {
        font-size: 26px;
        font-weight: 900;
        color: #444444;
        margin-bottom: 16px;
      }

      .class-list {
        font-size: 20px;
        font-weight: 900;
        color: #444444;
        margin-bottom: 10px;
      }
    }
  }
}

.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;

  .center-img {
    width: 100%;
    height: 600px;
    margin-top: 60px;
  }

  .shop-list {
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);
    padding: 25px 36px 45px 36px;

    .top-img {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      object-fit: cover;

      img {
        width: 236px;
        height: 187px;
        object-fit: cover;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .head {
        display: flex;
        align-items: center;
        width: 100% !important;

        .head-img {
          width: 117px;
          height: 117px;
          border-radius: 50%;
          margin-right: 15px;
          object-fit: cover;
        }

        .head-right {
          flex: 1;
          // width: 100% !important;

          .name {
            font-size: 20px;
            font-weight: 600;
            color: #101010;
            margin-bottom: 6px;
          }

          .collect {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 18px;

            .collect-img {
              margin-left: 21px;
              width: 36px !important;
              height: auto !important;
              cursor: pointer;
            }
          }

          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }

  .el-carousel {
    width: 100%;
    margin-top: 50px;

    .el-carousel-item {
      width: 600px;
    }
  }

  .title {
    font-size: 44px;
    font-weight: 500;
    color: #222a41;
    padding: 0 20px 0 20px;
    text-align: center;
    margin-top: 100px;
  }
}
.btns {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  div {
    height: 54px;
    background: #f5ad34;
    border-radius: 8px;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
  }
}
.btns > div:hover {
  background: #ffc972;
  border-color: #ffc972;
}
.pagesClick {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 00px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  div {
    margin: 0 20px;
  }
}
.collect-warp {
  position: relative;
  top: 0;
  right: 0;
  margin-left: 21px;
}
</style>
