<template>
  <div class="form-inline-input">
    <div class="code-box" id="code-box">
      <input type="text" name="code" class="code-input" />
      <p></p>
      <div class="sliding-text">{{ $t("contact.slidingValidation") }}</div>
      <span><i class="el-icon-d-arrow-right"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlidingValidation",
  props: {
    verification: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    var _this = this;
    // window.addEventListener('load',function(){
    //code是后台传入的验证字符串
    var code = "jsaidaisd656",
      codeFn = new _this.moveCode(code, _this);
  },
  methods: {
    //获取元素距离页面边缘的距离
    getOffset(box, direction) {
      var setDirection = direction == "top" ? "offsetTop" : "offsetLeft";
      var offset = box[setDirection];
      var parentBox = box.offsetParent;
      while (parentBox) {
        offset += parentBox[setDirection];
        parentBox = parentBox.offsetParent;
      }
      parentBox = null;
      return parseInt(offset);
    },
    moveCode(code, _this) {
      var fn = { codeVluae: code };
      var box = document.querySelector("#code-box"),
        progress = box.querySelector("p"),
        codeInput = box.querySelector(".code-input"),
        evenBox = box.querySelector("span");
      //默认事件
      var boxEven = ["mousedown", "mousemove", "mouseup"];
      //改变手机端与pc事件类型
      if (typeof document.ontouchstart == "object") {
        boxEven = ["touchstart", "touchmove", "touchend"];
      }
      var goX, offsetLeft, deviation, evenWidth, endX;

      function moveFn(e) {
        e.preventDefault();
        e = boxEven["0"] == "touchstart" ? e.touches[0] : e || window.event;
        endX = e.clientX - goX;
        endX = endX > 0 ? (endX > evenWidth ? evenWidth : endX) : 0;
        var local = localStorage.getItem("local");
        if (endX > evenWidth * 0.7) {
          progress.innerText = '';
          // console.log('this.$i18n',this.$i18n);
          progress.style.fontSize = "22px";
          progress.style.backgroundColor = "#F1D7B5";
        } else {
          progress.innerText = "";
          progress.style.backgroundColor = "#F1D7B5";
          progress.style.zIndex = "1000";
        }
        progress.style.width = endX + deviation + "px";
        evenBox.style.left = endX + "px";
      }

      function removeFn() {
        document.removeEventListener(boxEven["2"], removeFn, false);
        document.removeEventListener(boxEven["1"], moveFn, false);
        var local = localStorage.getItem("local");
        if (endX > evenWidth * 0.7) {
          progress.innerText = 'success'
          progress.style.width = evenWidth + deviation + "px";
          progress.style.zIndex = "1000";
          progress.style.fontSize = "22px";
          progress.style.color = "green";
          evenBox.style.left = evenWidth + "px";
          codeInput.value = fn.codeVluae;
          evenBox.onmousedown = null;
          // _this.ruleForm.verification = true;
          _this.$emit("update:verification", true);
        } else {
          progress.style.width = "0px";
          evenBox.style.left = "0px";
        }
      }

      function getOffset(box, direction) {
        var setDirection = direction == "top" ? "offsetTop" : "offsetLeft";
        var offset = box[setDirection];
        var parentBox = box.offsetParent;
        while (parentBox) {
          offset += parentBox[setDirection];
          parentBox = parentBox.offsetParent;
        }
        parentBox = null;
        return parseInt(offset);
      }
      evenBox.addEventListener(
        boxEven["0"],
        function (e) {
          e = boxEven["0"] == "touchstart" ? e.touches[0] : e || window.event;
          (goX = e.clientX),
            (offsetLeft = getOffset(box, "left")),
            (deviation = this.clientWidth),
            (evenWidth = box.clientWidth - deviation),
            endX;
          document.addEventListener(boxEven["1"], moveFn, false);
          document.addEventListener(boxEven["2"], removeFn, false);
        },
        false
      );
      fn.setCode = function (code) {
        if (code) fn.codeVluae = code;
      };
      fn.getCode = function () {
        return fn.codeVluae;
      };
      fn.resetCode = function () {
        evenBox.removeAttribute("style");
        progress.removeAttribute("style");
        codeInput.value = "";
      };
      return fn;
    },
  },
};
</script>

<style scoped>
.form-inline-input input,
.code-box {
  padding: 0 3px;
  width: 390px;
  height: 40px;
  color: #fff;
  background: #f7f4ef;
  border: 1px solid #999999;
  /*text-shadow: 1px 1px 1px black;*/
  outline: none;
}

.code-box {
  position: relative;
}
.sliding-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #999999;
}

.code-box p,
.code-box span {
  display: block;
  position: absolute;
  left: 0;
  height: 40px;
  text-align: center;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: #999999;
  z-index: 1000;
}

.code-box span {
  width: 40px;
  /*background-color: #fff;*/
  font-size: 16px;
  cursor: pointer;
  margin-right: 1px;
  border-right: 1px solid #999999;
  /*border-left:  1px solid #979797;*/
}

.code-box p {
  width: 0;
  background-color: #f5ad34;
  overflow: hidden;
  text-indent: -20px;
  transition: background 1s ease-in;
}

.code-box {
  position: relative;
}

.code-box .code-input {
  display: none;
}
.el-icon-d-arrow-right {
  color: #333;
  font-size: 24px;
  display: inline-block;
  margin-top: 8px;
}
</style>
