<template>
  <div class="">
    <img src="../../../static/images/top.png" class="top-image" />
    <div>
      <el-carousel :interval="5000" arrow="always" height="600px">
        <top-search-three class="top-search"></top-search-three>
        <el-carousel-item v-for="item in 4" :key="item">
          <img
            src="../../../static/images/home/topBanner.png"
            class="top-img"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="group-signature-style">
      <!-- 注意 -->
      <div class="be-careful" id="scrollAddress">
        <img src="../../../static/images/groupSignature/beCareful.png" />
        <div>
          <div class="title">{{ $t("groupSignature.beCareFul.title") }}</div>
          <div class="info-title">
            {{ $t("groupSignature.beCareFul.infoTitle") }}
          </div>
          <div class="info-desc">
            {{ $t("groupSignature.beCareFul.infoDesc") }}
          </div>
        </div>
      </div>

      <!-- 节点 -->
      <div class="list">
        <div class="xian"></div>
        <div class="node">
          <div v-for="(item, index) in list" :key="index" class="node-info">
            <img :src="item.icon" />
            <div
              :class="
                item.index == nowNodeIndex ? 'check-name' : 'default-name'
              "
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- 基本信息 -->
      <essential-information
        v-if="nowNodeIndex == 0"
        @changeInformation="changeInformation"
        @changeInformationScroll="changeInformationScroll"
      ></essential-information>

      <!-- 入境信息 -->
      <entry-information
        v-if="nowNodeIndex == 1"
        @entryInformationChange="entryInformationChange"
      ></entry-information>

      <!-- 上传信息 -->
      <upload-information
        v-if="nowNodeIndex == 2"
        @returnUploadInformation="returnUploadInformation"
      ></upload-information>

      <!-- 确认信息 -->
      <confirm-information
        v-if="nowNodeIndex == 3"
        @showInformationDetail="showInformationDetail"
        @confimInformation="confimInformation"
        @addNewMenber="addNewMenber"
      ></confirm-information>

      <!-- 服务方式 -->
      <service-type
        v-if="nowNodeIndex == 4"
        @servieType="servieType"
      ></service-type>

      <!-- 付款 -->
      <pay v-if="nowNodeIndex == 5"></pay>

      <!-- 确认信息详情页面 -->
      <information-detail
        v-if="nowNodeIndex == 7"
        @returnInformationDeatil="returnInformationDeatil"
      ></information-detail>
    </div>
    <group-signature-bottom></group-signature-bottom>
    <contact-us-two></contact-us-two>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsTwo from "../home/contactUsTwo.vue";
import EssentialInformation from "./essentialInformation.vue";
import EntryInformation from "./entryInformation.vue";
import UploadInformation from "./uploadInformation.vue";
import ConfirmInformation from "./confirmInformation.vue";
import serviceType from "./serviceType.vue";
import pay from "./pay.vue";
import InformationDetail from "./informationDetail.vue";
import groupSignatureBottom from "./groupSignatureBottom.vue";
export default {
  components: {
    topSearchThree,
    contactUsTwo,
    EssentialInformation,
    EntryInformation,
    UploadInformation,
    ConfirmInformation,
    serviceType,
    pay,
    InformationDetail,
    groupSignatureBottom,
  },
  data() {
    return {
      userList: [],
      nowNodeIndex: 0,
      list: [
        {
          title: this.$t("groupSignature.essentialInformation"),
          icon: require("../../../static/images/groupSignature/jibenxinxi.png"),
          index: 0,
        },
        {
          title: this.$t("groupSignature.entryInformation"),
          icon: require("../../../static/images/groupSignature/rujing.png"),
          index: 1,
        },
        {
          title: this.$t("groupSignature.uploadInformation"),
          icon: require("../../../static/images/groupSignature/shangchuan.png"),
          index: 2,
        },
        {
          title: this.$t("groupSignature.confirmationInformation"),
          icon: require("../../../static/images/groupSignature/queren.png"),
          index: 3,
        },
        {
          title: this.$t("groupSignature.serviceMode"),
          icon: require("../../../static/images/groupSignature/fuwu.png"),
          index: 4,
        },
        {
          title: this.$t("groupSignature.payment"),
          icon: require("../../../static/images/groupSignature/fukuan.png"),
          index: 5,
        },
        {
          title: this.$t("groupSignature.applicationStatus"),
          icon: require("../../../static/images/groupSignature/shenqing.png"),
          index: 6,
        },
      ],
    };
  },
  watch: {
    nowNodeIndex(val, oldVal) {
      //监听节点变化 滚动到某个位置
      document.getElementById("scrollAddress").scrollIntoView();
    },
  },
  methods: {
    addNewMenber() {
      this.nowNodeIndex = 0;
    },
    changeInformationScroll() {
      document.getElementById("scrollAddress").scrollIntoView();
    },
    returnUploadInformation() {
      this.nowNodeIndex = 3;
    },
    servieType() {
      this.nowNodeIndex = 5;
    },
    confimInformation() {
      this.nowNodeIndex = 4;
    },
    showInformationDetail() {
      this.nowNodeIndex = 7;
    },
    returnInformationDeatil() {
      this.nowNodeIndex = 3;
    },
    changeInformation() {
      this.nowNodeIndex = 1;
    },
    entryInformationChange() {
      this.nowNodeIndex = 2;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep() .bottom-bottom-title {
  font-size: 36px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 40px;
  cursor: pointer;
}
.group-signature-style {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  .be-careful {
    width: 1318px;
    background: #fdeed4;
    border-radius: 16px;
    margin-top: 36px;
    display: flex;
    padding: 30px;
    .title {
      font-size: 22px;
      font-weight: 900;
      color: #444444;
    }
    .info-desc {
      font-size: 16px;
      font-weight: 400;
      color: #666660;
      line-height: 32px;
    }
    .info-title {
      font-size: 22px;
      font-weight: 900;
      color: #444444;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    img {
      width: 36px;
      height: 36px;
      margin-right: 15px;
    }
  }
  .list {
    margin-bottom: 70px;
    .xian {
      width: 1300px;
      height: 1px;
      border-bottom: 5px dashed #979797;
      z-index: 98;
    }
    .node {
      display: flex;
      position: absolute;
      z-index: 99;
      top: -21px;
      margin-left: -30px;
      width: 1360px;
      justify-content: space-between;
      .node-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
          margin-bottom: 9px;
        }
        .default-name {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: #888888;
          line-height: 25px;
        }
        .check-name {
          font-size: 20px;
          font-weight: 900;
          text-align: center;
          color: #444444;
        }
      }
    }
    margin-top: 69px;
    position: relative;
  }
}
.top-search {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99999;
}
</style>
