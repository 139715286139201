<template>
  <div class="backgound">
    <div class="top">
      <div
        v-for="(item, index) in title"
        :key="index"
        :class="titleIndex == index + 1 ? 'title-check' : 'title-default'"
        @click="changeTitleIndex(index + 1)"
        class="marginRight40"
      >
        {{ item[$i18n.locale] }}
      </div>
    </div>

    <Company v-if="identity == 1" ref="RefOne" />
    <Elite v-if="identity == 2" ref="RefTwo" />
  </div>
</template>

<script>
import {
  reactive,
  nextTick,
  ref,
  toRefs,
  getCurrentInstance,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Company from "./JobChildCom/Company.vue";
import Elite from "./JobChildCom/Elite.vue";
import { locale } from 'element-plus';

export default {
  components: { Company, Elite },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();

    // 当前卡片下标
    let titleIndex = ref(1);
    const data = reactive({
      identity: JSON.parse(localStorage.getItem('userInfo')).identity,
      title1: {ZH: "已收藏岗位", US: "Favorites"},
      title2: {ZH: "已收藏精英", US: "Favorites"},
      title: [],
    });
    

    // 切换tab,获取数据
    const changeTitleIndex = (i) => {
      titleIndex.value = i;
    };

    const RefOne = ref(); // 旅游攻略
    const RefTwo = ref(); // 美食
    const RefThree = ref(); // 休闲/玩乐
    const RefFour = ref(); // 外国人服务站
    const RefFive = ref(); // 学习/培训
    const RefSix = ref(); // 景点门票

    watchEffect(() => {
      // 登录者身份
      if(data.identity == 1) {
        data.title = [];
        data.title.push(data.title1);
        nextTick(() => {
          RefOne.value.getData();
        });
      }else if(data.identity == 2) {
        data.title = [];
        data.title.push(data.title2);
        // DOM更新后调用
        nextTick(() => {
          RefTwo.value.getData();
        });
      }
    });

    return {
      ...toRefs(data),
      titleIndex,
      RefOne,
      RefTwo,
      RefThree,
      RefFour,
      RefFive,
      RefSix,
      changeTitleIndex,
    };
  },
};
</script>

<style scoped lang="scss">
.top {
  border-bottom: 1px solid #e6e9f4;
  display: flex;
  padding: 24px 0 0 41px;

  background-color: #fff;
  .marginRight40 {
    margin-right: 40px;
  }
  .title-check {
    height: 34px;
    color: #444444;
    line-height: 24px;
    border-bottom: 4px solid #f5a623;
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    cursor: pointer;
  }
  .title-default {
    height: 34px;
    color: #444444;
    line-height: 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #999999;
  }
}
.backgound {
  width: 1058px;
  margin-left: 22px;
}
.content {
  background-color: none;
}
</style>
