<template>
  <div class="page-wrap">
    <top-search-three :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''" :advertises="advertises" />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="faq-title" :class="[bannerList.length==0?'nav-top-mt':'']">
        Frequently Asked Questions /
        <text style="color: #f5a623">{{ leftTabbar[LeftIndex].value }}</text>
      </div>
      <div class="flex">
        <div class="left">
          <div class="left-top">CATEGORIES</div>
          <div
            v-for="(item, index) in leftTabbar"
            :key="index"
            :class="
                index == LeftIndex ? 'left-title-check' : 'left-title-default'
              "
            @click="getInfos(index,1)"
          >
            {{ item.lable }}
          </div>
        </div>
        <div class="right" >
          <template v-if="content.length!=0">
            <div v-for="(item, index) in content" :key="index" >
              <div class="right-title">{{ JSON.parse(item.title)[$i18n.locale] }}</div>
              <div class="right-desc">{{ JSON.parse(item.content)[$i18n.locale] }}</div>
            </div>
          </template>
        </div>
      </div>
        
          
      <div class="more">
        <page :total="total" :page-size="table.pageSize" @change-page="changePage"></page>
      </div>
    </div>

    <contact-us-two></contact-us-two>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsTwo from "../home/contactUsOne.vue";
import "swiper/swiper-bundle.css";

import Carousel from "../../components/Carousel/Carousel";
import { onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import api from "../../comm/api";
import { useStore } from "vuex";
import util from "@/comm/util";
import Page from "@/components/page";
export default {
  components: {
    Page,
    Carousel,
    topSearchThree,
    contactUsTwo,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      bannerList: [],
      advertises: [],
      leftTabbar: [
        {value:'ALL',lable:'ALL'},
        {value:'Buy and Sell',lable:'Buy and Sell'},
        {value:'Direntory',lable:'Direntory'},
        {value:'Events',lable:'Events'},
        {value:'Housing',lable:'Housing'},
        {value:'Jobs',lable:'Jobs'},
        {value:'Sales &Biz Dew',lable:'Sales &Biz Dew'},
        {value:'Accounts',lable:'Accounts'}
      ],
      LeftIndex: 0,
      content: [],
    });
    const params = reactive({
      total: 0,
      table: { page: 1, pageSize: 4, search: {classification:''} },
    });

    // 获取轮播列表
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "FAQ" });
    };

    //获取faq
    const getInfos = async (index,numbers)=> {
      if(numbers){
        params.table.page=1
      }
      data.LeftIndex=index
      let config=data.leftTabbar[data.LeftIndex].value=='ALL'?'':data.leftTabbar[data.LeftIndex].value
      params.table.search.classification=config
      await api.faqList(params.table).then((res)=>{
        params.total = res.total;
        data.content = res.records;
      })
    }

    // 翻页
    const changePage = ((e) => {
      params.table.page = e;
      getInfos(data.LeftIndex);
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 21);// 获取广告位 图
      getBanners(); // 轮播
      getInfos(0);//获取faq
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(data.advertises.length, data.bannerList.length);
    });

    return {
      ...toRefs(data),
      ...toRefs(params),
      getInfos,
      changePage,//翻页
    };
  }
};
</script>
<style scoped lang="scss">
.faq-title {
  font-size: 40px;
  color: #333333;
  margin: 40px 0 58px 300px;
}
.flex {
  display: flex;
  justify-content: center;
}
.left {
  width: 322px;
  height: 800px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 38px 0px #c0bebe;
  .left-title-check {
    width: 180px;
    background: #f5a623;
    border-radius: 16px;
    padding: 18px 0 18px 22px;
    font-size: 22px;
    color: #ffffff;
    margin-left: 32px;
    margin-top: 50px;
  }
  .left-title-default {
    font-size: 22px;
    color: #444444;
    margin-left: 52px;
    margin-top: 50px;
    cursor: pointer;
  }
  .left-top {
    height: 74px;
    background: #eaeaea;
    padding-left: 52px;
    font-size: 26px;
    text-align: left;
    color: #444444;
    line-height: 74px;
  }
}
.more{
  width:70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin:30px auto 0;
}
.right {
  width: 910px;
  background: #ffffff;
  border-radius: 12px;
  margin-left: 25px;
  padding: 30px;
  .right-title {
    background: #f1eae3;
    padding: 12px 26px 12px 26px;
    font-size: 26px;
    color: #444444;
    margin-bottom: 30px;
    text-align: justify;
  }
  .right-desc {
    font-size: 20px;
    font-weight: 400;
    color: #444444;
    line-height: 45px;
    margin-bottom: 40px;
    padding: 0 26px;
    text-align: justify;
  }
}
.top-search {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99999;
}
</style>
