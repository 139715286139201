const state = {
  user: {
    local: localStorage.getItem('local') == 'ZH' ? 'ZH' : 'US',
    isLogin: localStorage.getItem('token') ? true : false,
    isLoginRSA: localStorage.getItem('isLoginRSA') ? true : false,
    userInfo: localStorage.getItem('userInfo') != 'undefined' ? JSON.parse(localStorage.getItem('userInfo')) : {},
  },
  navBgColor: 'rgba(211, 192, 179, 1)', // 导航背景色
  areaCodeList: [], // 国家-区号
  allVisaInfoList: [], // 所有签证信息列表
  // bannerList: JSON.parse(localStorage.getItem("bannerList"))?JSON.parse(localStorage.getItem("bannerList")):[]
  // 广告位
  location: '',
  advertisesList: [],
  visaFormLabel: '350px',
  status: [
    { label: '是', value: '1' },
    { label: '否', value: '2' },
  ],
  gender: [
    { label: '男', value: '1' },
    { label: '女', value: '0' },
  ],
  maritalStatus: [
    { label: '已婚', value: '1' },
    { label: '未婚', value: '2' },
  ],
  education: [
    { label: '博士后', value: '博士后' },
    { label: '博士', value: '博士' },
    { label: '硕士', value: '硕士' },
    { label: '研究生', value: '研究生' },
    { label: '本科', value: '本科' },
    { label: '专科', value: '专科' },
    { label: '研究生', value: '研究生' },
    { label: '高中/职中/中专', value: '高中/职中/中专' },
    { label: '初中', value: '初中' },
    { label: '小学', value: '小学' },
  ],
  punishList: [
    // 曾在中国因违法被处罚的情况
    { label: '无', value: '0' },
  ],
  emergencyContactRelationshipList: [
    // 与紧急联系人关系
    { label: '父亲', value: '1' },
    { label: '母亲', value: '2' },
  ],
  occupationList: [
    // 职业
    { label: '工程师', value: '1' },
    { label: '程序员', value: '2' },
  ],
}
export default state
