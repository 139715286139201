<template>
  <div class="">
    <div class="top">
      <div class="top-select">
        <div class="title">
          <div @click="selectIndex == 1 ? (selectIndex = -1) : (selectIndex = 1)" style="cursor: pointer">
            International Students Platform
          </div>
          <div class="select-check1" v-if="selectIndex == 1">
            <div class="select-title">International Students Platform</div>
            <div class="platform-list">
              <div v-for="(item, index) in platformList" :key="index" class="list1" @click="goInfo(item)">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="shuxian"></div>
        <div class="title">
          <div @click="selectIndex == 2 ? (selectIndex = -1) : (selectIndex = 2)" style="cursor: pointer">
            School Lifet
          </div>
          <div class="select-check1" v-if="selectIndex == 2">
            <div class="select-title">School Lifet</div>
            <div class="platform-list">
              <div v-for="(item, index) in schoolLifet" :key="index" class="list2">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="shuxian"></div>
        <div class="title">
          <div @click="selectIndex == 3 ? (selectIndex = -1) : (selectIndex = 3)" style="cursor: pointer">
            WI-FI CARD
          </div>
          <div class="select-check1 right" v-if="selectIndex == 3">
            <div class="select-title">WI-FI CARD</div>
            <div class="platform-list">
              <div v-for="(item, index) in livingInShanghai" :key="index" class="list2">
                {{ JSON.parse(item.title)[$i18n.locale] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectIndex: -1,
      platformList: [
        { title: "高校推荐" },
        { title: "在校报名" },
        { title: "住宿登记" },
        { title: "留学生在校系统" },
        { title: "留学生新闻" },
        { title: "留学生须知" },
      ],
      schoolLifet: [
        { title: "住宿" },
        { title: "银行卡" },
        { title: "保险" },
        { title: "学习签证" },
        { title: "书籍推荐" },
        { title: "留学论坛" },
        { title: "留学生协会" },
        { title: "社团活动" },
        { title: "培训机构" },
      ],
      livingInShanghai: [
        { title: "住宿登记" },
        { title: "中国美食" },
        { title: "旅游攻略" },
        { title: "机票酒店" },
        { title: "照片" },
        { title: "二手买卖" },
        { title: "医疗" },
        { title: "景点门票" },
      ],
    };
  },
  methods: {
    goInfo() {
      this.$router.replace("/registerSchool");
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  width: 100%;
  height: 800px;
  background-image: url("../../../static/images/home/topBanner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;

  .top-select {
    white-space: nowrap;
    padding: 18px 73px;
    width: 1174px;
    height: 80px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 24px 48px 0px rgba(0, 13, 75, 0.24);
    margin-top: 186px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: 900;
      color: #222a41;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .shuxian {
      width: 1px;
      height: 80px;
      background: #e6ecf4;
    }

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }

    .right {
      right: -70px;
    }

    .select-check1 {
      visibility: visible;
      animation: fadeInUp 1.5s;
      -webkit-animation: fadeInUp 1.5s;
      animation-fill-mode: both;
      animation-name: fadeInUp;

      background: #ffffff;
      width: 453px;
      border-radius: 36px;
      top: 90px;
      position: absolute;
      padding: 30px;

      .select-title {
        font-size: 30px;
        font-weight: 900;
        color: #444444;
      }

      .platform-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .list1 {
          width: 40%;
          font-size: 22px;
          font-weight: 900;
          color: #444444;
          margin-top: 30px;
          cursor: pointer;
        }

        .list2 {
          width: 30%;
          font-size: 22px;
          font-weight: 900;
          color: #444444;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
