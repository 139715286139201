<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className" class="content">
      <Carousel :list="bannerList" />
      <div class="cardModelWrap">
        <!-- 热门场所美食 -->
        <hot-food
          ref="child"
          @change-data="changeData"
          @go-details="goDetails"
        />

        <!-- 美食种类分类 -->
        <div
          class="planeFood"
          v-if="classifyFoodList && classifyFoodList.length > 0"
        >
          <div class="planeTitle">{{ $t("food.RestaurantList") }}</div>
          <div class="planeFoodClass">
            <div
              @click="changeClassify(item.id, index)"
              class="planeFoodClassListWrap"
              v-for="(item, index) in classifyFoodList"
              :key="index"
            >
              <div class="planeFoodClassListWrap_lists">
                <img :src="item.image" alt="" />
                <div :class="currentIndex == index ? 'active-classify' : ''">
                  {{ JSON.parse(item.title)[$i18n.locale] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 分类列表 -->
        <div class="foodListWrap" v-if="foodList && foodList.length > 0">
          <!-- 效果一 -->
          <div
            class="foodListOne"
            v-if="foodList && foodList[0]"
            @click="goDetails(foodList[0].id)"
          >
            <FavoriteCard
              style="z-index: 8"
              @click.stop="collect(foodList[0], 0)"
              :favorite="foodList[0].favorite"
            />
            <img :src="foodList[0].image" alt="" />
            <div class="foodInfos">
              <div class="title">
                {{ JSON.parse(foodList[0].title)[$i18n.locale] }}
              </div>
              <div
                class="desc text-overflow-five"
                v-html="JSON.parse(foodList[0].detail)[$i18n.locale]"
              ></div>
            </div>
          </div>
          <!-- 效果二 -->
          <div class="planeFoodList" v-if="foodList && foodList[1]">
            <div
              v-if="foodList && foodList[1]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[1].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[1], 1)"
                    :favorite="foodList[1].favorite"
                  />
                  <img :src="foodList[1].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[1].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="foodList && foodList[2]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[2].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[2], 2)"
                    :favorite="foodList[2].favorite"
                  />
                  <img :src="foodList[2].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[2].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="foodList && foodList[3]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[3].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[3], 3)"
                    :favorite="foodList[3].favorite"
                  />
                  <img :src="foodList[3].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[3].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 效果三 -->
          <div
            class="foodListOne marginTops"
            v-if="foodList && foodList[4]"
            @click="goDetails(foodList[4].id)"
          >
            <FavoriteCard
              @click.stop="collect(foodList[4], 4)"
              :favorite="foodList[4].favorite"
            />
            <div class="foodInfos">
              <div class="title">
                {{ JSON.parse(foodList[4].title)[$i18n.locale] }}
              </div>
              <div class="desc text-overflow-five">
                {{ JSON.parse(foodList[4].detail)[$i18n.locale] }}
              </div>
            </div>
            <img :src="foodList[4].image" alt="" />
          </div>
          <!-- 效果二 -->
          <div class="planeFoodList" v-if="foodList && foodList[5]">
            <div
              v-if="foodList && foodList[5]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[5].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[5], 5)"
                    :favorite="foodList[5].favorite"
                  />
                  <img :src="foodList[5].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[5].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="foodList && foodList[6]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[6].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[6], 6)"
                    :favorite="foodList[6].favorite"
                  />
                  <img :src="foodList[6].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[6].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
            <div
              v-if="foodList && foodList[7]"
              class="planeFoodList_listsWrap"
              @click="goDetails(foodList[7].id)"
            >
              <div class="lists">
                <div class="images">
                  <FavoriteCard
                    @click.stop="collect(foodList[7], 7)"
                    :favorite="foodList[7].favorite"
                  />
                  <img :src="foodList[7].image" alt="" />
                </div>
                <div class="foodInfos">
                  <div>{{ JSON.parse(foodList[7].detail)[$i18n.locale] }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 翻页 -->
          <div class="pages">
            <page
              :total="foodTotal"
              :page-size="food.pageSize"
              @change-page="changePage"
            ></page>
          </div>
        </div>
      </div>
    </div>

    <!--联系我们-->
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import contactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import page from "@/components/page";
import api from "@/comm/api";
import util from "@/comm/util";
import { reactive, toRefs, ref, onMounted, watchEffect } from "vue";
import Carousel from "@/components/Carousel/Carousel";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import { getCurrentInstance } from "vue";

import { useI18n } from "vue-i18n";
import HotFood from "./ChildCom/HotFood";
import { ElMessage } from "element-plus";
//要在js中使用国际化
// import { getCurrentInstance } from 'vue';

export default {
  components: {
    HotFood,
    Carousel,
    contactUsOne,
    topSearchThree,
    page,
    FavoriteCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    //通过getCurrentInstance来获取全局方法，这个只能在setup里使用
    //获取banner图
    const data = reactive({
      currentIndex: null, // 当前分类
      className: "",
      advertises: [],
      foodList: [], // 美食列表
      classifyFoodList: [], // 美食分类列表
      bannerList: [], //banner 数组
    });

    const params = reactive({
      foodTotal: 0,
      classifyFoodTotal: 0,
      food: { page: 1, pageSize: 8, search: {} },
      classifyFood: { page: 1, pageSize: 12 },
    });

    // 父组件调用子组件的方法
    const child = ref();

    // 收藏
    const collect = (val, i) => {
      let favorite = val.favorite ? false : true;
      api
        .deliciousFoodFavorite({
          classification: val.classification,
          deliciousFoodId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            // data.foodList[i].favorite = favorite;
            // 父组件调用子组件的方法
            child.value.getHotFood();
            changeData();
          }
        });
    };

    // 子组件-收藏数据后重新请求数据
    const changeData = () => {
      getDeliciousFood();
    };

    // 获取美食分类列表
    const getClassifyFood = () => {
      api.deliciousFoodClassification(params.classifyFood).then((res) => {
        params.classifyFoodTotal = res.total;
        data.classifyFoodList = res.records;
      });
    };

    // 改变分类
    const changeClassify = (classification, index) => {
      data.currentIndex = index;
      params.food.search.classification = classification;
      // 根据分类改变美食列表
      getDeliciousFood();
    };

    // 获取美食列表
    const getDeliciousFood = () => {
      api.deliciousFood(params.food).then((res) => {
        params.foodTotal = res.total;
        data.foodList = res.records;
      });
    };

    // 改变美食列表的页码
    const changePage = (e) => {
      window.scrollTo(0, 0);
      params.food.page = e;
      getDeliciousFood();
    };

    onMounted(() => {
      store.dispatch("getAdvertisesData", 5); // 获取广告位 图
      window.scrollTo(0, 0);
      getBanners();
      getDeliciousFood();
      getClassifyFood();
    });

    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "FOOD" });
    };

    // 去详情页面
    const goDetails = (id) => {
      router.push({ path: "/deliciousFoodDetail", query: { id } });
    };

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      child,
      ...toRefs(data),
      ...toRefs(params),
      getBanners,
      changeClassify,
      changePage,
      collect,
      goDetails,
      changeData,
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cardModelWrap {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 79px;
}

.planeFood {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 68px;

  .planeTitle {
    margin-bottom: 36px;
    font-size: 44px;
    color: #444;
  }
}

.pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 20px;
}

.planeFoodList {
  width: 100%;
  display: flex;
  align-items: center;

  .planeFoodList_listsWrap {
    width: 33.3%;
    height: 532px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.planeFoodList_listsWrap:first-child {
  justify-content: flex-start !important;
}

.planeFoodList_listsWrap:last-child {
  justify-content: flex-end;
}

.planeFoodList_listsWrap > .lists {
  width: 416px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  .images {
    width: 100%;
    height: 322px;
    position: relative;

    img:nth-of-type(1) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  .foodInfos {
    flex: 1;
    width: 100%;
    padding: 27px 22px;
    box-sizing: border-box;
    font-size: 18px;
    color: #666;

    div {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      line-height: 25px;
      text-align: justify;
    }
  }
}

.planeFoodClass {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 37px 0px rgba(145, 145, 145, 0.25);
  padding: 45px 45px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .planeFoodClassListWrap {
    width: 16.6%;
    height: 170px;
    padding-bottom: 45px;
  }
}
.planeFoodClassListWrap {
  img {
    transition: all 0.5s ease-out;
    /* 过度 所有css属性 1秒过度 结束慢*/
    -moz-transition: all 0.5s ease-out;
    /* Firefox 4 */
    -webkit-transition: all 0.5s ease-out;
    /* Safari 和 Chrome */
    -o-transition: all 0.5s ease-out;
  }
}
.planeFoodClassListWrap:hover {
  .planeFoodClassListWrap_lists {
    color: rgb(247, 176, 53) !important;
    img {
      transform: scale(1.1, 1.1);
    }
  }
}
.planeFoodClassListWrap_lists {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 22px;
  color: #333;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}

// 分类列表
.foodListWrap {
  width: 100%;
}

.marginTops {
  margin-top: 62px;
}

.foodListOne {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e3f1ff;
  border-radius: 16px;
  margin-bottom: 62px;
  cursor: pointer;
  position: relative;

  img {
    width: 55%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  .foodInfos {
    flex: 1;
    height: 100%;
    position: relative;
    padding: 0 91px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    color: #444;

    .title {
      font-size: 40px;
      color: #444;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }

    .desc {
      font-size: 18px;
      color: #444;
      line-height: 28px;
      text-align: justify;
      -webkit-line-clamp: 10;
    }
  }
}

.icon-shoucang {
  font-size: 36px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.active-classify {
  color: #f5a623;
}
</style>
