<template>
  <div class="page-wrap">
    <top-search-three :advertises="advertises" />
    <!--帖子内容-->
    <div :class="className" class="content">
      <div class="banner-wrap">
        <div class="topic-box" v-if="infos != null">
          <img :src="infos.image[0]" />
          <div class="topic-right">
            <div class="title">{{ JSON.parse(infos.title)[$i18n.locale] }}</div>
            <div class="desc">
              <!-- {{ JSON.parse(infos.description)[$i18n.locale] }} -->
              <rich-text
                v-html="JSON.parse(infos.description)[$i18n.locale]"
              ></rich-text>
            </div>
            <div class="bottom">
              <div>
                {{ $t("forum.follow") }}：{{
                  infos.people == null ? 0 : infos.people
                }}
              </div>
              <div class="post">
                {{ $t("forum.article") }}：{{
                  infos.articles == null ? 0 : infos.articles
                }}
              </div>
              <div class="form-style">
                <el-button @click.stop="collect">{{
                  infos.follow == null || !infos.follow
                    ? $t("forum.follow")
                    : $t("forum.concerned")
                }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--其他帖子列表-->
      <div class="forum-topic">
        <div class="forum-topic-list">
          <TopicList
            :topicId="ids"
            :updateShow="updateShow"
            :change-update="changeUpdate"
          />
        </div>

        <!--其他推荐列表-->
        <div class="forum-topic-right" v-if="otherList.length != 0">
          <div class="otherTitle">{{ $t("forum.other") }}</div>
          <div
            class="else-forum-topic-list"
            v-for="(item, index) in otherList"
            :key="index"
            @click="otherClickUpdate(item.id)"
          >
            <img :src="item.image[0]" />

            <div class="list">
              <div class="text-overflow-three">
                <rich-text
                  v-html="JSON.parse(item.description)[$i18n.locale]"
                ></rich-text>
              </div>
              <count-user
                :msg="item.comment == null ? 0 : item.comment"
                :user="item.people == null ? 0 : item.people"
              />
            </div>
          </div>
        </div>
      </div>
      <!--发布新帖-->
      <div class="forum-new-topic">
        <new-topic :topicId="ids" @update-list="updateList" />
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import ContactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import Carousel from "../../components/Carousel/Carousel";
import CountUser from "../../components/CountUser/CountUser";
import TopicList from "./ChildCom/TopicList";
import NewTopic from "./ChildCom/NewTopic";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  name: "forumTopic",
  components: { NewTopic, TopicList, CountUser, ContactUsOne, topSearchThree },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      advertises: [],
      ids: route.query.id,
      infos: null,
      updateShow: false,
      otherList: [], //其他推荐
    });

    //获取话题详情
    const infosGet = (id) => {
      api.recommendedTopicInff(id ? id : data.ids).then((res) => {
        data.infos = res;
        data.infos.image = data.infos.image.split(",");
      });
    };

    //关注
    const collect = () => {
      let follow = data.infos.follow ? false : true;
      api
        .topicFollowUpdate({
          topicId: data.infos.id,
          follow,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: follow
                ? t("common.FocusOnSuccess")
                : t("common.AttentionCancelled"),
              type: "success",
            });
            infosGet(data.ids);
          }
        });
    };

    //其他推荐
    const otherListGet = async () => {
      api.recommendedTopicOrtherlist().then((res) => {
        data.otherList = res;
        data.otherList.map((val) => {
          val.image = val.image.split(",");
        });
      });
    };

    //点击其他推荐
    const otherClickUpdate = (id) => {
      data.ids = id;
      infosGet(id);
    };

    //子组件改值
    const changeUpdate = () => {
      data.updateShow = false;
    };

    //刷新 列表
    const updateList = () => {
      data.updateShow = true;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 9); // 获取广告位 图
      infosGet(); //话题详情
      otherListGet(); //其他推荐
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(data.advertises.length, 1);
    });

    return {
      ...toRefs(data),
      infosGet, //话题详情
      collect,
      updateList, //刷新文章列表
      changeUpdate,
      otherClickUpdate, //点击其他推荐
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.banner-wrap {
  width: 100%;
  height: 425px;
  background: #fff5e4;
}

.news-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.news-details-wrap {
  width: 70%;
  font-weight: 500;
  color: #444440;
}

.topic-box {
  padding: 154px 306px 0 306px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  img {
    width: 268px;
    height: 206px;
    border-radius: 5px;
    object-fit: cover;
  }

  .topic-right {
    margin-left: 30px;

    .title {
      font-size: 22px;
      color: #1e1f20;
    }

    .desc {
      font-size: 14px;
      color: #666666;
      margin: 26px 0 36px 0;
    }

    .bottom {
      display: flex;
      align-items: center;

      .post {
        margin: 0 54px;
      }

      .form-style {
        :deep .el-button {
          border-radius: 8px;
          padding: 8px 37px;
        }
      }
    }
  }
}

.forum-topic-right {
  width: 357px;
  display: flex;
  flex-direction: column;
}

.forum-topic {
  width: 100%;
  display: flex;
  padding: 70px 306px;
  box-sizing: border-box;
  justify-content: space-between;
}

.forum-topic-list {
  flex: 1;
  margin-right: 12px;
  overflow: hidden;
}

.else-forum-topic-list {
  width: 357px;
  height: 130px;
  display: flex;
  align-items: center;
  margin-bottom: 37px;

  img {
    height: 100%;
    width: 128px;
    margin-right: 12px;
  }

  .list {
    height: 100%;
    flex: 1;
    font-size: 18px;
    color: #333;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}

.forum-new-topic {
  margin: 70px 306px;
  box-sizing: border-box;
}
.otherTitle {
  margin-bottom: 30px;
  font-size: 24px;
  color: #000;
  line-height: 32px;
}
</style>
