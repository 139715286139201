<template>
  <div class="planeFood" v-if="hotFoodList && hotFoodList.length > 0">
    <div class="planeTitle">{{ $t("food.MostPopular") }}</div>
    <div class="planeFoodList">
      <div
        class="planeFoodList_listsWrap"
        v-for="(item, index) in hotFoodList"
        :key="index"
        @click="goDetails(item.id)"
      >
        <div class="lists">
          <div class="images">
            <FavoriteCard
              @click.stop="collect(item, index)"
              :favorite="item.favorite"
            />
            <img :src="item.image" alt="" />
          </div>
          <div class="foodInfos">
            <div v-html="JSON.parse(item.detail)[$i18n.locale]"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 翻页 -->
    <div class="pages">
      <page
        :total="hotFoodTotal"
        :page-size="hotFood.pageSize"
        @change-page="changeHotFoodPage"
      />
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
import { onMounted, reactive, toRefs } from "vue";
import Page from "@/components/page";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
export default {
  name: "HotFood",
  components: { Page, FavoriteCard },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const data = reactive({
      hotFoodList: [], // 热门美食列表
    });

    const params = reactive({
      hotFoodTotal: 0,
      hotFood: { page: 1, pageSize: 3 },
    });

    // 获取热门场所美食列表
    const getHotFood = () => {
      api.hotDeliciousFood(params.hotFood).then((res) => {
        params.hotFoodTotal = res.total;
        data.hotFoodList = res.records;
      });
    };

    // 去详情页面
    const goDetails = (id) => {
      window.scrollTo(0, 0);
      emit("goDetails", id);
      // router.replace({ path: "/deliciousFoodDetail", query: { id } });
    };

    //  @click="$router.push({path:'/deliciousFoodDetail',query:{id:item.id}})"

    // 改变热门场所美食列表的页码
    const changeHotFoodPage = (e) => {
      params.hotFood.page = e;
      getHotFood();
    };

    // 收藏
    const collect = (val, i) => {
      let favorite = val.favorite ? false : true;
      api
        .deliciousFoodFavorite({
          classification: val.classification,
          deliciousFoodId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            getHotFood();
            emit("changeData");
            // data.hotFoodList[i].favorite = favorite;
          }
        });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      getHotFood();
    });

    return {
      ...toRefs(data),
      ...toRefs(params),
      getHotFood,
      changeHotFoodPage,
      collect,
      goDetails,
    };
  },
};
</script>

<style scoped lang="scss">
.planeFood {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .planeTitle {
    margin-bottom: 36px;
    font-size: 44px;
    color: #444;
  }
}

.planeFoodList {
  width: 100%;
  display: flex;
  align-items: center;

  .planeFoodList_listsWrap {
    width: 33.3%;
    height: 532px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.planeFoodList_listsWrap:first-child {
  justify-content: flex-start !important;
}

.planeFoodList_listsWrap:last-child {
  justify-content: flex-end;
}

.planeFoodList_listsWrap > .lists {
  width: 416px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  .images {
    width: 100%;
    height: 322px;
    position: relative;

    img:nth-of-type(1) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  .foodInfos {
    flex: 1;
    width: 100%;
    padding: 27px 22px;
    box-sizing: border-box;
    font-size: 18px;
    color: #666;

    div {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      line-height: 25px;
      text-align: justify;
    }
  }
}

.pages {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
</style>
