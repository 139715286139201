<template>
  <div class="collect-warp">
     <img class="check-img" v-if="favorite==true" src="../../../static/images/icon/2.png"/>
    <img class="unCheck-img" v-else src="../../../static/images/icon/1.png"/>
    <!-- <i @click.stop="collect" class="iconfont" :class="favorite == true ? 'icon-xuanzhong' : 'icon-shoucang'"></i> -->
  </div>
</template>

<script>
export default {
  name: "FavoriteCard",
  props: {
    favorite: {
      type: Boolean,
      default: false
    }
  },
  setup(props,{emit}) {
    // 收藏
    const collect = (() => {
      emit('collect')
    });

    return {
      collect,
    }
  }
};
</script>

<style scoped lang='scss'>
.collect-warp {
  position: absolute;
  top: 20px;
  right: 20px;  
}
.check-img {
  width: 40px;
  height: auto;
}
.unCheck-img {
  width: 40px;
  height: auto;
}
</style>
