<template>
  <div class="demo-image__preview">
    <el-image
      style="width: 100px; height: 100px"
      :src="url"
      :preview-src-list="srcList"
      :initial-index="1"
    >
    </el-image>
  </div>
</template>

<script>

export default {
  name: "ImagePreview",
  props: {
    url: {
      type: String,
      default: ""
    },
    srcList: {
      type: Array,
      default: () => []
    }
  }
};
</script>
