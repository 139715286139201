let OSS = require("ali-oss");
const pageScorll = function (scortop, oneNode, callback) {
  if (scortop > oneNode - 100) {
    callback(0);
  } else {
    callback(0);
  }
  if (scortop > oneNode - 95) {
    this.opacitySty = 0.1;
    callback(0.1);
  }
  if (scortop > oneNode - 90) {
    callback(0.2);
  }
  if (scortop > oneNode - 85) {
    callback(0.3);
  }
  if (scortop > oneNode - 80) {
    callback(0.4);
  }
  if (scortop > oneNode - 75) {
    callback(0.5);
  }
  if (scortop > oneNode - 70) {
    callback(0.6);
  }
  if (scortop > oneNode - 65) {
    callback(0.7);
  }
  if (scortop > oneNode - 60) {
    callback(0.8);
  }
  if (scortop > oneNode - 55) {
    callback(0.9);
  }
  if (scortop > oneNode) {
    callback(1);
  }
};
// 防抖 --- 防止重复提交
function throttle(fn, gapTime) {
  if (gapTime == null || gapTime == undefined) {
    gapTime = 1500;
  }

  let _lastTime = null;

  // 返回新的函数
  return function () {
    let _nowTime = +new Date();
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments); //将this和参数传给原函数
      _lastTime = _nowTime;
    }
  };
}

function getFile(fileName) {
  // 后缀获取
  var suffix = "";
  // 获取类型结果
  var result = "";
  try {
    var flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
  // 进行图片匹配
  result = imglist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "image";
    return result;
  }
  // 匹配 视频
  var videolist = ["mp4", "m2v", "mkv"];
  result = videolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "video";
    return result;
  }
}

function upFile(file) {
  return new Promise((resolve, reject) => {
    let client = OSS({
      policy:
        "eyJleHBpcmF0aW9uIjoiMjAyNS0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==",
      accessKeyId: "LTAI5t7WeDpv2MTLp7eDz4aJ",
      accessKeySecret: "pVvMhnPD3z7Na0Q1blZJQmSBYbB1Qg",
      bucket: "chengteh",
      secure: true,
      signature: "VmujoAyYpVnx/BBIBBX8SVIG7nw=",
      region: "oss-cn-shanghai",
    });
    client.options.endpoint.href = "https://chengteh.oss-cn-shanghai.aliyuncs.com";
    client.options.endpoint.protocol = "https:";
    let ossUpAddress = "/";
    let tiemr = new Date();
    let address =
      tiemr.getFullYear() + "" + (tiemr.getMonth() + 1) + "" + tiemr.getDate(); //获取当前日期
    address = ossUpAddress + address + "/"; //文件放在当前日期下
    let str = file.name.substr(file.name.lastIndexOf(".")); //截取文件后缀名
    let nameStr = address + tiemr.getTime() + str; //拼接地址
    let ret = client
      .put(nameStr, file, {
        progress: async function (p) {
          let e = {};
          e.percent = p * 100;
          option.onProgress(e);
        },
      })
      .then((res) => {
        resolve(res);
      });
  });
}

//计算年龄
function getAge(birthday) {
  // 新建日期对象
  let date = new Date();
  // 今天日期，数组，同 birthday
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  // 分别计算年月日差值
  let age = today.map((value, index) => {
    return value - birthday[index];
  });
  // 当天数为负数时，月减 1，天数加上月总天数
  if (age[2] < 0) {
    // 简单获取上个月总天数的方法，不会错
    let lastMonth = new Date(today[0], today[1], 0);
    age[1]--;
    age[2] += lastMonth.getDate();
  }
  // 当月数为负数时，年减 1，月数加上 12
  if (age[1] < 0) {
    age[0]--;
    age[1] += 12;
  }
  return age;
}

/*函数节流*/
function checkInput(fn, interval) {
  var enterTime = 0; //触发的时间
  var gapTime = interval || 300; //间隔时间，如果interval不传，则默认300ms
  return function () {
    var context = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      fn.call(context, arguments);
      enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}

/*函数防抖*/
function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 1000; //间隔时间，如果interval不传，则默认1000ms
  return function () {
    clearTimeout(timer);
    var context = this;
    var args = arguments; //保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
    timer = setTimeout(function () {
      fn.call(context, args);
    }, gapTime);
  };
}

function dateFormat(date) {
  // var date = new Date(dateData)
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  const time = y + "-" + m + "-" + d;
  return time;
}

// 回到顶部
function toTop() {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  //实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
}

//手机号验证(很多国家，全局不验证)
function checkPhone(value) {
  // if (!value) {
  //   return false;
  // } else {
  //   const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  //   if (reg.test(value)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  return true;
}

//邮箱验证
function validateEmail(value) {
  if (!value) {
    return false;
  } else {
    const reg =
      /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/;
    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  }
}
//密码验证
function checkPassword(password) {
  let reg = /^[a-zA-Z0-9]{6,20}$/;
  if (!reg.test(password)) {
    return false;
  }
  return true;
}
//两次密码验证
function checkPasswordAgain(password, passwordAgin) {
  let reg = /^[a-zA-Z0-9]{6,20}$/;
  if (password != passwordAgin) {
    return false;
  }
  return true;
}

/**
 * 判断广告位、banner、是否存在
 * 1 只存在广告位 -- nav-pt
 * 2 只存在banner -- nav-banner-pt
 * 3 广告位、banner同时存在 -- nav-top-mt
 * 4 只有导航 -- nav-com-mt
 * */
function changeClassName(advertises, bannerList) {
  if (advertises > 0 && bannerList > 0) {
    return "nav-top-mt";
  } else if (advertises > 0 && bannerList == 0) {
    return "nav-pt";
  } else if (advertises == 0 && bannerList > 0) {
    return "nav-banner-pt";
  } else {
    return "nav-com-pt";
  }
}

// 团签
function validateEnglishName(name) {
  let reg = /^[A-Z]*(\s[A-Z]*)*$/;
  if (!reg.test(name)) {
    return false;
  }
  return true;
}

module.exports = {
  pageScorll,
  toTop,
  upFile,
  throttle,
  getFile,
  getAge,
  checkInput,
  debounce,
  validateEmail,
  checkPhone,
  checkPassword,
  checkPasswordAgain,
  changeClassName,
  validateEnglishName,
  dateFormat,
};
