<template>
  <div class="pagination">
    <el-pagination layout="prev, pager, next"
                   :total="total"
                   :page-size="pageSize"
                   :hide-on-single-page="true"
                   @current-change="changePage">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 3,
    },
  },
  setup (props, { emit }) {
    const changePage = (e) => {
      emit("changePage", e);
    };

    return {
      changePage,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
:deep .el-pagination {
  display: flex;
}
:deep .el-pagination .btn-prev {
  width: 70px;
  height: 70px;
  opacity: 1;
  border: 3px solid #dedede !important;
  border-radius: 50%;
  background: #e5e5e5 !important;
  color: #fff !important;
  text-align: center;
  padding: 0 !important;
  margin: 0;

  .el-icon {
    font-size: 20px !important;
  }
}
:deep .el-pagination .btn-prev {
  width: 70px;
  height: 70px;
  opacity: 1;
  border: 3px solid #f7bf62 !important;
  border-radius: 50%;
  background: #f7bf62 !important;
  color: #fff !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-icon {
    font-size: 20px !important;
  }

  margin-left: 20px !important;
}

:deep .el-pagination .btn-next {
  width: 70px;
  height: 70px;
  opacity: 1;
  border: 3px solid #f7bf62 !important;
  border-radius: 50%;
  background: #f7bf62 !important;
  color: #fff !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-icon {
    font-size: 20px !important;
  }

  margin-left: 20px !important;
}

:deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  line-height: 65px !important;
}

:deep .el-pagination button:disabled {
  border-color: #e5e5e5 !important;
  background-color: #e5e5e5 !important;
}

:deep .el-pagination .el-pager {
  li {
    width: 70px;
    height: 70px;
    opacity: 1;
    border: 3px solid #dedede !important;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    color: #999999;
    line-height: 70px;
    text-align: center;
    padding: 0;
    margin-left: 20px;
  }

  .el-icon-more {
    color: #999999;
  }

  .number:hover {
    color: #f7bf62 !important;
  }

  .active {
    width: 70px;
    height: 70px;
    opacity: 1;
    border: 3px solid #f7bf62 !important;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    color: #f7bf62;
    line-height: 70px;

    .btn-quicknext {
      line-height: 0;
    }
  }
}
</style>
