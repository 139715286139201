<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />

    <topSelect v-if="false"></topSelect>
    <div :class="className" class="content">
      <Carousel :list="bannerList" />
      <div class="application">
        <div class="title">{{ $t("foreignerServiceStation.title") }}</div>
        <div class="school-class">
          {{ $t("foreignerServiceStation.allAreas") }}
          <el-select
            filterable
            v-model="tableForm.search.classification"
            :placeholder="$t('register.PleaseSelect')"
            @change="listArray"
            clearable
          >
            <el-option
              v-for="item in classTypeList"
              :key="item.id"
              :label="item.title[$i18n.locale]"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <!--推荐-->
        <div class="recommend mb-100" v-if="foreigners.length > 0">
          <div class="recommend-wrap">
            <div
              class="recommend-list"
              v-for="(item, index) in foreigners[0]"
              :key="index"
            >
              <img class="recommend-img" :src="item.image[0]" />
              <div class="recommend-desc">
                <FavoriteCard
                  style="z-index: 8"
                  @click.stop="collect(item, 0, index)"
                  :favorite="item.favorite"
                />
                <div class="desc-box">
                  {{ JSON.parse(item.content)[$i18n.locale] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--分页列表-->
        <template v-if="foreigners.length > 1">
          <div class="center-show" v-for="(item, j) in foreigners[1]" :key="j">
            <FavoriteCard
              style="z-index: 8"
              @click.stop="collect(item, 1, j)"
              :favorite="item.favorite"
            />
            <div class="center-show-img">
              <div v-for="(val, index) in item.image" :key="index">
                <img :src="val" />
              </div>
            </div>
            <div class="desc">{{ JSON.parse(item.content)[$i18n.locale] }}</div>
          </div>
        </template>
        <!--分页-->
        <div class="pages">
          <page
            :total="total"
            :page-size="tableForm.pageSize"
            @change-page="changePage"
          ></page>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import topSearchThree from "../home/topSearchThree.vue";
import topSelect from "../home/topSelect.vue";
import Carousel from "../../components/Carousel/Carousel";
import Page from "../../components/page";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, toRefs, onMounted, watchEffect } from "vue";
import ContactUsOne from "../home/contactUsOne.vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Page,
    topSearchThree,
    topSelect,
    Carousel,
    ContactUsOne,
    FavoriteCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      className: "",
      advertises: [],
      bannerList: [], //banner 数组
      classTypeList: [], //分类列表
      foreigners: [], //列表
      allAreas: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
    });
    //翻页
    const params = reactive({
      total: 0,
      tableForm: { page: 1, pageSize: 4, search: { classification: "" } },
    });
    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "FOREIGN" });
    };

    // 收藏
    const collect = (val, item, index) => {
      let favorite = val.favorite ? false : true;
      api
        .foreignersfavorite({
          foreignersId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            data.foreigners[item][index].favorite =
              !data.foreigners[item][index].favorite;
            // listArray()
          }
        });
    };

    //获取分类列表
    const classList = async () => {
      api.foreignersgetClassification().then((res) => {
        res.map((val) => {
          val.title = JSON.parse(val.title);
        });
        data.classTypeList = res;
      });
    };

    //获取列表
    const listArray = () => {
      data.foreigners = [];
      api.foreignersList(params.tableForm).then((res) => {
        params.total = res.total;
        res.records.map((val) => {
          val.image = val.image.split(",");
        });
        let lists = res.records;
        // 将数组分割成3个一组
        for (var i = 0; i < lists.length; i += 2) {
          data.foreigners.push(lists.slice(i, i + 2));
        }
      });
    };

    // 改变页码
    const changePage = (e) => {
      window.scrollTo(0, 0);
      params.tableForm.page = e;
      listArray();
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 10); // 获取广告位 图
      getBanners(); //获取banner 图
      classList(); //获取分类列表
      listArray(); //获取列表
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(params),
      ...toRefs(data),
      getBanners, //banner图
      collect, //收藏
      changePage, //翻页
      listArray, //分类选择
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background: #fff;
}
.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0 150px;
  box-sizing: border-box;

  .martop55 {
    margin-top: 55px;
  }

  .martop100 {
    margin-top: 100px;
  }

  .center-show {
    width: 100%;
    background: #fff6e7;
    border-radius: 31px;
    padding: 60px 160px 60px 160px;
    box-sizing: border-box;
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .desc {
      width: 1267px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #444444;
      line-height: 25px;
      margin-top: 45px;
      max-height: 80px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .desc::-webkit-scrollbar {
      display: none;
    }

    .center-show-img {
      display: flex;
      justify-content: space-between;
      width: 100%;

      img {
        width: 360px;
        height: 368px;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }

  .top-show {
    display: flex;
    justify-content: space-between;
    //width: 1648px;
    .left {
      display: flex;

      img {
        width: 388px;
        height: 591px;
        border-radius: 8px;
        margin-right: 30px;
      }

      .desc {
        width: 388px;
        height: 591px;
        opacity: 1;
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 306px;
          font-size: 20px;
          color: #333333;
        }
      }
    }

    .right {
      display: flex;

      img {
        width: 388px;
        height: 591px;
        border-radius: 8px;
        margin-right: 30px;
      }

      .desc {
        width: 388px;
        height: 591px;
        opacity: 1;
        background: #f5f7f5;
        border-radius: 12px;
        box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 306px;
          font-size: 20px;
          color: #333333;
        }
      }
    }
  }

  .school-class {
    font-size: 26px;
    // font-weight: 700;
    color: #444444;
    width: 100%;
    margin: 95px 0 72px 0;

    .el-select {
      width: 351px;
      opacity: 1;
      background: #ffffff;
      border-radius: 3px;
      border-radius: 13px;
      box-shadow: 10px 10px 40px 0px rgba(54, 33, 0, 0.1);
    }
  }

  .title {
    font-size: 44px;
    // font-weight: 600;
    color: #222a41;
    padding: 0 20px 0 20px;
    text-align: center;
    margin-top: 100px;
  }
}
// :deep .el-select .el-input {
//     width: 351px;
//     height: 50px;
//     border-radius: 13px;
//     border: 1px solid #f5ad34;
//   }

//   :deep .el-input .el-input__inner {
//     width: 351px;
//     height: 50px;
//     font-size: 20px;
//     text-align: left;
//     color: #333333;
//     border-radius: 13px;
//     border: 1px solid #f5ad34;
//   }

:deep.el-select {
  width: 362px;
  height: 80px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  //line-height: 80px;
  background: #ffffff;
  border: 1px solid #f5ad34;
  border-radius: 22px;
  padding: 20px;
  box-sizing: border-box;
  margin-left: 25px;
}

:deep .el-select-dropdown__item {
  font-size: 24px;
  color: #333333;
  font-weight: 500 !important;
}

:deep .el-input__inner {
  font-size: 24px;
  color: #333333;
  font-weight: 500 !important;
  border: none !important;
}

.school-class :deep .el-input__inner::placeholder {
  font-size: 24px;
}

.recommend {
  display: flex;
  justify-content: space-between;
  //width: 70%;
}

.recommend-wrap {
  display: flex;
  justify-content: space-between;
}

.recommend-list {
  width: 49%;
  display: flex;
  justify-content: space-between;
  height: 590px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.2);
}

.recommend-list:nth-child(2) {
  background: #f5f7f5;
}

.recommend-img {
  width: 50%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.recommend-desc {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 41px;
  box-sizing: border-box;

  .desc-box {
    display: inline-block;
    width: 100%;
    height: 70%;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .desc-box::-webkit-scrollbar {
    display: none;
  }
}

.recommend-collect {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 36px;
  height: auto;
  cursor: pointer;
}

.mb-100 {
  margin-bottom: 100px;
}

.page-box {
  margin-bottom: 89px;
}
.pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
