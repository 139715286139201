<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <!--职位详情-->
          <div class="recruit-info" v-if="infos != null">
            <div class="info-top">
              <div>Published {{ infos.updateDate }}</div>
              <!-- <div>75 Views</div> -->
            </div>
            <div class="info-title">
              {{ JSON.parse(infos.job)[$i18n.locale] }}
            </div>
            <!-- <div class="info-design">RDD (RuiDu Design)</div> -->
            <div class="info-desc">
              <div>
                {{ $t("recruit.JobType") }}：{{
                  JSON.parse(infos.classificationName)[$i18n.locale]
                }}
              </div>
              <div>{{ $t("recruit.Salary") }} {{ infos.price }}</div>
              <div>
                {{ $t("recruit.CandidateLocation")
                }}{{ JSON.parse(infos.address)[$i18n.locale] }}
              </div>
            </div>
            <!--收藏-->
            <favorite @collect="collect" :favorite="infos.favorite" />
            <div class="xian"></div>
            <div class="other-info">
              <div class="other-desc" v-html="infos.content"></div>
            </div>
          </div>

          <!--热门职位-->
          <div class="recruit-title">{{ $t("recruit.MostPopularJob") }}</div>
          <hot-recruit
            background="#fff"
            :list="hotList"
            @change-data="changeData"
            @go-details="goDetails"
          />
          <!--招聘翻页-->
          <div class="more recruit-more">
            <page
              :total="totalRecruit"
              :page-size="tableRecruit.pageSize"
              @change-page="changePageRecruit"
            ></page>
          </div>
        </div>
      </div>
    </div>
    <contact-us-one></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import Carousel from "../../components/Carousel/Carousel";
import HotRecruit from "./childCom/HotRecruit";
import favorite from "../../components/Favorite/Favorite";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import Page from "../../components/page";
export default {
  components: {
    Page,
    favorite,
    HotRecruit,
    Carousel,
    topSearchThree,
    contactUsOne,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US", //中英文
      ids: useRoute().query.id, //获取父页面 路由传参值  id
      advertises: [],
      bannerList: [], //banner 数组
      hotList: [], //热门招聘
      infos: null,
    });
    const params = reactive({
      totalRecruit: 0, //招聘
      tableRecruit: { page: 1, pageSize: 3 },
    });

    //获取详情
    const getInfos = async (id) => {
      let config = id ? id : data.ids;
      await api.recruitInfos(config).then((res) => {
        data.infos = res;
        data.bannerList = res.banner.split(","); //banner图
        data.infos.content = JSON.parse(res.content)[data.local];
        // //处理富文本图片
        const regex = new RegExp("<img", "gi");
        data.infos.content = data.infos.content.replace(
          regex,
          `<img style="width: 100%; height: auto;margin:10px 0;"`
        );
        data.infos.content = data.infos.content
          .replace(
            "<iframe",
            `<video style="width:100%;" controls="" autoplay=""`
          )
          .replace("</iframe>", "</video>");
      });
    };
    // 收藏
    const collect = () => {
      const val = data.infos;
      let favorite = val.favorite ? false : true;
      api
        .recruitFavorite({
          recruitId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            data.infos.favorite = favorite;
            changeData();
          }
        });
    };

    //获取热门招聘
    const getHot = async () => {
      await api.recruitgetHotList(params.tableRecruit).then((res) => {
        params.totalRecruit = res.total;
        data.hotList = res.records;
      });
    };
    //刷新页面 招聘列表
    const changeData = () => {
      getHot();
    };

    //招聘翻页
    const changePageRecruit = (e) => {
      params.tableRecruit.page = e;
      getHot();
    };

    // 去详情页面
    const goDetails = (id) => {
      getInfos(id); //获取详情
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 17); // 获取广告位 图
      getInfos(); //获取详情
      getHot(); //热门招聘
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(params),
      ...toRefs(data),
      collect,
      changeData, //刷新页面
      goDetails, //跳转详情
      changePageRecruit, //翻页
    };
  },
};
</script>

<style scoped lang="scss">
.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 70%;
}
.recruit-title {
  height: 64px;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  color: #444444;
  margin: 80px 0 14px 0;
}
.recruit-info {
  background: #ffffff;
  padding: 26px;
  box-sizing: border-box;
  margin-top: 70px;
}
.info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #adadad;
}
.info-title {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  margin: 22px 0 16px 0;
}
.info-desc {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  div {
    padding-top: 10px;
  }
}
.info-design {
  font-size: 24px;
  font-weight: 400;
  color: #666660;
  margin-bottom: 22px;
}
.collection {
  width: 146px;
  background: #ffffff;
  height: 48px;
  border: 1px solid #afafaf;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
.collect-img {
  width: 36px;
  height: auto;
}
.xian {
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin: 35px 0;
}
.recruit-title {
  font-weight: 500;
}
.recruit-more {
  margin-top: 20px;
}
</style>
