import axios from "./axios";
import {
  encryptedData
} from "@/utils/encrypt";
import server from "./server.config";
import qs from "qs"; // 根据需求是否导入qs模块

const base = {
  // url: "http://10.1.1.12:8111" //本地地址
  // url: "http://localhost:8111", //本地地址
  url: server, //线上地址
};
const article = {
  getBaseUrl() {
    return base.url;
  },
  // 广告位
  getAdvertises(params) {
    return axios.post(base.url + "/api/advertiser/getlist", qs.parse(params));
  },
  //登陆
  login(params) {
    return axios.post(base.url + "/api/authorize/login", qs.parse(params));
  },
  //获取当前登录的用户信息
  userInfo() {
    // return axios.get(base.url + "/api/authorize/login");
    return axios.get(base.url + "/api/personalcenter/getuser");
  },
  //修改个人信息
  updateGetUser(params) {
    return axios.post(
      base.url + "/api/personalcenter/updateuser",
      qs.parse(params)
    );
  },
  //修改个人密码
  updatePassword(params) {
    return axios.post(
      base.url + "/api/personalcenter/updatePassword",
      qs.parse(params)
    );
  },
  //修改个人手机号码
  updatePhone(params) {
    return axios.post(
      base.url + "/api/personalcenter/updatePhone",
      qs.parse(params)
    );
  },
  //页面-重置密码
  restPassword(params) {
    return axios.post(
      base.url + "/api/personalcenter/restPassword",
      qs.parse(params)
    );
  },
  //页面-重置密码-验证（忘记密码）
  resetVerifyCode(params) {
    return axios.post(
      base.url + "/api/personalcenter/resetVerify",
      qs.parse(params)
    );
  },

  // 退出登录
  logout() {
    return axios.get(base.url + "/api/authorize/logout");
  },
  //美食推荐
  frs() {
    return axios.get(base.url + "/api/index/frs");
  },
  //休闲娱乐
  lps() {
    return axios.get(base.url + "/api/index/lps");
  },
  //Here-Store
  hs() {
    return axios.get(base.url + "/api/index/hs");
  },
  //新闻
  journalism() {
    return axios.get(base.url + "/api/index/journalism");
  },
  //留言
  lw(params) {
    return axios.post(base.url + "/api/index/lw", qs.parse(params));
  },

  //注册
  register(params) {
    return axios.post(base.url + "/api/user/register", qs.parse(params));
  },
  //发送验证码
  registerCode(params) {
    return axios.post(base.url + "/api/user/register/code", qs.parse(params));
  },
  // 验证码登录
  loginCode(params) {
    return axios.post(
      base.url + "/api/authorize/send/verify",
      qs.parse(params)
    );
  },
  // banner列表
  getBanner(params) {
    return axios.get(base.url + "/api/banner/list/?type=" + params.type);
  },
  // 加密
  getPublicKey() {
    return axios.get(base.url + "/api/authorize/pub/key");
  },
  // 美食列表
  deliciousFood(params) {
    return axios.post(base.url + "/api/deliciousfood/list", qs.parse(params));
  },
  // 美食收藏
  deliciousFoodFavorite(params) {
    // return axios.get(base.url + "/api/deliciousFoodFavorite/upd/?id="+params.id);
    return axios.post(
      base.url + "/api/deliciousFoodFavorite/upd",
      qs.parse(params)
    );
  },
  // 美食分类列表
  deliciousFoodClassification(params) {
    return axios.post(
      base.url + "/api/deliciousFoodClassification/list",
      qs.parse(params)
    );
  },
  // 热门场所美食列表
  hotDeliciousFood(params) {
    return axios.post(
      base.url + "/api/deliciousfood/getList",
      qs.parse(params)
    );
  },
  // 美食详情
  deliciousFoodById(params) {
    return axios.get(base.url + "/api/deliciousfood/getOne?id=" + params.id);
  },
  // 书籍列表
  book(params) {
    return axios.post(base.url + "/api/book/list", qs.parse(params));
  },
  // 书籍分类
  bookCategory(params) {
    return axios.get(base.url + "/api/book/category/" + params);
  },
  // 书籍推荐--底部
  bookRecommendBottom() {
    return axios.get(base.url + "/api/book/index/2");
  },
  // 书籍推荐--头部
  bookRecommendTop() {
    return axios.get(base.url + "/api/book/index/1");
  },
  //获取新闻列表
  deliciousNews(params) {
    return axios.get(
      base.url +
      "/api/mainJournalism/list/" +
      params.pageSize +
      "/" +
      params.page
    );
  },
  //获取新闻列表
  deliciousNewsInfos(params) {
    return axios.get(
      base.url + "/api/mainJournalism/getmainJournalism?id=" + params
    );
  },
  //首页：1住宿，2酒店
  dwell(params) {
    return axios.get(base.url + "/api/dwell/index/" + params);
  },
  //住宿区域分类
  stayDwell() {
    return axios.get(base.url + "/api/dwell/list/sa");
  },
  //酒店区域分类
  hotelDwell(params) {
    return axios.get(base.url + "/api/dwell/list/ha/" + params);
  },
  //酒店住宿详情
  stayHotelById(params) {
    return axios.get(base.url + "/api/dwell/getById?id=" + params.id);
  },
  //公共接口-住宿酒店区域列表
  stayHotelArea() {
    return axios.get(base.url + "/api/dwell/getlist");
  },
  //住宿酒店区域列表--分页
  getDwell(params) {
    return axios.post(base.url + "/api/dwell/list", qs.parse(params));
  },
  //住宿酒店推荐数据
  dwellRecommend(params) {
    return axios.post(base.url + "/api/dwell/recommend", qs.parse(params));
  },

  //最新旅游攻略
  tourismStrategyGetlist() {
    return axios.post(base.url + "/api/tourismStrategy/getlist");
  },
  //旅游攻略全部 列表
  tourismStrategyList(params) {
    return axios.post(base.url + "/api/tourismStrategy/list", qs.parse(params));
  },
  //旅游攻略全部 城市
  getAddressAll() {
    return axios.get(base.url + "/api/tourismStrategy/getAddress");
  },
  //旅游攻略 收藏
  tourismStrategyUpdateFavorite(params) {
    return axios.post(
      base.url + "/api/tourismStrategy/updateFavorite",
      qs.parse(params)
    );
  },
  //旅游攻略 收藏
  tourismStrategyInfos(params) {
    return axios.post(
      base.url + "/api/tourismStrategy/getOne",
      qs.parse(params)
    );
  },
  //休闲玩乐 收藏
  updPlayFavorite(params) {
    return axios.post(base.url + "/api/play/favorite", qs.parse(params));
  },
  //休闲玩乐详情
  playById(params) {
    return axios.get(base.url + "/api/play/getById?id=" + params.id);
  },
  //休闲玩乐列表
  play(params) {
    return axios.post(base.url + "/api/play/list", qs.parse(params));
  },
  //休闲玩乐分类列表
  playClassify() {
    return axios.get(base.url + "/api/play/getList");
  },

  //获取数字人民币
  numberRmbQueryOne() {
    return axios.get(base.url + "/api/numberRmb/queryOne");
  },

  //获取学习培训 分类
  learningTrainingClassType() {
    return axios.get(base.url + "/api/learningTraining/getClassification");
  },
  //获取学习培训 推荐
  learningTrainingRecomm() {
    return axios.post(base.url + "/api/learningTraining/getlist");
  },
  //获取学习培训 列表
  learningTrainingList(params) {
    return axios.post(
      base.url + "/api/learningTraining/list",
      qs.parse(params)
    );
  },
  //获取学习培训 收藏
  learningTrainingFavorite(params) {
    return axios.post(
      base.url + "/api/learningTraining/updateFavorite",
      qs.parse(params)
    );
  },
  //获取学习培训 详情
  learningTrainingInfos(params) {
    return axios.get(base.url + "/api/learningTraining/getOne?id=" + params);
  },

  //论坛 推荐话题
  recommendedTopicRecomm() {
    return axios.post(base.url + "/api/recommendedTopic/list");
  },
  //论坛 热门话题
  recommendedTopicHotlist() {
    return axios.post(base.url + "/api/recommendedTopic/hotlist");
  },

  //获取用户协议 关于我们 微信二维码
  userAgreementGet(params) {
    return axios.get(base.url + "/api/userAgreement/list?id=" + params);
  },

  //获取热门景点列表
  etHotList() {
    return axios.get(base.url + "/api/scenery/getHotList");
  },
  //获取景点列表 列表
  sceneryList(params) {
    return axios.post(base.url + "/api/scenery/list", qs.parse(params));
  },
  //获取景点 收藏
  sceneryFavorite(params) {
    return axios.post(base.url + "/api/scenery/favorite", qs.parse(params));
  },
  //获取景点分类
  sceneryClass(params) {
    return axios.post(
      base.url + "/api/scenery/getclassification",
      qs.parse(params)
    );
  },
  //获取景点详情
  sceneryInfos(params) {
    return axios.post(base.url + "/api/scenery/getById", qs.parse(params));
  },
  //获取招聘分类
  recruitClass() {
    return axios.get(base.url + "/api/recruitClassification/getlist");
  },
  //获取招聘 列表
  recruitList(params) {
    return axios.post(base.url + "/api/recruit/recruitList", qs.parse(params));
  },
  //获取招聘 收藏
  recruitFavorite(params) {
    return axios.post(base.url + "/api/recruit/favorite", qs.parse(params));
  },
  //获取招聘详情
  recruitInfos(params) {
    return axios.get(base.url + "/api/recruit/getById?id=" + params);
  },
  //获取招聘热门
  recruitgetHotList(params) {
    return axios.post(base.url + "/api/recruit/getHotList", qs.parse(params));
  },

  //获取精英 收藏
  eliteFavorite(params) {
    return axios.post(base.url + "/api/elite/favorite", qs.parse(params));
  },
  //获取精英 列表
  eliteList(params) {
    return axios.post(base.url + "/api/elite/list", qs.parse(params));
  },
  //获取faq
  faqList(params) {
    return axios.post(base.url + "/api/faq/getBytype", qs.parse(params));
  },
  // 医疗列表
  medicalList(params) {
    return axios.post(base.url + "/api/medical/list", qs.parse(params));
  },
  // 医疗详情
  medicalGetById(params) {
    return axios.get(base.url + "/api/medical/getById?id=" + params.id);
  },
  // 医疗分类
  medicalClassify() {
    return axios.get(base.url + "/api/medical/getlist");
  },
  // 医疗推荐列表
  medicalHotList() {
    return axios.post(base.url + "/api/medical/hotlist");
  },
  // 保险列表
  insuranceList() {
    return axios.post(base.url + "/api/insurance/list");
  },

  // 学习-工作-中国永居-延期签证
  getVisaAll(params) {
    return axios.post(base.url + "/api/visaAll/list", qs.parse(params));
  },
  // 添加学习-工作-中国永居-延期签证
  addVisaAll(params) {
    return axios.post(base.url + "/api/visaAll/option", qs.parse(params));
  },
  // 修改学习-工作-中国永居-延期签证
  updVisaAll(params) {
    return axios.post(base.url + "/api/visaAll/update", qs.parse(params));
  },
  // 删除学习-工作-中国永居-延期签证
  delVisaAll(params) {
    return axios.post(base.url + "/api/visaAll/delete", qs.parse(params));
  },
  // 签证信息
  getVisaInfo() {
    return axios.get(base.url + "/api/visa/getlist");
  },
  // vip服务信息
  getVipServeType(params) {
    return axios.post(base.url + "/api/vip/list", qs.parse(params));
  },

  // 二手交易分类列表
  transactionClassification() {
    return axios.post(base.url + "/api/transaction/getClassification");
  },
  // 添加二手交易
  addTransaction(params) {
    return axios.post(base.url + "/api/transaction/option", qs.parse(params));
  },
  // 修改二手交易
  updTransaction(params) {
    return axios.post(base.url + "/api/transaction/upd", qs.parse(params));
  },
  // 二手交易列表-最新推荐
  recommendTransaction(params) {
    return axios.post(base.url + "/api/transaction/list", qs.parse(params));
  },
  // 收藏二手交易
  favoriteTransaction(params) {
    return axios.post(base.url + "/api/transaction/favorite", qs.parse(params));
  },
  // 二手交易列表-分类数据
  transactionData(params) {
    return axios.post(
      base.url + "/api/transaction/getClassificationData",
      qs.parse(params)
    );
  },
  // 二手交易详情
  transactionById(params) {
    return axios.get(base.url + "/api/transaction/getById?id=" + params.id);
  },

  //外国人服务站  获取分类列表
  foreignersgetClassification() {
    return axios.get(base.url + "/api/foreigners/getClassification");
  },
  //外国人服务站  列表
  foreignersList(params) {
    return axios.post(base.url + "/api/foreigners/list", qs.parse(params));
  },
  //外国人服务站  收藏
  foreignersfavorite(params) {
    return axios.post(base.url + "/api/foreigners/favorite", qs.parse(params));
  },

  //论坛

  //论坛首页左 banner
  indexForumLeftQueryOne() {
    return axios.get(base.url + "/api/indexForumLeft/queryOne");
  },
  //推荐话题
  recommendedTopicList(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/list",
      qs.parse(params)
    );
  },
  //热门话题
  recommendedTopichotlist(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/hotlist",
      qs.parse(params)
    );
  },
  //论坛
  //我的关注
  topicFollowList(params) {
    return axios.post(base.url + "/api/topicFollow/list", qs.parse(params));
  },
  //我的收藏
  articleGetlist(params) {
    return axios.post(base.url + "/api/article/getlist", qs.parse(params));
  },
  //推荐话题
  recommendedTopicList(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/list",
      qs.parse(params)
    );
  },
  //热门话题
  recommendedTopichotlist(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/hotlist",
      qs.parse(params)
    );
  },
  //论坛页面-文章列表
  articleList(params) {
    return axios.post(base.url + "/api/article/list", qs.parse(params));
  },
  //关注话题
  topicFollowUpdate(params) {
    return axios.post(base.url + "/api/topicFollow/update", qs.parse(params));
  },
  //搜藏文章
  articleUpdate(params) {
    return axios.post(base.url + "/api/article/update", qs.parse(params));
  },
  //话题详情
  recommendedTopicInff(params) {
    return axios.get(base.url + "/api/recommendedTopic/getById?id=" + params);
  },
  //发布文章
  articleOption(params) {
    return axios.post(base.url + "/api/article/option", qs.parse(params));
  },
  //文章详情
  articleInfo(params) {
    return axios.get(base.url + "/api/article/getOne?id=" + params);
  },
  //修改文章
  updArticleInfo(params) {
    return axios.post(base.url + "/api/article/updatepc", qs.parse(params));
  },
  //获取父级评论列表
  articleOpinionList(params) {
    return axios.post(base.url + "/api/articleOpinion/list", qs.parse(params));
  },
  //获取子级评论列表
  articleOpinionChildlist(params) {
    return axios.post(
      base.url + "/api/articleOpinion/childlist",
      qs.parse(params)
    );
  },
  //发布评论
  articleOpinionoption(params) {
    return axios.post(
      base.url + "/api/articleOpinion/option",
      qs.parse(params)
    );
  },
  //修改评论状态
  updReleaseArticle(params) {
    return axios.post(
      base.url + "/api/articleOpinion/updaterelease",
      qs.parse(params)
    );
  },
  //其他推荐
  recommendedTopicOrtherlist() {
    return axios.post(base.url + "/api/recommendedTopic/ortherlist");
  },

  // 我的二手-我发布的二手
  myTransaction(params) {
    return axios.post(
      base.url + "/api/transaction/myTransaction",
      qs.parse(params)
    );
  },
  // 我的二手-我的收藏
  myFavorite(params) {
    return axios.post(
      base.url + "/api/transaction/myFavorite",
      qs.parse(params)
    );
  },
  // 我的论坛-我的关注话题
  myTopicConversation(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/myTopicConversation",
      qs.parse(params)
    );
  },
  // 我的论坛-收藏帖子
  myFavoriteArticle(params) {
    return axios.post(
      base.url + "/api/recommendedTopic/myFavoriteArticle",
      qs.parse(params)
    );
  },
  // 我的论坛-我的评论
  myArticleOpinion(params) {
    return axios.post(
      base.url + "/api/articleOpinion/myArticleOpinion",
      qs.parse(params)
    );
  },
  // 我的论坛-我的评论-删除
  delMyArticleOpinion(params) {
    return axios.post(
      base.url + "/api/articleOpinion/delete",
      qs.parse(params)
    );
  },
  // 我的论坛-已发布帖子
  myArticle(params) {
    return axios.post(base.url + "/api/article/myArticle", qs.parse(params));
  },
  // 我的论坛-已发布帖子-删除
  delMyArticle(params) {
    return axios.post(base.url + "/api/article/delete", qs.parse(params));
  },
  // 我的论坛-已发布帖子-改变状态
  updStatusMyArticle(params) {
    return axios.post(
      base.url + "/api/article/updatereleases",
      qs.parse(params)
    );
  },
  // 我的收藏-旅游攻略
  myTourismFavorite(params) {
    return axios.post(
      base.url + "/api/myfavorite/mytourismFavorite",
      qs.parse(params)
    );
  },
  // 我的收藏-美食
  myDeliciousFood(params) {
    return axios.post(
      base.url + "/api/myfavorite/myDeliciousFood",
      qs.parse(params)
    );
  },
  // 我的收藏-休闲/玩乐
  myPlay(params) {
    return axios.post(base.url + "/api/myfavorite/myPlay", qs.parse(params));
  },
  // 我的收藏-外国人服务站
  myForeigners(params) {
    return axios.post(
      base.url + "/api/myfavorite/myForeigners",
      qs.parse(params)
    );
  },
  // 我的收藏-学习/培训
  myLearningTraining(params) {
    return axios.post(
      base.url + "/api/myfavorite/myLearningTraining",
      qs.parse(params)
    );
  },
  // 我的收藏-景点门票
  myScenery(params) {
    return axios.post(base.url + "/api/myfavorite/myScenery", qs.parse(params));
  },
  // 我的求职-已收藏精英
  myElite(params) {
    return axios.post(base.url + "/api/myfavorite/myElite", qs.parse(params));
  },
  // 我的求职-已收藏岗位
  myRecruit(params) {
    return axios.post(base.url + "/api/myfavorite/myRecruit", qs.parse(params));
  },

  // 创建VIP接机订单
  addVipOrder(params) {
    return axios.post(base.url + "/api/vip/option", qs.parse(params));
  },
  // 获取VIP接机订单
  getVipOrder(params) {
    return axios.post(base.url + "/api/vip/order/list", qs.parse(params));
  },
  // 获取VIP接机订单详情
  getVipOrderDetails(params) {
    return axios.post(base.url + "/api/vip/order/detail", qs.parse(params));
  },
  // 修改VIP接机订单
  updVipOrder(params) {
    var formatDate = new Date(params.enterDate);
    formatDate.setHours(formatDate.getHours() + 8);
    params.enterDate = formatDate;
    return axios.post(base.url + "/api/vip/update", qs.parse(params));
  },
  // VIP接机订单--设置价格
  updVipOrderPrice(params) {
    return axios.post(base.url + "/api/vip/update/price", qs.parse(params));
  },
  // 证件识别
  certificates(params) {
    return axios.get(base.url + "/api/certificates/get?url=" + params.url);
  },

  // 创建订单列表
  addOrderInfo(params) {
    return axios.post(base.url + "/api/orderInfo/pay/model", qs.parse(params));
  },
  // 获取订单列表
  getOrderInfoList(params) {
    return axios.post(base.url + "/api/orderInfo/order/list", qs.parse(params));
  },
  // 获取订单信息
  getOrderInfoDetails(params) {
    return axios.post(base.url + "/api/orderInfo/detail", qs.parse(params));
  },

  // 商务签证列表
  getBusinessVisaList(params) {
    return axios.post(base.url + "/api/businessVisa/list", qs.parse(params));
  },
  // 修改商务签证
  updBusinessVisa(params) {
    return axios.post(base.url + "/api/businessVisa/update", qs.parse(params));
  },
  // 修改加急商务签证
  updBusinessVisaUrgent(params) {
    return axios.post(
      base.url + "/api/businessVisa/updateUrgent",
      qs.parse(params)
    );
  },
  // 添加商务签证
  addBusinessVisa(params) {
    return axios.post(base.url + "/api/businessVisa/option", qs.parse(params));
  },

  // 团签签证列表
  getGroupVisaList(params) {
    return axios.post(base.url + "/api/teamVisa/list", qs.parse(params));
  },
  // 修改团签签证
  updGroupVisa(params) {
    return axios.post(base.url + "/api/teamVisa/update", qs.parse(params));
  },
  // 修改加急团签签证服务
  updGroupVisaUrgent(params) {
    return axios.post(
      base.url + "/api/teamVisa/updateUrgent",
      qs.parse(params)
    );
  },
  // 添加团签签证
  addGroupVisa(params) {
    return axios.post(base.url + "/api/teamVisa/option", qs.parse(params));
  },

  // 服务类型列表
  getUrgentType(params) {
    return axios.post(base.url + "/api/urgentType/list", qs.parse(params));
  },
  // 签证价格
  getVisaAmount(params) {
    return axios.post(base.url + "/api/visaAmount/list", qs.parse(params));
  },
  // hereStore服务类型管理
  getServerType(params) {
    return axios.post(base.url + "/api/serverType/list", qs.parse(params));
  },
  // 不同详情
  getApplyDetail(params) {
    return axios.post(base.url + "/api/applyDetail/list", qs.parse(params));
  },
  // 上网卡商品列表
  getWifiGoodsList(params) {
    return axios.post(
      base.url + "/api/trafficCardOrderInfo/list",
      qs.parse(params)
    );
  },
  // 上网卡类型列表
  getWifiTypeList(params) {
    return axios.post(
      base.url + "/api/trafficCardOrderInfo/list/type",
      qs.parse(params)
    );
  },
  // 获取国家-区号
  getAreaCode() {
    return axios.get(base.url + "/api/areaCode/get");
  },
  // paypal付款
  goPaypal(params) {
    return axios.post(base.url + "/api/v2/pay", qs.parse(params));
  },
  //休闲推荐列表
  playRecommended(params) {
    return axios.post(base.url + "/api/play/recommended", qs.parse(params));
  },
  // 二手交易列表-删除二手交易
  transactionDel(params) {
    return axios.post(base.url + "/api/transaction/del", qs.parse(params));
  },
  // 包车服务价格列表
  charteredCar(params) {
    return axios.post(base.url + "/api/charteredCar/list", qs.parse(params));
  },
  // 包车服务价格列表
  charteredCarAll(params) {
    return axios.post(base.url + "/api/charteredCar/listAll");
  },
  // 上网卡--添加地址
  addWifiAddress(params) {
    return axios.post(base.url + "/api/addressInfo/add", qs.parse(params));
  },
  // 上网卡--地址列表
  getWifiAddress(params) {
    return axios.post(base.url + "/api/addressInfo/list", qs.parse(params));
  },
  // 上网卡--修改地址
  updWifiAddress(params) {
    return axios.post(base.url + "/api/addressInfo/upd", qs.parse(params));
  },
  // 上网卡--添加订单
  addWifiOrderInfo(params) {
    return axios.post(
      base.url + "/api/trafficCardOrderInfo/add",
      qs.parse(params)
    );
  },
  // 上网卡--删除订单
  delWifiOrderInfo(params) {
    return axios.post(
      base.url + "/api/trafficCardOrderInfo/del",
      qs.parse(params)
    );
  },
  // 上网卡--修改订单
  updWifiOrderInfo(params) {
    return axios.post(
      base.url + "/api/trafficCardOrderInfo/upd",
      qs.parse(params)
    );
  },
  // 广告页面
  getAdvertisingPage(params) {
    return axios.post(
      base.url + "/api/advertisement/getbyid",
      qs.parse(params)
    );
  },
  // 押金支付
  payDeposit(params) {
    return axios.post(base.url + "/api/orderInfo/update", qs.parse(params));
  },
  // 支付宝支付
  payAlipay(params) {
    return axios.post(base.url + "/api/alipay/pay", qs.parse(params));
  },
  // 支付宝支付回调
  payAlipayAlinotify(params) {
    return axios.post(base.url + "/api/alipay/alinotify", qs.parse(params));
  },
  // 网页支付
  payAlipayPage(params) {
    return axios.post(base.url + "/api/alipay/pays", qs.parse(params));
  },
  // 取消支付宝支付
  cancelPayAlipay(params) {
    return axios.post(base.url + "/api/alipay/cancelpay", qs.parse(params));
  },
  // 支付宝支付
  // payAlipay() {
  //   return axios.get(base.url + "/api/alipay/pay");
  // },
  // 反馈说明（操作人信息）
  operatorInformation(params) {
    return axios.post(
      base.url + "/api/operatorInformation/list",
      qs.parse(params)
    );
  },

  //获取首页数据
  homeVisa() {
    return axios.get(base.url + "/api/index/visa");
  },

  topMenu() {
    return axios.get(base.url + "/api/topMenu/list");
  },
  updateOrder(params) {
    return axios.get(
      base.url + `/api/orderInfo/updateOrderState/${params.id}/${params.status}/${params.order}`
    );
  },
  detailCardToPay(id) {
    return axios.get(
      base.url + `/api/trafficCardOrderInfo/d/p/${id}`
    );
  },
};

export default article;
// export default {
//   article,
//   base,
// };