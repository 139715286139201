module.exports = {
  language: {
    chinese: "Chinesisch",
    English: "Englisch",
    Italiano: "Italienisch",
    Español: "Spanisch",
    Français: " Französisch",
    Deutsch: "Deutsch",
  },
  commonData: {
    orderStatus: [
      { value: 0, label: "Unbezahlt" },
      { value: 1, label: "Unbezahlt" },
      { value: 2, label: "In Überarbeitung" },
      { value: 3, label: "Ergänzt" },
      { value: 4, label: "Abgelehnt" },
      { value: 5, label: "Abbrechen" },
      { value: 6, label: "Bezahlt" },
      { value: 7, label: "Erstattung" },
    ],
    paymentMode: [
      { value: 1, label: "Kreditkarte" },
      { value: 2, label: "Paypal" },
      { value: 3, label: "Alipay" },
      { value: 4, label: "Einzahlung" },
    ],
    type: [
      { value: 1, label: "China-Visum" },
      { value: 2, label: "Wi-Fi-Karte" },
      { value: 3, label: "Vip Flughafen Pick Up" },
    ],
    childType: [
      { value: 1, label: "Studienvisum" },
      { value: 2, label: "Arbeitsvisum" },
      { value: 3, label: "China PR" },
      { value: 4, label: "Erneuerung des Arbeitsvisums" },
      { value: 5, label: "Business e-Visum" },
      { value: 6, label: "Gruppenvisum" },
      { value: 7, label: "Vip Welcome" },
      { value: 8, label: "Vip Flughafen Transfers" },
      { value: 9, label: "Wi-Fi-Karte" },
    ],
    occupationList: [
      { label: "Arbeitnehmer", value: 1 },
      { label: "Geschäftsmann", value: 2 },
      { label: "Hausfrau", value: 3 },
      { label: "Pensioniert", value: 4 },
      { label: "Studenten", value: 5 },
      { label: "Kind", value: 6 },
    ],
    emergencyContactRelationshipList: [
      { label: "Eltern", value: 1 },
      { label: "Angehörige", value: 2 },
      { label: "Schwester", value: 3 },
      { label: "Bruder", value: 4 },
      { label: "Freund", value: 5 },
      { label: "Ehepartner", value: 6 },
      { label: "Tochter/Sohn", value: 7 },
    ],
    punishList: [
      { label: "Ja", value: 1 },
      { label: "Nein", value: 0 },
    ],
    comStatus: [
      { label: "Ja", value: 1 },
      { label: "Nein", value: 2 },
    ],
    gender: [
      { label: "Männer", value: 1 },
      { label: "Frau", value: 0 },
    ],
    maritalStatus: [
      { label: "Verheiratet", value: 1 },
      { label: "Unverheiratet", value: 2 },
      { label: "Scheidung", value: 3 },
      { label: "Verwitwet", value: 4 },
    ],
    education: [
      { label: "PostDoktor", value: "PostDoctor" },
      { label: "Doktorat", value: "Doctorate" },
      { label: "Meister", value: "Master" },
      { label: "Undergraduate", value: "Undergraduate" },
      { label: "Oberschule", value: "High school" },
      { label: "Unterhalb der Oberstufe", value: "Below high school" },
    ],
    phoneType: [
      { label: "China +86", value: 1 },
      { label: "andere Länder", value: 2 },
    ],
    serviceType: [
      { title: "Normal", desc: "5 Arbeitstage", value: 1 },
      {
        title: "Express",
        desc: "3 Arbeitstage. Express-Service erfordert zusätzliche Gebühr",
        value: 2,
      },
      {
        title: "Extra dringend",
        desc: "2 Arbeitstage. Eilservice erfordert zusätzliche Gebühr",
        value: 3,
      },
    ],
    groupServiceType: [
      { title: "Normal", desc: "5 Arbeitstage", value: 1 },
      {
        title: "Express",
        desc: "3 Arbeitstage. Express-Service erfordert zusätzliche Gebühr",
        value: 2,
      },
    ],
    vipCars: [
      { title: "Business 7-Sitzer", value: 1 },
      { title: "Premium 5-Sitzer", value: 2 },
    ],
    expressType: [
      { title: "Fedex", value: 1 },
      { title: "DHL", value: 2 },
      { title: "SF-EXPRESS", value: 3 },
      { title: "EMS", value: 4 },
    ],
  },
  contactLink: {
    aboutUs: "Um uns",
    chineseVisa: "Chinesisches Visum",
    contactUs: "Um uns zu kontaktieren",
    insurance: "Versicherungen",
    advertise: "Werbung",
    wcic: "Wi-Fi-Karte",
    faq: "FAQ",
    vipApu: "Flughafen-Transport",
    privacy: "Privacy",
    wechat: "APP",
  },
  common: {
    copyright: "COPYRIGHT©2021 Hereshanghai.com, All Rights Reserved.",
    collectSuccess: "Erfolgreich gerettet",
    cancelSuccess: "Abgesagt",
    cancelCollection: "Löschen",
    collectFail: "Scheitern",
    unit: "",
    month: "Monat",
    create: "Erster Beitrag",
    update: "Letzte Aktualisierung",
    Viewed: "Angezeigt",
    times: "Gewölbe",
    isImage: "Das Bild muss im GIF、JPG、PNG-Format vorliegen.",
    isLt2M: "Die Größe des Bildes darf 2MB nicht überschreiten.",
    success: "Die Anfrage wurde erfolgreich gesendet",
    payFail: "Zahlung fehlgeschlagen",
    paySuccess: "Erfolg bei der Zahlung",
    updSuccess: "Erfolgreich geändert",
    loginAgain: "Bitte melden Sie sich erneut an",
    updSubmit: "Bestätigung",
    codeError: "Falscher Verifizierungscode",
    username: "Name des Nutzers",
    FocusOnSuccess: "Folgen Sie",
    AttentionCancelled: "Abgesagt",
    PublishedSuccessfully: "Erfolgreich veröffentlicht",
    delete: "Löschen",
    deleteSuccess: "Gelöscht",
    Redact: "Bearbeiten",
    Note: "Hinweis",
    NotLoggedIn: "Bitte loggen Sie sich ein",
    Deleted: "Gelöscht",
    Rejected: "Abgelehnt",
    noVisa:
      "Es ist nicht möglich, die gewählte Staatsangehörigkeit zu beantragen.",
    noCity: "Wir bieten keinen VIP-Empfang für die ausgewählte Stadt an.",
    sample: "Mit gutem Beispiel vorangehen",
    aggrement:
      "Privacy and Terms of service, Allgemeine Geschäftsbedingungen und Datenschutzrichtlinien",
    printInvoice: "Rechnung drucken",
  },
  navigation: {
    Back: "Seitenanfang",
    SignIn: "Verbindung",
    Register: "Register",
  },
  message: {
    success: "mit Erfolg",
    registerSuccess: "Erfolg der Aufnahme",
    title: "Erfolg der Aufnahme",
    desc: "Bitte schützen Sie Ihre Kontodaten",
    goHome: "Zur ersten Seite gehen",
  },
  resetPw: {
    title: "Ein vergessenes Passwort zurücksetzen",
    password: "Ein Passwort mit 6 bis 20 Zeichen festlegen",
    next: "Vorgehen",
  },
  login: {
    EmailLogin: "E-Mail",
    Email: "E-Mail",
    MobileNumber: "Telefonnummer",
    accountNumber: "E-Mail",
    Password: "Passwort",
    PleaseEnterYourEmailAddress: "Geben Sie Ihre E-Mail-Adresse ein",
    PleaseEnterYourPassword: "Passwort eingeben",
    MobilePhone: "Telefonnummer (nur für chinesische Telefonnummern)",
    PleaseEnterYourPhone: "Geben Sie Ihre Telefonnummer ein",
    AuthCode: "Autorisierungscode",
    PleaseEnterTheAuthCode: "Autorisierungscode",
    GetCode: "Geben Sie den Autorisierungscode ein",
    SendCode: "Senden",
    SignIn: "Loggen Sie sich ein",
    StayloggedIn: "Bleiben Sie in Verbindung",
    Register: "Register",
    ForgotPassword: "Haben Sie Ihr Passwort vergessen?",
    Individual: "Privat",
    CompanyOrAgency: "Unternehmen/Agentur",
    Company: "Unternehmen/Agentur",
    sendCode: "Erfolgreich gesendet",
  },
  register: {
    validateRealName: "Name und Vorname",
    validatePhone: "Telefonnummer",
    validatePhoneInspect: "Bitte geben Sie die korrekte Handynummer ein",
    validateNewPhone:
      "Die neue Telefonnummer darf nicht dieselbe sein wie die vorherige.",
    validateEmail: "Geben Sie Ihre E-Mail-Adresse ein",
    validateEmailInspect: "Bitte geben Sie die korrekte E-Mail-Adresse ein",
    validateEmailCode: "Geben Sie den E-Mail-Verifizierungscode ein",
    validateCountry: "Nationalität",
    validatePassword: "Geben Sie ein Passwort ein",
    validatePasswordAgin: "Bestätigen Sie das Passwort",
    validatePasswordAtypism: "Die beiden Passwörter sind nicht konsistent",
    validateNickName: "Name des Nutzers",
    validatePhoneCode: "Geben Sie den SMS-Verifizierungscode ein",
    validateCompanyName: "Name der Firma/Agentur",
    validateCompanyType: "Art des Unternehmens",
    validateCompanySn: "MEHRWERTSTEUER",
    validateCompanyWebsite: "Website des Unternehmens",
    validateCompanyAddress: "Adresse des Unternehmens",
    validateCompanyPost: "Postleitzahl",
    validateCompanyCountry: "Wählen Sie das Land",
    validateCompanyCity: "Stadt",
    validateCompanyContact: "Name des Nutzers",

    EmailRegister: "Registrierung der Mailbox",
    MobileNumberRegister: "Registrierung mit Telefonnummer",
    checkBox: "Markieren Sie das Kästchen zum Akzeptieren",
    checkBoxText: "《Dienstleistungsvereinbarung für Nutzer》",
    checkBoxUserAgreement:
      "Allgemeine Geschäftsbedingungen und Datenschutzerklärung",
    AlreadyRegisteredSignIn:
      "Sie haben bereits ein Konto, melden Sie sich jetzt an",
    Register: "Register",
    PleaseSelect: "Bitte wählen Sie",
    PleaseInput: "Bitte geben Sie ein",
    Email: "E-mail",
    AuthCode: "E-Mail bestätigen",
    Password: "Passwort",
    ConfirmedPassword: "Bestätigen Sie das Passwort",
    CompanyName: "Name des Unternehmens",
    Typology: "Art des Unternehmens",
    VATTax: "MEHRWERTSTEUER",
    Website: "Website",
    ContactPerson: "Kontaktperson",
    PhoneNumber: "Telefonnummer",
    Address: "Adresse",
    PostalCode: "Postleitzahl",
    Country: "Land",
    City: "Stadt",
    Account: "Name des Nutzers",
    GetCode: "Den Überprüfungscode erhalten",

    Name: "Name",
    MobileNumber: "Telefonnummer",
    Nationality: "Nationalität",
    MobileNumberchina: "Mobiltelefonnummer",
    yam: "Verifizierungscode",
  },
  home: {
    FoodRecommendation: "Ernährungsempfehlungen",
    LeisurePlay: "Freizeit und Unterhaltung",
    visa: "Chinesisches Visum",
    Journalism: "Nachrichten",
    ContactUs: "Kontaktieren Sie uns",
    AcceptanceOrganization: "Akzeptierte Institutionen",
    AcceptanceOrganizationInfo:
      "Die nationale Migrationsbehörde überträgt den Ein- und Ausreisebehörden der Organe der öffentlichen Sicherheit auf Präfekturebene (einschließlich der Bezirke, die der Gerichtsbarkeit der direkt der Zentralregierung unterstehenden Gemeinden unterstehen) oder einer höheren Ebene die Aufgabe, Ein- und Ausreisegenehmigungen von Ausländern anzunehmen. Nach der Genehmigung der nationalen Migrationsbehörde durch die Organe für öffentliche Sicherheit auf Provinzebene können die Organe für öffentliche Sicherheit auf Kreisebene Ein- und Ausreisegenehmigungen für Ausländer auf der Grundlage der Delegation der nationalen Migrationsbehörde annehmen.",
  },
  contact: {
    email: "E-mail",
    name: "Name",
    nationality: "Nationalität",
    title: "Titel",
    content: "Inhalt",
    verification: "Überprüfung",
    submit: "Einreichen",
    insertEmail: "E-Mail einfügen",
    insertEmail2:
      "Bitte geben Sie Ihre E-Mail-Adresse in der Lieferadresse an.",
    insertName: "Namen einfügen",
    insertTitle: "E-Mail einfügen",
    insertCountry: "Land einfügen",
    insertContent: "Fügen Sie hier Ihre Nachricht ein",
    slidingValidation:
      "Halten Sie den Cursor gedrückt und ziehen Sie ihn nach ganz rechts.",
    validateEmailCode: "Falsches Format der E-Mail-Adresse",
    validateLengthCode: "Mindestlänge von 2",
    validateLengthContent: "Die Länge sollte zwischen 10 und 150 liegen",
    bottomTitle: "Shanghai",
    bottomDesc:
      "Shanghai Chende Talent Consulting Service Co., LTD  Telefon: +86 21 61806993 E-mail: info@hereshanghai.com",
  },
  groupSignature: {
    insertEmail: "E-Mail einfügen",
    tipBeCare: "Hinweis: ",
    submitBeCareFul: {
      title:
        "Bitte überprüfen Sie die Informationen zu jedem Mitglied. Das Visum wird auf der Grundlage der von Ihnen angegebenen Informationen ausgestellt.",
    },
    detailsBeCareFul: {
      title:
        "Bitte überprüfen Sie die Informationen zu jedem Mitglied. Das Visum wird auf der Grundlage der von Ihnen angegebenen Informationen ausgestellt.",
    },
    successBeCareFul: {
      title:
        "Ihre Anfrage wurde übermittelt, danke! Wir werden sie überprüfen. Bitte warten Sie auf unsere Bestätigungs-E-Mail.",
    },
    payAfter: {
      successText:
        "Ihr Antrag wurde eingereicht. Sie können den Status Ihrer Praxis in Ihrem Konto überprüfen.",
    },
    beCareFul: {
      title: `
		  Gruppenvisa werden für eine Gruppe von <span style='font-weight: 900'>mindestens 2</span> und höchstens 8 Mitgliedern ausgestellt.<br />
			Füllen Sie die Daten des ersten Passagiers aus und fügen Sie diese bei, und füllen Sie dann die Daten des zweiten und dritten Passagiers aus....<br />
			Was Sie vor der Beantragung eines Visums wissen sollten:
			Das Gruppenvisum wird auf einer einzigen Bescheinigung für die gesamte Gruppe und nicht auf dem individuellen Reisepass beantragt. Alle Gruppenmitglieder müssen gemeinsam nach China ein- und ausreisen.
		`,
      infoTitle: "Was Sie vor der Beantragung eines Visums wissen sollten :",
      infoDesc:
        "Das Gruppenvisum gilt für eine einzige Bescheinigung für die gesamte Gruppe und nicht für einen einzelnen Reisepass. Alle Mitglieder der Gruppe müssen bei der Ein- und Ausreise nach China gemeinsam reisen.",
    },
    comTipBeCareFul: {
      title:
        "Nachdem wir Ihren Antrag erhalten haben, werden unsere Mitarbeiter Sie per E-Mail kontaktieren und Ihnen mitteilen, welche Unterlagen Sie für die Beantragung des Visums einreichen müssen.",
      success:
        "Ihre Anfrage wurde übermittelt, danke! Wir werden sie überprüfen. Bitte warten Sie auf unsere Bestätigungs-E-Mail.",
    },
    uploadInfo: {
      format: "Unterstützte Formate: GIF/JPG/PNG/PDF",
      success: "Erfolgreiches Herunterladen",
      see: "Klicken Sie, um zu sehen",
      uploadText: "Herunterladen von Dateien",
    },
    tipText: {
      voluntarily:
        "Hinweis: Das System füllt sich automatisch, bitte achten Sie auf die Überprüfung",
    },
    essentialInformation: "Persönliche Informationen",
    personnelInformation: "Persönliche Daten von Passagieren",
    otherInformation: "Sonstige Informationen",
    entryInformation: "Informationen",
    uploadInformation: "Herunterladen von Dokumenten",
    confirmationInformation: "Überprüfung und Bestätigung",
    serviceMode: "Art des Dienstes",
    payment: "Zahlung",
    applicationStatus: "Status der Akte",
    pleaseEnter: "Geben Sie  ein",
    pleaseSelect: "Wählen Sie",
    confimNex: "Bestätigen und fortfahren",
    memberTitle: "Einen Mitfahrer hinzufügen:",
    rules: {
      passport: "Eine Kopie des Reisepasses hochladen",
      vipTicket: "Eine Kopie der Reisetickets hochladen",
      ticket: "Kopien von Flug- oder Bahntickets hochladen ",
      hotelOrder: "Eine Kopie der Hotelreservierungen hochladen",
      englishName: "Nur Großbuchstaben können eingegeben werden",
      idCard: "Eine Kopie des Passfotos hochladen",
      vaccinationCertificate: "Eine Kopie der Impfbescheinigung hochladen",
      invitationLetter: "Eine Kopie des Einladungsschreibens herunterladen",
      companyLicense:
        "Eine Kopie der chinesischen Unternehmenslizenz hochladen",
      deposit: "Den Überweisungsbeleg herunterladen",
    },
    submitInfo: {
      name: "Name und Vorname",
      No: "Nr.",
      seeDetails: "Details anzeigen",
      passengersInfo: "Informationen für Passagiere",
    },
    informationForm: {
      SerialNumber: "Nr.",
      No: "Nr.",

      passport: "Kopie des Reisepasses hochladen (Seite mit den Informationen)",
      EnglishSurname: "Nachname",
      EnglishName: "Name",
      ChineseName: "Chinesischer Name",
      nationality: "Nationalität",
      Gender: "Geschlecht",
      dateBirth: "Geburtsdatum",
      birthplace: "Geburtsort",
      placeIssue: "Ort der Ausstellung",
      certificatesNumber: "Nummer des Reisepasses",
      passengersEmail: "E-mail",
      CertificateDate: "Datum der Veröffentlichung",
      CertificateDateValid: "Datum des Ablaufs",
      occupation: "Beruf",
      emergencyContact: "Name der Kontaktperson im Notfall",
      emergencyContactRelationship: "Beziehung zur Kontaktperson",
      EmergencyContactInformation: "Notrufnummer",
      punish: "Sind Sie in China oder einem anderen Land vorbestraft?",
      OtherNameInChina:
        "Ist dies Ihr erster Reisepass oder hatten Sie in der Vergangenheit bereits einen anderen Pass? (erster Reisepass / hatten einen alten Reisepass)?",
      OtherNationalities: "Haben Sie andere Nationalitäten?",
      getShop: "Empfänger",
      maritalStatus: "Zivilstand",
      SpouseName: "Name des Ehegatten/der Ehegattin",
      spouseDate: "Geburtsdatum des Ehemannes/der Ehefrau",
      SpouseNationality: "Staatsangehörigkeit des Ehemannes/der Ehefrau",
      ResidenceSpouse: "Adresse des Ehemannes/der Ehefrau",
      SpouseContactNumber: "Telefonnummer des Ehepartners/der Ehepartnerin",
      HighestEducation: "Ihr Abschluss",
      SchoolName: "Name des Instituts",
      NameUnitOrganization: "Name des Unternehmens",
      OverseasGroupAgency: "Name des Reisebüros",
      otherCountriesVisited:
        "Länder, die in den letzten zwei Jahren besucht wurden (außer China)",
      ExpressAddress: "Postanschrift",
      TelephoneNumber: "Telefonnummer",
      addressInChina: "Adresse in China",
      CarPassengers: "Passagiere im Auto (max. 4 pax)",
      DestinationAddress: "Zieladresse (nur Shanghai)",
      expressCountry: "Land",
      expressCity: "Stadt",
      expressStreet: "Straßen- und Hausnummer",
      expressPostcode: "Postleitzahl",
      contactAddress: "Adresse",
      consignee: "Name des Empfängers",
    },
    entry: {
      enterCity: "Ankunftsstadt (China)",
      outCity: "Abreisestadt (China)",
      enterDate: "Datum der Ankunft in China",
      outDate: "Datum der Abreise aus China",
      inboundAirport: "Flughafen bei der Einreise nach China",
      inboundFlightNo: "Flugnummer für China",
      carsType: "Art des Fahrzeugs",
    },
    upload: {
      vipTicket: "Reisetickets",
      ticket: "Reiseticket (Flug/Zug/Coach/Boot)",
      hotelOrder: "Hotelreservierungen (alle Nächte)",
      idCard: "Passfoto",
      vaccinationCertificate: "Impfbescheinigung",
      invitationLetter: "Einladungsschreiben",
      companyLicense: "Chinesische Unternehmenslizenz",
    },
    confirm: {
      memberInformation: "Mitgliederinfos",
      contactNumber: "Telefonnummer",
      newMember: "Einen weiteren Reisenden hinzufügen",
      viewDetail: "Details anzeigen",
    },
  },

  search: {
    PleaseInput: "Bitte geben Sie ein",
  },

  messageText: {
    groupSignature:
      "Für die Einreichung eines Gruppenvisums ist eine Mindestanzahl von zwei Personen erforderlich.",
  },

  personalCenter: {
    userInfo: {
      avatar: "Avatar",
      nickName: "Name des Nutzers",
      loginName: "Name des Nutzers",
      name: "Name",
      companyName: "Name des Unternehmens",
      companyType: "Art des Unternehmens",
      vatTax: "MEHRWERTSTEUER",
      website: "Website",
      email: "E-mail",
      contactPerson: "Kontaktperson",
      phone: "Telefonnummer",
      mobileNumber: "Telefonnummer",
      password: "Passwort",
      nationality: "Nationalität",
      address: "Adresse",
      city: "Stadt",
      postalCode: "Postleitzahl",
      state: "Staat",
    },

    userInfoBtn: {
      changePhone: "Bearbeiten Telefon",
      changePassword: "Passwort ändern",
      changePhoneTag: "(Nur für chinesische Mobiltelefonnummern)",
      login: "Verbunden",
      logout: "Abmelden",
      placeholderInput: "Bitte geben Sie ein",
      newMobileNumber: "Neue Handynummer",
      newPassword: "Neues Passwort",
      confirmedPassword: "Bestätigtes Passwort",
      confirmedPasswordInput: "Bitte bestätigen Sie das neue Passwort erneut",
      submit: "Ergänzen",
    },
  },

  hereStroe: {
    buyNow: "Kaufen",
    address: "Empfangsadresse",
    addressInput: "Bitte geben Sie zuerst die Lieferadresse an",
    addAddress: "Eine Lieferadresse hinzufügen",
    nameInput: "Empfänger",
    phoneInput: "Telefonnummer des Empfängers",
    postAddress: "Adresse der Post",
    PleaseInput: "Bitte geben Sie ein",
    pleaseSelect: "Bitte wählen Sie",
    streetAndNumber: "Bitte geben Sie den Namen der Straße ein",
    postalcode: "Bitte geben Sie Ihre Postleitzahl ein",
    city: "Wählen Sie eine Stadt",
    country: "Wählen Sie ein Land aus",
    confirm: "Bestätigen",
    dataList: "Liste der Dokumente",
    processingTime: "Bearbeitungszeit",
    note: "Hinweis",
    Price: "Preis",
    OtherContent: "Sonstiger Inhalt",
    ProductDescription: "Beschreibung",
    inventory: "Nicht auf Lager",
  },

  elMessageBoxText: {
    title: "Rückzug",
    releaseTitle: "Notausgang",
    releaseDesc:
      "Diese Operation wird aus der Ausgabe herausgenommen, soll ich fortfahren?",
    addDesc:
      "Diese Operation wird aus der Ausgabe herausgenommen, soll ich fortfahren?",
    desc: "Diese Aktion wird Sie ausloggen, möchten Sie fortfahren?",
    MyArticleOpinionDesc:
      "Diese Aktion wird den Kommentar löschen, soll ich fortfahren?",
    delDesc: "Soll ich fortfahren?",
    visaText:
      "Diese Aktion überprüft die Informationen, um sicherzustellen, dass sie korrekt sind?",
    confirmButtonText: "Bestätigen",
    cancelButtonText: "Abbrechen",
    Confirm: "Bestätigen",
  },

  payMent: {
    Payment: "Zahlung",
    toPayment: "Bezahlen",
    practiceDetails: "Details zur Bestellung",
    type: "Name des Produkts",
    OrderDate: " Datum des Auftrags",
    PracticeNumber: "Nummer der Praxis",
    Total: "Total",
    PaymentMethod: "Zahlungsweise",
    PaymentDetails: "Zahlungsdetails",
    PassengerName: "Name des Passagiers",
    Price: "Preis",
    ExpressFee: "Expressgebühr",
    TotalAll: "Total",
    TotalPayAll: "Total",
    Pay: "Bezahlen",
    CreditCard: "Kreditkarte",
    Alipay: "Alipay",
    Deposit: "Hinterlegung",
    submit: "Soumettre",
    submit2: "Einreichen",
    DepositNote: "(mit dem Agenten abklären)",
    YourRequestIsSubmitted: "Erfolgreiche Zahlung",
    saveAndPrevious: "Speichern und Präzedenzfall",
    saveAndOrderInfo: "Bestellungen bestätigen und ansehen",
    imgFormat:
      "Es können nur Dateien im Format jpg/png heruntergeladen werden.",
    cancel: "Abbrechen",
  },

  payMentType: {
    wifiCard: "Karte für den Internetzugang",
    businessVisa: "Business-Visum",
    studyVisa: "Studienvisum",
    jobVisa: "Arbeits-Visum",
  },

  foreignerServiceStation: {
    title: "Ressourcen",
    allAreas: "Regionale Klassifizierung",
  },

  food: {
    MostPopular: "Beliebte Orte zum Essen",
    RestaurantList: "Kategorien von Lebensmitteln",
  },

  LeisurePlay: {
    leisureplay: "Freizeit/Unterhaltung",
    youMightAlsoLike: "Es könnte Ihnen auch gefallen",
    time: "Zeit:",
    address: "Adresse:",
    activityContent: "Aktivität Inhalt:",
  },

  forum: {
    join: "Teilnehmende Themen",
    recommendedTopics: "Aktuelle Themen",
    hotTopics: "Aktuelle Themen",
    followed: "Folge",
    favorites: "Favoriten",
    more: "Mehr",
    comments: "Kommentare",
    Reply: "Antworten",
    prevText: "Vorheriger Text",
    nextText: "Nächster Text",
    ReleasePost: "Release-Post",
    ReleaseTouchPost: "Freigabe der Tastenposition",
    newTopic: "Neues Thema",
    UploadCove: "Cover herunterladen",
    PublishContent: "Inhalte veröffentlichen",
    submit: "Senden Sie",
    loginIn: "Login",
    follow: "Folgen Sie",
    article: "Artikel",
    concerned: "Betroffene",
    other: "Andere",
    PleaseSelect: "Bitte wählen Sie",
    datas: "Bitte füllen Sie die Daten aus",
    successfully: "Erfolgreiche Befreiung",
    Comments: "Kommentar",
    Comment: "Kommentar",
    back: "Zurück",
    reply: "Zurück",
    NoComment: "Kein Kommentar",
    NoAttention: "Keine Aufmerksamkeit",
    NoCollection: "Keine Sammlung",
  },

  book: {
    books: "Bücher",
    more: "Mehr",
    category: "Kategorie",
  },

  house: {
    title: "Hotel",
    university: "Universität:",
    road: "Straße:",
    houseTitle: "Informationen zur Unterkunft",
    houseIntroduction: "Beschreibung des Hauses：",
    housing: "Hotel",
    serviceApartment: "Hotel",
    allAreas: "Alle Zonen",
    city: "Stadt",
    street: "Straße",
    like: "Sie können lieben",
    price: "Preis:",
    type: "Typ:",
    rooms: "Zimmer:",
    size: "Bereich:",
    floor: "Fußboden:",
    mainWindowFacing: "Orientierung:",
    district: "Stadtteil:",
    metroStation: "Metrostation:",
  },

  secondHand: {
    price: "Preis：",
    newGoods: "Neue Produkte",
    unit: "Einheit",
    Description: "Beschreibung",
    PickUp: "Abholung",
    Contact: "Kontakt",
    class: "Name der Klassifikation",
    secondHandgoods: "Gebrauchte Artikel",
    CommodityInformation: "Info-Artikel",
    name: "Titel Name",
    address: "Adresse der Übernahme",
    phone: "Telefonnummer",
    price: "Preis",
    classification: "Klassifizierung",
    introductionPicture: "Einleitung Bild",
    details: "Details",
    release: "Befreiung",
    cancel: " Abbrechen",
    success: "Erfolg",
    fail: "Scheitern",
    rulesCover: "Mindestens ein Titelbild hochladen",
  },

  recruit: {
    Favorite: "Favoriten",
    Salary: "Gehalt: ",
    CandidateLocation: "Lokalisierung: ",
    MostPopularJob: "Beliebteste Beschäftigung",
    JobType: "Art der Beschäftigung",
    Resumes: "C.V.",
    JobProfile: "Jobprofil",
  },
  travel: {
    MostRecent: "Neuere",
  },

  ticket: {
    HotPlaces: "Beliebte Sehenswürdigkeiten",
    RecommendedAttractions: "Empfohlene Sehenswürdigkeiten",
    Price: "Preis：",
    Classification: "Klassifizierung",
  },

  medical: {
    MostPopular: "Die beliebtesten",
    Phone: "Telefon：",
    Room: "Zimmer：",
    Address: "Adresse：",
    Introduction: " Einführung：",
    suite: "Suite",
    primaryClassification: "Primäre Klassifikation",
    secondaryClassification: "Sekundäre Klassifikation",
  },

  study: {
    mostPopular: "Die beliebtesten",
    education: "Bildung",
    introduction: "Einführung：",
  },

  tableConfig: {
    createDate: "Datum der Bestellung",
    orderNumber: "Laufende Nummer",
    childType: "Sortiment",
    enterDate: "Datum eingeben",
    leaveDate: "Datum der Abreise",
    price: "Preis",
    orderStatus: "Status der Bestellung",
  },

  orderInfo: {
    PracticeInfo: "Praktische Infos",
    PostAddress: "Postanschrift",
    CourierNo: "Courier No",
    Other:
      "Hier können Sie die Kopie des e-Business/Gruppenvisums herunterladen",
    FeedbackDescription: "Beschreibung des Feedbacks",
    label: {
      Type: "Visa-Typen：",
      PaxNo: "Pax No：",
      PurchaseNo: "Nummer des Kaufs：",
      PracticeNo: "Praxis Nr.：",
      PaymentNo: "Zahlung Nr.：",
      Price: "Preis：",
      Discount: "Discount：",
      Status: "Statut：",
      MethodOfPayment: "Zahlungsmethode：",
      Recipient: "Empfänger：",
      PhoneNumber: "Telefonnummer：",
      Address: "Adresse：",
      ExpressOrderNo: "Express-Bestellnummer：",
      Time: "Zeit：",
      DescriptionContent: "Beschreibung Inhalt：",
    },
    NoLogisticsInformation: "Keine Informationen über die Logistik",
    NoInformation: "Keine Informationen",
    NoFeedbackDescription: "Kein Feedback Beschreibung",
    CheckTheLogistics: "Logistik überprüfen",
    Download: "Download",
    continue: "Weiter",
  },
  orderStatus: [
    { value: 0, label: " Unbezahlt " },
    { value: 1, label: " Unbezahlt " },
    { value: 2, label: " Unter Überprüfung " },
    { value: 3, label: " Abgeschlossen " },
    { value: 4, label: " Abgelehnt " },
    { value: 5, label: " Abgesagt " },
    { value: 6, label: " Bezahlt " },
    { value: 7, label: " Erstattet " },
  ],
};
