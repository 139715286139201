<template>
  <div class="infomation">
    <div
      v-if="informationIndex == 0"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <el-form label-position="right" label-width="auto">
        <!-- 往返机票/火车票 -->
        <el-form-item :label="$t('groupSignature.upload.ticket')">
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">Click to upload</el-button>
            <div>jpg/png files with a size less than 500kb</div>
          </el-upload>
        </el-form-item>

        <!-- 酒店订单 -->
        <el-form-item :label="$t('groupSignature.upload.hotelOrder')">
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">Click to upload</el-button>
            <div>jpg/png files with a size less than 500kb</div>
          </el-upload>
        </el-form-item>

        <!-- 上传信息 -->
        <el-form-item :label="$t('groupSignature.upload.idPhoto')">
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">Click to upload</el-button>
            <div>jpg/png files with a size less than 500kb</div>
          </el-upload>
        </el-form-item>

        <!-- 上传信息 -->
        <!-- <el-form-item
          :label="$t('groupSignature.upload.VaccinationCertificate')"
        >
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">Click to upload</el-button>
            <div>jpg/png files with a size less than 500kb</div>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <el-button plain class="submit-next" @click="returnUploadInformation">
        {{ $t("groupSignature.confimNex") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      informationIndex: 0,
      input: "",
    };
  },
  methods: {
    returnUploadInformation() {
      this.$emit("returnUploadInformation");
    },
  },
};
</script>

<style scoped lang="scss">
.other-style {
  :deep .el-input {
    width: 220px !important;
    margin-right: 15px;
  }
}
:deep .el-upload {
  text-align: left !important;
}
:deep .el-input {
  width: 500px !important;
}
:deep .el-form-item__label {
  font-size: 18px;
  font-weight: 900;
  color: #444444;
}
.infomation {
  .form-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #333333;
    margin-bottom: 45px;
  }
  .submit-next {
    background: #f5a623;
    border-radius: 3px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding: 25px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
