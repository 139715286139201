<template>
  <div class="">
    <div class="title">在线支付</div>
    <div class="xian"></div>
    <div class="order">
      <div class="order-title">订单详情</div>
      <div class="order-info">
        <div>
          <div>产品名称：学习签证</div>
          <div class="martop">订单编号：2020106203435</div>
        </div>
        <div>
          <div>下单时间：2021年6月24日 14:30</div>
          <div class="martop">订单金额：¥2000.00</div>
        </div>
        <div></div>
      </div>
    </div>

    <div class="order">
      <div class="order-title">支付方式</div>
      <div class="pay-type">
        <div
          :class="payIndex == 0 ? 'card-check' : 'card-default'"
          @click="payIndex = 0"
        ></div>
        <div
          class="marleft"
          :class="payIndex == 1 ? 'card-check' : 'card-default'"
          @click="payIndex = 1"
        ></div>
        <div
          class="marleft"
          :class="payIndex == 2 ? 'card-check' : 'card-default'"
          @click="payIndex = 2"
        ></div>
      </div>
    </div>

    <div class="xian"></div>

    <div class="order">
      <div class="order-title">支付信息</div>
      <div class="pay-info">
        <div class="pay-number">团签人数：3人</div>
        <div>
          <div class="pay-people" v-for="(item, index) in 5" :key="index">
            <div class="name">成员姓名：张伟国</div>
            <div class="pay-money">团签费用：¥300.00</div>
            <div class="express-money">加急费用：¥100.00</div>
          </div>
        </div>
      </div>
    </div>

    <el-space alignment="flex-end">
      <div class="count">总金额:</div>
      <div class="count-money">¥2000.00</div>
    </el-space>
    <div class="pay-password-title">支付密码</div>
    <div>
      <el-space>
        <password-input @get-pwd="getPwd" class="get-pwd"></password-input>
      </el-space>
    </div>
    <el-button class="success-pay">确认支付</el-button>
  </div>
</template>

<script>
import PasswordInput from "@/components/PasswordInput";
export default {
  components: { PasswordInput },
  data() {
    return {
      payIndex: 0,
    };
  },
  methods: {
    getPwd(e) {
    },
  },
};
</script>

<style scoped lang="scss">
.pay-password-title {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  margin: 20px 0;
}
.success-pay {
  background: #f5ad34;
  border-radius: 4px;
  padding: 8px 35px;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  color: #ffffff;
  margin-top: 22px;
}
.count-money {
  font-size: 26px;
  font-weight: 900;
  color: #ff2342;
}
.count {
  font-size: 18px;
  font-weight: 900;
  color: #444444;
}
.title {
  font-size: 30px;
  font-weight: 900;
  color: #333333;
  text-align: center;
}
.order {
  margin-bottom: 50px;
  .pay-info {
    width: 1227px;
    background: #f7f7f7;
    border-radius: 8px;
    margin-top: 25px;
    padding: 13px 0 13px 25px;
    .pay-number {
      font-size: 20px;
      font-weight: 900;
      color: #333333;
    }
    .pay-people {
      display: flex;
      margin-top: 15px;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #6c7b8a;
        margin-right: 130px;
      }
      .pay-money {
        font-size: 16px;
        font-weight: 400;
        color: #6c7b8a;
        margin-right: 130px;
      }
      .express-money {
        font-size: 16px;
        font-weight: 400;
        color: #3f4a54;
      }
    }
  }
  .pay-type {
    display: flex;
    margin-top: 25px;
    .card-check {
      width: 227px;
      height: 63px;
      background: #ffffff;
      border: 2px solid #f5ad34;
      border-radius: 8px;
      cursor: pointer;
    }
    .card-default {
      width: 227px;
      height: 63px;
      background: #ffffff;
      border: 2px solid #88b4ef;
      border-radius: 8px;
      cursor: pointer;
    }
    .marleft {
      margin-left: 30px;
    }
  }
  .order-title {
    font-size: 20px;
    font-weight: 900;
    color: #333333;
    border-left: 6px solid #666666;
    padding-left: 15px;
  }
  .order-info {
    width: 1227px;
    background: #f7f7f7;
    border-radius: 8px;
    margin-top: 25px;
    padding: 35px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    .martop {
      margin-top: 55px;
    }
  }
}

.xian {
  width: 1316px;
  height: 1px;
  background: #d8d8d8;
  margin: 50px 0;
}
</style>
