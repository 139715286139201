<template>
  <div class="new-topic">
    <div class="new-topic-title">
      <div class="title">{{ $t("forum.ReleasePost") }}</div>
      <div class="touch" @click="open">{{ $t("forum.ReleaseTouchPost") }}</div>
    </div>
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <!--标题-->
      <el-form-item>
        <el-input
          v-model="form.title.ZH"
          :placeholder="$t('forum.newTopic')"
        ></el-input>
      </el-form-item>
      <!--封面-->
      <el-form-item>
        <div class="upload-cove">{{ $t("forum.UploadCove") }}</div>
        <div class="flexRow">
          <div class="flexRow" v-if="form.images.length > 0">
            <div
              class="user-avatar user-avatar-item"
              v-for="(item, index) in form.images"
              :key="index"
            >
              <el-image
                class="picture"
                :src="item"
                :preview-src-list="form.images"
                :initial-index="1"
              ></el-image>
              <i
                class="iconfont icon-roundclose"
                @click="delPicture(index)"
              ></i>
            </div>
          </div>
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :http-request="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            v-if="form.images.length < 6"
          >
            <div class="user-avatar">
              <img src="../../../../static/images/food/update.png" alt="" />
            </div>
          </el-upload>
        </div>
      </el-form-item>
      <!--富文本-->
      <el-form-item>
        <!-- <el-input
          v-model="form.description.ZH"
          :rows="10"
          type="textarea"
          :placeholder="$t('forum.PublishContent')"
        /> -->
        <div class="upload-cove">{{ $t("forum.PublishContent") }}</div>
        <rich-text-editor value="" @change="change" />
      </el-form-item>
    </el-form>

    <div class="form-style">
      <el-button :loading="btnLoading" @click="apply">{{
        $t("forum.submit")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import util from "@/comm/util.js";
import RichTextEditor from "@/components/RichTextEditor/RichTextEditor";
import ImagePreview from "../../../components/ImagePreview/ImagePreview";
import api from "@/comm/api";
import { useI18n } from "vue-i18n";
export default {
  name: "NewTopic",
  components: { ImagePreview, RichTextEditor },
  props: {
    topicId: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const data = reactive({
      token: localStorage.getItem("token"),
      btnLoading: false,
    });
    const form = reactive({
      title: { ZH: "", US: "" }, //名称
      images: [], //封面
      topicId: props.topicId, //话题id
      description: { ZH: "", US: "" }, //描述
    });
    const rules = reactive({});

    // 删除图片
    const delPicture = (i) => {
      form.images.splice(i, 1);
    };

    // 图片是否满足条件
    let imgState = ref(false);

    // 上传图片
    const handleAvatarSuccess = (e) => {
      // 满足条件上传图片
      if (imgState.value) {
        util.upFile(e.file).then((res) => {
          if (res.res.status == 200) {
            // data.userInfo.avatar = res.url;
            form.images.push(res.url);
          }
        });
      }
    };
    const change = (value) => {
      form.description.ZH = value;
    };
    const beforeAvatarUpload = (file) => {
      let types = reactive(["image/jpeg", "image/jpg", "image/png"]);
      const isImage = ref(types.includes(file.type));
      const isLt2M = ref(file.size / 1024 / 1024 < 2);

      // 图片限制
      if (!isImage.value) {
        ElMessage({
          message: t("payMent.imgFormat"),
          type: "error",
        });
      }
      if (!isLt2M.value) {
        ElMessage({
          message: t("common.isLt2M"),
          type: "error",
        });
      }
      imgState = isImage.value && isLt2M;
      return isImage.value && isLt2M;
    };

    // 打开协议
    const open = () => {
      let message =
        "用户不得通过本网站制作、复制、发布、传播含有下列内容的信息： （1）反对宪法所确定的基本原则的； （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； （3）损害国家荣誉和利益的； （4）煽动民族仇恨、民族歧视，破坏民族团结的； （5）破坏国家宗教政策，宣扬邪教和封建迷信的； （6）散布谣言，扰乱社会秩序，破坏社会稳定的； （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； （8）侮辱或者诽谤他人，侵害他人合法权益的； （9）含有法律、行政法规禁止的其他内容的。";
      ElMessageBox.alert(message, "话题协议及十条底线", {
        confirmButtonText: "OK",
      });
    };

    //发布
    const apply = () => {
      data.btnLoading = true;
      if (!data.token) {
        ElMessage({
          message: t("common.NotLoggedIn"),
          type: "warning",
        });
        data.btnLoading = false;
        return;
      }
      console.log(form);
      if (
        form.title.ZH == "" ||
        form.images.length == 0 ||
        form.description.ZH == ""
      ) {
        ElMessage({
          message: t("forum.datas"),
          type: "warning",
        });
        data.btnLoading = false;
        return;
      }
      let config = JSON.parse(JSON.stringify(form));
      config.title.US = config.title.ZH;
      config.description.US = config.description.ZH;

      config.images = config.images.join(",");
      config.title = JSON.stringify(config.title);
      config.description = JSON.stringify(config.description);
      api.articleOption(config).then((res) => {
        if (res) {
          ElMessage({
            message: t("forum.successfully"),
            type: "success",
          });
          data.btnLoading = false;
          form.title = { ZH: "", US: "" };
          form.images = [];
          (form.topicId = props.topicId), //话题id
            (form.description = { ZH: "", US: "" });
          emit("updateList");
        } else {
          data.btnLoading = false;
        }
      });
    };

    return {
      ...toRefs(data),
      rules,
      form,
      imgState,
      handleAvatarSuccess,
      beforeAvatarUpload,
      open,
      delPicture,
      apply,
      change,
    };
  },
};
</script>

<style scoped lang="scss">
.new-topic-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
  color: #333333;
  font-size: 18px;
}
.new-topic-title > .title {
  font-size: 34px;
  color: #000000;
}
.new-topic-title > .touch {
  cursor: pointer;
}
.new-topic-title > .touch:hover {
  color: #f5a623;
}
:deep .el-upload--picture-card {
  width: 110px;
  height: 110px;
}
.upload-cove {
  line-height: 35px;
  color: #333333;
  font-size: 18px;
}
.form-style {
  text-align: center;
  margin-top: 51px;
  :deep .el-button {
    padding: 0 67px;
    height: 54px;
    font-size: 20px;
  }
}
.is-message-box {
  z-index: 1000 !important;
}
.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-avatar img {
  width: 58px;
  height: 58px;
}
:deep .el-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-cove {
  margin-bottom: 11px;
}
.user-avatar {
  position: relative;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  vertical-align: top;
  display: flex;
  justify-content: center;
  align-items: center;
  .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.user-avatar-item {
  margin-right: 20px;
}
.icon-img {
  font-size: 40px;
  color: #c7c7c7;
}
.icon-roundclose {
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #c7c7c7;
}
.el-select {
  width: 100%;
}
</style>
