<template>
  <div class="backgound">
    <div class="top">
      <div
        v-for="(item, index) in title"
        :key="index"
        :class="titleIndex == index ? 'title-check' : 'title-default'"
        @click="titleIndex = index"
        class="marginRight40"
      >
        {{ item }}
      </div>
    </div>

    <el-form
      label-suffix="："
      class="form-style"
      ref="RefForm"
      :rules="formRules"
      label-position="right"
      label-width="auto"
      :model="userInfo"
    >
      <!--头像-->
      <el-form-item :label="$t('personalCenter.userInfo.avatar')">
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :http-request="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div class="user-avatar">
            <img
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('../../../static/images/personal/head.png')
              "
              class="head"
            />
            <div class="user-avatar-icon" v-if="!userInfo.avatar">
              <img src="../../../static/images/personal/camera.png" />
            </div>
          </div>
        </el-upload>
      </el-form-item>
      <!--昵称-->
      <el-form-item
        :label="$t('personalCenter.userInfo.nickName')"
        prop="nickName"
      >
        <el-input
          v-model="userInfo.nickName"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.nickName')
          "
        ></el-input>
        <el-button
          @click="updUserInfo"
          :loading="loginNameBtnLoading"
          class="chang-phone-btn"
          >{{ $t("common.updSubmit") }}</el-button
        >
      </el-form-item>
      <!--用户名-->
      <el-form-item :label="$t('personalCenter.userInfo.loginName')">
        <el-input
          disabled
          v-model="userInfo.loginName"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.loginName')
          "
        ></el-input>
      </el-form-item>
      <!--真实姓名-->
      <el-form-item
        v-if="userInfo.identity == 1"
        :label="$t('personalCenter.userInfo.name')"
      >
        <el-input
          disabled
          v-model="userInfo.realName"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.name')
          "
        ></el-input>
      </el-form-item>
      <!--公司名称-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.companyName')"
      >
        <el-input
          disabled
          v-model="userInfo.companyName"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.companyName')
          "
        ></el-input>
      </el-form-item>
      <!--公司类型-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.companyType')"
      >
        <el-input
          disabled
          v-model="userInfo.companyType"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.companyType')
          "
        ></el-input>
      </el-form-item>
      <!--税号-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.vatTax')"
      >
        <el-input
          v-model="userInfo.sn"
          disabled
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.vatTax')
          "
        ></el-input>
      </el-form-item>
      <!--网址-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.website')"
      >
        <el-input
          v-model="userInfo.website"
          disabled
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.website')
          "
        ></el-input>
      </el-form-item>
      <!--注册邮箱-->
      <el-form-item
        v-if="userInfo.identity == 1 || userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.email')"
      >
        <el-input
          disabled
          v-model="userInfo.loginName"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.email')
          "
        ></el-input>
      </el-form-item>
      <!--联系人-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.contactPerson')"
      >
        <el-input
          v-model="userInfo.contact"
          disabled
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.contactPerson')
          "
        ></el-input>
      </el-form-item>
      <!--绑定手机号-->
      <el-form-item
        v-if="userInfo.identity == 1 && userInfo.type == 2"
        :label="$t('personalCenter.userInfo.phone')"
      >
        <el-input
          disabled
          v-model="userInfo.phone"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.phone')
          "
        ></el-input>
        <el-button @click="changeUserInfo(1)" class="chang-phone-btn">{{
          $t("personalCenter.userInfoBtn.changePhone")
        }}</el-button>
        <span class="tip-color">{{
          $t("personalCenter.userInfoBtn.changePhoneTag")
        }}</span>
      </el-form-item>
      <!--电话号码(企业邮箱登录)-->
      <el-form-item
        v-if="userInfo.identity == 2"
        class="mobile-item"
        :label="$t('personalCenter.userInfo.mobileNumber')"
      >
        <!-- <span>中国+86</span>        -->
        <el-select
          disabled
          v-model="userInfo.phoneAscription"
          :placeholder="$t('register.PleaseSelect')"
        >
          <el-option
            v-for="item in $store.state.areaCodeList"
            :key="item.id"
            :label="
              $i18n.locale == 'ZH'
                ? item.zh + item.areaCode
                : item.us + item.areaCode
            "
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          disabled
          v-model="userInfo.contactPhone"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.mobileNumber')
          "
        ></el-input>
      </el-form-item>
      <!--电话号码(个人邮箱登录)-->
      <el-form-item
        v-if="userInfo.identity == 1 && userInfo.type == 1"
        class="mobile-item"
        :label="$t('personalCenter.userInfo.mobileNumber')"
      >
        <!-- <span>中国+86</span> -->
        <el-select
          disabled
          v-model="userInfo.phoneAscription"
          :placeholder="$t('register.PleaseSelect')"
        >
          <el-option
            v-for="item in $store.state.areaCodeList"
            :key="item.id"
            :label="
              $i18n.locale == 'ZH'
                ? item.zh + item.areaCode
                : item.us + item.areaCode
            "
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          disabled
          v-model="userInfo.phone"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.mobileNumber')
          "
        ></el-input>
      </el-form-item>
      <!--密码(邮箱登录、企业登录)-->
      <el-form-item :label="$t('personalCenter.userInfo.password')">
        <el-input
          disabled
          v-model="userInfo.newPwd"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.password')
          "
        ></el-input>
        <el-button @click="changeUserInfo(2)" class="chang-phone-btn">{{
          $t("personalCenter.userInfoBtn.changePassword")
        }}</el-button>
      </el-form-item>
      <!--地址-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.address')"
      >
        <el-input
          disabled
          v-model="userInfo.address"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.address')
          "
        ></el-input>
      </el-form-item>
      <!--邮编-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.postalCode')"
      >
        <el-input
          disabled
          v-model="userInfo.post"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.postalCode')
          "
        ></el-input>
      </el-form-item>
      <!--国籍-->
      <el-form-item :label="$t('personalCenter.userInfo.nationality')">
        <!-- <el-input v-model="userInfo.country" :placeholder="$t('personalCenter.userInfoBtn.placeholderInput')+$t('personalCenter.userInfo.nationality')"></el-input> -->
        <el-select
          disabled
          filterable
          v-model="userInfo.country"
          :placeholder="$t('register.PleaseSelect')"
        >
          <el-option
            v-for="item in $store.state.areaCodeList"
            :key="item.id"
            :label="$i18n.locale == 'ZH' ? item.zh : item.us"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--城市-->
      <el-form-item
        v-if="userInfo.identity == 2"
        :label="$t('personalCenter.userInfo.city')"
      >
        <el-input
          disabled
          v-model="userInfo.city"
          :placeholder="
            $t('personalCenter.userInfoBtn.placeholderInput') +
            $t('personalCenter.userInfo.city')
          "
        ></el-input>
      </el-form-item>
      <!--登录状态-->
      <el-form-item :label="$t('personalCenter.userInfo.state')">
        <el-button disabled>{{
          $t("personalCenter.userInfoBtn.login")
        }}</el-button>
        <el-button @click="loginOut">{{
          $t("personalCenter.userInfoBtn.logout")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, toRefs, reactive, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import util from "@/comm/util.js";
import api from "@/comm/api";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();

    // 个人中心类型 1手机号登录 2邮箱登录 3企业登录
    let loginType = ref(2);

    // 当前卡片下标
    let titleIndex = ref(0);
    // 图片是否满足条件
    let imgState = ref(false);

    const data = reactive({
      title: proxy.$commonData.personalCardTitle.userInfo, // 卡片组
      userInfo: {}, // 用户信息
      phoneType: 1,
    });

    data.userInfo.state = 1; // 1 已登录

    // 上传图片
    const handleAvatarSuccess = (e) => {
      // 满足条件上传图片
      if (imgState.value) {
        util.upFile(e.file).then((res) => {
          if (res.res.status == 200) {
            // data.userInfo.avatar = res.url;
            const info = {
              avatar: res.url,
            };
            // 调用接口
            updateGetUser(info);
          }
        });
      }
    };

    const beforeAvatarUpload = (file) => {
      let types = reactive(["image/jpeg", "image/jpg", "image/png"]);
      const isImage = ref(types.includes(file.type));
      const isLt2M = ref(file.size / 1024 / 1024 < 2);

      // 图片限制
      if (!isImage.value) {
        ElMessage({
          message: t("common.isImage"),
          type: "error",
        });
      }
      if (!isLt2M.value) {
        ElMessage({
          message: t("common.isLt2M"),
          type: "error",
        });
      }
      imgState = isImage.value && isLt2M;
      return isImage.value && isLt2M;
    };

    // 修改密码
    const changeUserInfo = (type) => {
      router.push({ path: "changeUserInfo", query: { type } });
    };

    const formRules = reactive({
      nickName: [
        {
          required: true,
          message: `${t("groupSignature.pleaseEnter")}${t(
            "personalCenter.userInfo.nickName"
          )}`,
          trigger: "change",
        },
      ],
    });

    const loadingState = reactive({
      loginNameBtnLoading: false, // 修改用户名
    });

    // 验证表单是否通过
    let RefForm = ref();
    const updUserInfo = () => {
      RefForm.value.validate((valid) => {
        if (valid) {
          loadingState.loginNameBtnLoading = true;
          const info = {
            nickName: data.userInfo.nickName,
          };
          // 调用接口
          updateGetUser(info);
        } else {
          return false;
        }
      });
    };

    // 修改用户信息
    const updateGetUser = (info) => {
      api
        .updateGetUser(info)
        .then((res) => {
          if (res) {
            ElMessage({
              message: t("common.updSuccess"),
              type: "success",
            });
            // 修改头像
            if (info.avatar) {
              data.userInfo.avatar = info.avatar;
            }
            loadingState.loginNameBtnLoading = false;
            store.dispatch("getUserData"); // 重新获取用户信息
          } else {
            loadingState.loginNameBtnLoading = false;
          }
        })
        .catch(() => {
          loadingState.loginNameBtnLoading = false;
        });
    };

    // 退出登录
    const loginOut = () => {
      ElMessageBox.confirm(
        `${t("elMessageBoxText.desc")}`,
        `${t("elMessageBoxText.title")}`,
        {
          confirmButtonText: `${t("elMessageBoxText.confirmButtonText")}`,
          cancelButtonText: `${t("elMessageBoxText.cancelButtonText")}`,
          type: "info",
        }
      )
        .then(() => {
          // if (!localStorage.getItem("token")) {
          //   router.push({ path: "/", query: router.currentRoute.path });
          // } else {

          // }
          api.logout().then((res) => {
            if (res) {
              router.push({ path: "/" });
              localStorage.removeItem("userInfo")
              localStorage.removeItem("token")
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // 获取用户信息
    const getUserInfo = () => {
      api.userInfo().then((res) => {
        data.userInfo = res;
        localStorage.setItem("userInfo", JSON.stringify(res));
      });
    };

    onMounted(() => {
      // 获取国家-区号
      store.dispatch("getAreaCodeData");
      getUserInfo();
    });

    return {
      loginType,
      titleIndex,
      imgState,
      ...toRefs(data),
      ...toRefs(loadingState),
      handleAvatarSuccess,
      beforeAvatarUpload,
      loginOut,
      changeUserInfo,
      updUserInfo,
      RefForm,
      formRules,
    };
  },
};
</script>

<style scoped lang="scss">
.form-style {
  margin-top: 42px;
  margin-left: 40px;
  :deep .el-form-item {
    margin-bottom: 28px;
  }
  // :deep .el-form-item__label {
  //   width: 140px;
  //   text-align: right;
  // }
  :deep .el-button.is-disabled {
    margin-right: 36px;
  }

  .flex {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .login-back {
    width: 100px;
    height: 40px;
    background: #f5a623;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 0px;
    margin-left: 36px;
    cursor: pointer;
  }

  .login-type {
    width: 100px;
    height: 40px;
    opacity: 1;
    background: #f2f4f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 40px;
    letter-spacing: 0px;
  }

  .head {
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }

  .title2 {
    width: 110px;
    font-size: 18px;
    font-weight: 900;
    text-align: left;
    color: #444444;
  }

  .title {
    width: 110px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #444444;
  }

  .el-input {
    width: 355px;
  }

  .el-select {
    width: 355px;
  }
}

.top {
  border-bottom: 1px solid #e6e9f4;
  display: flex;
  padding: 24px 0 0 41px;

  .marginRight40 {
    margin-right: 40px;
  }

  .title-check {
    height: 34px;
    color: #444444;
    line-height: 24px;
    border-bottom: 4px solid #f5a623;
    font-size: 18px;
    font-weight: 900;
    color: #444444;
    cursor: pointer;
  }

  .title-default {
    height: 34px;
    color: #444444;
    line-height: 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #999999;
  }
}

.backgound {
  width: 1058px;
  min-height: 838px;
  background: #ffffff;
  margin-left: 22px;
  padding-bottom: 22px;
}
.chang-phone-btn {
  margin: 0 12px 0 20px;
}
.user-avatar {
  position: relative;
}
.user-avatar-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 0px 4px 16px 0px rgba(179, 192, 231, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}
.mobile-item {
  span {
    height: 38px;
    width: 82px;
    display: inline-block;
    text-align: center;
    margin-right: 13px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
  }
  .el-select {
    height: 38px;
    width: 120px;
    display: inline-block;
    text-align: center;
    margin-right: 13px;
  }
  .el-input {
    width: 257px;
  }
}
</style>
