<template>
  <div class="">
    <div class="bottom" :style="{ background: background }">
      <div class="link">
        <div class="bottom-bottom">
          <div class="bottom-top">
            <div
              v-for="(item, index) in $enData.bottomLink"
              :key="index"
              class="list"
              @click="$router.push(item.router)"
            >
              <!-- {{ item.title }} -->
              {{ $t("contactLink."+item.key) }}
            </div>
          </div>
          <div>
            <div class="bottom-bottom-title">
              {{ $t("contact.bottomTitle") }}
            </div>
            <div class="desc" v-if="contactUs">
              <div>{{ JSON.parse(contactUs.address)[$i18n.locale] }}</div>
              <div>Phone: {{ contactUs.phone }}</div>
              <div>Mobile: {{ contactUs.mobile }}</div>
              <div>E-mail: {{ contactUs.email }}</div>
              <div>{{ contactUs.emails }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bq">{{ $t("common.copyright") }}</div>
    </div>
  </div>
</template>

<script>
import api from "@/comm/api";
export default {
  props: {
    background: {
      type: String,
      default: "#f5f5f5",
    },
  },
  data() {
    return {
      
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取广告数据
    getData() {
      api.getAdvertisingPage({ id: "6" }).then((res) => {
        if (res) {
          this.contactUs = res;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

.bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;
  // background-color: #f3f3f3;
  .bq {
    width: 100%;
    height: 88px;
    opacity: 1;
    background: #2a2a2c;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 88px;
  }
  .link {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    margin-top: -80px;
    .bottom-bottom {
      display: flex;
      width: 70%;
      justify-content: space-between;
      padding-bottom: 46px;
      z-index: 2;
      
      .desc {
        font-size: 18px;
        font-weight: 500;
        color: #444444;
        line-height: 36px;
      }
    }
    .bottom-top {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
      z-index: 2;
      .list {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
        width: 49%;
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .list:hover {
    color: #ffce7b !important;
  }
  .link:after {
    width: 140%;
    height: 100%;
    position: absolute;
    left: -20%;
    top: 0;
    z-index: 1;
    content: "";
    background: linear-gradient(#beb9b2, #beb9b2);
  }

  .contact {
    z-index: 2;
    .right {
      flex: 1;
      .submit {
        width: 152px;
        height: 42px;
        opacity: 1;
        background: #f1d7b5;
        border-radius: 21px;
        font-size: 24px;
        font-weight: 900;
        text-align: center;
        color: #444444;
        line-height: 42px;
        //margin-top: 30px;
        cursor: pointer;
      }
      .right-title {
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        margin: 18px 0 14px 0;
      }
      //margin-left: 65px;
      :deep .el-form-item__label {
        line-height: 60px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }
      .el-input .el-input__inner {
        background: #e3e0dc;
        height: 195px !important;
      }
      :deep(.el-input__inner) {
        background: #e3e0dc;
        height: 60px;
      }
      :deep(.el-textarea__inner) {
        background: #e3e0dc;
      }
    }
    padding: 0 25px;
    width: 1270px;
    height: 716px;
    opacity: 1;
    background: #f7f4ef;
    border-radius: 12px;
    display: flex;
    align-items: center;
    img {
      width: 533px;
      height: 533px;
    }
  }
  .title {
    width: 230px;
    background: rgba(245, 166, 35, 0.2);
    border-radius: 3px;
    margin-bottom: 70px;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    color: #000000;
  }
}
.demo-ruleForm {
  //:deep .el-form-item {
  //  margin-bottom: 24px;
  //}
  :deep .el-input__inner {
    font-size: 15px;
    color: #999999;
  }
  :deep .el-form-item__error {
    font-size: 15px;
  }
  :deep .el-form-item__label:before {
    display: none;
  }
}
.common-title {
  margin-bottom: 71px;
}
.common-title-bg {
  width: 236px;
  margin-left: -170px;
}
</style>
