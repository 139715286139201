<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    const query = this.$route.query;
    if (query && query.inv) {
      const inv = query.inv;
      localStorage.setItem("inv", inv);
    }
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 450px;
}
</style>
