<template>
  <div id="app" style="min-width: 1400px !important">
    <router-view></router-view>
    <div @click="$router.push({ path: '/weChat' })" v-if="showTop" class="top-btn">
      <!-- <i class="iconfont icon-arrow-top1"></i> -->
      <img class="wechatIcon" src="../static/images/wechat.png" alt="" />
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import api from "./comm/api.js";
export default {
  name: "App",
  data() {
    return {
      showTop: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
    !localStorage.getItem("local") ? localStorage.setItem("local", "US") : "";
    api.topMenu().then((res) => {
      localStorage.setItem("menuItem", JSON.stringify(res));
    });
  },
  methods: {
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 200 ? (this.showTop = true) : (this.showTop = false);
    },
    // 回到顶部
    goTop() {
      util.toTop();
    },
  },
};
</script>

<style>
/* @import "./font/font.css";
@import "./font/md.css"; */
@import "./assets/css/iconfont/iconfont.css";
@import "./assets/comIcon/iconfont.css";

/* 轮播 左右切换按钮效果  */
/* .el-carousel__arrow--left{
  left: 0 !important;
}
.el-carousel__arrow--right{
  right:0 !important;
}
 */
/* 轮播 左右切换按钮效果  */
.el-carousel__arrow {
  z-index: 9 !important;
}

html {
  height: 100%;
  min-width: 1280px;
}

#app {
  padding: 0;
  margin: 0;
  height: 100%;
  min-width: 1280px;
}

body {
  margin: 0;
  height: 100%;
  padding: 0 !important;
  min-height: 100%;
  /* overflow-x: scroll !important; */
  position: relative;
  min-width: 1280px;
}

.top-btn {
  width: 117px;
  height: 120px;
  border-radius: 50%;
  position: fixed;
  bottom: 55px;
  right: -3px;
  z-index: 999;
  line-height: 54px;
  text-align: center;
  cursor: pointer;
}

.top-btn img {
  width: 86px;
  height: 86px;
}

.icon-arrow-top1 {
  color: #fff;
  font-size: 25px;
}

/* body::-webkit-scrollbar {
  display: none;
  width: 0;
} */
.el-popup-parent--hidden {
  overflow: scroll !important;
}

/* p {
  font-size: 12px;
} */

.top-image {
  width: 100%;
  height: 236px;
  display: block;
}

img {
  vertical-align: buttom;
}

img {
  vertical-align: middle;
}

img {
  vertical-align: top;
}
</style>
