<template>
  <div class="top-search-box">
    <div class="search-wrap" :style="style">
      <!--广告位-->
      <Advertises
        v-if="advertises && advertises.length > 0"
        :list="advertises"
      />

      <div
        class="search"
        :style="{ 'background-color': backgroundColor }"
        v-if="!searchShow"
      >
        <router-link to="/" class="back-home"
          ><img src="../../../static/images/logo.png" class="logo"
        /></router-link>
        <div class="top">
          <div class="top-select">
            <div class="title">
              <div
                :class="selectIndex == 1 ? 'border-bottom' : ''"
                style="cursor: pointer"
                @click="goMeau(1)"
              >
                CHINA TOURS
                <span
                  :class="selectIndex != 1 ? '' : 'tabActive'"
                  class="iconfont icon-nav-jian-tou"
                  style="cursor: pointer"
                  @click.stop="selectIndexClick(1)"
                ></span>
                <!-- <img v-if="selectIndex!=1" class="jiantou" src="../../../static/images/food/jiantou.png" alt="" 
                @click.stop="selectIndexClick(1)"
                style="cursor: pointer">
                <img v-else class="jiantou" src="../../../static/images/food/jiantou1.png" alt="" 
                @click.stop="selectIndexClick(1)"
                style="cursor: pointer"> -->
              </div>
            </div>
            <div class="title margin">
              <div
                :class="selectIndex == 2 ? 'border-bottom' : ''"
                style="cursor: pointer"
                @click="goMeau(2)"
              >
                CHINESE VISA
                <span
                  :class="selectIndex != 2 ? '' : 'tabActive'"
                  class="iconfont icon-nav-jian-tou"
                  style="cursor: pointer"
                  @click.stop="selectIndexClick(2)"
                ></span>
                <!-- <img v-if="selectIndex!=2" class="jiantou" src="../../../static/images/food/jiantou.png" alt="" 
                @click.stop="selectIndexClick(2)"
                style="cursor: pointer">
                <img v-else class="jiantou" src="../../../static/images/food/jiantou1.png" alt="" 
                @click.stop="selectIndexClick(2)"
                style="cursor: pointer"> -->
              </div>
            </div>
            <div class="title">
              <div
                :class="selectIndex == 3 ? 'border-bottom' : ''"
                style="cursor: pointer"
                @click="goMeau(3)"
              >
                WI-FI CARD
                <span
                  :class="selectIndex != 3 ? '' : 'tabActive'"
                  class="iconfont icon-nav-jian-tou"
                  style="cursor: pointer"
                  @click.stop="selectIndexClick(3)"
                ></span>
                <!-- <img v-if="selectIndex!=3" class="jiantou" src="../../../static/images/food/jiantou.png" alt="" 
                @click.stop="selectIndexClick(3)"
                style="cursor: pointer">
                <img v-else class="jiantou" src="../../../static/images/food/jiantou1.png" alt="" 
                @click.stop="selectIndexClick(3)"
                style="cursor: pointer"> -->
              </div>
            </div>
            <transition name="el-zoom-in-top">
              <div
                :class="advertises.length != 0 ? 'modelTop' : ''"
                class="select-check1 right1"
                id="modelWrap"
                v-if="selectIndex != -1 || language"
              >
                <!-- 菜单切换 -->
                <template v-if="selectIndex != -1">
                  <div class="select-title">
                    {{
                      selectIndex == 1
                        ? "CHINA TOURS"
                        : selectIndex == 2
                        ? "CHINESE VISA"
                        : "WI-FI CARD"
                    }}
                  </div>
                  <div class="platform-list">
                    <div
                      v-for="(item, index) in selectIndex == 1
                        ? searchData.studentLife
                        : selectIndex == 2
                        ? searchData.travelServices
                        : searchData.livingInShanghai"
                      :key="index"
                      class="list2"
                      @click="goNext(item.router)"
                    >
                      {{ JSON.parse(item.title)[$i18n.locale] }}
                    </div>
                  </div>
                </template>
                <!-- 语言选择 -->
                <template v-if="language">
                  <div class="languageList">
                    <!-- <div
                      class="languageList_list"
                      style="cursor: pointer"
                      @click="changeLocale('ZH')"
                    >
                      <span>{{ $t("language.chinese") }}</span>
                      <img src="../../../static/images/china.png" alt="" />
                    </div> -->
                    <div
                      class="languageList_list"
                      style="cursor: pointer"
                      @click="
                        changeLocale('US')(($i18n.locale = 'US')),
                          (i18n = 'US'),
                          (gjhSHow = false),
                          window.reload()
                      "
                    >
                      <span>{{ $t("language.English") }}</span>
                      <img src="../../../static/images/english.png" alt="" />
                    </div>
                    <div class="languageList_list" style="cursor: pointer" @click="changeLocale('IT')">
                      <span>{{ $t("language.Italiano") }}</span>
                      <img src="../../../static/images/Italiano.jpg" alt="" />
                    </div>
                    <div class="languageList_list" style="cursor: pointer" @click="changeLocale('ES')">
                      <span>{{ $t("language.Español") }}</span>
                      <img src="../../../static/images/espanol.jpg" alt="" />
                    </div>
                    <div class="languageList_list" style="cursor: pointer" @click="changeLocale('FR')">
                      <span>{{ $t("language.Français") }}</span>
                      <img src="../../../static/images/francais.jpg" alt="" />
                    </div>
                    <div class="languageList_list" style="cursor: pointer" @click="changeLocale('DE')">
                      <span>{{ $t("language.Deutsch") }}</span>
                      <img src="../../../static/images/Deutsch.jpg" alt="" />
                    </div>
                  </div>
                </template>
              </div>
            </transition>
          </div>
        </div>
        <div class="right">
          <div class="search-right" @click="search">
            <img src="../../../static/images/home/search.png" />
          </div>
          <div class="guojihua" v-if="$route.path == '/'">
            <div @click="languageCheck" class="style">
              <!-- <img
                src="../../../static/images/home/internation.png"
                class="img1"
              /> -->
              
              <img v-if="i18n == 'US'" class="img1" src="../../../static/images/english.png" alt="" />
              <img v-if="i18n == 'IT'" class="img1" src="../../../static/images/Italiano.jpg" alt="" />
              <img v-if="i18n == 'ES'" class="img1" src="../../../static/images/espanol.jpg" alt="" />
              <img v-if="i18n == 'FR'" class="img1" src="../../../static/images/francais.jpg" alt="" />
              <img v-if="i18n == 'DE'" class="img1" src="../../../static/images/Deutsch.jpg" alt="" />
              {{ languageSimple[i18n] }}
              <img
                src="../../../static/images/home/jiantou.png"
                alt=""
                class="img2"
                :class="language ? 'checkslanguage' : ''"
              />
            </div>
          </div>

          <div class="user">
            <!--登录状态-->
            <router-link v-if="token" class="user-info" to="/personalCenter">
              <img
                :src="
                  $store.state.user.userInfo.avatar
                    ? $store.state.user.userInfo.avatar
                    : require('../../../static/images/home/head.png')
                "
              />
              <div class="user-name text-overflow-one">
                {{ $store.state.user.userInfo.nickName }}
              </div>
            </router-link>
            <!--未登录状态-->
            <div v-else>
              <router-link to="/login" class="login">{{
                $t("navigation.SignIn")
              }}</router-link>
              <router-link to="/login?login=1" class="register">{{
                $t("navigation.Register")
              }}</router-link>
            </div>

            <router-link v-if="$route.path != '/'" to="/" class="back-home"
              >⬅ {{ $t("navigation.Back") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div id="search-view" class="drawer-box" v-if="searchShow">
      <div class="select-check-search right1">
        <div class="search-box">
          <!--搜索框-->
          <!-- {{ JSON.parse(searchOptions[1].title).ZH }} -->
          <div class="input">
            <div @click="goSearchRouter" class="search-right search-bg">
              <i class="el-icon-search"></i>
            </div>

            <el-select
              v-model="searchKeyWord"
              filterable
              :placeholder="$t('search.PleaseInput')"
            >
              <el-option
                v-for="(item, index) in searchOptions"
                :key="index"
                style="z-index: 9999"
                :label="
                  $i18n.locale == 'ZH'
                    ? JSON.parse(item.title).ZH
                    : JSON.parse(item.title).US
                "
                :value="item.router"
                @click.prevent="$router.push({ path: item.router })"
              >
              </el-option>
            </el-select>
            <div class="search-right" @click="searchShow = false">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Advertises from "@/components/Advertises/Advertises";

export default {
  components: { Advertises },
  props: {
    backgroundColor: {
      type: String,
      default: "rgba(0,0,0,0)",
    },
    topImgShow: {
      type: Boolean,
      default: false,
    },
    advertises: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      drawer: true,
      direction: "ttb",
      i18n: this.$i18n.locale,
      languageSimple:{
        US: 'EN', // 英语
        IT: 'ITA', // 意大利
        ES: 'ES', // 西班牙
        FR: 'FR', // 法语
        DE: 'DE', // 德语
      },
      gjhSHow: false,
      selectIndex: -1,
      searchKeyWord: "",
      searchData: JSON.parse(localStorage.getItem("menuItem")),
      searchOptions: [], // 搜索数据
      searchShow: false, // 是否搜索
      token: localStorage.getItem("token"),
      opacity: 0,
      style: {},
      language: false, //语言弹框
    };
  },
  mounted() {
    // 监听页面是否为首页
    // 获取用户信息
    // this.userInfo = this.$store.state.user.userInfo;
    //监听页面滚动
    window.addEventListener("scroll", this.windowScroll);

    // // 关闭搜索弹框
    // document.addEventListener("mouseup", (e) => {

    //   const searchCon = document.getElementById("search-view");

    //   if (searchCon) {
    //     if (!searchCon.contains(e.target)) {
    //       this.selectIndex=-1
    //       this.searchKeyWord=''
    //       this.searchShow = false;
    //     }
    //   }
    // });
    // 搜索数据
    for (const key in this.searchData) {
      this.searchData[key].forEach((item) => {
        if (item) {
          const data = this.searchOptions.filter(
            (child) => child.title === item.title
          );
          if (data && data.length > 0) {
            return;
          }
          this.searchOptions.push(item);
        }
      });
    }
  },
  methods: {
    goSearchRouter() {
      this.$router.push({ path: this.searchKeyWord });
    },
    //菜单弹框选择
    selectIndexClick(type) {
      this.selectIndex = type == this.selectIndex ? -1 : type;
      this.language = false;
    },
    //选择语言弹框
    languageCheck() {
      this.language = !this.language;
      this.selectIndex = -1;
    },
    //菜单点击跳转
    goMeau(type) {
      this.$router.push({ path: "/meau", query: { type: type } });
      this.selectIndex = -1;
    },
    //页面跳转
    goNext(item) {
      if (item.indexOf("http") == 0) {
        window.open(item);
      } else {
        this.$router.push({ path: item });
      }
    },
    //监听页面滚动
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.opacity = Math.abs(Math.round(scrollTop)) / 250;
      this.style = { background: `rgba(211, 192, 179,${this.opacity})` };
    },
    changeLocale(e) {
      (this.$i18n.locale = e),
        (this.i18n = e),
        (this.gjhSHow = false),
        localStorage.setItem("local", e);
      this.$router.go(0);
    },
    // 显示搜索框
    search() {
      let _this = this;
      _this.drawer = true;
      _this.searchShow = !_this.searchShow;
    },
    handleClose() {
      let _this = this;
      _this.searchShow = false;
      _this.drawer = false;
    },
  },
  onUnmounted() {
    window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
  },
};
</script>

<style scoped lang="scss">
.top-img {
  width: 100%;
  height: 236px;
}

.search-wrap {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.top {
  .top-select {
    height: 80px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    .margin {
      margin: 0 70px 0 70px;
    }

    .title {
      font-size: 20px;
      font-weight: 900;
      color: #222a41;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;
      // height: 40px;
    }

    .border-bottom {
      border-bottom: 3px solid #f7bf62;
      color: #ffce7b;
    }

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }

    .title:hover > div {
      border-bottom: 3px solid #f7bf62;
      color: #ffce7b;

      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translate3d(0, 100%, 0);
        }
      }
    }

    .right1 {
      right: -70px;
    }

    .select-check1 {
      // visibility: visible;
      background: rgba(33, 33, 33, 0.5);
      // background: #ffffff;
      width: 100%;
      // border-radius: 36px;
      top: 0;
      position: absolute;
      left: 0;
      padding-top: 120px;
      padding-bottom: 100px;
      box-sizing: border-box;

      // height: 670px;
      // padding: 30px;
      .select-title {
        font-size: 30px;
        font-weight: 900;
        color: #ffffff;
        margin-left: 520px;
      }

      .platform-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 50%;
        margin-left: 520px;

        .list1 {
          width: 40%;
          font-size: 22px;
          font-weight: 900;
          color: #ffffff;
          margin-top: 30px;
          cursor: pointer;
        }

        .list2 {
          width: 50%;
          font-size: 22px;
          font-weight: 900;
          color: #ffffff;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }

    .select-check-search {
      background: rgba(33, 33, 33, 0.5);
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 670px;
      z-index: 9999;
    }
  }
}

.list2:hover {
  color: #ffce7b !important;
}

.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 8px 0 8px 0;
  padding: 8px 152px;
  box-sizing: border-box;

  .logo {
    height: 64px;
    mix-blend-mode: multiply;
  }

  .right {
    display: flex;
    align-items: center;

    .guojihua {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
      position: relative;
      margin-left: 20px;

      .style {
        display: flex;
        align-items: center;
        background: #f5ad34;
        border-radius: 27px;
        padding: 12px 15px;
        cursor: pointer;
      }

      .guojihua-check {
        cursor: pointer;
        width: 100%;
        position: absolute;
        left: 0;
        top: 60px;
        color: rgb(46, 45, 45);
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 21px 0px rgba(60, 78, 105, 0.22);
        text-align: center;
        line-height: 20px;
        font-size: 20px;
        line-height: 35px;
      }

      .img1 {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .img2 {
        width: 20px;
        margin-left: 10px;
      }
    }

    .search-right {
      width: 48px;
      height: 48px;
      background: #f5ad34;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // margin-right: 20px;

      img {
        width: 22px;
        height: 22px;
      }
    }

    .el-input {
      width: 320px;
    }

    .user {
      display: flex;
      align-items: center;

      .login {
        font-size: 22px;
        font-weight: 900;
        color: #444444;
        margin: 0 24px;
      }

      .back-home {
        height: 48px;
        max-height: 48px !important;
        font-size: 20px;
        font-weight: 900;
        color: #fff;
        margin-left: 10px;
        background: #f5ad34;
        border-radius: 24px;
        line-height: 40px;
        padding: 5px 8px;
        border-radius: 20px;
        white-space: nowrap;
      }

      .register {
        background: #f5ad34;
        border-radius: 8px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;
        padding: 12px 33px;
        border-radius: 100px;
      }

      img {
        width: 54px;
        height: 54px;
        margin: 0 15px 0 20px;
        border-radius: 100px;
      }

      .user-name {
        max-width: 120px;
        font-size: 16px;
        font-weight: 500;
        color: #444444;
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-top {
  width: 100%;
  height: 670px;
  background-size: cover;
  background: rgba(33, 33, 33, 0.5);
  position: relative;
}

.search-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.input {
  height: 54px;
  width: 850px;
  background: #fff;
  border-radius: 100px;
  overflow: hidden;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-input {
    width: 85%;
    font-size: 16px;
  }

  .el-select {
    width: 85%;
    font-size: 16px;
  }

  .el-select-dropdown {
    z-index: 99999;
  }

  :deep .el-input__inner {
    border: none;
    background: #fff;
  }
}

.search-bg {
  background: #f5ad34;
}

.search-right {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.el-icon-close {
  font-size: 30px;
  color: #333333;
}

.el-icon-search {
  font-size: 24px;
  color: #fff;
}

.right {
  position: relative;

  .search-input-box {
    background: rgba(33, 33, 33, 0.5);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 120px;
    padding-bottom: 200px;
    height: 670px;
  }
}

.top-search-box {
  position: relative;
}

.drawer-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 670px;
  // z-index: 9999;
  z-index: 2000;
  background: rgba(33, 33, 33, 0.5);
}

.modelTop {
  top: 210px !important;
}

.jiantou {
  width: 16px;
  height: 8px;
  margin-left: 10px;
}

.title > div {
  display: flex;
  align-items: center;
}

.languageList {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 200px;
}

.languageList > div {
  img {
    height: 36px;
  }
}

.languageList > div:first-child {
}

.languageList_list {
  display: flex;
  align-items: center;
  margin-right: 100px;

  span {
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 30px;
    margin-right: 36px;
  }
}

.checkslanguage {
  transform: rotate(180deg) !important;
}

.icon-nav-jian-tou {
  font-size: 20px;
  margin-left: 8px;
}
</style>
