module.exports = {
  language: {
    chinese: "Chinese", //简体中文
    English: "English", //英文
    Italiano: "Italiano", //意大利
    Español: "Español", //西班牙
    Français: "Français", //法语
    Deutsch: "Deutsch", //德语
  },
  contactLink: {
    aboutUs: "Su di noi",
    chineseVisa: "Visto cinese",
    contactUs: "Contattateci",
    insurance: "Assicurazione",
    advertise: "Pubblicità",
    wcic: "Scheda Wi-fi",
    faq: "FAQ",
    vipApu: "Trasporto aeroportuale",
    privacy: "Privacy",
    wechat: "APP",
  },
  commonData: {
    orderStatus: [
      { value: 0, label: "Non pagato" },
      { value: 1, label: "Non pagato" },
      { value: 2, label: "In lavorazione" },
      { value: 3, label: "Completato" },
      { value: 4, label: "Rifiutato" },
      { value: 5, label: "Annullato" },
      { value: 6, label: "Pagato" },
      { value: 7, label: "Rimborsato" },
    ],
    paymentMode: [
      { value: 1, label: "Carta di credito" },
      { value: 2, label: "Paypal" },
      { value: 3, label: "Alipay" },
      { value: 4, label: "Deposito" },
    ],
    type: [
      { value: 1, label: "Visto cinese" },
      { value: 2, label: "Scheda Wi-Fi" },
      { value: 3, label: "Accoglienza Vip Airport" },
    ],
    childType: [
      { value: 1, label: "Visto di studio" },
      { value: 2, label: "Visto di lavoro" },
      { value: 3, label: "China PR" },
      { value: 4, label: "Rinnovo visto di lavoro" },
      { value: 5, label: "Visto e-business" },
      { value: 6, label: "Visto di gruppo" },
      { value: 7, label: "Accoglienza VIP" },
      { value: 8, label: "Trasporto aeroportuale vip" },
      { value: 9, label: "Scheda Wi-Fi" },
    ],
    occupationList: [
      { label: "Impiegato", value: 1 },
      { label: "Imprenditore", value: 2 },
      { label: "Casalinga", value: 3 },
      { label: "Pensionato", value: 4 },
      { label: "Studente", value: 5 },
      { label: "Bambino/a", value: 6 },
    ],
    emergencyContactRelationshipList: [
      { label: "Genitore", value: 1 },
      { label: "Parente", value: 2 },
      { label: "Sorella", value: 3 },
      { label: "Fratello", value: 4 },
      { label: "Amico/a", value: 5 },
      { label: "Coniuge", value: 6 },
      { label: "Figlio/a", value: 7 },
    ],
    punishList: [
      { label: "Si", value: 1 },
      { label: "No", value: 0 },
    ],
    comStatus: [
      { label: "Si", value: 1 },
      { label: "No", value: 2 },
    ],
    gender: [
      { label: "Maschio", value: 1 },
      { label: "Femmina", value: 0 },
    ],
    maritalStatus: [
      { label: "Sposato", value: 1 },
      { label: "Celibe", value: 2 },
      { label: "Divorziato", value: 3 },
      { label: "Vedovo/a", value: 4 },
    ],
    education: [
      { label: "PostDottore", value: "PostDoctor" },
      { label: "Dottorato", value: "Doctorate" },
      { label: "Master", value: "Master" },
      { label: "Laurea", value: "Undergraduate" },
      { label: "Diploma", value: "High school" },
      {
        label: "Al di sotto della scuola secondaria",
        value: "Below high school",
      },
    ],
    phoneType: [
      { label: "China +86", value: 1 },
      { label: "Altri paesi", value: 2 },
    ],
    serviceType: [
      { title: "Normale", desc: "5 giorni lavorativi", value: 1 },
      {
        title: "Urgente",
        desc: "3 giorni lavorativi. Il servizio urgente richiede un supplemento",
        value: 2,
      },
      {
        title: "Extra urgente",
        desc: "2 giorni lavorativi. Il servizio extra urgente richiede un supplemento",
        value: 3,
      },
    ],
    groupServiceType: [
      { title: "Normale", desc: "5 giorni lavorativi", value: 1 },
      {
        title: "Express",
        desc: "3 giorni lavorativi. Il servizio urgente richiede un supplemento",
        value: 2,
      },
    ],
    vipCars: [
      { title: "Business 7 posti", value: 1 },
      { title: "Premium 5 posti", value: 2 },
    ],
    expressType: [
      { title: "Fedex", value: 1 },
      { title: "DHL", value: 2 },
      { title: "SF-EXPRESS", value: 3 },
      { title: "EMS", value: 4 },
    ],
  },
  common: {
    copyright: "©2021 Hereshanghai.com, All Rights Reserved.",

    collectSuccess: "Salvato con successo", // 收藏成功
    cancelSuccess: "Annullato", // 已取消收藏
    cancelCollection: "Cancella", // 取消收藏
    collectFail: "Insuccesso", // 收藏失败
    unit: "",
    month: "Mese",
    create: "Primo post", // 首次发布于
    update: ", Ultimo aggiornamento", // 最后更新于
    Viewed: " · Visualizzato", // 被浏览
    times: " Volte", // 次
    isImage: "L'immagine deve essere in formato GIF、JPG、PNG!", // 图片限制
    isLt2M: "La dimensione dell'immagine non deve superare i 2MB!", // 大小限制
    success: "La richiesta e' stata inviata con successo", // 申请成功
    payFail: "Pagamento fallito", // 付款失败
    paySuccess: "Pagamento con successo", // 付款成功
    updSuccess: "Modificato con successo", // 修改成功
    loginAgain: "Prego rifare il login!", // 请重新成功
    updSubmit: "Conferma", // 确定修改
    codeError: "Codice di verifica errata", // 验证码错误
    username: "Nome utente", // 用户名
    FocusOnSuccess: "Segui", //关注成功
    AttentionCancelled: "Annullato", //已取消关注
    PublishedSuccessfully: "Pubblicato con successo", //发布成功
    delete: "Cancella", // 删除
    deleteSuccess: "Cancellato", // 删除成功
    Redact: "Modifica", // 编辑
    Note: "Nota", // 注意
    NotLoggedIn: "Prego fare il Login", //未登录
    Deleted: "Cancellato", //已删除
    Rejected: "Respinto", //已驳回
    noVisa: "Non e' possibile fare la richiesta per la nazionalita' scelta", //该国籍不能办理
    noCity: "Non forniamo accoglienza VIP per la citta' selezionata", //该城市没有Vip接机包车
    sample: "Visualizza esempio",
    aggrement: "Termini e condizioni generali/Informativa sulla Privacy",
    printInvoice: "Stampa ricevuta",
  },
  // 头部导航菜单
  navigation: {
    Back: "Home page",
    SignIn: "Accedi",
    Register: "Registrati",
    // 这里英文也需要更改
    studentLife: "TOUR CINA", // 英文: CHINA TOURS  意大利语：TOUR CINA
    visaTravelServices: "VISTO CINESE ONLINE", // 英文：CHINA ONLINE VISA  意大利语：VISTO CINESE ONLINE
    livingInShangHai: "IN SHANGHAI", // 英文：IN SHANGHAI 意大利语：IN SHANGHAI
  },
  message: {
    success: "con successo",
    registerSuccess: "registrazione con successo",
    title: "Registrazione con successo",
    desc: "Prego tenere al sicuro i dati del vostro account",
    goHome: "Vai alla prima pagina",
  },
  // 重置密码
  resetPw: {
    title: "Ripristina la password dimenticata",
    password: "Impostare una password di 6-20 caratteri",
    next: "Procedere",
  },
  //登录
  login: {
    EmailLogin: "Email", // 邮箱登录
    Email: "Email",
    MobileNumber: "Numero di telefono", //短信登录
    accountNumber: "Email", //账号
    Password: "Password", //密码
    PleaseEnterYourEmailAddress: "Inserire il tuo indirizzo email", //请输入邮箱
    PleaseEnterYourPassword: "Inserire la password", //'请输入密码'
    MobilePhone: "Numero di telefono (solo per i numeri di telefono cinesi)", //手机号
    PleaseEnterYourPhone: "Inserisci il tuo numero di telefono", //请输入手机号
    Authcode: "Codice di autorizzazione", //验证码
    PleaseEnterTheAuthCode: "Inserire il Codice di autorizzazione", //请输入验证码
    GetCode: "Ottieni il codice", //获取验证码
    SignIn: "Accedi", //登录
    StayloggedIn: "Rimani connesso", //记住登录状态
    Register: "Registrati", //注册新用户
    ForgotPassword: "Password dimenticata?", //忘记密码
    Individual: "Privato", //个人
    CompanyOrAgency: "Azienda/Agenzia", //公司
    Company: "Azienda/Agenzia", //公司
    sendCode: "Inviato con successo", //send successfully
  },
  //注册
  register: {
    validateRealName: "Nome e cognome", //验证姓名
    validatePhone: "Numero di telefono", //验证手机号
    validatePhoneInspect: "Controllare se il numero di telefono e' corretto", //检查手机号是否正确
    validateNewPhone:
      "Il nuovo numero di telefono non puo' essere lo stesso a quello precedente", //验证手机号
    validateEmail: "Inserire il tuo indirizzo email",
    validateEmailInspect: "Inserire l'indirizzo email corretto",
    validateEmailCode: "Inserire il codice di verificazione dell'email",
    validateCountry: "Nazionalità",
    validatePassword: "Inserire una password",
    validatePasswordAgin: "Conferma la password",
    validatePasswordAtypism: "Le due password non sono coerenti",
    validateNickName: "Nome utente",
    validatePhoneCode: "Inserire il codice di verifica SMS",

    validateCompanyName: "Nome dell'azienda/agenzia",
    validateCompanyType: "Tipologia di azienda",
    validateCompanySn: "IVA",
    validateCompanyWebsite: "Sito web dell'azienda",
    validateCompanyAddress: "Indirizzo dell'azienda",
    validateCompanyPost: "Codice postale",
    validateCompanyCountry: "Selezionare paese",
    validateCompanyCity: "Citta",
    validateCompanyContact: "Nome utente",

    EmailRegister: "Registrazione via email", //邮箱注册
    MobileNumberRegister: "Registrazione con numero di telefono", //手机号注册
    checkBox: "Accetto ", //勾选同意 《用户服务协议》
    checkBoxText: "Termini e condizioni generali/Informativa sulla Privacy",
    checkBoxUserAgreement: "Condizioni per l'utilizzo",
    AlreadyRegisteredSignIn: "Sei gia' registato? Accedi", //已有账号，立即登录
    Register: "Registrati", //注册
    PleaseSelect: "Seleziona", //请选择
    PleaseInput: "Inserisci", //请输入
    Email: "Email", //邮箱
    AuthCode: "Codice di autorizzazione", //确认邮箱
    Password: "Password", //密码
    ConfirmedPassword: "Conferma password", //确认密码
    CompanyName: "Nome della societa", //公司名称 '
    Typology: "Tipologia", //公司类型
    VATTax: "IVA", //税号
    Website: "sito website", //网址
    ContactPerson: "Persona di riferimento", //联系人
    PhoneNumber: "Numero di telefono", //电话号码
    Address: "Indirizzo", //地址
    PostalCode: "Codice postale", //邮编
    Country: "Paese", //国家
    City: "Citta", //城市 '
    Account: "Utente", //用户名
    GetCode: "Ottieni il codice", //获取验证码

    Name: "Nome e cognome", //真实姓名
    MobileNumber: "Numero di telefono", //电话号码
    Nationality: "Nazionalità", //国籍 '
    MobileNumberchina: "Numero di telefono cinese", //手机号(中国)
    yam: "Codice di autorizzazione", //验证码
  },
  home: {
    hereStore: "Here-Store",
    FoodRecommendation: "Scheda WI-FI/Internet in Cina", // 这个模块要求删除 替换成英文：WI-FI card/Internet in China 意大利语：Scheda WI-FI/Internet in Cina
    LeisurePlay: "Things to do", // 这个模块要求删除
    visa: "Visto",
    Journalism: "Notizie",
    ContactUs: "Contattaci",
    AcceptanceOrganization: "Organizzazione per l'accettazione",
    AcceptanceOrganizationInfo:
      "L'Amministrazione statale per l'immigrazione affida all'amministrazione per l'ingresso e l'uscita degli organi di pubblica sicurezza a livello di prefettura o superiore (compresi i distretti e le contee sotto la giurisdizione della municipalità che dipende direttamente dal governo centrale) il compito di accettare i permessi di ingresso e uscita degli stranieri. Su approvazione dell'Amministrazione statale per l'immigrazione e su istruzione dell'amministrazione per l'ingresso e l'uscita dell'organo di pubblica sicurezza provinciale, l'amministrazione per l'ingresso e l'uscita dell'organo di pubblica sicurezza a livello di contea può accettare il permesso di ingresso e uscita degli stranieri su incarico dell'Amministrazione statale per l'immigrazione.",
  },
  contact: {
    email: "Email", //邮箱
    name: "Nome e Cognome", //姓名
    nationality: "Nazionalità", //国籍 '
    title: "Titolo", //标题
    content: "Testo", //内容
    verification: "Verificazione", //验证
    submit: "Invia",
    insertEmail: "Indirizzo email",
    insertEmail2: "Indirizzo email",
    insertName: "Nome e cognome",
    insertTitle: "Titolo",
    insertContent: "Informazioni",
    insertCountry: "Nazionalità",
    slidingValidation: "Tenere premuto e trascinare all'estrema destra",
    validateEmailCode: "Inserire l'email corretto",
    validateLengthCode: "La lunghezza minima e' 2",
    validateLengthContent: "La lunghezza dovrebbe essere compresa tra 10 e 150",
    bottomTitle: "HereShanghai",
    bottomDesc:
      "Shanghai Orange & Orange Travel Agency Co., Ltd. - 251 YaoHua Rd.Free-Trade Zone Shanghai (China) Telefono: +86 21 61806991 Cellulare: +86 16601891870 E-mail: info@chinavisadirect.com info@hereshanghai.com",
  },
  groupSignature: {
    insertEmail: "Indirizzo email",
    tipBeCare: "Nota: ",
    // 确定团员信息注意
    submitBeCareFul: {
      title:
        "Si prega di controllare i dati di ogni membro, il visto verrà rilasciato in base ai dati inserite.",
    },
    // 查看团员信息注意
    detailsBeCareFul: {
      title:
        "Si prega di controllare i dati di ogni membro, il visto verrà rilasciato in base ai dati inserite.",
    },
    // 申请成功注意
    successBeCareFul: {
      title:
        "Nota: La richiesta è stata inoltrata, grazie! Dopo aver controllato la pratica, vi manderemo una e-mail di conferma.",
    },
    // 支付后的状态提示
    payAfter: {
      successText:
        'La richiesta è stata inoltrata, puoi controllare lo stato della pratica nel proprio account su "I miei ordini".',
    },
    // 常规注意
    beCareFul: {
      title: `
        Il visto di gruppo viene rilasciato a un gruppo composto da <span style="font-weight: 900">almeno 2 membri</span> e massimo 8 membri.<br />
        Compilare ed allegare i dati del primo passeggero, per poi compilare quelli del secondo e terzo... pax.<br />
        Cose da sapere prima di richiedere il visto:
        Il visto di gruppo viene applicato su un unico certificato per l'intero gruppo, anziché sul passaporto individuale. Tutti i membri del gruppo devono viaggiare insieme sia in entrata che in uscita dalla Cina.
      `,

      infoTitle: "Da sapere prima di richiedere il visto:",
      infoDesc:
        "Il visto di gruppo viene applicato a un unico certificato per l'intero gruppo, anziché su un passaporto individuale. Tutti i membri del gruppo devono viaggiare insieme sia in entrata che in uscita dalla Cina.",
    },
    // 学习签证注意提示
    comTipBeCareFul: {
      title:
        "Dopo aver ricevuto la tua richiesta, il nostro staff vi contatterà via e-mail per informarvi sui documenti da fornire per richiedere il visto.", // 一个订单只能申请一个人。
      success:
        "La richiesta è stata inoltrata, grazie! Dopo aver controllato la pratica, vi manderemo una e-mail di conferma.", // 申请成功提示
    },
    uploadInfo: {
      format: "Formati supportati: GIF/JPG/PNG/PDF", // 支持格式
      success: "Caricamento riuscito", // 上传成功
      see: "Visualizza", // 点击查看
      uploadText: "Fare clic per caricare", // 上传文件
    },
    tipText: {
      voluntarily: "Nota: Verificare i dati personali!", // 自动填充
    },
    essentialInformation: "Informazioni personali", //基本信息
    personnelInformation: "Dati personali del passeggero", //人员信息
    otherInformation: "Altre informazioni", //其他信息
    entryInformation: "Informazioni di viaggio", //入境信息
    uploadInformation: "Caricamento documenti", //上传信息
    confirmationInformation: "Verifica e Conferma", //确认信息
    serviceMode: "Tipo di servizio", //服务方式
    payment: "Pagamento", //付款
    applicationStatus: "Stato della pratica", //申请状态
    pleaseEnter: "Inserisci", //请输入
    pleaseSelect: "Seleziona", //请选择
    confimNex: "Conferma e procedi",
    memberTitle: "Aggiungi passeggero：", // 已添加成员
    rules: {
      passport: "Carica copia del passaporto", // 请上传护照
      vipTicket: "Carica copia dei biglietti di viaggio", // 请上传往返机票
      ticket: "Carica copie dei biglietti aerei/treni", // 请上传往返机票/火车票照片
      hotelOrder: "Carica copia delle prenotazioni alberghiere", // 请上传酒店订单
      englishName: "È possibile inserire solo le lettere maiuscole", // 只能输入2至20位英文
      idCard: "Carica copia della fototessera", //请上传证件照
      vaccinationCertificate: "Carica copia del certificato di vaccinazione", //请上传疫苗接种证明
      invitationLetter: "Carica copia della lettera di invito.", //请上传邀请函
      companyLicense: "Carica copia della licenza della compagnia cinese", //请上传中国公司营业执照
      deposit: "Caricare la ricevuta del trasferimento bancario", //请上传押金凭证
    },
    submitInfo: {
      name: "Nome e Cognome", //英文姓名
      No: "No.", //序号
      seeDetails: "Visualizza i dettagli", //查看详情
      passengersInfo: "Informazioni sui passeggeri", //团成员信息
    },
    informationForm: {
      //基本信息表单
      SerialNumber: "No.", //序号
      passport: "Carica la copia del passaporto (pagina con info)", //护照识别
      EnglishSurname: "Cognome", //英文姓
      EnglishName: "Nome", //英文名
      ChineseName: "Nome cinese", //中文名
      nationality: "Nazionalità", //国籍  '
      Gender: "Sesso", //性别
      dateBirth: "Data di nascita", //出生日期
      birthplace: "Luogo di nascita", //出生地
      placeIssue: "Luogo di rilascio", //护照签发地
      certificatesNumber: "Numero passaporto", //所持证件号码
      passengersEmail: "Indirizzo email del passeggero", //所持证件号码
      CertificateDate: "Data di rilascio", //所持证件签发日期
      CertificateDateValid: "Data di scadenza", //所持证件有效日期
      occupation: "Professione", //职业
      emergencyContact: "Nome del referente di emergenza", //紧急联系人
      emergencyContactRelationship: "Rapporto con il referente", //与紧急联系人关系
      EmergencyContactInformation: "Numero di telefono per le emergenze", //紧急联系人方式
      punish: "Ha precedenti penali in Cina o in altri paesi?", //曾在中国因违法被处罚的情况
      OtherNameInChina:
        "È il tuo primo passaporto o ne hai avuto un altro in passato? (primo passaporto/avevo un vecchio passaporto)?", //出境入境中国使用的其他名字
      OtherNationalities: "Hai altre nazionalità'?", //具有其他国籍
      getShop: "Destinatario",
      maritalStatus: "Stato civile", //婚姻状况
      SpouseName: "Nome del coniuge", //配偶姓名
      spouseDate: "Data di nascita del coniuge", //配偶出生日期
      SpouseNationality: "Nazionalita' del coniuge", //配偶国籍
      ResidenceSpouse: "Indirizzo del coniuge", //配偶居住地
      SpouseContactNumber: "Numero di telefono del coniuge", //配偶联系电话
      HighestEducation: "Il tuo titolo di studio", //最高学历
      SchoolName: "Nome dell'istituto", //学校名称
      NameUnitOrganization: "Nome della ditta", //单位/机构名称,
      OverseasGroupAgency: "Nome dell'agenzia di viaggio", //境外组团社
      otherCountriesVisited:
        "Paesi visitati negli ultimi 2 anni (escluto la Cina)", //近2年访问过的其他国家（中国除外）
      ExpressAddress: "Indirizzo postale", //快递地址
      TelephoneNumber: "Numero di telefono", //电话号码
      addressInChina: "Indirizzo in Cina", //在华地址
      CarPassengers: "Passeggeri in auto (max 4 pax)", //坐车人数（最多为4人）
      DestinationAddress: "Indirizzo di destinazione (solo Shanghai)", //包车目的地的地址
      expressCountry: "Paese", //国家
      expressCity: "Citta", //城市  '
      expressStreet: "Via e numero civico", //路名
      expressPostcode: "Codice postale", //邮编
      contactAddress: "Indirizzo", //系地址
      consignee: "Nome del destinatario", //收货人
    },
    entry: {
      //入境表单
      enterCity: "Citta' di arrivo (Cina)", //入境城市
      outCity: "Citta' di partenza (Cina)", //出境城市
      enterDate: "Data di arrivo in Cina", //入境日期
      outDate: "Data di partenza dalla Cina", //出境日期
      inboundFlightNo: "Numero di volo per la Cina", //入境航班
      inboundAirport: "Aereoporto di entrata in Cina", //入境机场
      carsType: "Tipo di auto", //服务车型
    },
    upload: {
      vipTicket: "Biglietti di viaggio", //往返机票
      ticket: "Biglietto di viaggio (aereo/treno/pullman/nave)", //往返机票/火车票
      hotelOrder: "Prenotazioni alberghiere (tutte le notti)", //酒店订单
      idCard: "Fototessera", //证件照
      vaccinationCertificate: "Certificato di vaccinazione", //疫苗接种证明
      invitationLetter: "Lettera di invito", //邀请函
      companyLicense: "Licenza della ditta cinese", //中国公司营业执照
    },
    confirm: {
      memberInformation: "Info membri", //成员信息
      contactNumber: "Numero di telefono", //联系电话
      newMember: "Aggiungi un altro passeggero", //新增成员信息
      viewDetail: "Visualizza i dettagli", //查看详情
    },
  },
  // 个人中心
  personalCenter: {
    // 我的信息
    userInfo: {
      avatar: "Foto profilo", // 头像
      nickName: "Nickname", // 昵称
      loginName: "Utente", // 用户名
      name: "Nome e Cognome", // 真实姓名
      companyName: "Nome della societa", // 公司名称  '
      companyType: "Tipologia di azienda", // 公司类型
      vatTax: "IVA", // 税号
      website: "Sito website", // 网址
      email: "Email", // 注册邮箱
      contactPerson: "Persona di riferimento", // 联系人
      phone: "Numero di telefono", // 绑定手机号
      mobileNumber: "Numero di telefono", // 电话号码
      password: "Password", // 密码
      nationality: "Nazionalità", // 国籍  '
      address: "Indirizzo", // 地址
      city: "Citta", // 城市  '
      postalCode: "Codice postale", // 邮编
      state: "Stato di accesso", // 登录状态
    },
    // 我的信息-按钮
    userInfoBtn: {
      changePhone: "Cambiare numero cellulare", // 更换绑定
      changePassword: "Ripristinare la password dimenticata",
      changePhoneTag: "(Solo numeri di telefono cinesi)", // （只限制中国手机号）
      login: "Accedi", // 登录
      logout: "Disconnetti", // 退出登录
      placeholderInput: "Inserisci ", // 请输入,
      newMobileNumber: "Nuovo numero di telefono", // 新手机号
      newPassword: "Nuova password", // 新密码
      confirmedPassword: "Conferma nuova password", // 确认新密码
      confirmedPasswordInput: "Riconferma la nuova password", // 请再次确认新密码
      submit: "Inviato", // 完成
    },
  },
  // 搜索
  search: {
    PleaseInput: "Inserisci",
  },
  // 提示语
  messageText: {
    groupSignature:
      "Il visto di gruppo deve essere richiesto per almeno 2 persone.", // 团签至少需要两个人才能提交
  },
  //hereStroe
  hereStroe: {
    buyNow: "Compra", //立即购买
    address: "Indirizzo postale", //收货地址
    addressInput: "Compila l'indirizzo di consegna", //请先填写收货地址
    addAddress: "Aggiungi indirizzo postale", //添加收货地址
    nameInput: "Destinatario", //收货人
    phoneInput: "Numero di telefono del destinatario", //收货人电话
    postAddress: "Indirizzo postale", //快递地址
    pleaseSelect: "Seleziona", //请选择
    PleaseInput: "Inserisci", //请输入
    streetAndNumber: "Via e numero civico", //请输入路名
    postalcode: "Codice postale", //请输入邮编
    city: "Citta", //请选择城市  '
    country: "Paese", //请选择国家
    confirm: "Conferma", //保存
    Price: "Prezzo", // 费用
    note: "Nota", //注意事项
    processingTime: "Tempo di lavorazione", //处理时间
    dataList: "Lista dei documenti", //材料清单
    OtherContent: "Altro", //其他内容
    ProductDescription: "Descrizione del prodotto", //商品描述
    inventory: "Nessun inventario", // 暂无库存
  },
  // 弹框提示确认
  elMessageBoxText: {
    title: "Out to determine", // 退出确定
    releaseTitle: "Out to release", // 退出发布
    desc: "Questa operazione comporta il logout. Vuoi continuare?", // 此操作将退出登录, 是否继续?
    MyArticleOpinionDesc:
      "Questa operazione cancellerà il commento. Vuoi continuare?", // 此操作将删除评论, 是否继续?
    delDesc: "Questa operazione cancellerà questi dati. Vuoi continuare?", // 此操作将删除该条数据, 是否继续?
    confirmButtonText: "Conferma", // 确定
    cancelButtonText: "Cancella", // 取消
    visaText: `Sei sicuro che tutte le informazioni siano corrette? In caso affermativo, fare clic su 'conferma'. Nota: in caso di errori, la nostra azienda non si assume alcuna responsabilità".`,
    releaseDesc:
      "L'operazione consente l'uscire dalla pubblicazione. Volete continuare?",
    addDesc: "Volete continuare a rilasciare prodotti?", // 是否要继续发布商品?
    Confirm: "Conferma", // 确定
  },
  //支付
  payMent: {
    Payment: "Paga", //在线支付
    toPayment: "Invia",
    practiceDetails: "Dettagli della pratica", //订单详情
    type: "Tipologia", //产品名称
    OrderDate: "Data ordine", //下单时间
    PracticeNumber: "Numero pratica", //订单编号
    Total: "Totale", //订单金额
    PaymentMethod: "Metodo di pagamento", //支付方式
    PaymentDetails: "Dettagli sul pagamento", //支付信息
    PassengerName: "Nome del passseggero", //申请人姓名
    Price: "Prezzo", //上网卡费用
    ExpressFee: "Tariffa Express", //加急费用
    TotalAll: "Totale", //应付金额
    TotalPayAll: "Totale", //总金额
    Pay: "Paga", //确认支付
    CreditCard: "Carta di credito", //信用卡
    Alipay: "Alipay", //支付宝
    Deposit: "Deposito", //押金
    submit: "Invia", // 提交
    submit2: "Invia", // 提交
    DepositNote: "(occorre verificare con l'agenzia)", //押金提示
    YourRequestIsSubmitted: "La richiesta è stata inoltrata", //付款成功
    saveAndPrevious: "Salva", //确认&上一步
    saveAndOrderInfo: "Conferma e visuallizza", //确认&查看订单
    imgFormat: "È possibile caricare solo file JPG/PNG.", //确认&上一步
    cancel: "Pagamento cancellato",
  },
  // 支付产品类型
  payMentType: {
    wifiCard: "Scheda WI-FI", // 上网卡
    businessVisa: "Visto e-Business", // 商务签证
    studyVisa: "Visto di studio", // 学习签证
    jobVisa: "Visto di lavoro", // 工作签证
  },
  // 留学生协会
  foreignerServiceStation: {
    title: "RISORSE", // 外国人服务站  英文也需要改：RESOURCES  意大利语：RISORSE
    allAreas: "Categoria", // 区域分类
  },
  // 美食
  food: {
    MostPopular: "Più popolari", //热门场所美食
    RestaurantList: "Elenco dei ristoranti", //美食种类分类
  },
  //休闲玩乐
  LeisurePlay: {
    leisureplay: "Svago e divertimento", //休闲/玩乐
    youMightAlsoLike: "Potrebbe piacerti", //你可能也喜欢
    time: "Apertura", // 时间
    address: "Indirizzo：", // 地点
    activityContent: "Testo：", // 活动内容
  },
  // 论坛
  forum: {
    join: "Partecipa all'argomento", // 参与话题
    recommendedTopics: "Argomenti consigliati", // 推荐话题
    hotTopics: "Hot Topics", // 热门话题
    followed: "Segui", // 关注的话题
    favorites: "Preferiti", // 收藏的话题
    more: "Di piu", // 查看更多
    Comments: "Commenti", // 条评论
    Comment: "Commenti", // 条评论
    Reply: "Scrivi il tuo commento…", // 写下你的评论…
    prevText: "Precedente", // 上一页
    nextText: "Successivo", // 下一页…

    ReleasePost: "Rilasciare un post", // 请添加话题标题
    ReleaseTouchPost: 'Vietato postare "Argomenti sensitivi".', // 请添加话题标题
    newTopic: "Aggiungi un titolo all'argomento", // 请添加话题标题
    UploadCove: "Carica la copertina", // 上传封面
    PublishContent: "Pubblica il commento", // 发布内容
    submit: "Invia", //发布
    loginIn: "Accedi", //立即登录
    follow: "Segui", //关注
    article: "Articolo", //帖
    concerned: "Seguito", //已关注
    other: "Altri argomenti", //其他推荐
    PleaseSelect: "Seleziona un argomento", //请选择话题
    datas: "Si prega di completare i dati", //请完善数据
    successfully: "Pubblicato con successo", //发布成功
    Comments: "Commenti", //条评论
    back: "Indietro", //返回
    reply: "Rispondi", //回复
    NoComment: "Nessun commento", //暂无评论
    NoAttention: "Nessun seguace", //暂无关注
    NoCollection: "Nessuna collezione", //暂无收藏
  },
  //书籍
  book: {
    books: "Libri", //书籍
    more: "Altro", //更多
    category: "Categoria", //分类
  },
  //住宿
  house: {
    title: "Informazioni", // 宿舍信息
    university: "Info:",
    road: "Via:",
    houseTitle: "Informazioni", // 房屋信息
    houseIntroduction: "Introduzione：", // 房屋简介：
    housing: "Hotel", //住宿
    serviceApartment: "Hotel", //酒店式公寓
    allAreas: "Tutte le aree", //区域分类
    city: "Citta", //区 '
    street: "Via", //街道
    like: "Potrebbe piacerti", //你可能喜欢
    price: "Prezzo:", //价格
    type: "Tipologia:", //类型
    rooms: "Camere:", //房型
    size: "Mq:", //面积
    floor: "Piano:", //楼层
    mainWindowFacing: "Facciata:", //朝向
    district: "Area:", //区域
    metroStation: "Metropolitana:", //地铁站
  },
  // 二手交易
  secondHand: {
    price: "Prezzo：", //价格
    newGoods: `Piu' recenti`, //最新发布的商品
    unit: "RMB",
    Description: "Descrizione", // 描述
    PickUp: "Ritiro merce", // 取货
    Contact: "Contatto", // 联系
    class: "Nome di classificazione", //分类名称
    secondHandgoods: "Rilascio di beni di seconda mano", //发布二手商品
    name: "Nome", // 标题名称
    address: "Indirizzo ritiro merce", // 取货地址
    phone: "Contatto", // 联系方式
    price: "Prezzo", // 价格
    classification: "Classifica", // 分类
    introductionPicture: "Immagine", // 介绍图片
    details: "Dettagli", // 详情
    release: "Pubblica", // 发布
    cancel: "Cancella", // 取消
    success: "Aggiunto con successo", // 添加成功
    fail: "Fallito", // 添加失败
    rulesCover: "Caricare almeno un'immagine!", // 请上传封面图
  },
  // 职位
  recruit: {
    Favorite: "Preferito", // 收藏
    Salary: "Salario: ", // 月薪
    CandidateLocation: "Luogo: ", // 地点
    MostPopularJob: "I piu' richiesti", // 热门职位
    JobType: "Tipologia", // 工作分类
    Resumes: "Curriculum", // 精英
    JobProfile: "Profili", //职位简介
    CommodityInformation: "Informazioni sui prodotti", //商品信息
  },
  // 旅游攻略
  travel: {
    MostRecent: "Piu' recenti", // 最新攻略
  },
  // 景点门票
  ticket: {
    HotPlaces: "Luoghi Hot", // 热门景点
    RecommendedAttractions: "Potrebbe piacerti anche", // 推荐景点
    Price: "Prezzo：", // 售价：
    Classification: "Classifica", // 分类
  },
  // 医疗
  medical: {
    MostPopular: "Piu' popolari", // 热门推荐
    Phone: "Numero telefono：", // 联系电话
    Room: "Camere：", // 房间数
    Address: "Indirizzo：", // 医院地址
    Introduction: " Introduzione：", //  医院简介
    suite: "Suite", //  套房
    primaryClassification: "Classificazione primaria", //  二级分类
    secondaryClassification: "Classificazione secondaria", //  二级分类
  },
  //学习/培训
  study: {
    mostPopular: "Piu' popolari", //热门推荐
    education: "Istituzione", //学习/培训
    introduction: "Introduzione al corso：", //课程简介
  },

  // 订单表格配置
  tableConfig: {
    createDate: "Data ordine",
    orderNumber: "Numero pratica.",
    childType: "Tipologia",
    enterDate: "Data di arrivo",
    leaveDate: "Data di partenza",
    price: "Prezzo",
    orderStatus: "Stato",
    type: "Stato",
  },
  // 订单信息
  orderInfo: {
    PracticeInfo: "Info Pratica", // 订单信息
    PostAddress: "Indirizzo postale", // 订单信息
    CourierNo: "Numero Corriere", // 快递单号
    Other: "Qui puoi scaricare la copia del visto e-Business/Gruppo", // 其他
    PostAddress: "Indirizzo postale", // 反馈说明
    FeedbackDescription: "Feedback", // 其他
    label: {
      Type: "Tipologia visto", // 签证种类
      PaxNo: "Numero passeggeri:", // 人数数量
      PurchaseNo: "Numero di acquisto:", // 购买数量
      PracticeNo: "Numero Pratica:", // 订单单号
      PaymentNo: "No. pagamento:", // 付款单号
      Price: "Prezzo:", // 价格
      Discount: "Sconto:", // 折扣
      Status: "Stato:", // 订单状态
      MethodOfPayment: "Metodo di pagamento:", // 付款方式
      Recipient: "Destinatario：", // 收货人
      PhoneNumber: "Numero di telefono:", // 联系电话
      Address: "Indirizzo:", // 地址
      ExpressOrderNo: " Numero ordine corriere:", // 快递单号
      Time: "Tempo:", // 时间
      DescriptionContent: "Descrizione del testo:", // 说明内容
    },
    NoLogisticsInformation: "Nessuna informazione sulla logistica", // 暂无物流信息
    NoInformation: "Nessuna informazione", // 暂无
    NoFeedbackDescription: "Nessun feedback", // 暂无反馈说明
    CheckTheLogistics: "Controlla la logistica", // 查看物流
    Download: "Scarica", // 查看物流
    continue: "Continua",
  },
  orderStatus: [
    { value: 0, label: "Non pagato" },
    { value: 1, label: "Non pagato" },
    { value: 2, label: "In fase di revisione" },
    { value: 3, label: "Completato" },
    { value: 4, label: "Respinto" },
    { value: 5, label: "Cancellato" },
    { value: 6, label: "Pagato" },
    { value: 7, label: " Rimborsato" },
  ],
};
