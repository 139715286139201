<template>
  <div class="">
    <!-- <router-view></router-view> -->
    <top-search-two></top-search-two>
    <div class="top">
      <img src="../../../static/images/school/top.png" class="top-img" />

      <div class="school-class">
        学校分类
        <el-select filterable v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="school-list">
        <div
          v-for="(item, index) in 10"
          :key="index"
          class="list"
          @mouseover="listIndex = index"
          @mouseleave="listIndex = -1"
        >
          <div class="title">Accommodation registration</div>
          <img src="../../../static/images/school/right.png" class="list-img" />
          <div class="detail" v-show="index == listIndex">
            <div class="detail-title">INTRODUCTION</div>
            <div class="detail-desc">
              The school is famous for its professional foreign language
              teaching. Establish foreign language courses in English, German,
              French, Japanese, Russian and Spanish, and implement small-class
              teaching
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-page">
        <div></div>
        <page></page>
      </div>
    </div>
    <contact-us-one></contact-us-one>
  </div>
</template>

<script>
import page from "../../components/page.vue";
import ContactUsOne from "../home/contactUsOne.vue";
import TopSearchTwo from "../home/topSearchTwo.vue";
export default {
  components: { page, ContactUsOne, TopSearchTwo },
  data() {
    return {
      listIndex: -1,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  .bottom-page {
    width: 65%;
    display: flex;
    justify-content: space-between;
  }
  .school-list {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    .list {
      width: 50%;
      margin-bottom: 100px;
      position: relative;
      .detail {
        width: 448px;
        position: absolute;
        opacity: 1;
        background: #ffffff;
        border-radius: 16px;
        box-shadow: 0px 24px 64px 0px rgba(41, 15, 0, 0.1);
        z-index: 99;
        left: 6%;
        bottom: -30%;
        padding: 27px 26px 52px 45px;
        .detail-title {
          font-size: 22px;
          font-weight: 900;
          text-align: left;
          color: #f5ad34;
          letter-spacing: 1px;
          margin-bottom: 10px;
        }
        .detail-desc {
          width: 80%;
          font-size: 20px;
          font-weight: 900;
          color: #333333;
        }
      }
      .title {
        font-size: 30px;
        font-weight: 900;
        text-align: center;
        color: #444444;
        margin: 0 0 44px 0;
      }
      .list-img {
        width: 602px;
        height: 486px;
        opacity: 1;
      }
    }
  }
  .school-class {
    font-size: 20px;
    font-weight: 900;
    color: #444444;
    width: 70%;
    margin: 95px 0 72px 0;
    .el-select {
      width: 351px;
      opacity: 1;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 10px 10px 40px 0px rgba(54, 33, 0, 0.1);
    }
    .el-select >>> .el-input {
      width: 351px;
      height: 50px;
    }
    .el-select >>> .el-input__inner {
      width: 351px;
      height: 50px;
      font-size: 20px;
      font-weight: 900;
      text-align: left;
      color: #333333;
    }
  }
  .top-img {
    width: 1647px;
    height: 494px;
    margin-top: 130px;
  }
}
</style>
