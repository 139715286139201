<template>
  <div class="page-wrap">
    <top-search-three :advertises="advertises" />
    <!--banner-->
    <Carousel :list="bannerList" :class="[advertises.length > 0 ? 'nav-top-mt' : '']" />
    <div class="content">
      <div class="recommended-schools">
        <!--Here-Store-->
        <div class="title" v-if="hsList.length > 0">Here-Store</div>
        <div class="detail" v-if="hsList.length > 0" style="cursor: pointer"
          @click="$router.push({ path: '/hereStore' })">
          <div class="image">
            <img :src="hsList[0].image" mode="" />
          </div>
          <div>
            <div class="title1">{{ hsList[0].title }}</div>
            <!-- <div class="title2">
              普通中等学校913所<br />
              普通小学721所<br />
              特殊教育学校30所
            </div> -->
            <div class="title3">
              <rich-text v-html="hsList[0].content"></rich-text>
            </div>

            <!--            <div class="bottom">-->
            <!--              <div class="yeelow"></div>-->
            <!--              <div class="blak"></div>-->
            <!--              <div class="blak"></div>-->
            <!--            </div>-->
          </div>
          <img src="../../../static/images/home/diandian.png" style="background: #ffffff" class="right" />
        </div>
		
		<div class="title" v-if="lpsList.length > 0">WI-FI card/Internet in China</div>
		<div class="detail" v-if="lpsList.length > 0" style="cursor: pointer"
		  @click="$router.push({ path: '/wifiCard' })">
		  <div style="padding-right: 50px;">
		    <div class="title1">{{ lpsList[0].title }}</div>
		    <div class="title3">
		      <rich-text v-html="lpsList[0].content"></rich-text>
		    </div>
		  </div>
		  <div class="image">
		    <img :src="lpsList[0].image" mode="" />
		  </div>
		</div>
        <!--美食推荐-->
        <!-- <div class="delicious-food">
          <div class="top2">
            <div class="title">{{ $t("home.FoodRecommendation") }}</div>
          </div>
          <div class="el-carousel-height">
            <el-carousel type="card" :autoplay="false" indicator-position="none" v-if="frsList.length > 0">
              <el-carousel-item class="card-frc" v-for="item in frsList" :key="item">
                <div class="foodList">
                  <div class="top2">
                    <div class="desc">
                      <div>
                        <div class="desc-title">{{ item.location }}</div>
                        <div class="desc-soda">{{ item.title }}</div>
                      </div>
                      <div class="desc-price">Price:{{ item.price }}</div>
                    </div>
                  </div>
                  <div class="food-image" @click="$router.push({ path: '/deliciousFood' })">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="desc-content desc-text">{{ item.content }}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->
        <!--休闲娱乐-->
        <!-- <div class="community-activities">
          <div class="title">{{ $t("home.LeisurePlay") }}</div>
          <div class="box">
            <div class="list" v-for="(item, index) in lpsList" :key="index"
              @click="$router.push({ path: '/leisureTime' })">
              <img :src="item.image" alt="" />
              <div class="desc">
                <div class="desc-content">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!--签证-->
      <div class="accommodation-registration" @click="$router.push({ path: '/hereStore/ChineseVisa' })"
        v-if="visa.length > 0">
        <div class="title">{{ $t("home.visa") }}</div>
        <div class="detail">
          <div>
            <div class="detail-title">
              {{ visa[0].title }}
            </div>
            <div class="detail-desc"></div>
            <rich-text v-html="visa[0].content"></rich-text>
          </div>
          <div class="right">
            <img src="../../../static/images/home/diandian.png" class="image-back" />
            <img :src="visa[0].image" class="image" />
          </div>
        </div>
      </div>
      <!--新闻-->
      <div class="international-student-news">
        <div class="title">{{ $t("home.Journalism") }}</div>
        <div class="journalism-carousel">
          <el-carousel :interval="5000" arrow="always" indicator-position="none" v-if="journalismList.length > 0">
            <el-carousel-item v-for="item in journalismList" :key="item">
              <div class="journalism" @click="$router.push({ path: '/overseasStudentNews' })">
                <img :src="item.faceImage" />
                <div class="info">
                  <div class="journalism-desc">{{ item.content }}</div>
                  <div class="journalism-title">{{ item.title }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!--联系我们-->
      <contact-us-one background="#fff"></contact-us-one>
    </div>
  </div>
</template>

<script>
import contactUsOne from "./contactUsOne.vue";
import api from "../../comm/api";
import { onMounted, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import Carousel from "../../components/Carousel/Carousel";
import TopSearchThree from "./topSearchThree";

export default {
  components: { TopSearchThree, Carousel, contactUsOne },
  setup() {
    const router = useRouter(),
      route = useRoute();
    const data = reactive({
      advertises: [],
      bannerList: [],
      frsList: [],
      lpsList: [],
      hsList: [],
      journalismList: [],
      visa: [],
      inv: "",
    });

    const getFrsData = () => {
      api.homeVisa().then((res) => {
        data.visa = res;
      });
      api.getBanner({ type: "INDEX" }).then((res) => {
        if (res) {
          data.bannerList = res;
        }
      });
      api.getAdvertises({ location: "1" }).then((res) => {
        if (res) {
          data.advertises = res;
        }
      });
      api.frs().then((res) => {
        data.frsList = res;
      });
      api.lps().then((res) => {
        data.lpsList = res;
      });
      api.hs().then((res) => {
        data.hsList = res;
      });
      api.journalism().then((res) => {
        data.journalismList = res;
      });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      getFrsData();
      const query = router.query;
      if (query && query.inv) {
        const inv = query.inv;
        localStorage.setItem("inv", inv);
      }
    });

    return {
      ...toRefs(data),
    };
  },
  // data() {
  //   return {
  //     bannerList: [],
  //     frsList: [],
  //     lpsList: [],
  //     hsList: [],
  //     journalismList: []
  //   };
  // },
  // mounted() {
  //   this.getFrsData();
  // },
  // methods: {
  //   getFrsData() {
  //     let that = this;
  //     api.getBanner({ type: "INDEX" }).then((res) => {
  //       if (res) {
  //         that.bannerList = res;
  //         localStorage.setItem('bannerList',JSON.stringify(res));
  //       }
  //     });
  //     api.frs().then((res) => {
  //       that.frsList = res;
  //     });
  //     api.lps().then((res) => {
  //       that.lpsList = res;
  //     });
  //     api.hs().then((res) => {
  //       that.hsList = res;
  //     });
  //     api.journalism().then((res) => {
  //       that.journalismList = res;
  //      });
  //   }
  // }
};
</script>

<style scoped lang="scss">
// :deep .el-carousel-height .is-active {
//   padding: 0 40px;
// }

.el-carousel-height {
  height: 730px;
}

:deep .el-carousel-height .el-carousel__container {
  height: 730px !important;
}

.desc-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical;
  /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 4;
  /**显示的行数**/
  overflow: hidden;
  /**隐藏超出的内容**/
  word-break: break-all;
  display: flex;
  justify-content: center;
}

.banner-carousel {
  :deep .el-carousel__container {
    height: 670px;
  }
}

.journalism-carousel {
  width: 100%;

  :deep .el-carousel__container {
    height: 540px;
  }
}

.top-banner {
  width: 100%;
  height: 670px;
}

.content {
  .international-student-news {
    display: flex;
    flex-direction: column;
    align-items: center;

    .journalism {
      position: relative;
      height: 540px;

      .info {
        width: 600px;
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        margin-left: -300px;
        text-align: center;
        margin-top: -270px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .journalism-title {
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }

        .journalism-desc {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          color: #fff;
          line-height: 38px;
          margin-bottom: 50px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      img {
        width: 100%;
        height: 540px;
      }
    }

    .el-carousel {
      width: 100%;
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      color: #222a41;
      padding: 0 20px 0 20px;
      text-align: center;
      margin-bottom: 65px;
      background: linear-gradient(to right,
          #fdeed4 236px,
          #fdeed4 236px,
          white 52%,
          white 100%);
      margin-top: 100px;
    }
  }

  .accommodation-registration {
    cursor: pointer;
    width: 100%;
    // height: 820px;
    padding-bottom: 80px;
    opacity: 1;
    background: #fff5e4;
    margin-top: 130px;
    padding-top: 45px;

    .detail {
      display: flex;
      justify-content: center;

      .right {
        width: 643px;
        height: 542px;
        position: relative;
        margin-left: 140px;
        position: relative;

        .image {
          width: 643px;
          position: absolute;
          height: 542px;
          z-index: 2;
        }

        .image-back {
          width: 176px;
          height: 176px;
          position: absolute;
          bottom: -68px;
          left: -68px;
          z-index: 1;
        }
      }

      .detail-title {
        font-size: 46px;
        font-weight: 500;
        color: #101010;
        margin-bottom: 50px;
      }

      .detail-desc {
        width: 550px;
        font-size: 18px;
        font-weight: 500;
        color: #666666;
        line-height: 50px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 125px;
      width: 104px;
      height: 48px;
      // opacity: 0.2;
      background: #fce2b9;
      border-radius: 24px;
      margin-top: 100px;

      .yeelow {
        width: 8px;
        height: 8px;
        opacity: 1;
        background: #f5a623;
        border-radius: 50%;
      }

      .blak {
        width: 8px;
        height: 8px;
        opacity: 0.1;
        background: #000000;
        border-radius: 50%;
        margin-left: 16px;
      }
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      text-align: center;
      color: #444444;
      margin-bottom: 81px;
    }
  }

  .community-activities {
    display: flex;
    flex-direction: column;
    align-items: center;

    .box {
      display: flex;
      justify-content: center;
      margin-top: 140px;

      .list {
        width: 406px;
        height: 624px;
        background-color: #fff;
        box-shadow: 0px 0px 21px 0px rgba(60, 78, 105, 0.21);
        cursor: pointer;

        img {
          width: 100%;
          height: 408px;
        }

        .desc {
          height: 216px;
          width: 100%;
          line-height: 32px;
          font-weight: 400;
          font-size: 18px;
          padding: 25px 15px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
        }

        .desc-content {
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      .list:nth-child(2) {
        margin: -65px 52px 0 52px;
        background-color: #fff5e4;
      }
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      color: #222a41;
      padding: 0 20px 0 20px;
      text-align: center;
      background: linear-gradient(to right,
          #fdeed4 236px,
          #fdeed4 236px,
          white 52%,
          white 100%);
      margin-top: 100px;
    }
  }

  //.community-activities {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //  .list {
  //    width: 406px;
  //    height: 408px;
  //    box-shadow: 0px 0px 21px 0px rgba(60, 78, 105, 0.21);
  //    margin-right: 50px;
  //    margin-top: 143px;
  //    overflow: hidden;
  //    //height: 408px;
  //    cursor: pointer;
  //    img {
  //      width: 406px;
  //      height: 408px;
  //    }
  //    .desc {
  //      padding: 25px 20px;
  //      font-size: 18px;
  //      color: #444444;
  //    }
  //  }
  //  .list:nth-child(2) {
  //    margin-top: 80px;
  //  }
  //  //.list:hover {
  //  //  transform: scale(1.2);
  //  //}
  //  .title {
  //    font-size: 44px;
  //    font-weight: 600;
  //    color: #222a41;
  //    padding: 0 20px 0 20px;
  //    text-align: center;
  //    background: linear-gradient(
  //      to right,
  //      #fdeed4 236px,
  //      #fdeed4 236px,
  //      white 52%,
  //      white 100%
  //    );
  //    margin-top: 100px;
  //  }
  //}
  .delicious-food {
    width: 70%;
    overflow: hidden;

    img {
      width: 100%;
      height: 500px;
      padding: 0;
      margin: 0;
      display: block;
    }

    .desc-content {
      background: #ffffff;
      font-size: 16px;
      font-weight: 500;
      color: #444444;
      margin: 10px 20px;
    }

    .card-frc {
      background-color: #fff;
    }

    .top2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .desc {
        display: flex;
        justify-content: space-between;
        width: 560px;
        margin-top: 10px;
        margin-bottom: 37px;

        .desc-price {
          font-size: 22px;
          font-weight: 600;
          color: #f5a623;
        }

        .desc-title {
          font-size: 32px;
          font-weight: 600;
          color: #2a2d3a;
        }

        .desc-soda {
          font-size: 22px;
          font-weight: 600;
          color: #999999;
          margin-top: 5px;
        }
      }

      .title {
        font-size: 44px;
        font-weight: 600;
        color: #222a41;
        padding: 0 20px 0 20px;
        text-align: center;
        background: linear-gradient(to right,
            #fdeed4 50%,
            #fdeed4 50%,
            white 52%,
            white 100%);
        margin-top: 100px;
      }
    }

    .el-carousel {
      margin-top: 30px;
    }
  }

  .recommended-schools {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .detail {
      display: flex;
      margin-top: 80px;

      .right {
        width: 176px;
        height: 176px;
        background: #000000;
        margin-top: 40px;
        margin-left: 30px;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 125px;
        width: 104px;
        height: 48px;
        opacity: 0.2;
        background: #fdeed4;
        border-radius: 24px;
        margin-top: 190px;

        .yeelow {
          width: 8px;
          height: 8px;
          opacity: 1;
          background: #f5a623;
          border-radius: 50%;
        }

        .blak {
          width: 8px;
          height: 8px;
          opacity: 0.1;
          background: #000000;
          border-radius: 50%;
          margin-left: 16px;
        }
      }

      .title3 {
        width: 332px;
        font-size: 18px;
        color: #666666;
        margin-top: 20px;
        margin-left: 125px;
        line-height: 36px;
      }

      .title2 {
        font-size: 22px;
        font-weight: 900;
        color: #666666;
        margin-left: 125px;
      }

      .title1 {
        font-size: 46px;
        font-weight: 900;
        color: #101010;
        margin: 60px 0 60px 125px;
      }

      .image {
        width: 570px;
        height: 624px;
        opacity: 1;
        background: linear-gradient(135deg, #f3efe3, #f6f0ec);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 528px;
          margin-right: -70px;
          object-fit: cover;
        }
      }
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      color: #222a41;
      padding: 0 20px 0 20px;
      background: linear-gradient(to right,
          #fdeed4 236px,
          #fdeed4 236px,
          white 52%,
          white 100%);
      margin-top: 100px;
    }
  }

  display: flex;
  flex-direction: column;
}

.search-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.input {
  height: 54px;
  width: 850px;
  background: #fff;
  border-radius: 100px;
  overflow: hidden;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-input {
    width: 85%;
    font-size: 16px;
  }

  >.el-input__inner {
    border: none;
    background: #fff;
  }
}

.search-bg {
  background: #f5ad34;
}

.search-right {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.el-icon-close {
  font-size: 30px;
  color: #333333;
}

.el-icon-search {
  font-size: 24px;
  color: #fff;
}

.right {
  position: relative;

  .search-input-box {
    background: rgba(33, 33, 33, 0.5);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 120px;
    padding-bottom: 200px;
    height: 670px;
  }
}

:deep .delicious-food .el-carousel__arrow {
  display: none;
}

.food-image {
  border-radius: 20px;

  img {
    border-radius: 20px;
  }
}

.is-active .foodList img {
  transition: all 0.5s ease-out;
  /* 过度 所有css属性 1秒过度 结束慢*/
  -moz-transition: all 0.5s ease-out;
  /* Firefox 4 */
  -webkit-transition: all 0.5s ease-out;
  /* Safari 和 Chrome */
  -o-transition: all 0.5s ease-out;
}

.is-active:hover .foodList img {
  transform: scale(1.1, 1.1);
}

.list {
  transition: all 0.5s ease-out;
  /* 过度 所有css属性 1秒过度 结束慢*/
  -moz-transition: all 0.5s ease-out;
  /* Firefox 4 */
  -webkit-transition: all 0.5s ease-out;
  /* Safari 和 Chrome */
  -o-transition: all 0.5s ease-out;
}

.list:hover {
  transform: scale(1.1, 1.1);
}
</style>
