<template>
  <div :class="{ 'search-wrap': fixed }" class="search-wrap" :style="{ backgroundColor }">
    <div class="search">
      <img src="../../../static/images/logo.png" class="logo" />
      <div class="top">
        <div class="top-select">
          <div class="title">
            <div @click="selectIndex == 1 ? (selectIndex = -1) : (selectIndex = 1)"
              :class="selectIndex == 1 ? 'border-bottom' : ''" style="cursor: pointer">
              CHINA TOURS
            </div>
          </div>
          <div class="title margin">
            <div @click="selectIndex == 2 ? (selectIndex = -1) : (selectIndex = 2)"
              :class="selectIndex == 2 ? 'border-bottom' : ''" style="cursor: pointer">
              CHINESE VISA
            </div>
          </div>
          <div class="title">
            <div @click="selectIndex == 3 ? (selectIndex = -1) : (selectIndex = 3)"
              :class="selectIndex == 3 ? 'border-bottom' : ''" style="cursor: pointer">
              WI-FI CARD
            </div>
          </div>
          <transition name="el-zoom-in-top">
            <div class="select-check1 right1" v-if="selectIndex != -1">
              <div class="select-title">
                {{
                    selectIndex == 1
                      ? "CHINA TOURS"
                      : selectIndex == 2
                        ? "CHINESE VISA"
                        : "WI-FI CARD"
                }}
              </div>
              <div class="platform-list">
                <div v-for="(item, index) in selectIndex == 1
              ? searchData.platformList
              : selectIndex == 2
                ? searchData.schoolLifet
                : searchData.livingInShanghai" :key="index" class="list2" @click="$router.push(item.router)">
                  {{ JSON.parse(item.title)[$i18n.locale] }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="right">
        <!-- <el-input
          placeholder="请输入关键词"
          suffix-icon="el-icon-search"
        ></el-input> -->
        <router-link to="search" class="search-right">
          <img src="../../../static/images/home/search.png" />
        </router-link>
        
        <div class="user">
          <!--登录状态-->
          <router-link v-if="token" class="user-info" to="/personalCenter">
            <img src="../../../static/images/home/head.png" />
            <div class="user-name text-overflow-one">
              Cammy HedlingCammy HedlingCammy HedlingCammy HedlingCammy
              HedlingCammy HedlingCammy HedlingCammy Hedling
            </div>
          </router-link>
          <!--未登录状态-->
          <div v-else>
            <router-link to="/login" class="login">{{
                $t("navigation.SignIn")
            }}</router-link>
            <router-link to="/login?login=1" class="register">{{
                $t("navigation.Register")
            }}</router-link>
          </div>
          <router-link v-if="token" to="/" class="back-home">⬅ {{ $t("navigation.Back") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      i18n: this.$i18n.locale,
      gjhSHow: false,
      selectIndex: -1,
      searchData: JSON.parse(localStorage.getItem("menuItem")),
      token: localStorage.getItem("token"),
      // platformList: [
      //   { title: "Here-STORE" },
      //   { title: "论坛", router: "forum" },
      //   { title: "住宿", router: "accommodation" },
      //   { title: "学习/培训" },
      //   { title: "留学生须知", router: "overseasStudentNews" },
      //   { title: "书籍" },
      //   { title: "外国人服务站" },
      //   { title: "数字人民币" },
      //   { title: "新闻", router: "overseasStudentNews" },
      // ],
      // schoolLifet: [
      //   { title: "签证", router: "groupSignature" },
      //   { title: "上网卡" },
      //   { title: "保险" },
      //   { title: "数字人民币" },
      //   { title: "Vip接机" },
      //   { title: "Here-Store" },
      // ],
      // livingInShanghai: [
      //   { title: "住宿登记", router: "accommodation" },
      //   { title: "旅游攻略", router: "travel" },
      //   { title: "美食", router: "deliciousFood" },
      //   { title: "招聘", router: "recruit" },
      //   { title: "医疗" },
      //   { title: "休闲/玩乐" },
      //   { title: "二手买卖" },
      //   { title: "论坛", router: "forum" },
      //   { title: "景点门票", router: "ticket" },
      // ],
    };
  },
  methods: {
    // removeAutoHideSubMenuEvent() {
    //   var frameWindows = getBrowerWindows();
    //   for(var i = 0 ; i < frameWindows.length; i++) {
    //     try{
    //       var frameWindow = frameWindows[i];
    //       $(frameWindow.document).unbind("click.autosubmenu");
    //     } catch (e) {
    //       // handle exception
    //     }
    //   }
    // },
    changeLocale(e) {
      (this.$i18n.locale = e),
        (this.i18n = e),
        (this.gjhSHow = false),
        localStorage.setItem("local", e);
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.search-wrap {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.top {
  margin-left: 87px;

  .top-select {
    height: 80px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    .margin {
      margin: 0 70px 0 70px;
    }

    .title {
      font-size: 20px;
      font-weight: 900;
      color: #222a41;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;
      // height: 40px;
    }

    .border-bottom {
      border-bottom: 3px solid #f7bf62;
      color: #ffce7b;
    }

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }

    .right1 {
      right: -70px;
    }

    .select-check1 {
      // visibility: visible;
      background: rgba(33, 33, 33, 0.5);
      // background: #ffffff;
      width: 100%;
      // border-radius: 36px;
      top: 0;
      position: absolute;
      left: 0;
      padding-top: 120px;
      padding-bottom: 200px;

      // padding: 30px;
      .select-title {
        font-size: 30px;
        font-weight: 900;
        color: #ffffff;
        margin-left: 550px;
      }

      .platform-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 30%;
        margin-left: 550px;

        .list1 {
          width: 40%;
          font-size: 22px;
          font-weight: 900;
          color: #ffffff;
          margin-top: 30px;
          cursor: pointer;
        }

        .list2 {
          width: 30%;
          font-size: 22px;
          font-weight: 900;
          color: #ffffff;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 8px 0;

  .logo {
    height: 64px;
    margin-left: 130px;
    mix-blend-mode: multiply;
  }

  .right {
    display: flex;
    align-items: center;

    .guojihua {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
      position: relative;
      height: 48px;

      .style {
        display: flex;
        align-items: center;
        background: #f5ad34;
        border-radius: 27px;
        padding: 12px 15px;
        cursor: pointer;
      }

      .guojihua-check {
        cursor: pointer;
        width: 100%;
        position: absolute;
        left: 0;
        top: 60px;
        color: rgb(46, 45, 45);
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 21px 0px rgba(60, 78, 105, 0.22);
        text-align: center;
        line-height: 20px;
        font-size: 20px;
        line-height: 35px;
      }

      .img1 {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .img2 {
        width: 20px;
        margin-left: 10px;
      }
    }

    .search-right {
      width: 48px;
      height: 48px;
      background: #f5ad34;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 20px;

      img {
        width: 22px;
        height: 22px;
      }
    }

    .el-input {
      width: 320px;
    }

    .user {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 130px;

      .login {
        font-size: 22px;
        font-weight: 900;
        color: #444444;
        margin: 0 26px 0 16px;
      }

      .back-home {
        height: 48px;
        max-height: 48px !important;
        font-size: 20px;
        font-weight: 900;
        color: #fff;
        margin-left: 10px;
        background: #f5ad34;
        border-radius: 24px;
        line-height: 40px;
        padding: 5px 8px;
        border-radius: 20px;
        white-space: nowrap;
      }

      .register {
        background: #f5ad34;
        border-radius: 8px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;
        padding: 12px 15px;
        border-radius: 100px;
      }

      img {
        width: 54px;
        height: 54px;
        margin: 0 15px 0 20px;
      }

      .user-name {
        width: 200px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
