<template>
  <div class="left">
    <template v-if="token != null">
      <div class="flex alitem-center head">
        <img
          v-if="userInfo && userInfo.avatar == null"
          src="../../../../static/images/home/head.png"
          alt=""
        />
        <img v-else :src="userInfo && userInfo.avatar" alt="" />
        <div class="titels">{{ userInfo && userInfo.nickName }}</div>
      </div>
      <div class="follow-title">{{ $t("forum.followed") }}</div>
      <template v-if="follow.length != 0">
        <div
          v-for="(item, index) in follow"
          :key="index"
          class="follow-list"
          @click="$router.push({ path: '/forumTopic', query: { id: item.id } })"
        >
          {{ JSON.parse(item.title)[$i18n.locale] }}
        </div>
        <div class="flex justcenter">
          <div class="form-style">
            <el-button
              @click="
                $router.push({ path: '/personalCenter', query: { index: 3 } })
              "
              >{{ $t("forum.more") }}</el-button
            >
          </div>
        </div>
      </template>
      <div v-else class="nodata">{{ $t("forum.NoAttention") }}</div>
      <div class="follow-title">{{ $t("forum.favorites") }}</div>
      <div class="collection">
        <div class="xian"></div>
        <template v-if="collection.length != 0">
          <div
            v-for="(item, index) in collection"
            :key="index"
            class="textList"
            @click="
              $router.push({ path: '/forumDetails', query: { id: item.id } })
            "
          >
            <div class="collection-title">
              {{ JSON.parse(item.title)[$i18n.locale] }}
            </div>
            <div class="collection-desc">
              {{ JSON.parse(item.description)[$i18n.locale] }}
            </div>
            <div class="xian"></div>
          </div>
        </template>
        <div v-else class="nodata">{{ $t("forum.NoCollection") }}</div>
      </div>
    </template>
    <template v-else>
      <div class="logins">
        <div @click="$router.push({ path: '/login' })">
          {{ $t("forum.loginIn") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, watchEffect } from "vue";
import api from "@/comm/api";
export default {
  name: "MyLikeForum",
  props: {
    updateShow: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      token: localStorage.getItem("token"),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      follow: [], //关注话题
      collection: [], //收藏文章
    });

    //获取关注话题
    const topicGet = async () => {
      api.topicFollowList({ page: 1, pageSize: 5 }).then((res) => {
        data.follow = res.records;
      });
    };

    //获取文章收藏
    const articleGet = async () => {
      api.articleGetlist({ page: 1, pageSize: 4 }).then((res) => {
        data.collection = res.records;
      });
    };
    onMounted(() => {
      if (data.token) {
        topicGet(); //获取关注话题
        articleGet(); //文章收藏
      }
    });
    watchEffect(() => {
      if (props.updateShow) {
        emit("isShowClick");
        topicGet(); //获取关注话题
        articleGet(); //文章收藏
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.left {
  width: 250px;
  background: #f8f9fc;
  padding: 35px 25px;
  .collection {
    margin-top: 26px;
    .collection-title {
      font-size: 18px;
      font-weight: 500;
      color: #202020;
      margin-bottom: 6px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .collection-desc {
      font-size: 14px;
      font-weight: 500;
      color: #757575;
      margin-bottom: 15px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .xian {
      width: 100%;
      height: 1px;
      background: #c9c9c9;
      margin-bottom: 14px;
    }
  }
  .follow-more {
    width: 153px;
    height: 41px;
    opacity: 1;
    background: #f3ca15;
    border-radius: 4px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 41px;
    margin-bottom: 40px;
  }
  .follow-list:hover {
    color: #f3ca15;
  }
  .follow-list {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    line-height: 25px;
    margin-top: 20px;
    cursor: pointer;
  }
  .follow-title {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin-top: 20px;
  }
  .head {
    img {
      margin-right: 10px;
      width: 88px;
      height: 88px;
      border-radius: 50%;
    }
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }
}

.flex {
  display: flex;
}
.alitem-center {
  align-items: center;
}
.justcenter {
  justify-content: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.justspace {
  justify-content: space-between;
}
.titels {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.logins {
  width: 100%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    font-size: 16px;
    background: #f5ad34;
    color: #fff;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
}
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  color: #999;
}
.form-style {
  margin: 30px 0 20px;
}
.textList {
  cursor: pointer;
}
.textList:hover .collection-title {
  color: #f3ca15;
}
</style>
