const enData = {
  // 底部链接
  bottomLink: [
    { title: 'About us', router: '/aboutUs' , key: 'aboutUs'},
    { title: 'Chinese Visa', router: '/hereStore/ChineseVisa' , key: 'chineseVisa' },
    { title: 'Contact Us', router: '/contact' , key: 'contactUs' },
    { title: 'FAQ', router: '/faq' , key: 'faq' },
    // { title: 'Insurance', router: '/insurance' , key: 'insurance' },
    { title: 'Wifi-card/Internet in China', router: '/wifiCard' , key: 'wcic' },
    { title: 'Advertise', router: '/advertising' , key: 'advertise' },
    { title: 'Vip Airport Pick-Up', router: '/vipAirportPickUp' , key: 'vipApu' },
    { title: 'Privacy', router: '/agreement' , key: 'privacy' },
    // { title: 'HereShanghai Wechat', router: '/weChat' , key: 'wechat' },
  ],
  // hereStore 首页
  hereStoreIndex: [
    {
      title: 'Visa',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
    },
    {
      title: 'Wi-Fi Card',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
    },
    {
      title: 'Vip Airport Pick Up',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
    },
  ],
  // 签证
  visaList: [
    {
      title: 'Group Visa',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Business e-Visa',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Study Visa',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Work Visa',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Chian PR',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Renewal',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: 'APPLY',
      img: '../../../static/images/hereStore/bg1.png',
    },
  ],
  // 上网卡
  wifiCardList: [
    {
      title: 'China 15 DAY Wi-fi SIM',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: '申请',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'China 30 DAY Wi-fi SIM',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: '申请',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'China 180 DAY Wi-fi SIM',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: '申请',
      img: '../../../static/images/hereStore/bg1.png',
    },
  ],
  // Vip服务
  vipServeList: [
    {
      title: 'Vip Welcome',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: '申请',
      img: '../../../static/images/hereStore/bg1.png',
    },
    {
      title: 'Vip Airport Transfers',
      desc: 'If you are a frequent international traveler, a second valid passport allows you to obtain visas while traveling abroad or to assist with travel between politically sensitive countries. It’s global travel without barriers.',
      apply: '申请',
      img: '../../../static/images/hereStore/bg1.png',
    },
  ],
  // 个人中心
  personalCenter: [
    { title: 'Account', router: 'personalInfo' },
    { title: 'My orders', router: 'personalInfo' },
    // { title: 'Buy&Sell', router: 'personalInfo' },
    // { title: 'Forum', router: 'personalInfo' },
    { title: 'Favorites', router: 'personalInfo' },
    // { title: 'Jobs', router: 'personalInfo' },
  ],
  // 个人中心-卡片标题
  personalCardTitle: {
    // 个人中心
    userInfo: ['Account'],
    // 我的订单
    myOrder: ['My Orders'],
    // 我的二手
    mySecondHand: ['My Buy&Sell', 'My favorites'],
    // 我的论坛
    myForum: ['Followed', 'Posted', 'My comment', 'Favorite'],
    // 我的收藏
    myFavorites: [
      'Travel Guides',
      'Eat&Drink',
      'Things to do',
      'Foreigner Service Stations',
      'Education',
      'Attractions',
    ],
    // 我的论坛
    myJob: ['Favorites', 'Favorites'],
  },
  // 个人中心-我的订单
  myOrder: {
    tableInfo: [
      { prop: 'createDate', label: 'Order date' },
      { prop: 'orderNumber', label: 'Practice no.' },
      { prop: 'childType', label: 'Type' },
      { prop: 'enterDate', label: 'Date of arrival' },
      { prop: 'leaveDate', label: 'Date of departure', width: '120px' },
      { prop: 'price', label: 'Price' },
      { prop: 'orderStatus', label: 'Status', type: 'status' },
    ],
    // 订单状态
    orderStatus: [
      { value: 0, label: 'Unpaid' },
      { value: 1, label: 'Unpaid' },
      { value: 2, label: 'Under review ' },
      { value: 3, label: 'Completed' },
      { value: 4, label: 'Audit failed' },
      { value: 5, label: 'Cancel' },
      { value: 6, label: 'Paid' },
      { value: 7, label: 'Refund' },
    ],
    // 支付方式
    paymentMode: [
      { value: 1, label: 'Credit card' },
      { value: 2, label: 'Paypal' },
      { value: 3, label: 'Alipay' },
      { value: 4, label: 'Deposit' },
    ],
    // 大类
    type: [
      { value: 1, label: 'Visa' },
      { value: 2, label: 'Wi-Fi Card' },
      { value: 3, label: 'Vip Airport Pick Up' },
    ],
    // 子类
    childType: [
      { value: 1, label: 'Study visa' },
      { value: 2, label: 'Work visa' },
      { value: 3, label: 'China PR' },
      { value: 4, label: 'Renewal work visa' },
      { value: 5, label: 'Business e-visa' },
      { value: 6, label: 'Group visa' },
      { value: 7, label: 'Vip Welcome' },
      { value: 8, label: 'Vip Airport Transfers' },
      { value: 9, label: 'Wi-Fi Card' },
    ],
  },
  // 职业
  occupationList: [
    { label: 'Employee', value: 1 },
    { label: 'Businessman', value: 2 },
    { label: 'Housewife', value: 3 },
    { label: 'Retired', value: 4 },
    { label: 'Student', value: 5 },
    { label: 'Child', value: 6 },
  ],
  // 与紧急联系人关系
  emergencyContactRelationshipList: [
    { label: 'Parents', value: 1 },
    { label: 'Relatives', value: 2 },
    { label: 'Sister', value: 3 },
    { label: 'Brother', value: 4 },
    { label: 'Friend', value: 5 },
    { label: 'Spouse', value: 6 },
    { label: 'Daughter/Son', value: 7 },
  ],
  // 曾在中国因违法被处罚的情况
  punishList: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ],
  comStatus: [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 },
  ],
  gender: [
    { label: 'male', value: 1 },
    { label: 'female', value: 0 },
  ],
  // 结婚状况
  maritalStatus: [
    { label: 'Married', value: 1 },
    { label: 'Unmarried', value: 2 },
    { label: 'Divorce', value: 3 },
    { label: 'Widowed', value: 4 },
  ],
  // 教育状况
  education: [
    { label: 'PostDoctor', value: 'PostDoctor' },
    { label: 'Doctorate', value: 'Doctorate' },
    { label: 'Master', value: 'Master' },
    { label: 'Undergraduate', value: 'Undergraduate' },
    { label: 'High school', value: 'High school' },
    { label: 'Below high school', value: 'Below high school' },
  ],
  // 电话
  phoneType: [
    { label: 'China +86', value: 1 },
    { label: 'other countries', value: 2 },
  ],
  // 服务类型
  serviceType: [
    { title: 'Normal', desc: '5 working days', value: 1 },
    {
      title: 'Express',
      desc: '3 working days. Express service requires extra fee',
      value: 2,
    },
    {
      title: 'Extra urgent',
      desc: '2 working days. Urgent service requires extra fee',
      value: 3,
    },
  ],
  // 团签--服务类型
  groupServiceType: [
    { title: 'Normal', desc: '5 working days', value: 1 },
    {
      title: 'Express',
      desc: '3 working days. Express service requires extra fee',
      value: 2,
    },
  ],
  // vip接机车型
  vipCars: [
    { title: 'Business 7 Seater', value: 1 },
    { title: 'Premium 5 Seater', value: 2 },
  ],
  // 快递类型
  expressType: [
    { title: 'Fedex', value: 1 },
    { title: 'DHL', value: 2 },
    { title: 'SF-EXPRESS', value: 3 },
    { title: 'EMS', value: 4 },
  ],
}
export default enData
