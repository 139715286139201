import api from "../comm/api";

const actions = {
  async getAdvertisesData({ state, commit }, params) {
    if (state.location == params) {
      return;
    }
    commit("updateLocation", params);
    const result = await api.getAdvertises({ location: params });
    commit("updateAdvertises", result);
  },
  // 用户信息
  async getUserData({ commit }) {
    const result = await api.userInfo();
    commit("updateUserInfo", result);
  },
  // 国家-区号
  async getAreaCodeData({ commit }) {
    const result = await api.getAreaCode();
    commit("setAreaCode", result);
  },
  // 所有签证信息列表
  async getAllVisaInfoData({ commit }) {
    let local = localStorage.getItem("local")
      ? localStorage.getItem("local")
      : "US"; //中英文
    const list = await api.getVisaInfo();
    list.forEach((item) => {
      item.image = item.image.split(",");
      item.title = JSON.parse(item.title)[local];
      item.processingTime = JSON.parse(item.processingTime)[local];
      item.content = JSON.parse(item.content)[local];
      item.dataList = JSON.parse(item.dataList)[local].replace(
        /\n/g,
        "<br /><p></p>"
      );
      item.needingAttention = JSON.parse(item.needingAttention)[local].replace(
        /\n/g,
        "<br /><p></p>"
      );
      item.other = JSON.parse(item.other)[local];
    });
    commit("setAllVisaInfo", list);
  },
};
export default actions;
