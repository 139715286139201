<template>
  <div class="page-wrap" style="background: #fff">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />

    <div :class="className" class="content">
      <Carousel :list="bannerList" />
      <div class="cardModelWrap">
        <!-- 住宿 -->
        <div class="housWrap">
          <div class="housTitle">{{ $t("house.housing") }}</div>
          <!-- 列表 -->
          <div
            class="housWrapList"
            @click="
              $router.push({
                path: '/accommodationInfo',
                query: { id: item.id, stayType: 1 },
              })
            "
            v-for="(item, index) in accommodationList"
            :key="index"
          >
            <img :src="item.images" alt="" />
            <div class="houseInfos">
              <div class="houseInfosTop">
                <div class="houseInfosTitle">
                  <div class="titles">{{ $t("house.title") }}</div>
                  <div class="price">
                   {{ item.price }}
                  </div>
                </div>
                <div class="houseInfosDesc">{{ item.textField }}</div>
              </div>
              <div class="houseInfosBottom">
                <div class="address">
                  <div>{{ item.district }}</div>
                  <div>{{ item.road }}</div>
                  <div>{{ item.university }}</div>
                </div>
                <div class="discount">{{ getNumbers(item.size) }}m²</div>
              </div>
            </div>
          </div>
          <!-- 更多 -->
          <div class="more">
            <div @click="$router.push({ path: '/accommodation/list' })">
              {{ $t("book.more")
              }}<img src="../../../static/images/food/right.png" alt="" />
            </div>
          </div>
        </div>

        <!-- 酒店式公寓 -->
        <div class="housWrap">
          <div class="housTitle">{{ $t("house.serviceApartment") }}</div>
          <!-- 更多 -->
          <div class="more">
            <div @click="$router.push({ path: '/accommodation/list' })">
              {{ $t("book.more")
              }}<img src="../../../static/images/food/right.png" alt="" />
            </div>
          </div>
          <!-- 列表 -->
          <div class="apartmentListwrap">
            <div
              class="apartmentList"
              @click="
                $router.push({
                  path: '/accommodationInfo',
                  query: { id: item.id, stayType: 2 },
                })
              "
              v-for="(item, index) in hotelList"
              :key="index"
            >
              <div class="lists">
                <img :src="item.images" alt="" />
                <div class="infos">
                  <div class="infosTops">
                    <div class="infosTopsdetail">
                      <div class="infosTopsTitle">
                        {{ $t("house.houseTitle") }}
                      </div>
                      <div class="price">
                        <!-- {{ $t("common.unit") }} -->
                        {{ item.price }}
                      </div>
                    </div>
                    <div class="desc">
                      {{ $t("house.houseIntroduction") }}{{ item.textField }}
                    </div>
                  </div>
                  <div class="infosBottoms">
                    <div class="address">
                      <div>{{ item.district }}</div>
                      <div>{{ item.road }}</div>
                      <div>{{ item.university }}</div>
                    </div>
                    <div class="discount">{{ getNumbers(item.size) }}m²</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--联系我们-->
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import contactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import page from "@/components/page";
import Carousel from "@/components/Carousel/Carousel";
import api from "@/comm/api";
import util from "@/comm/util";
import { useStore } from "vuex";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted, watchEffect } from "@vue/runtime-core";

export default {
  components: { contactUsOne, topSearchThree, Carousel, page },
  setup() {
    const store = useStore();

    const data = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US",
      className: "",
      advertises: [],
      bannerList: [], //banner 数组
      accommodationList: [], // 住宿列表
      hotelList: [], // 酒店列表
    });

    const params = reactive({
      hotelTotal: 0,
      accommodationTotal: 0,
      hotel: { page: 1, pageSize: 8, search: {} },
      accommodation: { page: 1, pageSize: 12 },
    });

    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "STAY" });
    };

    //数据处理
    const getNumbers = (val) => {
      if(!val){
        return 0;
      }
      let vals = (val+'').split(".");
      if (vals[1] === "00") {
        return vals[0];
      } else {
        return val;
      }
    };

    // 获取1住宿，2酒店列表
    const getData = async () => {
      await api.dwell(1).then((res) => {
        if (res) {
          jsonData(res, "accommodationList");
        }
      });

      await api.dwell(2).then((res) => {
        if (res) {
          jsonData(res, "hotelList");
        }
      });
    };

    // 处理数据
    const disposeData = reactive({
      newList: [], // 新数组
      newJson: {}, // 单数据
    });

    // 处理数据
    const jsonData = (res, list) => {
      // 初始化数据
      disposeData.newList = [];
      // 判断数据是否有值
      if (res && res.length > 0) {
        // 循环便利数组
        res.forEach((item) => {
          disposeData.newJson = {};
          // 循环便利对象
          for (const [key, val] of Object.entries(item)) {
            // 如果val不为空
            if (val) {
              // 判断val是否为字符串
              if (typeof val == "string") {
                let first = val.indexOf("{") == -1 ? false : true;
                let last = val.lastIndexOf("}") == -1 ? false : true;

                // 判断首尾是{}
                if (first && last) {
                  if (key == "price") {
                    disposeData.newJson[key] = JSON.parse(val).ZH;
                  } else {
                    disposeData.newJson[key] = JSON.parse(val)[data.local];
                  }
                } else {
                  disposeData.newJson[key] = val;
                }
              } else {
                // 判断val不是字符串
                disposeData.newJson[key] = val;
              }
            }
          }
          disposeData.newList.push(disposeData.newJson);
        });
      }

      // 赋值给对应的list
      data[list] = disposeData.newList;
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 7); // 获取广告位 图
      getBanners(); //获取banner 图
      getData();
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      ...toRefs(params),
      getBanners, //获取banner图 方法
      getNumbers, //数据处理
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // background: transparent;
}
.cardModelWrap {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 79px;
}
.housWrap:last-child {
  margin-top: 50px;
}
.housWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .housTitle {
    font-size: 44px;
    color: #444;
    margin-bottom: 26px;
  }
  .housWrapList {
    width: 100%;
    height: 464px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    margin-top: 38px;
    padding: 37px 40px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 506px;
      height: 391px;
      border-radius: 24px;
      object-fit: cover;
    }
  }
}
.houseInfos {
  flex: 1;
  height: 100%;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.houseInfosTop {
  width: 700px;
  .houseInfosTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    color: #444;
    .titles {
      flex: 1;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      text-align: justify;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 20px;
    }
    .price {
      font-size: 30px;
      color: #f49d44;
    }
  }
}

.houseInfosDesc {
  font-size: 18px;
  color: #666;
  margin-top: 32px;
  line-height: 32px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.houseInfosBottom {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #444;
}
.houseInfosBottom .address {
  overflow: hidden;
  width: 80%;
  display: flex;
  align-items: center;
  div:first-child {
    width: 20%;
  }
  div {
    // width: 50%;
    text-align: center;
    padding-right: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:last-child {
    padding-right: 0;
  }
}
.more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  color: #444;
  margin: 22px 0 48px;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 38px;
      height: 38px;
      margin-left: 29px;
      object-fit: cover;
    }
  }
}

.apartmentListwrap {
  width: 100%;
  display: flex;
  align-items: center;
  .apartmentList {
    width: 33.3%;
    height: 490px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .apartmentList:first-child {
    justify-content: flex-start;
  }
  .apartmentList:nth-of-type(3) {
    justify-content: flex-end;
  }
}
.apartmentList .lists {
  width: 404px;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 6px 42px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 264px;
    object-fit: cover;
  }
  .infos {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px 16px 13px;
    box-sizing: border-box;
  }
}
.infos .infosTopsdetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #444;
  .price {
    font-size: 22px;
    color: #f49d44;
  }
}
.infosTops .desc {
  font-size: 16px;
  color: #666;
  margin-top: 13px;
  line-height: 22px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.infosBottoms {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #444;
}
.infosBottoms .address {
  width: 80%;
  display: flex;
  align-items: center;
  div {
    width: 33.3%;
    text-align: left;
    padding-right: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:last-child {
    padding-right: 0;
  }
}
</style>
