<template>
  <div class="banner-carousel" v-if="list && list.length > 0">
    <el-carousel
      :interval="5000"
      arrow="always"
      indicator-position="none"
      v-if="list.length > 0"
    >
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <img :src="item.image ? item.image : item" class="top-banner" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "Carousel",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped lang="scss">
// .banner-carousel {
//   width: 1920px;
//   :deep .el-carousel__container {
//     width: 1920px;
//     height: 800px;
//   }
//   :deep .carousel__item {
//     width: 1920px;
//     height: 800px;
//   }
// }
// .top-banner {
//   width: 1920px;
//   height: 800px;
// }

.banner-carousel {
  height: 800px;
  width: 100%;
  :deep .el-carousel__container,
  .el-carousel {
    width: 1920px;
    height: 800px;
  }
  :deep .el-carousel__item {
    width: 1920px;
    height: 800px;
    img {
      object-fit: cover;
      height: 800px;
      width: 100%;
    }
  }
  .top-banner {
    width: 1920px;
    height: 800px;
  }
}
</style>
