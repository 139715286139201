<template>
  <div class="content-wrap">
    <div class="flex-wrap">
      <div class="empty-content" v-if="collectionList.length == 0">
        <el-empty :image-size="200"></el-empty>
      </div>
      <div v-else v-for="(item, index) in collectionList" :key="index" class="list"
        @click=" $router.push({ path: '/ticketInfo', query: { id: item.id } }) "
      >
        <img :src="item.faceImage[0]" alt="" />
        <div class="list-style">
              <div class="list-title text-overflow-two">{{item.title[$i18n.locale]}}</div>
              <div class="list-desc text-overflow-five">{{item.introduction[$i18n.locale]}}</div>
              <div>
                <div class="money">{{$t('ticket.Price')}}{{item.price.ZH}}</div>
              </div>
            </div>
      </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from "@/components/page.vue";
import { reactive, toRefs } from "vue";
import api from "@/comm/api";
export default {
  components: { Page },
  name: "Six",
  setup() {
    const data = reactive({
      collectionList: [],
      total: 0,
      common: { page: 1, pageSize: 2, search: {} },
    });

    // 获取[休闲/玩乐]数据
    const getData = () => {
      api.myScenery(data.common).then((res) => {
        if (res) {
          data.total = res.total;
          res.records.forEach((item) => {
            item.faceImage = item.faceImage.split(',');
            item.title = JSON.parse(item.title);
            item.price = JSON.parse(item.price);
            item.introduction = JSON.parse(item.introduction);
          });
          data.collectionList = res.records;
        }
      });
    };

    // 根据页码改变列表数据
    const changePage = (e) => {
      data.common.page = e;
      getData();
    };

    return {
      ...toRefs(data),
      getData,
      changePage,
    };
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  min-height: 500px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  padding: 40px 40px 10px 40px;
  box-sizing: border-box;
  min-height: 518px;
}
.page-wrap {
  margin-top: 40px;
}
.img-box {
  width: 100%;
  height: 180px;
  img {
    border-radius: 11px 11px 0 0;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}

.list {
  width: 988px;
  height: 336px;
  opacity: 1;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.13);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.list-style {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 291px;
}
.list-title {
  width: 434px;
  font-size: 22px;
  font-weight: 900;
  color: #444444;
}
.list-desc {
  width: 423px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #666666;
  line-height: 28px;
  margin-top: 10px;
}
.list-address {
  width: 378px;
  font-size: 20px;
  font-weight: 900;
  text-align: left;
  color: #444444;
  margin-bottom: 10px;
}
.money {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #f5ad34;
}
.list {
  img {
    width: 434px;
    height: 291px;
    margin-right: 35px;
    border-radius: 16px;
    object-fit: cover;
  }
}
</style>