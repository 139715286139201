<template>
  <div class="infomation">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="top-title">
        {{ $t("groupSignature.confirmationInformation") }}
      </div>
      <div class="xian"></div>
      <el-form label-position="right" label-width="auto">
        <!-- 护照识别 -->
        <div>
          <el-form-item>
            <div class="title">
              {{ $t("groupSignature.essentialInformation") }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('groupSignature.informationForm.passport')">
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">Click to upload</el-button>
              <div>jpg/png files with a size less than 500kb</div>
            </el-upload>
            <el-popover placement="right-start" trigger="hover">
              <template #default>
                <sample-visa />
              </template>
              <template #reference>
                <el-button style="margin-left: 200px; height: 50px">{{
                  $t("common.sample")
                }}</el-button>
              </template>
            </el-popover>
          </el-form-item>

          <!-- 序号 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.SerialNumber')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 英文姓 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.EnglishSurname')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 英文名 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.EnglishName')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 中文名 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.ChineseName')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 国籍 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.nationality')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 性别 -->
          <el-form-item :label="$t('groupSignature.informationForm.Gender')">
            <el-select
              filterable
              v-model="value"
              :placeholder="$t('groupSignature.pleaseSelect')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 出生日期 -->
          <el-form-item :label="$t('groupSignature.informationForm.dateBirth')">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 出生地 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.birthplace')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 护照签发地 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.placeIssue')"
          >
            <!-- <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            /> -->
            <el-select
              filterable
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            >
              <el-option
                v-for="item in $store.state.areaCodeList"
                :key="item.id"
                :label="$i18n.locale == 'ZH' ? item.zh : item.us"
                :value="
                  input && input.length == 2
                    ? item.two
                      ? item.two
                      : '-'
                    : item.three
                    ? item.three
                    : '-'
                "
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 所持证件号码 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.certificatesNumber')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 所持证件签发日期 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.CertificateDate')"
          >
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 所持证件有效日期 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.CertificateDateValid')"
          >
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
            <a class="form-desc">注：系统自动填充，请注意检查</a>
          </el-form-item>

          <!-- 职业 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.occupation')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 紧急联系人 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.emergencyContact')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 与紧急联系人关系 -->
          <el-form-item
            :label="
              $t('groupSignature.informationForm.emergencyContactRelationship')
            "
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 紧急联系人方式 -->
          <el-form-item
            :label="
              $t('groupSignature.informationForm.EmergencyContactInformation')
            "
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>

          <!-- 曾在中国因违法被处罚的情况 -->
          <el-form-item :label="$t('groupSignature.informationForm.punish')">
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
            />
          </el-form-item>
        </div>

        <!-- 其他信息 -->
        <div>
          <el-form-item>
            <div class="title">
              {{ $t("groupSignature.otherInformation") }}
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('groupSignature.informationForm.OtherNameInChina')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>
          <!-- *具有其他国籍 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.OtherNationalities')"
          >
            <el-select
              filterable
              v-model="value"
              :placeholder="$t('groupSignature.pleaseSelect')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 婚姻状况 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.maritalStatus')"
          >
            <el-select
              filterable
              v-model="value"
              :placeholder="$t('groupSignature.pleaseSelect')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-form-item>

          <!-- 配偶姓名 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.SpouseName')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 配偶出生日期 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.spouseDate')"
          >
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker
          ></el-form-item>

          <!-- 配偶国籍 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.SpouseNationality')"
          >
            <el-select
              filterable
              v-model="value"
              :placeholder="$t('groupSignature.pleaseSelect')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-form-item>

          <!-- 配偶居住地 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.ResidenceSpouse')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 配偶联系电话 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.SpouseContactNumber')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 最高学历 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.HighestEducation')"
          >
            <el-select
              filterable
              v-model="value"
              :placeholder="$t('groupSignature.pleaseSelect')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-form-item>

          <!-- 学校名称 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.SchoolName')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 单位/机构名称 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.NameUnitOrganization')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 境外组团社 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.OverseasGroupAgency')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 近2年访问过的其他国家（中国除外） -->
          <el-form-item
            :label="$t('groupSignature.informationForm.otherCountriesVisited')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 快递地址 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.ExpressAddress')"
          >
            <div class="other-style">
              <el-input v-model="input" placeholder="请输入路名" />
              <el-input v-model="input" placeholder="请输入邮编" />
              <el-select filterable v-model="value" placeholder="请选择城市">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select filterable v-model="value" placeholder="请选择国家">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <!-- 电话号码 -->
          <el-form-item
            :label="$t('groupSignature.informationForm.TelephoneNumber')"
          >
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>
        </div>

        <!-- 入境信息 -->
        <div>
          <el-form-item>
            <div class="title">
              {{ $t("groupSignature.entryInformation") }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('groupSignature.entry.enterCity')">
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>

          <!-- 出境城市 -->
          <el-form-item :label="$t('groupSignature.entry.outCity')">
            <el-input
              v-model="input"
              :placeholder="$t('groupSignature.pleaseEnter')"
          /></el-form-item>
          <!-- 入境日期 -->
          <el-form-item :label="$t('groupSignature.entry.enterDate')">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker
          ></el-form-item>

          <!-- 出境日期 -->
          <el-form-item :label="$t('groupSignature.entry.outDate')">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="Pick a day"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker
          ></el-form-item>
        </div>

        <!-- 上传信息 -->
        <div>
          <el-form-item>
            <div class="title">
              {{ $t("groupSignature.uploadInformation") }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('groupSignature.upload.ticket')">
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">Click to upload</el-button>
              <div>jpg/png files with a size less than 500kb</div>
            </el-upload>
          </el-form-item>

          <!-- 酒店订单 -->
          <el-form-item :label="$t('groupSignature.upload.hotelOrder')">
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">Click to upload</el-button>
              <div>jpg/png files with a size less than 500kb</div>
            </el-upload>
          </el-form-item>

          <!-- 上传信息 -->
          <el-form-item :label="$t('groupSignature.upload.idPhoto')">
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">Click to upload</el-button>
              <div>jpg/png files with a size less than 500kb</div>
            </el-upload>
          </el-form-item>

          <!-- 上传信息 -->
          <!-- <el-form-item
            :label="$t('groupSignature.upload.VaccinationCertificate')"
          >
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">Click to upload</el-button>
              <div>jpg/png files with a size less than 500kb</div>
            </el-upload>
          </el-form-item> -->
        </div>
      </el-form>
      <el-button plain class="submit-next" @click="returnInformationDeatil">
        {{ $t("groupSignature.confimNex") }}
      </el-button>
    </div>
    <!-- <div style="display: flex; flex-direction: column; align-items: center">
      <el-form label-position="right" label-width="auto"> </el-form>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      informationIndex: 0,
      input: "",
    };
  },
  methods: {
    returnInformationDeatil() {
      this.$emit("returnInformationDeatil");
    },
  },
};
</script>

<style scoped lang="scss">
.other-style {
  :deep .el-input {
    width: 220px !important;
    margin-right: 15px;
  }
}
:deep .el-upload {
  text-align: left !important;
}
:deep .el-input {
  width: 500px !important;
}
:deep .el-form-item__label {
  font-size: 18px;
  font-weight: 900;
  color: #444444;
  line-height: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.infomation {
  .form-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
  }
  .title {
    font-size: 22px;
    font-weight: 900;
    color: #333333;
  }
  .top-title {
    font-size: 30px;
    font-weight: 900;
    color: #333333;
    text-align: center;
  }
  .xian {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    margin: 29px 0 50px 0;
  }
  .submit-next {
    background: #f5a623;
    border-radius: 3px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding: 25px;
    text-align: center;
    cursor: pointer;
  }
}
:deep .el-form-item__label {
  font-size: 18px;
  font-weight: 900;
  color: #444444;
  line-height: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
