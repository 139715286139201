import api from "../comm/api";

const mutations = {
  setIsLogin(state, payload) {
    state.user.isLogin = payload;
    // 是否登录，取用户信息
    if (state.user.isLogin) {
      api.userInfo().then((res) => {
        if (res) {
          localStorage.setItem("userInfo", JSON.stringify(res));
          state.user.userInfo = res;
        }
      });
    }
  },
  setIsLoginRSA(state, payload) {
    state.user.isLoginRSA = payload;
  },
  setUserInfo(state) {
    // 是否登录
    if (state.user.isLogin) {
      api.userInfo().then((res) => {
        if (res) {
          localStorage.setItem("userInfo", JSON.stringify(res));
          state.user.userInfo = res;
        }
      });
    }
  },

  // 获取国家-区号
  setAreaCode(state, payload) {
    state.areaCodeList = payload;
  },
  // 获取所有签证信息列表
  setAllVisaInfo(state, payload) {
    state.allVisaInfoList = payload;    
  }, 

  // 获取用户信息
  updateUserInfo(state, payload) {
    state.user.userInfo = payload;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },

  // 获取广告位图片
  updateAdvertises(state, payload) {
    state.advertisesList = payload;
  },
  updateLocation(state, payload) {
    state.location = payload;
  }
};
export default mutations;
