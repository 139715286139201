<template>
  <div class="content-wrap">
    <div class="content">
      <div class="container">
          <div class="empty-content" v-if="commonList.length == 0">
            <el-empty :image-size="200"></el-empty>
          </div>
          <div v-else v-for="(item, index) in commonList" :key="index" class="list-wrap">
            <div class="list">
              <img :src="item.comImage[0]" />        
              <div class="right">
                <div class="top">
                  <div class="name">{{JSON.parse(item.title)[$i18n.locale]}}</div>
                </div>
                <div class="desc">{{JSON.parse(item.description)[$i18n.locale]}}</div>
                <div class="box-bottom">
                    <CountUser :isUser="false" :msg="item.comComments" :user="item.comPeople" />
                </div>
              </div>
            </div>
            <div class="list">      
              <div class="right">
                <div class="top">
                  <div class="name">{{$t('forum.Comment')}}</div>
                  <div class="skew" v-if="item.release && item.release == 3">
                    <div class="skew-main">{{$t('common.Deleted')}}</div>
                  </div>
                </div>
                <div class="desc">{{item.opinion}}</div>
                <div class="box-bottom">
                    <div class="time">{{item.updateDate}}</div>
                    <div class="box-bottom-btn" v-if="item.release != 3">
                      <span class="hand-shank" @click.stop="del(item.id)">{{$t('common.delete')}}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from '@/components/page.vue';
import CountUser from "@/components/CountUser/CountUser";
import {reactive, toRefs} from 'vue';
import api from "@/comm/api";
import { useRouter } from 'vue-router';
import { ElMessage,ElMessageBox } from "element-plus";
import { useI18n } from 'vue-i18n'//要在js中使用国际化

export default {
  components: { Page,CountUser },
  name: "Three",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const data = reactive({
        commonList: [],
        total: 0,
        common: { page:1, pageSize: 6,search: {} },
    });    

    // 获取[我的评论]数据
    const getData = (() => {
        api.myArticleOpinion(data.common).then((res) => {
            if(res) {
                data.total = res.total;
                res.records.forEach(item => {
                  item.comImage = item.images.split(',');
                  item.comComments = item.comments;
                  item.comPeople = item.people;
                });
                data.commonList = res.records;
            }
        })
    })
    
    // 删除【我的评论】
    const del = ((id) => {
      ElMessageBox.confirm(`${t('elMessageBoxText.MyArticleOpinionDesc')}`, `${t('common.Note')}`, {
        confirmButtonText: `${t('elMessageBoxText.confirmButtonText')}`,
        cancelButtonText: `${t('elMessageBoxText.cancelButtonText')}`,
        type: "info"
      }).then(() => {
        api.updReleaseArticle({id,release:3}).then((res) => {
          if(res) {            
            ElMessage({
              message: t("common.deleteSuccess"),
              type: "success"
            });
            getData();
          }
        }).catch(()=>{})
      }).catch(()=>{})
    });

    // 根据页码改变列表数据
    const changePage = ((e) => {
      data.common.page = e;
      getData();
    })

    return {
        ...toRefs(data),
        getData,
        changePage,
        del,
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  
  min-height: 518px;
}
.container {  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 26px;
  box-sizing: border-box;
}
.list-wrap {
  width: 97%;
  height: 170px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.15);
}
.list {
  width: 50%;
  padding: 18px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  img {
    width: 128px;
    height: 130px;
    margin-right: 16px;
  }
}
.right{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  flex: 1;
  font-size: 24px;
  font-weight: 600;
  color: #101010;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-sizing: border-box
}
.skew {
  background: rgb(242,58,58);
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 10px;
  text-align: center;
  font-size: 16px;
  transform: skew(-25deg);
  color: #fff;
}

.skew-main {
  transform: skew(25deg);
}
.desc {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-sizing: border-box;
}
.box-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.box-bottom-btn {
  span:last-child {
    display: inline-block;
    margin-left: 10px;
  }
  span:hover {
    color: #f5a623;
  }
}
.time {
  color: #f7bf66;
}
.page-wrap {
    margin-top: 40px;
}
</style>