<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="personal">
        <div class="left">
          <div
            v-for="(item, index) in leftInfo"
            :key="index"
            :class="leftIndex == index ? 'left-check' : 'left-default'"
            @click="checkIndex(index)"
            class="marbottom20"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="right">
          <personal-information v-if="leftIndex == 0"></personal-information>
          <my-second-hand v-if="leftIndex == 2"></my-second-hand>
          <collection v-if="leftIndex == 4"></collection>
          <job-wanted v-if="leftIndex == 5"></job-wanted>
          <my-forum v-if="leftIndex == 3"></my-forum>
          <my-order v-if="leftIndex == 1"></my-order>
        </div>
      </div>
    </div>
    <contact-us-two></contact-us-two>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsTwo from "../home/contactUsOne.vue";
import PersonalInformation from "./personalInformation.vue";
import MySecondHand from "./mySecondHand.vue";
import Collection from "./collection.vue";
import JobWanted from "./jobWanted.vue";
import MyForum from "./myForum.vue";
import MyOrder from "./myOrder.vue";
import Carousel from "../../components/Carousel/Carousel";
import { getCurrentInstance } from "vue";
import api from "@/comm/api";
import util from "@/comm/util";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import { useStore } from "vuex";
import { reactive, toRefs, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
export default {
  components: {
    topSearchThree,
    Carousel,
    contactUsTwo,
    PersonalInformation,
    MySecondHand,
    Collection,
    JobWanted,
    MyForum,
    MyOrder,
  },
  /**
   *attrs: (...) == this.$attrs
   emit: (...) == this.$emit
   listeners: (...) == this.$listeners
   parent: (...) == this.$parent
   refs: (...) == this.$refs
   root: (...) == this
   */
  setup() {
    // 通过getCurrentInstance来获取全局方法，这个只能在setup里使用
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();

    const data = reactive({
      leftInfo: proxy.$commonData.personalCenter,
      bannerList: [],
      advertises: [],
      leftIndex: route.query.index ? route.query.index : 0,
    });

    // 用户信息
    const userInfo = reactive({
      ...store.state.user.userInfo,
    });
    //菜单切换
    const checkIndex = (index) => {
      data.leftIndex = index;
	  console.log(proxy.$commonData);
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 22); // 获取广告位 图
      getBanners();
    });

    //获取banner 图
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "PERSONAL" });
    };

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      userInfo,
      checkIndex, //菜单切换
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.personal {
  display: flex;
  justify-content: center;
  padding-top: 70px;

  .left {
    width: 180px;
    background: #ffffff;
    box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
    padding: 40px 30px 40px 30px;
    height: 500px;

    .marbottom20 {
      margin-bottom: 20px;
    }

    .left-check {
      width: 158px;
      height: 63px;
      opacity: 1;
      background: #f5a623;
      border-radius: 16px;
      padding-left: 20px;
      font-size: 22px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      line-height: 63px;
      cursor: pointer;
    }

    .left-default {
      width: 158px;
      height: 63px;
      border-radius: 16px;
      padding-left: 20px;
      font-size: 22px;
      font-weight: 500;
      text-align: left;
      color: #444444;
      line-height: 63px;
      cursor: pointer;
    }
  }
}

.background {
  background: #f5f5f5;
}
</style>
