<template>
  <div class="contens">
    <div class="form">
      <!-- <el-form ref="form" :model="form" :rules="rules" class="form"> -->
      <el-form-item
        :label="$t('register.Email')"
        class="input-style"
        prop="loginName"
        :rules="[
          {
            required: true,
            message: $t('register.validateEmailCode'),
            trigger: 'blur',
          },
        ]"
      >
        <!--邮箱-->
        <div class="input">
          <img src="../../../static/images/login/email.png" />
          <el-input
            clearable
            v-model="form.loginName"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.yam')"
        class="input-style left37"
        :rules="[
          {
            required: true,
            message: $t('register.validateEmailCode'),
            trigger: 'blur',
          },
        ]"
        prop="emailCode"
      >
        <!--确认邮箱-->
        <div class="input2">
          <img src="../../../static/images/login/code.png" />
          <el-input
            clearable
            v-model="form.emailCode"
            maxlength="6"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
        <div v-if="codeType == 0" class="get-code" @click="getCode">
          {{ $t("register.GetCode") }}
        </div>
        <!--获取验证码-->
        <div v-if="codeType == 1" class="get-code">{{ waitCode }}</div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Password')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validatePassword'),
            trigger: 'blur',
          },
        ]"
        prop="password"
      >
        <!--密码-->
        <div class="input">
          <img src="../../../static/images/login/Lock.png" />
          <el-input
            type="password"
            clearable
            v-model="form.password"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :rules="[
          {
            required: true,
            message: $t('register.validatePassword'),
            trigger: 'blur',
          },
        ]"
        :label="$t('register.ConfirmedPassword')"
        class="input-style"
        prop="passwordAgin"
      >
        <!--确认密码-->
        <div class="input">
          <img src="../../../static/images/login/Lock.png" />
          <el-input
            type="password"
            clearable
            v-model="form.passwordAgin"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.CompanyName')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyName'),
            trigger: 'blur',
          },
        ]"
        prop="companyName"
      >
        <!--公司名称-->
        <div class="input">
          <img src="../../../static/images/login/companyIcon.png" />
          <el-input
            clearable
            v-model="form.companyName"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Typology')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyType'),
            trigger: 'blur',
          },
        ]"
        prop="companyType"
      >
        <!--公司类型-->
        <div class="input">
          <img src="../../../static/images/login/companyIcon.png" />
          <el-input
            clearable
            v-model="form.companyType"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.VATTax')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanySn'),
            trigger: 'blur',
          },
        ]"
        prop="sn"
      >
        <!--税号-->
        <div class="input">
          <img src="../../../static/images/login/sh.png" />
          <el-input
            clearable
            v-model="form.sn"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Website')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyWebsite'),
            trigger: 'blur',
          },
        ]"
        prop="website"
      >
        <!--网址-->
        <div class="input">
          <img src="../../../static/images/login/wz.png" />
          <el-input
            clearable
            v-model="form.website"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.ContactPerson')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyContact'),
            trigger: 'blur',
          },
        ]"
        prop="contact"
      >
        <!--联系人-->
        <div class="input">
          <img src="../../../static/images/login/phone.png" />
          <el-input
            clearable
            v-model="form.contact"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.PhoneNumber')"
        class="input-style left37"
        prop="contactPhone"
      >
        <!--电话号码-->
        <div class="input3">
          <el-select
            filterable
            v-model="form.phoneAscription"
            :placeholder="$t('register.PleaseSelect')"
          >
            <el-option
              v-for="item in $store.state.areaCodeList"
              :key="item.id"
              :label="
                $i18n.locale == 'ZH'
                  ? item.zh + '+' + item.areaCode
                  : item.us + '+' + item.areaCode
              "
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="input2">
          <el-input
            clearable
            v-model="form.contactPhone"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Address')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyAddress'),
            trigger: 'blur',
          },
        ]"
        prop="address"
      >
        <!--地址-->
        <div class="input">
          <img src="../../../static/images/login/Lock.png" />
          <el-input
            clearable
            v-model="form.address"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.PostalCode')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyPost'),
            trigger: 'blur',
          },
        ]"
        prop="post"
      >
        <!--邮编-->
        <div class="input">
          <img src="../../../static/images/login/email.png" />
          <el-input
            clearable
            v-model="form.post"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Country')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyCountry'),
            trigger: 'blur',
          },
        ]"
        prop="country"
      >
        <!--国家-->
        <div class="input">
          <img src="../../../static/images/login/companyIcon.png" />
          <!-- <el-input
            clearable
            v-model="form.country"
            :placeholder="$t('register.PleaseInput')"
          ></el-input> -->
          <el-select
            filterable
            v-model="form.country"
            :placeholder="$t('register.PleaseSelect')"
          >
            <el-option
              v-for="item in $store.state.areaCodeList"
              :key="item.id"
              :label="$i18n.locale == 'ZH' ? item.zh : item.us"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.City')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateCompanyCity'),
            trigger: 'blur',
          },
        ]"
        prop="city"
      >
        <!--城市-->
        <div class="input">
          <img src="../../../static/images/login/companyIcon.png" />

          <el-input
            clearable
            v-model="form.city"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('register.Account')"
        class="input-style"
        :rules="[
          {
            required: true,
            message: $t('register.validateNickName'),
            trigger: 'blur',
          },
        ]"
        prop="nickName"
      >
        <!--用户名-->
        <div class="input">
          <img src="../../../static/images/login/User.png" />
          <el-input
            clearable
            v-model="form.nickName"
            :placeholder="$t('register.PleaseInput')"
          ></el-input>
        </div>
      </el-form-item>
      <!-- </el-form> -->
    </div>
  </div>
</template>

<script>
import util from "@/comm/util.js";
import api from "@/comm/api";
export default {
  props: ["form"],
  data() {
    return {
      waitCode: "60",
      codeType: 0,
      rules: [],
    };
  },
  methods: {
    getCode: util.debounce(function () {
      //防抖成功
      // console.log("防抖成功");
      this.codeType = 1;
      // 发送验证码
      this.setCode();
      // this.$message("发送成功");
      // this.beginInterval();
    }),

    setCode() {
      var dat = {
        type: 1,
        operation: this.form.loginName,
      };

      api.registerCode(dat).then((res) => {
        if (res) {
          this.$message.success(this.$t("login.sendCode"));
          this.beginInterval();
        }
      });
    },

    beginInterval() {
      this.waitCode = this.waitCode - 1;
      if (this.waitCode <= 0) {
        this.waitCode = 60;
        this.codeType = 0;
        this.disabled = false;
      } else {
        setTimeout(this.beginInterval, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep .el-input__inner {
  border: 0;
  background-color: transparent;
}
:deep .el-form-item {
  display: flex;
  flex-direction: column;
}
:deep .el-form-item__label {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #a8acb9;
  padding-left: 20px;
  box-sizing: border-box;
}
:deep .left37 .el-form-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep .el-select {
  width: 400px;
}
.contens {
  width: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-style {
  width: 405px;
  margin-top: 20px;
  .submit {
    width: 408px;
    height: 69px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 99px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 69px;
    letter-spacing: 2px;
    margin-top: 80px;
    margin-bottom: 40px;
    cursor: pointer;
  }
  .get-code {
    cursor: pointer;
    width: 154px;
    height: 56px;
    opacity: 1;
    background: #f5ad34;
    border-radius: 100px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  .input3 {
    width: 140px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .el-select {
      width: 140px;
    }
  }
  .input2 {
    width: 228px;
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;

    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .input {
    height: 56px;
    background: #eff0f4;
    border-radius: 100px;
    overflow: hidden;
    .el-input >>> .el-input__inner {
      border: none !important;
      background: #eff0f4;
    }
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #a8acb9;
    margin: 20px 0 10px 20px;
  }
}
</style>
