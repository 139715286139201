<template>
	<vue-easy-print ref="printRef">
		<div style="height: 287mm;">
			<img class="print-logo" src="../../assets/printLogo.png" />
			<div style="text-align: center; margin: 0 30px; border: 0.5px solid #666666;">
				<el-row style="border: 0.5px solid #666666;">
					<el-col :span="8"
						style="border-right: 1px solid #666666;margin-top: 0;color: #666;font-size: 16px;font-weight: 400;">
						<div class="t-item" style="text-align: center; padding: 10px;">
							<p style="margin: 0;margin-bottom: 20px;">Document N.</p>
							<p style="margin: 0;font-size: 20px;font-weight: bold;">{{printObj.invoiceNo}}</p>
						</div>
					</el-col>
					<el-col :span="8"
						style="border-right: 1px solid #666666;margin-top: 0; color: #666; font-size: 16px; font-weight: 400;">
						<div class="t-item" style="text-align: center; padding: 10px;">
							<p style="margin: 0;margin-bottom: 20px;">Document Date</p>
							<p style="margin: 0;font-size: 20px;font-weight: bold;">{{printObj.createDate}}</p>
						</div>
					</el-col>
					<el-col :span="8" style="margin-top: 0; color: #666; font-size: 16px; font-weight: 400;">
						<div class="t-item" style="text-align: center; padding: 10px;">
							<p style="margin: 0;margin-bottom: 20px;">Page</p>
							<p style="margin: 0;font-size: 20px;font-weight: bold;">1</p>
						</div>
					</el-col>
				</el-row>
				<el-row style="border: 0.5px solid #666666;">
					<el-col :span="8"
						style="border-right: 1px solid #666666;margin-top: 0; color: #666; font-size: 16px; font-weight: 400;">
						<div class="t-item2"
							style="display: flex;align-items: center;justify-content: center;height: 50px;">
							<span>Description</span>
						</div>
					</el-col>
					<el-col :span="8"
						style="border-right: 1px solid #666666;margin-top: 0; color: #666; font-size: 16px; font-weight: 400;">
						<!-- <div class="t-item2">
                <div>Quantity Individual</div>
                <div>Quantity Group</div>
              </div> -->
						<div class="t-item2"
							style="display: flex;align-items: center;justify-content: center;height: 50px;">
							<span>Quantity Individual</span>
						</div>
					</el-col>
					<el-col :span="8" style="margin-top: 0; color: #666; font-size: 16px; font-weight: 400;">
						<div class="t-item2"
							style="display: flex;align-items: center;justify-content: center;height: 50px;">
							<div
								style="height: 50px;width: 50%;display: flex;align-items: center;justify-content: center;border-right: 1px solid #666666;">
								Unit Price</div>
							<div
								style="height: 50px;width: 50%;display: flex;align-items: center;justify-content: center;">
								Total Price</div>
						</div>
					</el-col>
				</el-row>
				<el-row style="border: 0.5px solid #666666;">
					<el-col :span="8" class="t-item4"
						style="margin-top: 0; color: #666; font-size: 16px; font-weight: 400;height: 600px;border-right: 1px solid #666666;">
						<div class="t-item4-c" style="width: 100%;min-height: 20px;">
							<div style="text-align: left;line-height: 25px;height: 60px;">
								<p>Visa fee</p>
								<p>N.{{printObj.orderNumber}} date: {{printObj.createDate}}.</p>
							</div>
							<!-- <div style="text-align: left;line-height: 25px;height: 50px;">
								<p>Visa fee</p>
								<p>N.2023/000031 date: 29 Jan.</p>
							</div> -->
							<!-- <p style="font-size: 14px;">(单号和下单日期)</p> -->
						</div>
					</el-col>
					<el-col :span="8" class="t-item4"
						style="margin-top: 0; color: #666; font-size: 16px; font-weight: 400;height: 600px;border-right: 1px solid #666666;">
						<!-- <div class="t-item5">
                <div class="t-item5-c">
                  <div>1</div>
                  <div></div>
                  <p>(商务签人数)</p>
                </div>
                <div class="t-item5-c">
                  <div></div>
                  <div>2</div>
                  <p>(团签人数)</p>
                </div>
              </div> -->
						<div class="t-item4-c" style="width: 100%;min-height: 20px;">
							<div style="margin: 0 20px;text-align: left;height: 60px;">
								<div style="text-align: center;line-height: 60px;line-height: 60px;">{{printObj.peopleNumber}}</div>
							</div>
							<!-- <div style="margin: 0 20px;text-align: left;line-height: 25px;height: 50px;">
								<div style="text-align: center;line-height: 50px;">2</div>
							</div> -->
							<!-- <p style="font-size: 14px;">({{printObj.childType == 6 ? '团签' : '商务签' }}人数)</p> -->
						</div>
					</el-col>
					<el-col :span="8" class="t-item4"
						style="margin-top: 0; color: #666; font-size: 16px; font-weight: 400;border-right: 0;height: 600px;">
						<div class="t-item5" style="display: flex;align-items: center;justify-content: space-between;">
							<div class="t-item5-c" style="width: 100%;text-align: center;height: 600px;">
								<div style="height: 60px;display: flex;align-items: center;justify-content: center;">
									{{printObj.unitPrice}}</div>
								<!-- <div style="height: 50px;display: flex;align-items: center;justify-content: center;">
									149.00</div> -->
								<!-- <p>(单价)</p> -->
							</div>
							<div class="t-item5-c" style="width: 100%;text-align: center;height: 600px;">
								<div style="height: 60px;display: flex;align-items: center;justify-content: center;">
									{{printObj.totalPrice}}
								</div>
								<!-- <div style="height: 50px;display: flex;align-items: center;justify-content: center;">2
								</div> -->
								<!-- <p>(总价)</p> -->
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div style="height: 50px; position: relative; ">
				<div style="display: flex; position: absolute; right: 30px; bottom: 0;">
					<div class="t-item6"
						style="width: 150px;height: 40px;border: 1px solid #666666;text-align: center;line-height: 40px;">
						TOTAL EUR</div>
					<div class="t-item6"
						style="width: 150px;height: 40px;border: 1px solid #666666;text-align: center;line-height: 40px;">
						{{printObj.totalPrice}}</div>
				</div>
			</div>
			<!-- <div style="height: 25px; position: relative; ">
				<div style="display: flex; position: absolute; right: 50px; bottom: 0;">
					(总金额)
				</div>
			</div> -->
			<div class="print-bottom">
				<p>Shanghai Orange&Orange Travel Agency Co., Ltd Ningguo Rd. 228, Junjiang lnt.L Bui lding, Apt. 1803
					Shanghai
					200090 China Tel: +86-21 61806991 Fax: +86-21 61806993 http://www.chinavisadirect.com</p>
				<img class="print-zhang" src="../../assets/printZhang.png" />
			</div>
		</div>
	</vue-easy-print>
</template>

<script>
	import vueEasyPrint from "vue-easy-print";
	export default {
		components: {
			vueEasyPrint
		},
		props: {
			data: ''
		},
		data() {
			return {
				printObj: {}
			}
		},
		methods: {
			handlPrint() {
				this.printObj = this.data
				this.$nextTick(()=>{
					this.$refs.printRef.print()
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	// 设置页眉页脚
	@page {
		size: auto;
		margin: 5mm 10mm;
	}

	.print-logo {
		width: 100%;
		padding-bottom: 5mm;
		border-bottom: 2px solid #ccc;
		margin-bottom: 5mm;
	}

	.print-bottom {
		width: 100%;
		border-top: 2px solid #ccc;
		margin-top: 5mm;
		text-align: center;
	}

	.print-zhang {
		width: 50mm;
		height: 45mm;
		position: absolute;
		right: 10mm;
		bottom: 40mm;
		z-index: 9999;
	}

	.t-item {
		text-align: center;
		padding: 10px;

		p {
			margin: 0;

			&:first-child {
				margin-bottom: 20px;
			}

			&:last-child {
				font-size: 20px;
				font-weight: bold;
			}
		}
	}

	.t-item2 {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;

		div {
			height: 50px;
			padding: 10px 0;
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			&:first-child {
				border-right: 1px solid #666666;
			}
		}
	}

	.t-item3 {
		height: 600px;
		font-weight: 500;

		div {
			margin: 0 20px;

			p {
				text-align: left;
			}
		}

		p {
			margin: 5px;
		}
	}

	.t-item4 {
		height: 600px;
		border-right: 1px solid #666666;

		&:last-child {
			border-right: 0;
		}

		.t-item4-c {
			width: 100%;
			min-height: 20px;

			div {
				margin: 0 20px;
				text-align: left;
				line-height: 25px;
				height: 50px;

				p {
					margin: 0;
				}

				div {
					text-align: center;
					line-height: 50px;
				}
			}
		}

		.t-item5 {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.t-item5-c {
				width: 100%;
				text-align: center;
				height: 600px;

				&:first-child {
					border-right: 1px solid #666666;
				}

				div {
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	.t-item6 {
		width: 150px;
		height: 40px;
		border: 1px solid #666666;
		text-align: center;
		line-height: 40px;

		&:first-child {
			border-right: 0;
		}
	}

	.el-col-8,
	.el-col-16,
	.el-col-24,
	.flexRow {
		margin-top: 0;
		color: #666;
		font-size: 16px;
		font-weight: 400;
		// border: 0.5px solid #666666;
	}
</style>
