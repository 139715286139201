<template>
  <div class="page-wrap">
    <top-search-three :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''" :advertises="advertises" />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="international-student-news">
        <div class="list" v-for="(item,index) of newList" :key="index">
          <div @click="$router.push({path: '/overseasStudentNewsDetails',query:{id:item[0].id}})" class="detail top90" v-if="item && item[0]">
            <img :src="item[0].faceImage" class="left right70" />
            <div class="right">
              <div class="xian"></div>
              <div class="detail-title">{{ JSON.parse(item[0].title)[$i18n.locale] }}</div>
              <div class="detail-desc">{{ JSON.parse(item[0].describe)[$i18n.locale] }}</div>
            </div>
          </div>
          <div @click="$router.push({path: '/overseasStudentNewsDetails',query:{id:item[1].id}})" class="detail top90" v-if="item && item[1]">
            <div class="right">
              <div class="xian"></div>
              <div class="detail-title">{{ JSON.parse(item[1].title)[$i18n.locale] }}</div>
              <div class="detail-desc">{{ JSON.parse(item[1].describe)[$i18n.locale] }}</div>
            </div>
            <img :src="item[1].faceImage" class="left left70" />
          </div>
          <div @click="$router.push({path: '/overseasStudentNewsDetails',query:{id:item[2].id}})" class="center" v-if="item && item[2]" :style="{backgroundImage: 'url(' + item[2].faceImage + ')', backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat'}">
            <div class="center-title"></div>
            <div class="center-desc">{{ JSON.parse(item[2].describe)[$i18n.locale] }}</div>
            <div class="center-bottom">{{ JSON.parse(item[2].title)[$i18n.locale] }}</div>
          </div>
        </div>
      </div>
    </div>
      
    <!-- 翻页 -->
    <div class="pages">
      <page :total="total" :page-size="tableForm.pageSize" @change-page="changePage"></page>
    </div>
    <contact-us-one></contact-us-one>
  </div>
</template>

<script>
import page from "@/components/page";
import ContactUsOne from "../home/contactUsOne.vue";
import topSearchThree from "../home/topSearchThree.vue";
import Carousel from "../../components/Carousel/Carousel";
import util from "../../comm/util";
import api from "../../comm/api";
import { useStore } from "vuex";
import { reactive, toRefs, onMounted, watchEffect } from "vue";
export default {
  components: { Carousel, page, ContactUsOne, topSearchThree },
  setup() {
    const store = useStore();
    //翻页
    const params = reactive({
      total: 0,
      tableForm: { page: 1, pageSize:6},
    });
    const states =reactive({
      advertises: [],//广告位
      bannerList: [],//banner 数组
      newList:[],//新闻列表
    })
    //获取banner 图
    const getBanners = async () => {
      states.bannerList = await api.getBanner({ type: "NEWS" });
    };
    //监听
    watchEffect(() => {
      // 获取广告位
      states.advertises = store.state.advertisesList;
      // 获取头部className
      states.className = util.changeClassName(states.advertises.length,states.bannerList.length);
    });

    //获取新闻列表
    const deliciousNews = (() => {
      api.deliciousNews(params.tableForm).then((res) => {
        params.total = res.total;
        let lists = res.records;
        // 将数组分割成3个一组
        for(var i=0;i<lists.length;i+=3){
            states.newList.push(lists.slice(i,i+3));
        }
      });
    });
    
    

    // 改变页码
    const changePage = ((e) => {
      params.tableForm.page = e;
      deliciousNews();
    })
    
    onMounted(() => {
      store.dispatch("getAdvertisesData", 2);// 获取广告位 图 
      window.scrollTo(0, 0);
      getBanners();//获取banner
      deliciousNews();//新闻列表
    });
    return {
      ...toRefs(states),
      ...toRefs(params),
      getBanners,//获取banner图
      changePage
    }
  }
};
</script>

<style scoped lang="scss">
.international-student-news {
  .list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  .check-page {
    display: flex;
    width: 85%;
    margin-top: 50px;
    justify-content: space-between;
  }
  .center {
    width: 100%;
    height: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    .center-bottom {
      width: 800px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .center-desc {
      width: 621px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 34px;
      margin: 20px 0 50px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
    .center-title {
      font-size: 60px;
      color: #ffffff;
    }
  }
  .title {
    font-size: 44px;
    font-weight: 600;
    color: #222a41;
    padding: 0 20px 0 20px;
    text-align: center;
    margin-bottom: 65px;
    background: linear-gradient(
      to right,
      #fdeed4 236px,
      #fdeed4 236px,
      white 52%,
      white 100%
    );
    margin-top: 100px;
  }
  .top90 {
    margin-top: 90px;
  }
  .detail {
    display: flex;
    cursor: pointer;
    .left {
      width: 720px;
      height: 530px;
      object-fit: cover;
    }
    .left70 {
      margin-left: 70px;
    }
    .right70 {
      margin-right: 70px;
    }
    .right {
      display: flex;
      flex-direction: column;
      .xian {
        margin: 60px 0 30px 0;
        width: 170px;
        height: 6px;
        background: #e7c182;
      }
      .detail-title {
        width: 470px;
        font-size: 40px;
        color: #333333;
        line-height: 48px;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .detail-desc {
        width: 489px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
      }
    }
  }
}
}


.pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 20px
}

</style>
