<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className">
      <Carousel :list="bannerList" />
      <div class="recruit">
        <div class="recruit-wrap">
          <!--收藏-->
          <Favorite :favorite="details.favorite" @collect="collect" />
          <!--旅游攻略详情-->
          <div v-html="newInfos"></div>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import Carousel from "../../components/Carousel/Carousel";
import Favorite from "../../components/Favorite/Favorite";
import util from "../../comm/util";
import api from "../../comm/api";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { reactive, toRefs, onMounted, watchEffect } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  components: {
    Favorite,
    Carousel,
    topSearchThree,
    contactUsOne,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const states = reactive({
      local: localStorage.getItem("local")
        ? localStorage.getItem("local")
        : "US", //中英文
      newsId: useRoute().query.id, //获取父页面 路由传参值  新闻id
      advertises: [], //广告位
      bannerList: [], //banner 数组
      newInfos: null, //详情
      details: "",
    });
    //监听
    watchEffect(() => {
      // 获取广告位
      states.advertises = store.state.advertisesList;
      // 获取头部className
      states.className = util.changeClassName(
        states.advertises.length,
        states.bannerList.length
      );
    });

    //获取详情
    const tourismStrategyInfos = async () => {
      await api.tourismStrategyInfos({ id: states.newsId }).then((res) => {
        states.details = res;
        states.bannerList = res.banner.split(","); //banner图
        states.newInfos = JSON.parse(res.content)[states.local];
        //处理富文本图片
        const regex = new RegExp("<img", "gi");
        states.newInfos = states.newInfos.replace(
          regex,
          `<img style="width: 100%; height: auto;margin:10px 0;"`
        );
        states.newInfos = states.newInfos
          .replace(
            "<iframe",
            `<video style="width:100%;" controls="" autoplay=""`
          )
          .replace("</iframe>", "</video>");
      });
    };

    // 收藏
    const collect = () => {
      const val = states.details;
      let favorite = val.favorite ? false : true;
      api
        .tourismStrategyUpdateFavorite({
          tourismId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            states.details.favorite = favorite;
          }
        });
    };

    onMounted(() => {
      store.dispatch("getAdvertisesData", 13); // 获取广告位 图
      window.scrollTo(0, 0);
      tourismStrategyInfos(); //获取详情
    });

    return {
      ...toRefs(states),
      tourismStrategyInfos, //获取详情
      collect, //收藏
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background: #fff;
}
.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 70%;
}
.travel-title {
  font-size: 24px;
  color: #444444;
  margin-bottom: 40px;
}
.travel-desc {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  text-align: justify;
}
img {
  width: 100%;
  height: auto;
}
</style>
