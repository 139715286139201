module.exports = {
  language: {
    chinese: "Chinese", //简体中文
    English: "English", //英文
    Italiano: "Italiano", //意大利
    Español: "Español", //西班牙
    Français: "Français", //法语
    Deutsch: "Deutsch", //德语
  },
  commonData: {
    orderStatus: [
      { value: 0, label: "No pagado" },
      { value: 1, label: "No pagado" },
      { value: 2, label: "En revisión" },
      { value: 3, label: "Completado" },
      { value: 4, label: "Rechazado" },
      { value: 5, label: "Cancelado" },
      { value: 6, label: "Pagado" },
      { value: 7, label: "Reembolso" },
    ],
    paymentMode: [
      { value: 1, label: "Tarjeta de crédito" },
      { value: 2, label: "Paypal" },
      { value: 3, label: "Alipay" },
      { value: 4, label: "Depósito" },
    ],
    type: [
      { value: 1, label: "Visado chino" },
      { value: 2, label: "Tarjeta Wi-Fi" },
      { value: 3, label: "Vip Recogida Aeropuerto" },
    ],
    childType: [
      { value: 1, label: "Visado de estudios" },
      { value: 2, label: "Visado de trabajo" },
      { value: 3, label: "China PR" },
      { value: 4, label: "Renovación del visado de trabajo" },
      { value: 5, label: "Business e-visa" },
      { value: 6, label: "Visado colectivo" },
      { value: 7, label: "Vip Welcome" },
      { value: 8, label: "Traslados Aeropuerto Vip" },
      { value: 9, label: "Tarjeta Wi-Fi" },
    ],
    occupationList: [
      { label: "Empleado", value: 1 },
      { label: "Empresario", value: 2 },
      { label: "Ama de casa", value: 3 },
      { label: "Retirado", value: 4 },
      { label: "Estudiante", value: 5 },
      { label: "Niño", value: 6 },
    ],
    emergencyContactRelationshipList: [
      { label: "Padres", value: 1 },
      { label: "Familiares", value: 2 },
      { label: "Hermana", value: 3 },
      { label: "Hermano", value: 4 },
      { label: "Amigo", value: 5 },
      { label: "Cónyuge", value: 6 },
      { label: "Hija/o", value: 7 },
    ],
    punishList: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
    comStatus: [
      { label: "Sí", value: 1 },
      { label: "No", value: 2 },
    ],
    gender: [
      { label: "masculino", value: 1 },
      { label: "femenino", value: 0 },
    ],
    maritalStatus: [
      { label: "Casado", value: 1 },
      { label: "Solteros", value: 2 },
      { label: "Divorcio", value: 3 },
      { label: "Viuda/o", value: 4 },
    ],
    education: [
      { label: "PostDoctor", value: "PostDoctor" },
      { label: "Doctorado", value: "Doctorate" },
      { label: "Master", value: "Master" },
      { label: "Licenciatura", value: "Undergraduate" },
      { label: "Bachillerato", value: "High school" },
      { label: "Por debajo del bachillerato", value: "Below high school" },
    ],
    phoneType: [
      { label: "China +86", value: 1 },
      { label: "otros países", value: 2 },
    ],
    serviceType: [
      { title: "Normal", desc: "5 días laborables", value: 1 },
      {
        title: "Urgente",
        desc: "3 días laborables. El servicio urgente tiene un coste adicional",
        value: 2,
      },
      {
        title: "Extra urgente",
        desc: "2 días laborables. El servicio urgente requiere una tarifa adicional",
        value: 3,
      },
    ],
    groupServiceType: [
      {
        title: "Normal",
        desc: "5 días laborables",
        value: 1,
      },
      {
        title: "Urgente",
        desc: "3 días laborables. El servicio urgente tiene un coste adicional",
        value: 2,
      },
    ],
    vipCars: [
      { title: "Business 7 plazas", value: 1 },
      { title: "Premium 5 plazas", value: 2 },
    ],
    expressType: [
      { title: "Fedex", value: 1 },
      { title: "DHL", value: 2 },
      { title: "SF-EXPRESS", value: 3 },
      { title: "EMS", value: 4 },
    ],
  },
  contactLink: {
    aboutUs: "Acerca de nosotros",
    chineseVisa: "Visado chino",
    contactUs: "Contacta con nosotros",
    insurance: "Seguro",
    advertise: "Publicidad",
    wcic: "Tarjeta Wi-Fi",
    faq: "FAQ",
    vipApu: "Transporte aeroportuario",
    privacy: "Privacy",
    wechat: "APP",
  },
  common: {
    copyright: "©2021 Hereshanghai.com, All Rights Reserved.",

    collectSuccess: "Salvado", // 收藏成功
    cancelSuccess: "Anulado", // 已取消收藏
    cancelCollection: "Eliminar", // 取消收藏
    collectFail: "Fracaso", // 收藏失败
    unit: "",
    month: "Mes", //
    create: "Primer puesto", // 首次发布于
    update: ", Última actualización", // 最后更新于
    Viewed: " · Visualizado", // 被浏览
    times: " Veces", // 次
    isImage: "La imagen debe estar en formato GIF、JPG、PNG!", // 图片限制
    isLt2M: "El tamaño de la imagen no debe superar los 2 MB!", // 大小限制
    success: "La solicitud se ha enviado correctamente", // 申请成功
    payFail: "Pago fallido", // 付款失败
    paySuccess: "Pago efectuado", // 付款成功
    updSuccess: "Modificado correctamente", // 修改成功
    loginAgain: "Conéctese de nuevo!", // 请重新成功
    updSubmit: "Confirmación", // 确定修改
    codeError: "Código de verificación incorrecto", // 验证码错误
    username: "Nombre usuario", // 用户名
    FocusOnSuccess: "Seguir", //关注成功
    AttentionCancelled: "Anulado", //已取消关注
    PublishedSuccessfully: "Publicado con éxito", //发布成功
    delete: "Eliminar", // 删除
    deleteSuccess: "Eliminado", // 删除成功
    Redact: "Edite", // 编辑
    Note: "Nota", // 注意
    NotLoggedIn: "Conectarse", //未登录
    Deleted: "Eliminado", //已删除
    Rejected: "Rechazado", //已驳回
    noVisa: "No es posible solicitar la nacionalidad elegida", //该国籍不能办理
    noCity: "No proporcionamos recepción VIP para la ciudad seleccionada", //该城市没有Vip接机包车
    sample: "Ejemplo", //
    aggrement: "Términos y condiciones generales/Política de privacidad",
    printInvoice: "Imprimir recibo",
  },
  // 头部导航菜单
  navigation: {
    Back: "Página de inicio",
    SignIn: "Iniciar sesión",
    Register: "Regístrate",
    // 这里英文也需要更改
    studentLife: "CHINA TOURS", // 英文: CHINA TOURS  西班牙语：CHINA TOURS
    visaTravelServices: "VISADO ONLINE CHINA", // 英文：CHINA ONLINE VISA  西班牙语：VISADO ONLINE CHINA
    livingInShangHai: "IN SHANGHAI", // 英文：IN SHANGHAI 西班牙语：IN SHANGHAI
  },
  message: {
    success: "éxito",
    registerSuccess: "Registro con éxito",
    title: "Registro con éxito",
    desc: "Mantenga a salvo los datos de su cuenta",
    goHome: "Ir a la página principal",
  },
  // 重置密码
  resetPw: {
    title: "Restablecer contraseña olvidada",
    password: "Establecer una contraseña de 6-20 caracteres",
    next: "Procede",
  },
  //登录
  login: {
    EmailLogin: "Email", // 邮箱登录
    Email: "Email",
    MobileNumber: "Número de teléfono", //短信登录
    accountNumber: "Email", //账号
    Password: "Contraseña", //密码
    PleaseEnterYourEmailAddress: "Introducir dirección emai", //请输入邮箱
    PleaseEnterYourPassword: "Introduzca la contraseña", //'请输入密码'
    MobilePhone: "Número de teléfono (sólo para números de teléfono chinos)", //手机号
    PleaseEnterYourPhone: "Introduzca su número de teléfono", //请输入手机号
    Authcode: "Código de autorización", //验证码
    PleaseEnterTheAuthCode: "Introducir el código de autorización", //请输入验证码
    GetCode: "Obtener el código", //获取验证码
    SignIn: "Iniciar sesión", //登录
    StayloggedIn: "Mantente conectado", //记住登录状态
    Register: "Regístrese en", //注册新用户
    ForgotPassword: "¿Ha olvidado su contraseña?", //忘记密码
    Individual: "Privado", //个人
    CompanyOrAgency: "Empresa/Agencia", //公司
    Company: "Empresa/Agencia", //公司
    sendCode: "Enviado con éxito", //send successfully
  },
  //注册
  register: {
    validateRealName: "Nombre y apellido", //验证姓名
    validatePhone: "Número de teléfono", //验证手机号
    validatePhoneInspect: "Compruebe si el número de teléfono es correcto", //检查手机号是否正确
    validateNewPhone:
      "El nuevo número de teléfono no puede ser el mismo que el anterior", //验证手机号
    validateEmail: "Introduzca su dirección de correo electrónico",
    validateEmailInspect:
      "Introduzca la dirección de correo electrónico correcta",
    validateEmailCode:
      "Introduzca el código de verificación de correo electrónico",
    validateCountry: "Nacionalidad",
    validatePassword: "Introduzca una contraseña",
    validatePasswordAgin: "Confirmar contraseña",
    validatePasswordAtypism: "Las dos contraseñas no son coherentes",
    validateNickName: "Nombre usuario",
    validatePhoneCode: "Introduzca el código de verificación SMS",

    validateCompanyName: "Nombre de la empresa/agencia",
    validateCompanyType: "Tipo de empresa",
    validateCompanySn: "IVA",
    validateCompanyWebsite: "Sitio web de la empresa",
    validateCompanyAddress: "Dirección de la empresa",
    validateCompanyPost: "Código postal",
    validateCompanyCountry: "Seleccione un país",
    validateCompanyCity: "Ciudad",
    validateCompanyContact: "Nombre usuario",

    EmailRegister: "Registro por correo electrónico", //邮箱注册
    MobileNumberRegister: "Registro con número de teléfono", //手机号注册
    checkBox: "Acepto ", //勾选同意 《用户服务协议》
    checkBoxText: "Términos y condiciones generales/Política de privacidad",
    checkBoxUserAgreement: "Condiciones de utilización",
    AlreadyRegisteredSignIn: "¿Ya se ha registrado? Conectarse", //已有账号，立即登录
    Register: "Regístrese en", //注册
    PleaseSelect: "Seleccione", //请选择
    PleaseInput: "Entre en", //请输入
    Email: "Email", //邮箱
    AuthCode: "Código de autorización", //确认邮箱
    Password: "Contraseña", //密码
    ConfirmedPassword: "Confirmación de contraseña", //确认密码
    CompanyName: "Nombre de la empresa", //公司名称
    Typology: "Tipo", //公司类型
    VATTax: "IVA", //税号
    Website: "página web", //网址
    ContactPerson: "Persona de contacto", //联系人
    PhoneNumber: "Número de teléfono", //电话号码
    Address: "Dirección", //地址
    PostalCode: "Código postal", //邮编
    Country: "País", //国家
    City: "Ciudad", //城市
    Account: "Usuario", //用户名
    GetCode: "Obtener el código", //获取验证码

    Name: "Nombre y apellido", //真实姓名
    MobileNumber: "Número de teléfono", //电话号码
    Nationality: "Nacionalidad", //国籍
    MobileNumberchina: "Número de teléfono chino", //手机号(中国)
    yam: "Código de autorización", //验证码
  },
  home: {
    hereStore: "Here-Store",
    FoodRecommendation: "Tarjeta WI-FI/Internet en China", // 这个模块要求删除 替换成英文：WI-FI card/Internet in China 意大利语：Tarjeta WI-FI/Internet en China
    LeisurePlay: "Things to do", // 这个模块要求删除
    visa: "Visado",
    Journalism: "Noticias",
    ContactUs: "Contáctenos",
    AcceptanceOrganization: "Organización para la aceptación",
    AcceptanceOrganizationInfo:
      "La Administración Estatal de Inmigración confía a la Administración de Entrada y Salida de los Órganos de Seguridad Pública a nivel de prefectura o superior (incluidos los distritos y condados bajo la jurisdicción del municipio que depende directamente del gobierno central) la tarea de aceptar los permisos de entrada y salida de extranjeros. Previa aprobación de la Administración Estatal de Inmigración y la instrucción de la Administración de Entrada y Salida del Cuerpo de Seguridad Pública a nivel de condado, la Administración de Entrada y Salida del Cuerpo de Seguridad Pública a nivel de condado podrá aceptar los permisos de entrada y salida de extranjeros por instrucción de la Administración Estatal de Inmigración.",
  },
  contact: {
    email: "Email", //邮箱
    name: "Nombre y apellido", //姓名
    nationality: "Nacionalidad", //国籍
    title: "Título", //标题
    content: "Contenido", //内容
    verification: "Verificación", //验证
    submit: "Envíe",
    insertEmail: "Dirección de correo electrónico",
    insertEmail2: "Dirección de correo electrónico",
    insertName: "Nombre y apellido",
    insertTitle: "Título",
    insertContent: "Información",
    insertCountry: "Nacionalidad",
    slidingValidation: "Mantén pulsado y arrastra hacia la derecha",
    validateEmailCode: "Introduzca el correo electrónico correcto",
    validateLengthCode: "La duración mínima es de 2",
    validateLengthContent: "La longitud debe oscilar entre 10 y 150",
    bottomTitle: "HereShanghai",
    bottomDesc:
      "Shanghai Orange & Orange Travel Agency Co., Ltd. - 251 YaoHua Rd.Free-Trade Zone Shanghai (China) Teléfono: +86 21 61806991 Móvil: +86 16601891870 E-mail: info@chinavisadirect.com info@hereshanghai.com",
  },
  groupSignature: {
    insertEmail: "Correo electrónico",
    tipBeCare: "Nota: ",
    // 确定团员信息注意
    submitBeCareFul: {
      title:
        "Por favor, compruebe los datos de cada miembro, el visado se expedirá de acuerdo con los datos introducidos.",
    },
    // 查看团员信息注意
    detailsBeCareFul: {
      title:
        "Por favor, compruebe los datos de cada miembro, el visado se expedirá de acuerdo con los datos introducidos.",
    },
    // 申请成功注意
    successBeCareFul: {
      title:
        "Nota: Su solicitud ha sido enviada, ¡gracias! Después de comprobar el archivo, le enviaremos un correo electrónico de confirmación.",
    },
    // 支付后的状态提示
    payAfter: {
      successText: `La solicitud ha sido enviada, puede comprobar el estado en su cuenta en "Mis órdenes".`,
    },
    // 常规注意
    beCareFul: {
      title: `
        El visado colectivo se expide a un grupo de <span style="font-weight: 900">al menos 2</span> y un máximo de 8 miembros.<br />
        Rellene y adjunte los datos del primer pasajero y, a continuación, rellene los del segundo y tercer... pax.<br />
         <span style="font-weight: 900">Lo que hay que saber antes de solicitar un visado:</span><br />
        El visado colectivo se solicita en un único certificado para todo el grupo, en lugar de en el pasaporte individual. Todos los miembros del grupo deben viajar juntos tanto a la entrada como a la salida de China.
      `,
      infoTitle: "Lo que hay que saber antes de solicitar un visado:",
      infoDesc:
        "El visado colectivo se aplica a un único certificado para todo el grupo, en lugar de a un pasaporte individual. Todos los miembros del grupo deben viajar juntos tanto a la entrada como a la salida de China.",
    },
    // 学习签证注意提示
    comTipBeCareFul: {
      title:
        "Tras recibir su solicitud, nuestro personal se pondrá en contacto con usted por correo electrónico para informarle de los documentos que debe aportar para solicitar el visado.", // 一个订单只能申请一个人。
      success:
        "Su solicitud ha sido enviada, ¡gracias! Después de comprobar el archivo, le enviaremos un correo electrónico de confirmación.", // 申请成功提示
    },
    uploadInfo: {
      format: "Formatos compatibles: GIF/JPG/PNG/PDF", // 支持格式
      success: "Carga con éxito", // 上传成功
      see: "Ver", // 点击查看
      uploadText: "Pulse para cargar", // 上传文件
    },
    tipText: {
      voluntarily: "Nota: Comprobar datos personales!", // 自动填充
    },
    essentialInformation: "Información personal", //基本信息
    personnelInformation: "Dati personali del passeggero", //人员信息
    otherInformation: "Datos personales de los pasajeros", //其他信息
    entryInformation: "Información sobre viajes", //入境信息
    uploadInformation: "Cargar documentos", //上传信息
    confirmationInformation: "Verificación y confirmación", //确认信息
    serviceMode: "Tipo de servicio", //服务方式
    payment: "Pago", //付款
    applicationStatus: "Estado de la práctica", //申请状态
    pleaseEnter: "Entre en", //请输入
    pleaseSelect: "Seleccione", //请选择
    confimNex: "Confirmar y proceder",
    memberTitle: "Añadir pasajero：", // 已添加成员
    rules: {
      passport: "Subir copia del pasaporte", // 请上传护照
      vipTicket: "Subir copia de los billetes de viaje", // 请上传往返机票
      ticket: "Subir copias de los billetes de avión o tren", // 请上传往返机票/火车票照片
      hotelOrder: "Subir copia de las reservas de hotel", // 请上传酒店订单
      englishName: "Sólo se pueden introducir mayúsculas", // 只能输入2至20位英文
      idCard: "Subir copia de la foto de pasaporte", //请上传证件照
      vaccinationCertificate: "Subir copia del certificado de vacunación", //请上传疫苗接种证明
      invitationLetter: "Subir copia de la carta de invitación.", //请上传邀请函
      companyLicense: "Subir copia de la licencia de empresa china", //请上传中国公司营业执照
      deposit: "Subir recibo de transferencia bancaria", //请上传押金凭证
    },
    submitInfo: {
      name: "Nombre y apellido", //英文姓名
      No: "No.", //序号
      seeDetails: "Ver detalles", //查看详情
      passengersInfo: "Información para pasajeros", //团成员信息
    },
    informationForm: {
      //基本信息表单
      SerialNumber: "No.", //序号
      passport:
        "Subir copia del pasaporte (página que contiene la foto y los datos personales del pasaporte)", //护照识别
      EnglishSurname: "Apellido", //英文姓
      EnglishName: "Nombre", //英文名
      ChineseName: "Nombre chino", //中文名
      nationality: "Nacionalidad", //国籍
      Gender: "Sexo", //性别
      dateBirth: "Fecha de nacimiento", //出生日期
      birthplace: "Lugar de nacimiento", //出生地
      placeIssue: "Lugar de expedición", //护照签发地
      certificatesNumber: "Número de pasaporte", //所持证件号码
      passengersEmail: "Correo electrónico del pasajero", //所持证件号码
      CertificateDate: "Fecha de expeccion", //所持证件签发日期
      CertificateDateValid: "Fecha de caducidad", //所持证件有效日期
      occupation: "Profesión", //职业
      emergencyContact:
        "Nombre de la persona de contacto en caso de emergencia", //紧急联系人
      emergencyContactRelationship: "Relación con la persona de contacto", //与紧急联系人关系
      EmergencyContactInformation: "Teléfono de urgencias", //紧急联系人方式
      punish: "¿Tiene antecedentes penales en China o en otros países?", //曾在中国因违法被处罚的情况
      OtherNameInChina:
        "S'agit-il de votre premier passeport ou en avez-vous eu un autre dans le passé ? (premier passeport/avait un ancien passeport)?", //出境入境中国使用的其他名字
      OtherNationalities: "¿Tiene alguna otra nacionalidad?", //具有其他国籍
      getShop: "Destinatario",
      maritalStatus: "Estado civil", //婚姻状况
      SpouseName: "Nombre del cónyuge", //配偶姓名
      spouseDate: "Fecha de nacimiento del cónyuge", //配偶出生日期
      SpouseNationality: "Nacionalidad del cónyuge", //配偶国籍
      ResidenceSpouse: "Dirección del cónyuge", //配偶居住地
      SpouseContactNumber: "Número de teléfono del cónyuge", //配偶联系电话
      HighestEducation: "Su cualificación", //最高学历
      SchoolName: "Nombre del instituto", //学校名称
      NameUnitOrganization: "Nombre de la empresa", //单位/机构名称,
      OverseasGroupAgency: "Nombre de la agencia de viajes", //境外组团社
      otherCountriesVisited:
        "Países visitados en los últimos 2 años (excluida China)", //近2年访问过的其他国家（中国除外）
      ExpressAddress: "Dirección postal", //快递地址
      TelephoneNumber: "Número de teléfono", //电话号码
      addressInChina: "Dirección en China", //在华地址
      CarPassengers: "Pasajeros en coche (máx. 4 pax)", //坐车人数（最多为4人）
      DestinationAddress: "Dirección de destino (sólo Shanghai)", //包车目的地的地址
      expressCountry: "País", //国家
      expressCity: "Ciudad", //城市
      expressStreet: "Calle y número de casa", //路名
      expressPostcode: "Código postal", //邮编
      contactAddress: "Dirección", //系地址
      consignee: "Nombre del destinatario", //收货人
    },
    entry: {
      //入境表单
      enterCity: "Ciudad de llegada (China)", //入境城市
      outCity: "Ciudad de partida (China)", //出境城市
      enterDate: "Fecha de llegada a China", //入境日期
      outDate: "Fecha de partida de China", //出境日期
      inboundFlightNo: "Número de vuelo a China", //入境航班
      inboundAirport: "Aeropuerto de entrada en China", //入境机场
      carsType: "Tipo de vehículo", //服务车型
    },
    upload: {
      vipTicket: "Billetes de viaje", //往返机票
      ticket: "Billete de viaje (avión/tren/autocar/barco)", //往返机票/火车票
      hotelOrder: "Reservas de hotel (todas las noches)", //酒店订单
      idCard: "Foto ID", //证件照
      vaccinationCertificate: "Certificado de vacunación", //疫苗接种证明
      invitationLetter: "Carta de invitación", //邀请函
      companyLicense: "Licencia de empresa china", //中国公司营业执照
    },
    confirm: {
      memberInformation: "Información para miembros", //成员信息
      contactNumber: "Número de teléfono", //联系电话
      newMember: "Añadir otro pasajero", //新增成员信息
      viewDetail: "Ver detalles", //查看详情
    },
  },
  // 个人中心
  personalCenter: {
    // 我的信息
    userInfo: {
      avatar: "Fotos de perfil", // 头像
      nickName: "Nickname", // 昵称
      loginName: "Usuario", // 用户名
      name: "Nombre y apellido", // 真实姓名
      companyName: "Nombre de la empresa", // 公司名称
      companyType: "Tipo de empresa", // 公司类型
      vatTax: "IVA", // 税号
      website: "Sitio web", // 网址
      email: "Correo electrónico", // 注册邮箱
      contactPerson: "Persona de contacto", // 联系人
      phone: "Número de teléfono", // 绑定手机号
      mobileNumber: "Número de teléfono", // 电话号码
      password: "Contraseña", // 密码
      nationality: "Nacionalidad", // 国籍
      address: "Dirección", // 地址
      city: "Ciudad", // 城市
      postalCode: "Código postal", // 邮编
      state: "Estado del acceso", // 登录状态
    },
    // 我的信息-按钮
    userInfoBtn: {
      changePhone: "Cambiar el número de teléfono móvil", // 更换绑定
      changePassword: "Restablecer contraseña olvidada",
      changePhoneTag: "(Sólo números de teléfono chinos)", // （只限制中国手机号）
      login: "Iniciar sesión", // 登录
      logout: "Desconecte", // 退出登录
      placeholderInput: "Entre en ", // 请输入,
      newMobileNumber: "Nuevo número de teléfono", // 新手机号
      newPassword: "Nueva contraseña", // 新密码
      confirmedPassword: "Confirmar nueva contraseña", // 确认新密码
      confirmedPasswordInput: "Reconfirmar nueva contraseña", // 请再次确认新密码
      submit: "Enviado", // 完成
    },
  },
  // 搜索
  search: {
    PleaseInput: "Entre en",
  },
  // 提示语
  messageText: {
    groupSignature:
      "Debe solicitarse un visado de grupo para al menos 2 personas.", // 团签至少需要两个人才能提交
  },
  //hereStroe
  hereStroe: {
    buyNow: "Comprar", //立即购买
    address: "Dirección postal", //收货地址
    addressInput: "Introduzca la dirección de entrega", //请先填写收货地址
    addAddress: "Añadir dirección postal", //添加收货地址
    nameInput: "Destinatario", //收货人
    phoneInput: "Número de teléfono del destinatario", //收货人电话
    postAddress: "Dirección postal", //快递地址
    pleaseSelect: "Seleccione", //请选择
    PleaseInput: "Entre en", //请输入
    streetAndNumber: "Calle y número de casa", //请输入路名
    postalcode: "Código postal", //请输入邮编
    city: "Ciudad", //请选择城市
    country: "País", //请选择国家
    confirm: "Confirmación", //保存
    Price: "Precio", // 费用
    note: "Nota", //注意事项
    processingTime: "Tiempo de procesamiento", //处理时间
    dataList: "Lista de documentos", //材料清单
    OtherContent: "Más", //其他内容
    ProductDescription: "Descripción del producto", //商品描述
    inventory: "Sin inventario", // 暂无库存
  },
  // 弹框提示确认
  elMessageBoxText: {
    title: "Out to determine", // 退出确定
    releaseTitle: "Out to release", // 退出发布
    desc: "Esta operación implica cerrar la sesión. ¿Quiere continuar?", // 此操作将退出登录, 是否继续?
    MyArticleOpinionDesc: "Esto eliminará el comentario. ¿Quieres continuar?", // 此操作将删除评论, 是否继续?
    delDesc: "Esta operación borrará estos datos. ¿Quieres continuar?", // 此操作将删除该条数据, 是否继续?
    confirmButtonText: "Confirmación", // 确定
    cancelButtonText: "Eliminar", // 取消
    visaText: `"¿Está seguro de que toda la información es correcta? En caso afirmativo, haga clic en "confirmar". Nota: en caso de error, nuestra empresa declina toda responsabilidad.".`,
    releaseDesc:
      "La operación permite salir de la publicación. ¿Quiere continuar?",
    addDesc: "¿Quiere seguir lanzando productos?", // 是否要继续发布商品?
    Confirm: "Confirmación", // 确定
  },
  //支付
  payMent: {
    Payment: "Pagar", //在线支付
    toPayment: "Enviar",
    practiceDetails: "Detalles de la práctica", //订单详情
    type: "Tipo", //产品名称
    OrderDate: "Fecha del pedido", //下单时间
    PracticeNumber: "Número de práctica", //订单编号
    Total: "Total", //订单金额
    PaymentMethod: "Método de pago", //支付方式
    PaymentDetails: "Detalles del pago", //支付信息
    PassengerName: "Nombre del pasajero", //申请人姓名
    Price: "Precio", //上网卡费用
    ExpressFee: "Tarifa exprés", //加急费用
    TotalAll: "Total", //应付金额
    TotalPayAll: "Total", //总金额
    Pay: "Pagar", //确认支付
    CreditCard: "Tarjeta de crédito", //信用卡
    Alipay: "Alipay", //支付宝
    Deposit: "Depósito", //押金
    submit: "Enviar", // 提交
    submit2: "Enviar", // 提交
    DepositNote: "(consultar con la agencia)", //押金提示
    YourRequestIsSubmitted: "La solicitud se realizó", //付款成功
    saveAndPrevious: "Salvar", //确认&上一步
    saveAndOrderInfo: "Confirmar y visualizar", //确认&查看订单
    imgFormat: "Sólo se pueden cargar archivos JPG/PNG.", //确认&上一步
    cancel: "Pago anulado",
  },
  // 支付产品类型
  payMentType: {
    wifiCard: "WI-FI Card", // 上网卡
    businessVisa: "Visado e-Business", // 商务签证
    studyVisa: "Visado de estudios", // 学习签证
    jobVisa: "Visado de trabajo", // 工作签证
  },
  // 留学生协会
  foreignerServiceStation: {
    title: "RECURSOS", // 外国人服务站  英文也需要改：RESOURCES  意大利语：RECURSOS
    allAreas: "Categoría", // 区域分类
  },
  // 美食
  food: {
    MostPopular: "Más populares", //热门场所美食
    RestaurantList: "Lista de restaurantes", //美食种类分类
  },
  //休闲玩乐
  LeisurePlay: {
    leisureplay: "Entretenimiento y diversión", //休闲/玩乐
    youMightAlsoLike: "Te puede interesar", //你可能也喜欢
    time: "Apertura", // 时间
    address: "Dirección：", // 地点
    activityContent: "Contenido：", // 活动内容
  },
  // 论坛
  forum: {
    join: "Participar en el tema", // 参与话题
    recommendedTopics: "Temas recomendados", // 推荐话题
    hotTopics: "Hot Topics", // 热门话题
    followed: "Sigue", // 关注的话题
    favorites: "Favoritos", // 收藏的话题
    more: "Más", // 查看更多
    Comments: "Comentarios", // 条评论
    Comment: "Comentarios", // 条评论
    Reply: "Escribe tu comentario…", // 写下你的评论…
    prevText: "Previous", // 上一页
    nextText: "Siguiente", // 下一页…

    ReleasePost: "Publicar un puesto", // 请添加话题标题
    ReleaseTouchPost: 'Está prohibido publicar "argumentos psíquicos".', // 请添加话题标题
    newTopic: "Añadir un título de tema", // 请添加话题标题
    UploadCove: "Subir cubierta", // 上传封面
    PublishContent: "Publicar comentario", // 发布内容
    submit: "Enviar", //发布
    loginIn: "Iniciar sesión", //立即登录
    follow: "Sigue", //关注
    article: "Artículo", //帖
    concerned: "Seguimiento", //已关注
    other: "Otros temas", //其他推荐
    PleaseSelect: "Seleccione un tema", //请选择话题
    datas: "Por favor, complete los datos", //请完善数据
    successfully: "Publicado con éxito", //发布成功
    Comments: "Comentarios", //条评论
    back: "Atrás", //返回
    reply: "Respuesta", //回复
    NoComment: "Sin comentarios", //暂无评论
    NoAttention: "Sin seguidores", //暂无关注
    NoCollection: "Sin recogida", //暂无收藏
  },
  //书籍
  book: {
    books: "Libros", //书籍
    more: "Más", //更多
    category: "Categoría", //分类
  },
  //住宿
  house: {
    title: "Información", // 宿舍信息
    university: "Info:",
    road: "Calle:",
    houseTitle: "Información", // 房屋信息
    houseIntroduction: "Introducción：", // 房屋简介：
    housing: "Hoteles", //住宿
    serviceApartment: "Hoteles", //酒店式公寓
    allAreas: "Todas las zonas", //区域分类
    city: "Ciudad", //区
    street: "Calle", //街道
    like: "Te puede gustar", //你可能喜欢
    price: "Precio:", //价格
    type: "Tipo:", //类型
    rooms: "Habitaciones:", //房型
    size: "Mq:", //面积
    floor: "Plan:", //楼层
    mainWindowFacing: "Fachada:", //朝向
    district: "Zona:", //区域
    metroStation: "Metro:", //地铁站
  },
  // 二手交易
  secondHand: {
    price: "Precio：", //价格
    newGoods: "Más recientes", //最新发布的商品
    unit: "RMB",
    Description: "Descripción", // 描述
    PickUp: "Retirada de productos", // 取货
    Contact: "Contacto", // 联系
    class: "Nombre de la clasificación", //分类名称
    secondHandgoods: "Liberación de productos de segunda mano", //发布二手商品
    name: "Nombre", // 标题名称
    address: "Dirección de recogida", // 取货地址
    phone: "Contacto", // 联系方式
    price: "Precio", // 价格
    classification: "Clasificación", // 分类
    introductionPicture: "Imagen", // 介绍图片
    details: "Detalles", // 详情
    release: "Publique", // 发布
    cancel: "Eliminar", // 取消
    success: "Añadido con éxito", // 添加成功
    fail: "Fallido", // 添加失败
    rulesCover: "Subir al menos una imagen！", // 请上传封面图
  },
  // 职位
  recruit: {
    Favorite: "Preferido", // 收藏
    Salary: "Salario: ", // 月薪
    CandidateLocation: "Luego: ", // 地点
    MostPopularJob: "Más populares", // 热门职位
    JobType: "Tipo", // 工作分类
    Resumes: "Curriculum", // 精英
    JobProfile: "Perfiles", //职位简介
    CommodityInformation: "Información sobre el producto", //商品信息
  },
  // 旅游攻略
  travel: {
    MostRecent: "Más recientes", // 最新攻略
  },
  // 景点门票
  ticket: {
    HotPlaces: "Lugares de interés", // 热门景点
    RecommendedAttractions: "También le puede interesar", // 推荐景点
    Price: "Precio：", // 售价：
    Classification: "Clasificación", // 分类
  },
  // 医疗
  medical: {
    MostPopular: "Más populares", // 热门推荐
    Phone: "Número de teléfono：", // 联系电话
    Room: "Habitaciones：", // 房间数
    Address: "Dirección：", // 医院地址
    Introduction: " Introducción：", //  医院简介
    suite: "Suite", //  套房
    primaryClassification: "Clasificación primaria", //  二级分类
    secondaryClassification: "Clasificación secundaria", //  二级分类
  },
  //学习/培训
  study: {
    mostPopular: "Más populares", //热门推荐
    education: "Institución", //学习/培训
    introduction: "Introducción al curso：", //课程简介
  },

  // 订单表格配置
  tableConfig: {
    createDate: "Fecha del pedido",
    orderNumber: "Número de práctica.",
    childType: "Tipo",
    enterDate: "Fecha de llegada",
    leaveDate: "Fecha de salida",
    price: "Precio",
    orderStatus: "Status",
    type: "Status",
  },
  // 订单信息
  orderInfo: {
    PracticeInfo: "Info Práctica", // 订单信息
    PostAddress: "Dirección postal", // 订单信息
    CourierNo: "Número de correo", // 快递单号
    Other:
      "Aquí puede descargar la copia del visado electrónico e-Business/grupo", // 其他
    PostAddress: "Dirección postal", // 反馈说明
    FeedbackDescription: "Feedback", // 其他
    label: {
      Type: "Tipo de visado", // 签证种类
      PaxNo: "Número de pasajeros:", // 人数数量
      PurchaseNo: "Número de compra:", // 购买数量
      PracticeNo: "Número de Práctica:", // 订单单号
      PaymentNo: "No. Pago:", // 付款单号
      Price: "Precio:", // 价格
      Discount: "Descuento:", // 折扣
      Status: "Status：", // 订单状态
      MethodOfPayment: "Método de pago:", // 付款方式
      Recipient: "Destinatario：", // 收货人
      PhoneNumber: "Número de teléfono:", // 联系电话
      Address: "Dirección:", // 地址
      ExpressOrderNo: " Número de pedido de mensajería:", // 快递单号
      Time: "Tiempo:", // 时间
      DescriptionContent: "Descripción del contenido:", // 说明内容
    },
    NoLogisticsInformation: "No hay información sobre logística", // 暂无物流信息
    NoInformation: "No hay información", // 暂无
    NoFeedbackDescription: "Sin comentarios", // 暂无反馈说明
    CheckTheLogistics: "Logística de control", // 查看物流
    Download: "Descargar", // 查看物流
    continue: "Sigue",
  },
  orderStatus: [
    { value: 0, label: " No pagado " },
    { value: 1, label: " No pagado " },
    { value: 2, label: " En revisión " },
    { value: 3, label: " Completado " },
    { value: 4, label: " Rechazado " },
    { value: 5, label: " Cancelado " },
    { value: 6, label: " Pagado" },
    { value: 7, label: " Reembolsado" },
  ],
};
