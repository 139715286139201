<template>
  <div class="content-wrap">
    <div class="content">
      <div class="container">
          <div class="empty-content" v-if=" commonList.length == 0">
            <el-empty :image-size="200"></el-empty>
          </div>
          <div v-else v-for="(item, index) in commonList" :key="index" class="list-wrap">
            <div class="list" @click="$router.push({path:'/forumDetails',query: {id:item.id}})">
              <img :src="item.comImage[0]" />        
              <div class="right">
                <div class="name">{{JSON.parse(item.title)[$i18n.locale]}}</div>
                <div class="desc">{{JSON.parse(item.description)[$i18n.locale]}}</div>
                <div class="box-bottom">
                    <CountUser :msg="item.comComments" :user="item.comPeople" />
                    <div class="form-style">
                      <el-button @click.stop="unFollow(item.id, index)">{{$t('common.cancelCollection')}}</el-button>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <Page class="page-wrap" :total="total" :page-size="common.pageSize" @change-page="changePage" />
  </div>
</template>
<script>
import Page from '@/components/page.vue';
import CountUser from "@/components/CountUser/CountUser";
import {reactive, toRefs} from 'vue';
import api from "@/comm/api";
import { ElMessage } from "element-plus";
import { useI18n } from 'vue-i18n'//要在js中使用国际化
export default {
  components: { Page,CountUser },
  name: "Four",
  setup() {
    const { t } = useI18n();
    const data = reactive({
        commonList: [],
        total: 0,
        common: { page:1, pageSize: 6,search: {} },
    });    

    // 获取[已关注论坛]数据
    const getData = (() => {
        api.myFavoriteArticle(data.common).then((res) => {
            if(res) {
                data.total = res.total;
                res.records.forEach(item => {
                    item.comImage = item.images.split(',');
                    item.comComments = item.comments;
                    item.comPeople = item.people;
                });
                data.commonList = res.records;
            }
        })
    })

    // 取消收藏
    const unFollow = ((id,i) => {
      api.articleUpdate({    
        aimId: id,
        topicId:data.commonList[i].topicId,
        collection: false
      }).then((res) => {
        if (res) {
          ElMessage({
            message: t("common.cancelSuccess"),
            type: "success"
          });
          getData();
        }
      })
    })

    // 根据页码改变列表数据
    const changePage = ((e) => {
      data.common.page = e;
      getData();
    })

    return {
        ...toRefs(data),
        getData,
        changePage,
        unFollow
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  
  min-height: 518px;
}
.container {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 26px;
  box-sizing: border-box;
}
.list-wrap {
  width: 50%;
  height: 170px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  cursor: pointer;
}
.list {
  width: 479px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.15);
  padding: 18px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  img {
    width: 128px;
    height: 130px;
    margin-right: 16px;
  }
}
.right{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name {
  font-size: 24px;
  font-weight: 600;
  color: #101010;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-sizing: border-box;
}
.desc {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-sizing: border-box;
}
.box-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.page-wrap {
    margin-top: 40px;
}
</style>