<template>
  <div class="page-wrap">
    <top-search-three
      :backgroundColor="bannerList.length == 0 ? $store.state.navBgColor : ''"
      :advertises="advertises"
    />
    <div :class="className" class="recruit">
      <Carousel :list="bannerList" />
      <div class="recruit-wrap">
        <div class="recruit-title">{{ $t("ticket.HotPlaces") }}</div>
        <!--热门景点-->
        <hot-ticket
          :hot-list="hotList"
          @change-data="changeData"
          @go-details="goDetails"
        />
        <!--分类-->
        <div class="select-class">
          <span>{{ $t("ticket.Classification") }}</span>
          <el-select
            v-model="table.search.classification"
            :placeholder="$t('register.PleaseSelect')"
            clearable
            @change="selectClick"
          >
            <el-option
              v-for="item in allAreas"
              :key="item.id"
              :label="item.title ? item.title[$i18n.locale] : ''"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <!--常规景点-->
        <div class="ticket-list2">
          <div
            v-for="(item, index) in list"
            @click="goDetails(item.id)"
            :key="index"
            class="info"
          >
            <FavoriteCard
              @click.stop="collect(item, index)"
              :favorite="item.favorite"
            />
            <img :src="item.faceImage" />
            <div class="right">
              <div class="title">
                {{ JSON.parse(item.title)[$i18n.locale] }}
              </div>
              <div class="desc">
                {{ JSON.parse(item.introduction)[$i18n.locale] }}
              </div>
              <div class="money">
                {{ $t("ticket.Price") }}
                <span v-if="JSON.parse(item.price).ZH != ''">
                  <span>{{ JSON.parse(item.price).ZH }}</span>
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </div>
        </div>

        <!--更多景点列表-->
        <div class="more">
          <page
            :total="total"
            :page-size="table.pageSize"
            @change-page="changePage"
          ></page>
        </div>
      </div>
    </div>
    <contact-us-one background="#fff"></contact-us-one>
  </div>
</template>

<script>
import topSearchThree from "../home/topSearchThree.vue";
import contactUsOne from "../home/contactUsOne.vue";
import { onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import api from "@/comm/api";
import Carousel from "@/components/Carousel/Carousel";
import FavoriteCard from "@/components/FavoriteCard/FavoriteCard.vue";
import Page from "@/components/page";
import Macy from "./ChildComp/Macy";
import HotTicket from "./ChildComp/HotTicket";
import { useStore } from "vuex";
import util from "@/comm/util";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  components: {
    HotTicket,
    Macy,
    Carousel,
    Page,
    topSearchThree,
    contactUsOne,
    FavoriteCard,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const data = reactive({
      bannerList: [], //banner列表
      advertises: [], //广告位
      hotList: [], //热门景点
      list: [], //景点列表
      allAreas: [],
    });
    const params = reactive({
      total: 0,
      table: { page: 1, pageSize: 4, search: { classification: "" } },
    });

    //获取热门景点
    const hotListGet = async () => {
      data.hotList = await api.etHotList();
    };
    //获取景点分类
    const sceneryClassGet = async () => {
      data.allAreas = await api.sceneryClass();
      data.allAreas.map((val) => {
        val.title = JSON.parse(val.title);
      });
    };

    //获取景点列表
    const sceneryListGet = async () => {
      await api.sceneryList(params.table).then((res) => {
        params.total = res.total;
        data.list = res.records;
      });
    };
    //搜索
    const selectClick = () => {
      sceneryListGet();
    };

    // 改变景点列表的页码
    const changePage = (e) => {
      params.table.page = e;
      sceneryListGet();
    };

    // 获取轮播列表
    const getBanners = async () => {
      data.bannerList = await api.getBanner({ type: "ATTRACTIONS" });
    };

    // 收藏
    const collect = (val, i) => {
      let favorite = val.favorite ? false : true;
      api
        .sceneryFavorite({
          sceneryId: val.id,
          favorite,
        })
        .then((res) => {
          if (res) {
            ElMessage({
              message: favorite
                ? t("common.collectSuccess")
                : t("common.cancelSuccess"),
              type: "success",
            });
            changeData();
          }
        });
    };

    //刷新热门景点 景点列表
    const changeData = () => {
      hotListGet();
      sceneryListGet();
    };

    // 去详情页面
    const goDetails = (id) => {
      router.push({ path: "/ticketInfo", query: { id } });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getAdvertisesData", 8); // 获取广告位 图
      getBanners(); // 轮播
      hotListGet(); //获取热门景点
      sceneryClassGet(); //景点分类
      sceneryListGet(); //景点列表
    });

    watchEffect(() => {
      // 获取广告位
      data.advertises = store.state.advertisesList;
      // 获取头部className
      data.className = util.changeClassName(
        data.advertises.length,
        data.bannerList.length
      );
    });

    return {
      ...toRefs(data),
      ...toRefs(params),
      collect,
      goDetails, //详情
      changeData, //子页面刷新
      selectClick, //搜索
      changePage, //翻页
    };
  },
};
</script>

<style scoped lang="scss">
.page-wrap {
  background-color: #fff;
}

.nav-top-mt {
  margin-top: 236px;
}

.recruit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recruit-wrap {
  width: 70%;
}

.recruit-title {
  height: 64px;
  font-size: 44px;
  font-weight: 500;
  text-align: center;
  color: #444444;
  margin: 80px 0 14px 0;
}

//.ticket-list {
//  display: flex;
//  justify-content: space-between;
//  flex-wrap: wrap;
//}
//
//.ticket-list-item {
//  width: 305px;
//  border-radius: 16px;
//  margin-bottom: 40px;
//  cursor: pointer;
//  position: relative;
//
//  img {
//    width: 100%;
//    height: 235px;
//  }
//
//  .collect-img {
//    width: 36px;
//    height: auto;
//    position: absolute;
//    top: 20px;
//    right: 20px;
//  }
//}
//
//.list-title {
//  margin: 31px 0 8px 0;
//  font-size: 24px;
//  color: #333333;
//  margin: 20px 0;
//}
//
//.list-price {
//  font-size: 20px;
//  color: #f49d44;
//}

.more {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 29px 0;
}
.ticket-list2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .info {
    width: 1321px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 38px 110px 38px 40px;
    position: relative;
    cursor: pointer;
    .fixed {
      position: absolute;
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 15px 0;
      box-sizing: border-box;
    }
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
    .money {
      font-size: 20px;
      font-weight: 600;
      color: #f49d44;
    }
    .desc {
      width: 595px;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      color: #666666;
      line-height: 35px;
      opacity: 0.7;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      overflow: hidden;
    }
    img {
      width: 506px;
      height: 390px;
      border-radius: 24px;
      margin-right: 50px;
    }
  }
}

// 分类
.select-class {
  font-size: 26px;
  color: #444444;
  width: 100%;
  //margin-top: 45px;
  margin: 106px 0 25px 0;

  :deep.el-select {
    width: 314px;
    height: 80px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    background: #ffffff;
    border: 1px solid #f5ad34;
    border-radius: 22px;
    padding: 20px;
    box-sizing: border-box;
  }

  :deep .el-select-dropdown__item {
    font-size: 24px;
    color: #333333;
    font-weight: 500 !important;
  }

  :deep .el-input__inner {
    font-size: 24px;
    color: #333333;
    border: 1px solid #b7babe;
    padding: 24px 32px;
    margin-left: 25px;
  }

  :deep .el-input__inner::placeholder {
    font-size: 24px;
  }

  :deep .el-input__suffix {
    margin-top: 4px;
  }
}
</style>
